import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { Match } from "../../types/Match";

export const LastFiveMatches: FC = () => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);

  const [matches, setMatches] = useState<Match[] | null>(null);
  const [emptySlots, setEmptySlots] = useState<number>(5);

  useEffect(() => {
    // Get last 5 results
    if (gameData.tournaments != null && gameData.matches) {
      let tournament = gameData.tournaments.find((t) => t.active);
      if (tournament != null) {
        let tmpMatches = gameData.matches[tournament.id];
        //console.log(tmpMatches);
        let numRemaining = 5;
        if (tmpMatches != null && tmpMatches.length >= 1) {
          let numMatches = tmpMatches.slice(0, 5);
          numRemaining = numRemaining - numMatches.length;
          setMatches(numMatches);
        }
        //console.log("EMPTY SLOTS: " + numRemaining);
        setEmptySlots(numRemaining);
      }
    }
  }, [gameData.tournaments, gameData.matches]);

  const getTeamName = (teamId: number): string => {
    if (gameData.userTeams != null) {
      let team = gameData.userTeams[teamId];
      if (team != null) {
        return team.name;
      }
    }
    if (gameData.opponentTeams) {
      let team = gameData.opponentTeams[teamId];
      if (team != null) {
        return team.name;
      }
    }
    return "";
  };

  const getWinClassName = (side: string, match: Match): string => {
    if (match.homescore == match.awayscore) {
      return "win";
    }
    if (side == "home") {
      return match.homescore > match.awayscore ? "win" : "";
    }
    if (side == "away") {
      return match.awayscore > match.homescore ? "win" : "";
    }
    return "win";
  };

  const getResultTime = (date: Date): string => {
    let time = moment(date);
    return time.format("llll");
  };

  const renderEmptySlots = (): JSX.Element[] | null => {
    if (!emptySlots) {
      return null;
    }
    var elements: JSX.Element[] = [];
    for (let i = 0; i < emptySlots; i++) {
      elements.push(
        <li
          className="list-group-item"
          style={{
            backgroundColor: "#001636",
            color: "#fff",
            fontSize: "12px",
          }}
        >
          <span> --- </span>
        </li>
      );
    }
    return elements;
  };

  return (
    <div className="col-md-12 mt-5">
      <div
        className="card"
        style={{
          width: "100%",
          backgroundColor: "#000b1d",
          color: "#fff",
          border: "1px solid #99ff32",
        }}
      >
        <div className="card-body">
          <h5 className="card-title">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ marginRight: "10px" }}
                src={"/images/results-icon.svg"}
                width="42"
                height="42"
                alt="Vintage Niftys Boost Results"
              />
              Last 5 Games
            </div>
          </h5>
        </div>
        <ul className="list-group list-group-flush">
          {matches != null &&
            matches.map((value, index) => (
              <li
                className="list-group-item"
                style={{
                  backgroundColor: "#001636",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                <span className={getWinClassName("home", value)}>
                  {getTeamName(value.hometeam_id)}
                </span>{" "}
                {value.homescore}-{value.awayscore}{" "}
                <span className={getWinClassName("away", value)}>
                  {getTeamName(value.awayteam_id)}
                </span>
                {" - "}
                <span style={{ fontSize: "12px" }}>
                  {getResultTime(value.created)}
                </span>
              </li>
            ))}
          {emptySlots != null && renderEmptySlots()}
        </ul>
      </div>
    </div>
  );
};
