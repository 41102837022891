import { TransactionResult } from "../../types/TransactionResult";
import * as actionTypes from './types';


export const setSaveTeamResult = (data: TransactionResult) =>{
   
    return{
        type: actionTypes.SAVE_TEAM_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const setSaveTeamError = (error: string) =>{
    return{
        type: actionTypes.ERROR_CREATE_TEAM,
        error: error,
        isFetchig: false 
    };
}

export const setDeleteTeamResult = (data: TransactionResult) =>{
   
    return{
        type: actionTypes.DELETE_TEAM_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const setEnterTournoResult = (data: TransactionResult) =>{
   
    return{
        type: actionTypes.ENTER_TOURNAMENT_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const setStartMatchResult = (data: TransactionResult) =>{
    return{
        type: actionTypes.START_MATCH_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};