import { Alert, Button, Snackbar } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { HtmlTooltip } from "../utils/toolTip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import { teamColumnsNew, TeamsNoRowsOverlay } from "../utils/teamGrid";
import { useNavigate } from "react-router-dom";
import { CustomPagination } from "../../types/Grid";
import {
  FORMATION_3142,
  FORMATION_343,
  FORMATION_352,
  FORMATION_4321,
  FORMATION_433,
  FORMATION_4411,
  FORMATION_442,
  FORMATION_442_DIAMOND,
  FORMATION_451,
  FORMATION_532,
  FORMATION_541,
  TEAMSETUP_ROUTE,
} from "../../utils/constants";
import { Team } from "../../types/Team";
import {
  calculateTeamAttributeValues,
  TotalAttributeScoreData,
} from "../../utils/calculateTeamTotal";
import { MyTeam4411 } from "../formations/myteam/4411";
import { MyTeam433 } from "../formations/myteam/433";
import { MyTeam352 } from "../formations/myteam/352";
import { MyTeam4321 } from "../formations/myteam/4321";
import { MyTeam3142 } from "../formations/myteam/3142";
import { MyTeam541 } from "../formations/myteam/541";
import { MyTeam451 } from "../formations/myteam/451";
import { MyTeam442Diamond } from "../formations/myteam/442Diamond";
import { MyTeam442 } from "../formations/myteam/442";
import { MyTeam343 } from "../formations/myteam/343";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";

export const MyTeams: FC = () => {
  const { data: appConfig } = useSelector(
    (state: RootState) => state.appConfig
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [myTeam, setMyTeam] = useState<Team | null>(null);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let activeTeam: Team | null = null;

    // find what team user has in current tournament and use that
    if (gameData.tournamentPositions != null) {
      let pos = gameData.tournamentPositions.find(
        (t) => t.manager === userData.userName
      );
      if (pos != null && gameData.userTeams != null) {
        activeTeam = gameData.userTeams[pos.teamId];
        setMyTeam(activeTeam);
        return;
      }
    }
    // otherwise choose team with highest tav
    if (gameData.userTeams != null) {
      let teamScores: { [teamId: number]: number } = {};
      for (let teamId in gameData.userTeams) {
        let team = gameData.userTeams[teamId];
        let tactic = gameData.tactics![team.tacticId];
        let scores = calculateTeamScores(tactic.desc, team.lineup);
        teamScores[teamId] = scores == null ? 0 : scores.totalScore;
      }
      var scoreEntries = Object.entries(teamScores);
      if (scoreEntries.length > 0) {
        var sortedArray = scoreEntries.sort(([, a], [, b]) => b - a);
        var firstEntry = sortedArray[0];
        activeTeam = gameData.userTeams[parseInt(firstEntry[0])];
        setMyTeam(activeTeam);
      }
    }
  }, [gameData]);

  const calculateTeamScores = (
    formationDesc: string,
    tempLineup: number[]
  ): TotalAttributeScoreData | null => {
    if (assetData.assets != null && gameData.players != null) {
      let scores = calculateTeamAttributeValues(
        gameData.players,
        assetData.assets,
        formationDesc,
        tempLineup
      );
      return scores;
    }
    return null;
  };

  const renderTeam = (team: Team | null) => {
    if (team == null) {
      return (
        <div
          className="col-xxl-4 col-xl-6  col-lg-6 col-md-12 team-dash"
          style={{
            padding: "5px",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "14px" }}>You don't have any teams</span>
        </div>
      );
    }
    let tactic = gameData.tactics![team.tacticId];
    switch (tactic.desc) {
      case FORMATION_343:
        return <MyTeam343 team={team} />;
      case FORMATION_352:
        return <MyTeam352 team={team} />;
      case FORMATION_433:
        return <MyTeam433 team={team} />;
      case FORMATION_442:
        return <MyTeam442 team={team} />;
      case FORMATION_442_DIAMOND:
        return <MyTeam442Diamond team={team} />;
      case FORMATION_451:
        return <MyTeam451 team={team} />;
      case FORMATION_532:
        return <MyTeam352 team={team} />;
      case FORMATION_541:
        return <MyTeam541 team={team} />;
      case FORMATION_3142:
        return <MyTeam3142 team={team} />;
      case FORMATION_4321:
        return <MyTeam4321 team={team} />;
      case FORMATION_4411:
        return <MyTeam4411 team={team} />;
      default:
        return <MyTeam442 team={team} />;
    }
  };

  // TODO - show error dialog when creating > max teams
  const addNewTeam = () => {
    // We limit users to 5 teams max - no need to create more (this is just for performance reasons and
    // also do users really need 5 teams).
    if (gameData.userTeams != null) {
      let numTeams = Object.keys(gameData.userTeams).length;
      if (numTeams == appConfig.maxTeams) {
        setShowError(true);
        return;
      }
    }

    navigate(TEAMSETUP_ROUTE);
  };

  const handleClose = () => {
    setShowError(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert variant="filled" onClose={handleClose} severity="error">
          You can only create {appConfig.maxTeams} teams.
        </Alert>
      </Snackbar>
      <div className="col-md-12 mt-5">
        <div
          className="card"
          style={{
            width: "100%",
            backgroundColor: "#000b1d",
            color: "#fff",
            border: "1px solid #99ff32",
          }}
        >
          <div className="card-body">
            <h5 className="card-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    id="teams"
                    style={{ marginRight: "10px" }}
                    src={"/images/teams-icon.svg"}
                    width="42"
                    height="42"
                    alt="Vintage Niftys Boost Team"
                  />
                  My Teams (
                  {gameData.userTeams == null
                    ? 0
                    : Object.keys(gameData.userTeams).length}
                  )
                  {/* <HtmlTooltip
                  arrow
                  placement="right"
                  title={
                    <div style={{ width: "400px" }}>
                      This shows all your current teams (you can only create{" "}
                      {appConfig.maxTeams}). <br />
                      <br />
                      <b>TAV (Total Attribute Value)</b>- Is the sum of all your
                      players TAV scores including chemistry multipliers.
                      <br />
                      <br />
                      <b>Locked</b>- You can only join a tournament if you have
                      locked your team. Once you have joined a tournament and
                      the team is locked you can play matches but you won't be
                      able to edit the team. Unlock the team to edit the lineup.
                      When a team is unlocked you can't play matches nor can
                      opponents play you (think of it as a timeout!)
                    </div>
                  }
                >
                  <HelpOutlineIcon fontSize="small" color="secondary" />
                </HtmlTooltip> */}
                </div>
                <Button
                  className="green-btn"
                  variant="contained"
                  style={{ display: "flex" }}
                  startIcon={<AddIcon />}
                  onClick={addNewTeam}
                >
                  Add
                </Button>
              </div>
            </h5>
          </div>

          {renderTeam(myTeam)}
          {/* <DataGrid
            headerHeight={25}
            rows={teamRows}
            columns={teamColumnsNew}
            rowHeight={45}
            hideFooterSelectedRowCount={true}
            pageSize={20}
            pagination
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: TeamsNoRowsOverlay,
            }}
            rowsPerPageOptions={[5]}
          /> */}

          {/* Add my teams data grid - but reduced - just name, edit team
                    and add new team */}
        </div>
      </div>
    </>
  );
};
