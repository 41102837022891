import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { GameData } from "../types/GameData";


export const getUpgradeCost= (asset : IAsset, gameData : GameData) : number =>{
    try{
        if(gameData.cardConfig){
            const cardConfig = gameData.cardConfig[parseInt(asset.template?.template_id!)];
            if(cardConfig){
                const value = (cardConfig.baseTav/10)*Math.pow(1.15,parseInt(asset.data.Level))+50;
                return value;
            }   
        }

    }
    catch(e:any){
        console.log(e);
        throw e;
    }
    throw new Error('The upgrade cost could not be determined');
}