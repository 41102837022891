import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/reducers";
import {
  ADMIN_ROUTE,
  CARDS_ROUTE,
  DASHBOARD_ROUTE,
  LEAGUETABLE_ROUTE,
  MARKETPLACE_ROUTE,
  MYPLAYERS_ROUTE,
  MYTEAMS_ROUTE,
  VINTYNIFTYS_ACCOUNT,
  TOURNAMENTS_ROUTE,
} from "../../utils/constants";
import SettingsIcon from "@mui/icons-material/Settings";

interface NavBarProps {
  selectedMenuItem: string;
  onHelp?(page: string): void;
}

export const NavBar: FC<NavBarProps> = ({ selectedMenuItem, onHelp }) => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );

  const navigatePage = (e: React.MouseEvent, page: string) => {
    e.preventDefault();
    if (page === selectedMenuItem) {
      if (page != MYTEAMS_ROUTE) {
        return;
      }
    }
    navigate(page);
  };

  // If admin then show admin page
  useEffect(() => {
    if (userData != null) {
      setIsAdmin(userData.account == VINTYNIFTYS_ACCOUNT);
    }
  }, [userData]);

  const getClassName = (page: string): string => {
    return page === selectedMenuItem ? "nav-link navlinkSelected" : "nav-link";
  };

  const startHelp = (e: React.MouseEvent) => {
    // stop the url link
    e.preventDefault();
    // cancel event bubble up the dom tree
    // for some reason if we omit this, the driver js code does not run.
    e.stopPropagation();
    if (onHelp) {
      onHelp(selectedMenuItem);
    }
  };

  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav mt-3">
            <a
              className={getClassName(DASHBOARD_ROUTE)}
              onClick={(e) => navigatePage(e, DASHBOARD_ROUTE)}
            >
              <div className="sb-nav-link-icon">
                <img src={"/images/dashboard icon.svg"} width="30" alt="" />
              </div>
              Dashboard
            </a>
            <a
              className={getClassName(MYTEAMS_ROUTE)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
              onClick={(e) => navigatePage(e, MYTEAMS_ROUTE)}
            >
              <div className="sb-nav-link-icon">
                <img src={"/images/teams-icon.svg"} width="30" alt="" />
              </div>
              My Teams
            </a>
            <a
              className={getClassName(MYPLAYERS_ROUTE)}
              onClick={(e) => navigatePage(e, MYPLAYERS_ROUTE)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <img src={"/images/players-icon.svg"} width="30" alt="" />
              </div>
              My Players
            </a>
            {/* <a
              className={getClassName(TOURNAMENTS_ROUTE)}
              onClick={(e) => navigatePage(e, TOURNAMENTS_ROUTE)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <img src={"/images/tourno-icon.svg"} width="30" alt="" />
              </div>
              Tournaments
            </a> */}
            <a
              className={getClassName(LEAGUETABLE_ROUTE)}
              onClick={(e) => navigatePage(e, LEAGUETABLE_ROUTE)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <img src={"/images/Legends-icon.svg"} width="30" alt="" />
              </div>
              League Table
            </a>
            <a
              className={getClassName(MARKETPLACE_ROUTE)}
              onClick={(e) => navigatePage(e, MARKETPLACE_ROUTE)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <img src="/images/NB icon.svg" width="30" alt="" />
              </div>
              Marketplace
            </a>
            <a
              className={getClassName(CARDS_ROUTE)}
              onClick={(e) => navigatePage(e, CARDS_ROUTE)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <img src="/images/Card Values icon.svg" width="30" alt="" />
              </div>
              Card Values
            </a>
            <div
              className="nav-link"
              onClick={(e) => startHelp(e)}
              aria-expanded="false"
              aria-controls="collapseLayouts"
            >
              <div className="sb-nav-link-icon">
                <img src="/images/Help icon.svg" width="30" alt="" />
              </div>
              Help
            </div>

            {isAdmin && (
              <a
                className={getClassName(ADMIN_ROUTE)}
                onClick={(e) => navigatePage(e, ADMIN_ROUTE)}
                aria-expanded="false"
                aria-controls="collapseLayouts"
              >
                <div className="sb-nav-link-icon">
                  <SettingsIcon color="secondary" />
                </div>
                Admin
              </a>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};
