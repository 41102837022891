import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Templates } from "../../inventory";
import { RootState } from "../../store/reducers";
import { getKeyFrames } from "../../utils/Theme";
import CloseIcon from "@mui/icons-material/Close";
import { useDrag, useDrop } from "react-dnd";
import { DragItemTypes } from "../../utils/constants";
import type { Identifier, XYCoord } from "dnd-core";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { HtmlTooltip } from "../utils/toolTip";
import { calculatePlayerFitnessPercentage } from "../../utils/calculateFitness";
import { Box } from "@mui/system";

export interface PlayerDetailsProps {
  useSmallBox?: boolean;
  index: number;
  position: string;
  assetId: number;
  swapped?: boolean;
  chemistry: number;
  isTeamLocked: boolean;
  onPlayerHover(
    index: number,
    asset: IAsset,
    position: string,
    outOfPosition: boolean
  ): void;
  onInvalidPlayer?(asset: IAsset): void;
  onPlayerRemove(asset: IAsset): void;
  onPlayerMove(dragIndex: number, hoverIndex: number): void;
  onPlayerAdd(index: number, position: string): void;
}

export interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const PlayerDetails: FC<PlayerDetailsProps> = ({
  index,
  position,
  assetId,
  chemistry,
  isTeamLocked,
  onPlayerHover,
  onInvalidPlayer,
  onPlayerRemove,
  onPlayerMove,
  onPlayerAdd,
  useSmallBox = false,
}) => {
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [asset, setAsset] = useState<IAsset | null>(null);
  const [fitnessPercent, setFitnessPercent] = useState(0);
  const [inWrongPosition, setInWrongPosition] = useState(false);
  const [playerRemoved, setPlayerRemoved] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // DRAG
  const [{ isDragging }, drag] = useDrag({
    type: DragItemTypes.PLAYER,
    item: () => {
      return { assetId, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // DROP
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragItemTypes.PLAYER,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem, monitor) {
      if (isTeamLocked) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      onPlayerMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //item.index = hoverIndex;

      //console.log(dragIndex + ' ' + hoverIndex);
    },
  });

  useEffect(() => {
    /*
            TODO - here we check if the asset still belongs to the user 
            IF NOT - then we show in red border and we need to check this when we challenge an opponent.
        */

    if (assetData.assets != null) {
      let tmpAsset = assetData.assets[assetId];
      setAsset(tmpAsset);
      //console.log(tmpAsset);

      // check if player is in the incorrect position, show orange border
      let tmpWrongPosition = false;
      if (tmpAsset != null) {
        tmpWrongPosition = tmpAsset.mutable_data.Position != position;
        setInWrongPosition(tmpWrongPosition);
      } else {
        // so....player does not exist in users wallet, so show red icon - need to test this.
        // TODO: NOEL FIX
      }

      // if (tmpAsset != null) {
      //   if (playerRemoved) {
      //     console.log("PLAYER ADDED TO " + index);
      //     onPlayerHover(index, tmpAsset, position, tmpWrongPosition);
      //     setPlayerRemoved(false);
      //   }
      // }
    }
    let handle: NodeJS.Timer | null = null;
    if (gameData.players != null) {
      const player = gameData.players[assetId];

      if (player != null) {
        const fitness = player.full_fitness_time;

        calcFitnessPercentage(fitness);
        if (fitness > 0) {
          handle = setInterval(() => {
            calcFitnessPercentage(fitness);
          }, 5000);
        }
      } else {
        // here the player has yet to be added to a team and doesn't exist in the players table.
        // So default to 100%
        calcFitnessPercentage(0);
      }
    } else {
      // here the player has yet to be added to a team and doesn't exist in the players table.
      // So default to 100%
      calcFitnessPercentage(0);
    }

    return () => {
      if (handle != null) {
        clearInterval(handle);
      }
    };
  }, [assetId, assetData.assets]);

  const calcFitnessPercentage = (fitness: number) => {
    let percentFitness = calculatePlayerFitnessPercentage(fitness);
    return setFitnessPercent(percentFitness);
  };

  const getImagePath = (asset: IAsset): string => {
    return "images/" + Templates[asset.template!.template_id].imgPath;
  };

  const removePlayer = () => {
    if (isTeamLocked) {
      return;
    }
    if (asset != null) {
      // now flag this instance
      setPlayerRemoved(true);
      onPlayerRemove(asset);
    }
  };

  drag(drop(ref));

  const getChemistryBorderColour = () => {
    if (chemistry > 1 && chemistry < 1.2) {
      return "#b8c2cc";
    }
    if (chemistry >= 1.2 && chemistry < 1.5) {
      return "#4dc0b5";
    }
    if (chemistry >= 1.5 && chemistry < 2) {
      return "#38c172";
    }
    if (chemistry > 2 && chemistry < 2.5) {
      return "#ffed4a";
    }
    if (chemistry >= 2.5 && chemistry < 3) {
      return "#f6993f";
    }
    if (chemistry >= 3) {
      return "#e3342f";
    }
  };

  const chemistryStyle = {
    border: "2px solid " + getChemistryBorderColour(),
    boxShadow: "0px 0px 20px 1px " + getChemistryBorderColour(),
  };

  const chemistryStyleWithShadow = {
    border: "2px solid " + getChemistryBorderColour(),
    boxShadow: "0px 0px 20px 1px " + getChemistryBorderColour(),
    WebkitBoxShadow: "0px 0px 20px 1px " + getChemistryBorderColour(),
    MozBoxShadow: "0px 0px 20px 1px " + getChemistryBorderColour(),
  };

  const getChemistryStyle = () => {
    if (chemistry >= 1.4) {
      return chemistryStyleWithShadow;
    }
    return chemistryStyle;
  };

  // JSX.Element
  const renderDetails = (): JSX.Element => {
    if (asset != null) {
      return (
        <div
          className={useSmallBox ? "card-box-sm" : "card-box"}
          style={getChemistryStyle()}
        >
          <div className="icon-box">
            <div style={{ marginTop: "-2px" }}>
              {inWrongPosition ? (
                <HtmlTooltip
                  arrow
                  placement="top"
                  title={
                    <div style={{ width: "100px" }}>
                      This player is out of position and will incur a 10% stat
                      reduction
                    </div>
                  }
                >
                  <FmdBadIcon fontSize="small" sx={{ color: "orange" }} />
                </HtmlTooltip>
              ) : (
                <Box sx={{ width: "16px" }}></Box>
              )}
            </div>
            <div
              className="flip-box"
              ref={ref}
              style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isTeamLocked ? "default" : "move",
              }}
              data-handler-id={handlerId}
            >
              <div className="flip-box-inner">
                <div
                  className="flip-box-front"
                  onClick={() =>
                    onPlayerHover(index, asset!, position, inWrongPosition)
                  }
                >
                  <img src={getImagePath(asset)} alt={asset.name} />
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  cursor: isTeamLocked ? "default" : "pointer",
                  marginTop: "-5px",
                }}
                onClick={removePlayer}
              >
                <CloseIcon color="secondary" fontSize="small" />
              </div>
            </div>
          </div>
          <p>
            <Link
              onClick={() => {
                onPlayerHover(index, asset!, position, inWrongPosition);
              }}
            >
              {asset.name}
              <span style={{ display: "block" }}>{asset.data.Rarity}</span>
            </Link>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "-5px",
              justifyContent: "center",
            }}
          >
            <Grid container width={"80px"}>
              <Grid xs item>
                <LinearProgress
                  variant="determinate"
                  value={fitnessPercent}
                  sx={{
                    height: 5,
                    borderRadius: 60,
                    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                    "& .MuiLinearProgress-bar1Determinate": {
                      animation:
                        getKeyFrames(fitnessPercent) + " 3s normal forwards",
                      ...(fitnessPercent <= 25 && {
                        background:
                          "linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(177, 0, 0, 1) 100%)",
                      }),
                      ...(fitnessPercent > 25 &&
                        fitnessPercent <= 50 && {
                          background:
                            "linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 183, 26, 1) 50%)",
                        }),
                      ...(fitnessPercent > 50 &&
                        fitnessPercent <= 75 && {
                          background:
                            "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                        }),
                      ...(fitnessPercent > 75 && {
                        background:
                          "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                      }),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <span>Fitness {fitnessPercent}%</span>
        </div>
      );
    } else {
      return (
        <div
          className="card-box"
          style={{
            height: "115px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="icon-box" ref={ref} data-handler-id={handlerId}>
            <div className="flip-box">
              <div className="flip-box-inner">
                <div
                  className="flip-box-front"
                  onClick={() => onPlayerAdd(index, position)}
                >
                  <img src="images/Add-player.png" alt="" />
                </div>
              </div>
            </div>
            {/* <div className="flip-box-inner">
              <div className="flip-box-front">
                <img src="images/Add-player.png" alt="" />
              </div>
            </div> */}
          </div>
        </div>
      );
    }
  };

  return <div className="player-column">{renderDetails()}</div>;
};
