import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';
import { GameData } from "../../types/GameData";
import { Player } from "../../types/Player";

const initialState: InitialState<GameData>= {
    isFetching: true,
    error: undefined,
    data:{
        userTeams:  undefined,
        opponentTeams: undefined,
        tactics: undefined,
        players: undefined,
        matches: undefined,
        teamPlayers: undefined
    }
};

export const gameData = (
    state = initialState,
    action: ReducerAction<GameData>
)=>{
    switch(action.type){
        case actionTypes.REQUEST_USER_TEAMS:
        case actionTypes.REQUEST_ALL_USERS:
        case actionTypes.REQUEST_TACTICS:
        case actionTypes.REQUEST_PLAYERS:
        case actionTypes.REQUEST_MATCHES:
        case actionTypes.REQUEST_TOURNAMENTS:
        case actionTypes.REQUEST_TOURNAMENT_BOARD:
        case actionTypes.REQUEST_TOURNAMENT_ENTER:
        case actionTypes.REQUEST_STAKED_PLAYERS:
        case actionTypes.REQUEST_CARDS_CONFIG:
            return{
                ...state,
                error: undefined,
                isFetching: true
            };
        case actionTypes.RECEIVE_USER_TEAMS:
            return{
                ...state,
                data:{
                    ...state.data,
                    userTeams: action.payload.data.userTeams,
                    opponentTeams: action.payload.data.opponentTeams,
                    teamPlayers: action.payload.data.teamPlayers
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.UPDATE_TEAM_READYSTATUS:
            return{
                ...state,
                data:{
                    ...state.data,
                    userTeams:{
                        ...state.data.userTeams,
                        [Object.keys(action.payload.data.userTeams!)[0]] : Object.values(action.payload.data.userTeams!)[0]
                    }
                }
            };
        case actionTypes.RECEIVE_TACTICS:
            return{
                ...state,
                data:{
                    ...state.data,
                    tactics: action.payload.data.tactics,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_PLAYERS:
            return{
                ...state,
                data:{
                    ...state.data,
                    players: action.payload.data.players,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_SINGLE_PLAYER:
            return{
                ...state,
                data:{
                    ...state.data,
                    players: {
                        ...state.data.players,
                        [Object.keys(action.payload.data.players!)[0]] : Object.values(action.payload.data.players!)[0]
                    }
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_STAKED_PLAYERS:
            return{
                ...state,
                data:{
                    ...state.data,
                    players: updatePlayers(state.data.players!, action.payload.data.players!)
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_MATCHES:
            return{
                ...state,
                data:{
                    ...state.data,
                    matches: action.payload.data.matches,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_TOURNAMENTS:
            return{
                ...state,
                data:{
                    ...state.data,
                    tournaments: action.payload.data.tournaments,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_TOURNAMENT_BOARD:
            return{
                ...state,
                data:{
                    ...state.data,
                    tournamentPositions: action.payload.data.tournamentPositions,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_ALL_USERS:
            return{
                ...state,
                data:{
                    ...state.data,
                    users: action.payload.data.users,
                },
                isFetching: false,
                error: undefined
            }
            case actionTypes.RECEIVE_CARDS_CONFIG:
            return{
                ...state,
                data:{
                    ...state.data,
                    cardConfig: action.payload.data.cardConfig,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.ERROR_USER_TEAMS:
        case actionTypes.ERROR_TACTICS:
        case actionTypes.ERROR_PLAYERS:
        case actionTypes.ERROR_MATCHES:
        case actionTypes.ERROR_CREATE_TEAM:
        case actionTypes.ERROR_TOURNAMENTS:
        case actionTypes.ERROR_TOURNAMENT_BOARD:
        case actionTypes.ERROR_TOURNAMENT_ENTER:
        case actionTypes.ERROR_ALL_USERS:
        case actionTypes.ERROR_STAKED_PLAYERS:
        case actionTypes.ERROR_CARDS_CONFIG:
            return{
                ...state,
                error:action.error,
                isFetching:false,
            }
        default:
            return state;
    }
};

const updatePlayers = (currentPlayers : {[id: number] : Player }, newPlayers : {[id: number] : Player }) : {[id: number] : Player } =>{
    let updatedPlayers : {[id: number] : Player } = {};
    Object.entries(currentPlayers).forEach(
        ([key, value]) => {
            let id = parseInt(key);
            if(id in newPlayers){
                updatedPlayers[id] = newPlayers[id];
            }
            else{
                updatedPlayers[id] = value;
            }
        }
      );
    return updatedPlayers;
}