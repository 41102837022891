import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { FC, useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import {
  EmotionRarities,
  EnforcersRarities,
  PromoRarties,
  Series1Rarities,
  Series2Rarities,
  SpecialRarities,
  Templates,
  WorldCupRarities,
} from "../../inventory";
import { RootState } from "../../store/reducers";
import {
  EMOTION,
  ENFORCERS,
  PROMOPLAYERS,
  SERIES1,
  SERIES2,
  SPECIALS,
  WCLEGENDS1,
} from "../../utils/constants";
import { debounce } from "lodash";
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import "../../css/style.css";
import { sortAttributes } from "../../utils/sortAttributes";
import InfiniteScroll from "react-infinite-scroll-component";
import { getBaseTavScore } from "../../utils/createMutableAttributes";

interface SearchPlayerProps {
  // pass position i.e. Forward, Midfield, Defender etc?
  showDialog: boolean;
  lineup?: number[];
  onPlayerSelected(asset: IAsset | null): void;
  scrubbList?: number[];
  showPlayers: boolean;
}

export const PlayerSearch: FC<SearchPlayerProps> = ({
  showDialog,
  lineup,
  scrubbList,
  onPlayerSelected,
  showPlayers,
}) => {
  const { data: schemaData } = useSelector(
    (state: RootState) => state.schemaInfo
  );
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [selectedSchema, setSelectedSchema] = useState("");
  const [rarities, setRarities] = useState([] as string[]);
  const [searchResults, setSearchResults] = useState([] as IAsset[]);
  const [scrollResults, setScrollResults] = useState([] as IAsset[]);
  const [selectedAsset, setSelectedAsset] = useState<IAsset | null>();
  const [searchText, setSearchText] = useState("");
  const [selectedRarity, setSelectedRarity] = useState("");
  const [sortedAttributes, setSortedAttributes] = useState([] as string[]);
  const [prefilteredAssets, setPrefilteredAssets] = useState([] as IAsset[]);
  const [positions, setPositions] = useState<{ [key: string]: number }>({});
  const [selectedPosition, setSelectedPosition] = useState("");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [blockSize, setBlockSize] = useState(20);
  const [totalSearchItems, setTotalSearchItems] = useState(0);
  const SEARCH_ITEMS_LENGTH = 50;
  const [hasMore, setHasMore] = useState(true);

  // const [activePlayers, setActivePlayers] = useState<{
  //   [key: string]: boolean;
  // }>({});
  /*
        If user has no assets display links to AH/NB
        1. THIS IS IMPORTANT - filter out any players who are currently in a team AND current lineup **********************
        2. If we're swapping players, then we allow current lineup BUT NOT other players in other teams.
    */

  useEffect(() => {
    if (showDialog) {
      if (assetData.assets != null) {
        let assetPositions: { [key: string]: number } = {};
        //let activePlayers: { [key: string]: boolean } = {};
        // let availablePlayers: { [key: string]: boolean } = {};
        let teamPlayers = Object.keys(gameData.teamPlayers ?? {});
        //console.log(lineup);

        let assets = Object.values(assetData.assets).filter((asset) => {
          // filter out any players in current lineup
          if (lineup && lineup.includes(parseInt(asset.asset_id))) {
            // if we're swapping we allow players in current lineup in the same position
            //return swap ? true : false;
            return false;
          }
          // filter out any players in any of the users teams.
          // Oh dear, this is where we fall down, if you remove a player while editing, you can't add the card back in
          if (teamPlayers.includes(asset.asset_id)) {
            // check our scrub list to see if the player was removed from the current lineup, if so, we can include in our search list
            if (scrubbList && scrubbList.includes(parseInt(asset.asset_id))) {
              //return res;
            }
            return false;
          }

          // fllter out existing players if launching from MyPlayers
          if (!showPlayers && gameData.players) {
            if (parseInt(asset.asset_id) in gameData.players) {
              return false;
            }
          }

          // build up position counts.
          if (asset.template != null) {
            let template = Templates[asset.template.template_id];
            // An  asset we don't support e.g. attribute cards or chelsea scarfs etc

            if (template == null) {
              return false;
            }

            if (assetPositions[template.position]) {
              assetPositions[template.position] =
                assetPositions[template.position] + 1;
            } else {
              assetPositions[template.position] = 1;
            }
          }

          return true;
        });

        setPositions(assetPositions);
        setPrefilteredAssets(assets);
        processSearchResults(assets);
      }
    }
  }, [showDialog]);

  const debouncedSearch = useRef(
    debounce((name, assets, position, schema, rarity) => {
      try {
        let searchName = name.toLowerCase();
        setSearchText(searchName);

        if (assets == null || assets.length == 0) {
          return;
        }

        let results = (assets as IAsset[]).filter((asset) => {
          let res = true;
          if (searchName != "") {
            res = asset.name.toLowerCase().startsWith(searchName);
          }
          if (position != "all" && position != "") {
            let template = Templates[asset.template!.template_id];
            res = res == true && template.position == position;
          }
          if (schema != "all" && schema != "") {
            console.log(res);
            res = res == true && asset.schema.schema_name == schema;
          }
          if (rarity != "") {
            res = res == true && asset.data.Rarity == rarity;
          }
          // // filter on position
          // if(asset.mutable_data.Position != undefined){
          //     res = (res == true) && asset.mutable_data.Position == position;
          // }
          return res;
        });
        if (results != null) {
          processSearchResults(results);
        }
      } catch (e) {
        alert(e);
      }
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const positionChange = (event: SelectChangeEvent) => {
    let position = event.target.value as string;
    setSelectedPosition(position);
    debouncedSearch(
      searchText,
      prefilteredAssets,
      position,
      selectedSchema,
      selectedRarity
    );
  };

  const schemaChange = (event: SelectChangeEvent) => {
    let schema = event.target.value as string;
    setSelectedSchema(schema);
    // reset rarity
    setSelectedRarity("");

    if (schema != "") {
      switch (schema) {
        case "all":
          setRarities([]);
          break;
        case SERIES1:
          setRarities(Series1Rarities);
          break;
        case SERIES2:
          setRarities(Series2Rarities);
          break;
        case EMOTION:
          setRarities(EmotionRarities);
          break;
        case ENFORCERS:
          setRarities(EnforcersRarities);
          break;
        case PROMOPLAYERS:
          setRarities(PromoRarties);
          break;
        case SPECIALS:
          setRarities(SpecialRarities);
          break;
        case WCLEGENDS1:
          setRarities(WorldCupRarities);
          break;
      }
      debouncedSearch(
        searchText,
        prefilteredAssets,
        selectedPosition,
        schema,
        ""
      );
    }
  };

  const rarityChange = (event: SelectChangeEvent) => {
    let rarity = event.target.value as string;
    if (rarity != "") {
      setSelectedRarity(rarity);
      debouncedSearch(
        searchText,
        prefilteredAssets,
        selectedPosition,
        selectedSchema,
        rarity
      );
    }
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //alert(e.target.value);
    debouncedSearch(
      e.target.value,
      prefilteredAssets,
      selectedPosition,
      selectedSchema,
      selectedRarity
    );
  };

  const viewAsset = () => {
    window.open(
      "https://wax.atomichub.io/explorer/asset/" + selectedAsset?.asset_id,
      "_blank"
    );
  };

  const onClose = () => {
    clearState();
    onPlayerSelected(null);
  };

  const onPlayerClick = (asset: IAsset) => {
    let keys = sortAttributes(
      Object.keys(asset.mutable_data),
      asset.mutable_data.Position,
      false
    );

    setSortedAttributes(keys);
    setSelectedAsset(asset);
  };

  const onSelectPlayer = () => {
    if (selectedAsset) {
      // TODO: need to check if the player has been onboarded
      // if not we need to apply the default attribute values to the card
      onPlayerSelected(selectedAsset);
      clearState();
    }
  };

  const clearState = () => {
    setBlockSize(SEARCH_ITEMS_LENGTH);
    setSelectedAsset(null);
    setSearchText("");
    setSelectedSchema("");
    setSelectedRarity("");
  };

  const processSearchResults = (assets: IAsset[]) => {
    // console.log("total asset items " + assets.length);
    setTotalSearchItems(assets.length);
    setSearchResults(assets);
    setBlockSize(SEARCH_ITEMS_LENGTH);
    //console.log("displaying " + SEARCH_ITEMS_LENGTH + " assets");
    //console.log(assets.slice(0, SEARCH_ITEMS_LENGTH));
    setScrollResults(assets.slice(0, SEARCH_ITEMS_LENGTH));
  };

  const getNextResults = () => {
    // if (scrollResults.length >= totalSearchItems) {
    //   setHasMore(false);
    //   return;
    // }
    let nextSize = blockSize + SEARCH_ITEMS_LENGTH;
    console.log("Getting next block of data " + nextSize);
    setScrollResults(searchResults.slice(0, nextSize));
    setBlockSize(nextSize);
  };

  const hasMoreResults = (): boolean => {
    return totalSearchItems > scrollResults.length;
  };

  return (
    <Dialog open={showDialog} maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <span style={{ fontSize: mobile ? "16px" : "" }}>Player Search</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Search your assets by name, schema and/or rarity
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",

            marginTop: "10px",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            onChange={onTextChange}
            label="Search Player Name"
            type="username"
            size="small"
            sx={{
              width: mobile ? "400px" : "275px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #99ff32",
              },
              "&:hover": {
                "&& fieldset": {
                  border: "1px solid #99ff32",
                },
              },
            }}
          />
          <FormControl sx={{ width: mobile ? "400px" : "160px" }}>
            <InputLabel size="small">Position</InputLabel>
            <Select
              onChange={positionChange}
              label="Schemas"
              size="small"
              defaultValue="all"
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #99ff32",
                },
                "&:hover": {
                  "&& fieldset": {
                    border: "1px solid #99ff32",
                  },
                },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {positions &&
                Object.entries(positions).map(([key, value]) => (
                  <MenuItem value={key}>
                    {key} ({value})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: mobile ? "400px" : "178px" }}>
            <InputLabel size="small">Schemas</InputLabel>
            <Select
              onChange={schemaChange}
              label="Schemas"
              defaultValue="all"
              size="small"
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #99ff32",
                },
                "&:hover": {
                  "&& fieldset": {
                    border: "1px solid #99ff32",
                  },
                },
              }}
            >
              <MenuItem value="all">All Schemas</MenuItem>
              {schemaData.schemaCounts &&
                Object.entries(schemaData.schemaCounts).map(([key, value]) => (
                  <MenuItem value={key}>{key}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: mobile ? "400px" : "200px" }}>
            <InputLabel size="small">Rarity</InputLabel>
            <Select
              onChange={rarityChange}
              label="Rarity"
              size="small"
              defaultValue=""
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #99ff32",
                },
                "&:hover": {
                  "&& fieldset": {
                    border: "1px solid #99ff32",
                  },
                },
              }}
            >
              {rarities.map((r) => (
                <MenuItem value={r}>{r}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              height: "400px",
              padding: "0 0px 0 0",
              width: mobile ? "60%" : "75%",
            }}
          >
            <InfiniteScroll
              className="player-select-grid"
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                height: "400px",
                padding: "0 10px 0 0",
                width: "100%",
              }}
              height={400}
              dataLength={scrollResults.length}
              hasMore={hasMoreResults()}
              next={getNextResults}
              loader={<span>Loading...</span>}
            >
              {scrollResults.map(
                (asset) =>
                  asset.template &&
                  Templates[asset.template.template_id] && (
                    <div
                      key={asset.asset_id}
                      onClick={() => onPlayerClick(asset)}
                      className="playerSearchResultBox"
                    >
                      <img
                        style={{ width: "56px", height: "84px" }}
                        src={`images/${
                          Templates[asset.template!.template_id].imgPath
                        }?w=67&h=100&fit=crop&auto=format`}
                        srcSet={`images/${
                          Templates[asset.template!.template_id].imgPath
                        }?w=67&h=100&fit=crop&auto=format&dpr=2 2x`}
                        alt={asset.name}
                        loading="lazy"
                      />
                      <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                        {asset.data.Rarity}
                      </span>
                      <span style={{ fontSize: "9px" }}>
                        {Templates[asset.template!.template_id].position}
                      </span>
                    </div>
                  )
              )}
            </InfiniteScroll>
          </div>
          <Box sx={{ width: mobile ? "50%" : "25%", height: "400px" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                border: "1px solid #99ff32",
                borderRadius: "5px",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#000b1d",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50px",
                  borderBottom: "1px solid #99ff32",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <div style={{ fontSize: mobile ? "12px" : "" }}>
                  {selectedAsset?.name}
                </div>
                <div style={{ fontSize: "10px" }}>
                  <span>Asset: {selectedAsset?.asset_id}</span>
                  {!mobile && (
                    <span>, Level: {selectedAsset?.mutable_data["Level"]}</span>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  flexDirection: "column",

                  marginLeft: !mobile ? "2px" : "0px",
                  marginRight: "2px",
                }}
              >
                {selectedAsset &&
                  sortedAttributes.map((item) => (
                    <div style={{ display: "flex", lineHeight: "25px" }}>
                      <div style={{ flex: "1" }}>
                        <span style={{ marginLeft: "10px" }}>{item}</span>
                      </div>
                      <div style={{ flex: "1", fontWeight: "bold" }}>
                        <span style={{ marginLeft: "5px" }}>
                          {selectedAsset.mutable_data[item]}
                        </span>
                      </div>
                    </div>
                  ))}
                {selectedAsset &&
                  Object.keys(selectedAsset.mutable_data).length == 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "12px",
                        gap: "5px",
                        flex: "1",
                      }}
                    >
                      <span>
                        {selectedAsset.name} is not yet in the game, sign him
                        up! His starting TAV score is
                      </span>

                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {getBaseTavScore(
                          selectedAsset.schema.schema_name,
                          selectedAsset.data.Rarity
                        )}
                      </span>
                    </div>
                  )}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: mobile ? "5px" : "10px",
                  flex: 1,
                  flexDirection: mobile ? "column" : "row",

                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Button
                  size="small"
                  onClick={viewAsset}
                  className="blue-btn"
                  disabled={selectedAsset == null}
                >
                  View
                </Button>
                <Button
                  size="small"
                  className="green-btn"
                  variant="outlined"
                  onClick={onSelectPlayer}
                  disabled={selectedAsset == null}
                >
                  Select
                </Button>
              </div>
            </div>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
