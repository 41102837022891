import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { DEFENDER, EMOTION, ENFORCERS, FORWARD, GOALKEEPER, MIDFIELDER, PROMOPLAYERS, SERIES1, SERIES2, SPECIALS, WCLEGENDS1 } from "./constants";

/*
    Base Attribute Values (BAV) per rarity

    These values are assigned when a new card is onboarded into the game
*/
// Series1
// "Common","Uncommon","Limited","Relic","Rare","Epic","Legendary","Mythical","Celestial","Transfer","Manager"
export const SERIES1_COMMON_BAV = 10;
export const SERIES1_COMMON_STAKE_REWARD = 2;

export const SERIES1_UNCOMMON_BAV = 20;
export const SERIES1_UNCOMMON_STAKE_REWARD = 4;

export const SERIES1_LIMITED_BAV = 40;
export const SERIES1_LIMITED_STAKE_REWARD = 6;

export const SERIES1_RELIC_BAV = 60;
export const SERIES1_RELIC_STAKE_REWARD = 8;

export const SERIES1_RARE_BAV = 100;
export const SERIES1_RARE_STAKE_REWARD = 10;

export const SERIES1_EPIC_BAV = 250;
export const SERIES1_EPIC_STAKE_REWARD = 15;

export const SERIES1_LEGENDARY_BAV = 500;
export const SERIES1_LEGENDARY_STAKE_REWARD = 20;

export const SERIES1_MYTHICAL_BAV = 1000;
export const SERIES1_MYTHICAL_STAKE_REWARD = 25;

export const SERIES1_CELESTIAL_BAV = 5000;
export const SERIES1_CELESTIAL_STAKE_REWARD = 125;

export const SERIES1_TRANSFER_BAV = 1000;
export const SERIES1_MANAGER_BAV = 1000;

// Enforcers
// "Base", "Light Bruising", "Yellow Card", "The Terry Butcher", "Red Card", "Locked Up", "Halo", "MOTM", "Red Mist"
export const ENFORCERS_BASE_BAV = 100;
export const ENFORCERS_BASE_STAKING_REWARD = 5;

export const ENFORCERS_LIGHTB_BAV = 200;
export const ENFORCERS_LIGHTB_STAKING_REWARD = 7;

export const ENFORCERS_YELLOW_BAV = 300;
export const ENFORCERS_YELLOW_STAKING_REWARD = 10;

export const ENFORCERS_TERRY_BAV = 500;
export const ENFORCERS_TERRY_STAKING_REWARD = 15;

export const ENFORCERS_RED_CARD_BAV = 1000;
export const ENFORCERS_RED_CARD_STAKING_REWARD = 25;

export const ENFORCERS_LOCKED_BAV = 3000;
export const ENFORCERS_LOCKED_STAKING_REWARD = 150;

export const ENFORCERS_HALO_BAV = 800;
export const ENFORCERS_HALO_STAKING_REWARD = 50;

export const ENFORCERS_MOTM_BAV = 1500;
export const ENFORCERS_MOTM_STAKING_REWARD = 50;

export const ENFORCERS_RED_MIST_BAV = 2000;
export const ENFORCERS_RED_MIST_STAKING_REWARD = 75;

// Emotion
// "Base","Promo"
export const EMOTION_BASE_BAV = 1500;//Promo the same
export const EMOTION_STAKING_REWARD = 25;

// PromoPlayers
export const PROMOPLAYERS_BAV = 500;
export const PROMOPLAYERS_STAKING_REWARD = 25;

// Series2 
export const SERIES2_COMMON = 250;
export const SERIES2_COMMON_STAKING = 10;

export const SERIES2_LIMITED = 750;
export const SERIES2_LIMITED_STAKING = 15;

export const SERIES2_RARE = 1000;
export const SERIES2_RARE_STAKING = 20;

export const SERIES2_EPIC = 1500;
export const SERIES2_EPIC_STAKING = 25;

export const SERIES2_LEGENDARY = 3000;
export const SERIES2_LEGENDARY_STAKING = 50;


// WC Legends
export const WCLEGENDS1_COMMON = 1000;
export const WCLEGENDS1_COMMON_STAKING = 25;
export const WCLEGENDS1_RARE = 1500;
export const WCLEGENDS1_RARE_STAKING = 50;

// Specials
export const SPECIALS_GHOULKEEPERS = 500;
export const SPECIALS_GHOULKEEPERS_STAKING_REWARD = 500;


export const forwardPositionSort = ['Level', 'Position','Physical','Heading','Pace','Passing','Finishing','Shooting'];
export const midfielderPositionSort = ['Level', 'Position','Physical','Dribbling','Passing','Pace','Technique','Creativity'];
export const defenderPositionSort = ['Level', 'Position','Physical','Tackling','Marking','Pace','Heading','Passing'];
export const goalKeeperPositionSort = ['Level', 'Position','Physical','Reflexes','Diving','Throwing','Handling','Distribution'];



export const createMutableAttributes = (position : string, asset : IAsset): {key: string; value:(string|number)[]}[] | null =>{
    // random ranges based on rarity
    let values = generateAttributeValues(asset.schema.schema_name, asset.data.Rarity);
    return getAttributeMap(position, values);
}

const generateAttributeValues = (schema : string, rarity : string) : number[] => {
    let array = Array<number>(6);
    switch(schema){
        case SERIES1:
            switch(rarity){
                case "Common":
                    return array.fill(SERIES1_COMMON_BAV);
                case "Uncommon":
                    return array.fill(SERIES1_UNCOMMON_BAV);
                case "Limited":
                    return array.fill(SERIES1_LIMITED_BAV);
                case "Relic":
                    return array.fill(SERIES1_RELIC_BAV);
                case "Rare":
                    return array.fill(SERIES1_RARE_BAV);
                case "Epic":
                    return array.fill(SERIES1_EPIC_BAV);
                case "Legendary":
                    return array.fill(SERIES1_LEGENDARY_BAV);
                case "Mythical":
                    return array.fill(SERIES1_MYTHICAL_BAV);
                case "Celestial":
                    return array.fill(SERIES1_CELESTIAL_BAV);
                case "Transfer":
                    return array.fill(SERIES1_TRANSFER_BAV);
                case "Manager":
                    return array.fill(SERIES1_MANAGER_BAV);
            }
            break;
        case EMOTION:
            return array.fill(EMOTION_BASE_BAV);
        case ENFORCERS:
            switch(rarity){
                case "Base":
                    return array.fill(ENFORCERS_BASE_BAV);
                case "Light Bruising":
                    return array.fill(ENFORCERS_LIGHTB_BAV);
                case "Yellow Card":
                    return array.fill(ENFORCERS_YELLOW_BAV);
                case "The Terry Butcher":
                    return array.fill(ENFORCERS_TERRY_BAV);
                case "Red Card":
                    return array.fill(ENFORCERS_RED_CARD_BAV);
                case "Locked Up":
                    return array.fill(ENFORCERS_LOCKED_BAV);
                case "Halo":
                    return array.fill(ENFORCERS_HALO_BAV);
                case "MOTM":
                    return array.fill(ENFORCERS_MOTM_BAV);
                case "Red Mist":
                    return array.fill(ENFORCERS_RED_MIST_BAV);
            }
            break;
        case PROMOPLAYERS:
            return array.fill(PROMOPLAYERS_BAV);
        case SERIES2:
            switch(rarity){
                case "Common":
                    return array.fill(SERIES2_COMMON);
                case "Limited":
                    return array.fill(SERIES2_LIMITED);
                case "Rare":
                    return array.fill(SERIES2_RARE);
                case "Epic":
                    return array.fill(SERIES2_EPIC);
                case "Legendary":
                    return array.fill(SERIES2_LEGENDARY);
            }
            break;
        case WCLEGENDS1:
            switch(rarity){
                case "Common":
                    return array.fill(WCLEGENDS1_COMMON);
                case "Rare":
                    return array.fill(WCLEGENDS1_RARE);
            }
            break;
        case SPECIALS:
            switch(rarity){
                case "Ghoulkeepers":
                    return array.fill(SPECIALS_GHOULKEEPERS);
            }
            break;
                
        default:
            // add zeros for non mapped cards - we shouldn't get here
            return array.fill(0);
    }
    return array.fill(0);
}

export const getAttributeMap = (position : string, values : number[]) : {key: string; value:(string|number)[]}[] | null =>{
    switch(position){
        case GOALKEEPER:
            let goalKeeper =[ 
                {"key": "Level", "value": ["uint64", 1]},
                {"key": "Position", "value": ["string", GOALKEEPER]},
                {"key": "Reflexes", "value": ["uint64", values[0]]},
                {"key": "Diving", "value": ["uint64",  values[1]]},
                {"key": "Throwing", "value": ["uint64",  values[2]]},
                {"key": "Handling", "value": ["uint64",  values[3]]},
                {"key": "Physical", "value": ["uint64",  values[4]]},
                {"key": "Distribution", "value": ["uint64",  values[5]]}
            ];
            return goalKeeper;
        case DEFENDER:
            let defender =[ 
                {"key": "Level", "value": ["uint64", 1]},
                {"key": "Position", "value": ["string", DEFENDER]},
                {"key": "Tackling", "value": ["uint64", values[0]]},
                {"key": "Marking", "value": ["uint64",  values[1]]},
                {"key": "Physical", "value": ["uint64",  values[2]]},
                {"key": "Heading", "value": ["uint64",  values[3]]},
                {"key": "Pace", "value": ["uint64",  values[4]]},
                {"key": "Passing", "value": ["uint64",  values[5]]}
            ];
            return defender;
        case MIDFIELDER:
            let midfielder =[ 
                {"key": "Level", "value": ["uint64", 1]},
                {"key": "Position", "value": ["string", MIDFIELDER]},
                {"key": "Passing", "value": ["uint64", values[0]]},
                {"key": "Dribbling", "value": ["uint64",  values[1]]},
                {"key": "Pace", "value": ["uint64",  values[2]]},
                {"key": "Physical", "value": ["uint64",  values[3]]},
                {"key": "Technique", "value": ["uint64",  values[4]]},
                {"key": "Creativity", "value": ["uint64",  values[5]]}
            ];
            return midfielder;
        case FORWARD:
            let forward =[ 
                {"key": "Level", "value": ["uint64", 1]},
                {"key": "Position", "value": ["string", FORWARD]},
                {"key": "Finishing", "value": ["uint64", values[0]]},
                {"key": "Shooting", "value": ["uint64",  values[1]]},
                {"key": "Heading", "value": ["uint64",  values[2]]},
                {"key": "Physical", "value": ["uint64",  values[3]]},
                {"key": "Pace", "value": ["uint64",  values[4]]},
                {"key": "Passing", "value": ["uint64",  values[5]]}
            ];
            return forward;
    }
    
    return null;
}


export const getBaseTavScore = (schema : string, rarity : string) : number => {
    switch(schema){
        case SERIES1:
            switch(rarity){
                case "Common":
                    return SERIES1_COMMON_BAV;
                case "Uncommon":
                    return SERIES1_UNCOMMON_BAV;
                case "Limited":
                    return SERIES1_LIMITED_BAV;
                case "Relic":
                    return SERIES1_RELIC_BAV;
                case "Rare":
                    return SERIES1_RARE_BAV;
                case "Epic":
                    return SERIES1_EPIC_BAV;
                case "Legendary":
                    return SERIES1_LEGENDARY_BAV;
                case "Mythical":
                    return SERIES1_MYTHICAL_BAV;
                case "Celestial":
                    return SERIES1_CELESTIAL_BAV;
                case "Transfer":
                    return SERIES1_TRANSFER_BAV;
                case "Manager":
                    return SERIES1_MANAGER_BAV;
            }
            break;
        case EMOTION:
            return EMOTION_BASE_BAV;
        case ENFORCERS:
            switch(rarity){
                case "Base":
                    return ENFORCERS_BASE_BAV;
                case "Light Bruising":
                    return ENFORCERS_LIGHTB_BAV;
                case "Yellow Card":
                    return ENFORCERS_YELLOW_BAV;
                case "The Terry Butcher":
                    return ENFORCERS_TERRY_BAV;
                case "Red Card":
                    return ENFORCERS_RED_CARD_BAV;
                case "Locked Up":
                    return ENFORCERS_LOCKED_BAV;
                case "Halo":
                    return ENFORCERS_HALO_BAV;
                case "MOTM":
                    return ENFORCERS_MOTM_BAV;
                case "Red Mist":
                    return ENFORCERS_RED_MIST_BAV;
            }
            break;
        case PROMOPLAYERS:
            return PROMOPLAYERS_BAV;
        case SERIES2:
            switch(rarity){
                case "Common":
                    return SERIES2_COMMON;
                case "Limited":
                    return SERIES2_LIMITED;
                case "Rare":
                    return SERIES2_RARE;
                case "Epic":
                    return SERIES2_EPIC;
                case "Legendary":
                    return SERIES2_LEGENDARY;
            }
            break;
        case WCLEGENDS1:
            switch(rarity){
                case "Common":
                    return WCLEGENDS1_COMMON;
                case "Rare":
                    return WCLEGENDS1_RARE;
            }
            break;
        case SPECIALS:
            switch(rarity){
                case "Ghoulkeepers":
                    return SPECIALS_GHOULKEEPERS;
            }
            break;
    }
    return 0;
}