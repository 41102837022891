/*
 ERROR CODES
 -----------
   100 = username already exists
   101 = wax wallet already exists.
   102 = user does not exist.
   200 = team does not exist.
   201 = You are trying to update a team that is not yours!
   202 = You are trying to delete a team that is not yours!
   300 = match does not exist.
   400 = player does not exist in team.
*/
export const errorCode: { [id: string]: string; } = {
    "100": "The username already exists",
    "101" : "The wax wallet already exists",
    "102" : "The user does not exist",
    "200" : "The team does not exist",
    "201" : "You are trying to update a team that is not yours!",
    "202" : "You are trying to delete a team that is not yours!",
    "300" : "The match does not exist",
    "400" : "The player does not exist in team"
 };

 export const getErrorMessage =(error: string | undefined) => {
    try{
        if(error === undefined){
            return "Oops! An error occurred.";
        }
        // assertion failure with error code: 100
        console.log(error);
        let err = error.toString().split(':')[2];
        if(err != ""){
            let code = err.replace(/\s/g, '');
            return errorCode[code];
        }
    }
    catch(e){
        console.log(e);
        return "Oops! An error occurred.";
    }
 }