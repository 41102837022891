import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { getPlayerSchemaCounts } from "../../utils/filterUserPlayers";
import StarIcon from "@mui/icons-material/Star";
import { Templates } from "../../inventory";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getPlayersScore } from "../../utils/calculateTeamTotal";
import {
  EMOTION,
  ENFORCERS,
  PROMOPLAYERS,
  SERIES1,
  SERIES2,
  SPECIALS,
  WCLEGENDS1,
} from "../../utils/constants";

export const MyPlayers: FC = () => {
  const [schemaCounts, setSchemaCounts] = useState<{
    [schema: string]: number;
  }>({});
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [starPlayer, setStarPlayer] = useState<IAsset | null>(null);

  // Set attribute card counts & star player
  useEffect(() => {
    if (userAssets.attributeCards != null) {
      if (userAssets.starPlayer != null) {
        setStarPlayer(userAssets.starPlayer);
      }
    }
    setSchemaCounts(getPlayerSchemaCounts(userAssets, gameData.players));
  }, [userAssets, gameData]);

  const getTotalPlayers = (): number => {
    return Object.values(schemaCounts).reduce((a, b) => a + b, 0);
  };

  const viewAsset = () => {
    if (starPlayer != null) {
      window.open(
        "https://wax.atomichub.io/explorer/asset/" + starPlayer?.asset_id,
        "_blank"
      );
    }
  };

  return (
    <div className="col-md-12 mt-5">
      <div
        className="card"
        style={{
          width: "100%",
          backgroundColor: "#000b1d",
          color: "#fff",
          border: "1px solid #99ff32",
        }}
      >
        <div className="card-body">
          <h5 className="card-title">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                id="players"
                style={{ marginRight: "10px" }}
                src={"/images/players-icon.svg"}
                width="42"
                height="42"
                alt="Vintage Niftys Tournaments"
              />
              My Players ({getTotalPlayers()})
            </div>
          </h5>
        </div>
        <div
          className="list-group list-group-flush"
          style={{
            backgroundColor: "#001636",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5px",
              gap: "10px",
            }}
          >
            <div>
              {starPlayer && (
                <img
                  height={80}
                  width={60}
                  style={{ cursor: "pointer" }}
                  onClick={viewAsset}
                  alt={starPlayer.name}
                  src={
                    "/images/" +
                    Templates[starPlayer.template!.template_id].imgPath
                  }
                />
              )}
              {!starPlayer && (
                <img
                  height={80}
                  width={60}
                  src={"/images/placeholder_player.png"}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ borderBottom: "1px solid  #22395A" }}>
                <span style={{ fontSize: "16px" }}>
                  STAR PLAYER{" "}
                  <StarIcon sx={{ marginTop: "-10px" }} color="secondary" />
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  fontSize: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>Name: &nbsp; {starPlayer?.name}</span>
                  <span>Rarity: &nbsp; {starPlayer?.data.Rarity}</span>
                  <span>Schema: &nbsp; {starPlayer?.schema.schema_name} </span>
                  <span style={{ fontSize: "16px" }}>
                    TAV:&nbsp;
                    <b>
                      {starPlayer &&
                        getPlayersScore(
                          null,
                          starPlayer,
                          Templates[starPlayer.template!.template_id],
                          false
                        ).score}
                    </b>
                  </span>
                  <span style={{ fontSize: "16px" }}>
                    Lvl: <b>{starPlayer && starPlayer.mutable_data.Level}</b>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderTop: "1px solid  #22395A",

              fontSize: "12px",
            }}
          >
            <div style={{ borderBottom: "1px solid  #22395A" }}>
              <span style={{ marginLeft: "5px" }}>
                <b>Schema Counts</b>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                gap: "40px",
                padding: "5px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  series1: <b>{schemaCounts[SERIES1]}</b>
                </span>
                <span>
                  series2: <b>{schemaCounts[SERIES2]}</b>
                </span>
                <span>
                  wclegends1: <b>{schemaCounts[WCLEGENDS1]}</b>
                </span>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  emotion: <b>{schemaCounts[EMOTION]}</b>
                </span>
                <span>
                  enforcers: <b>{schemaCounts[ENFORCERS]}</b>
                </span>
                <span>
                  specials: <b>{schemaCounts[SPECIALS]}</b>
                </span>
                <span>
                  promoplayers: <b>{schemaCounts[PROMOPLAYERS]}</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
