import { Player } from "../types/Player";
import { UserAssets } from "../types/UserAssets";

export const filterUserPlayers = (userAssets : UserAssets, players : {[id: number] : Player | null }| undefined) =>{
    if(userAssets.assets  == null){
      return [];
    }
    if(players == null){
      return [];
    }
    let userPlayers: Player[] = [];
 
    Object.entries(players).forEach(
      ([key, value]) => {
        if(value != null){
          if(value.asset_id in userAssets.assets!){
            userPlayers.push(value!);
          }
        }
        
      }
    );
    return userPlayers;
  }


  export const getPlayerSchemaCounts = (userAssets : UserAssets, players : {[schema: string] : Player | null }| undefined) : {[schema: string] : number} =>{
    if(userAssets.assets  == null){
      return {};
    }
    if(players == null){
      return {};
    }
    let schemaCounts: {[schema : string] : number} = {};
 
    Object.entries(players).forEach(
      ([key, value]) => {
        if(value != null){
          let asset = userAssets.assets![value.asset_id];
          if(asset == null && userAssets.stakedAssets != null){
            asset = userAssets.stakedAssets[value.asset_id];
          }
          if(asset){
            if(asset.schema.schema_name in schemaCounts){
              schemaCounts[asset.schema.schema_name] = schemaCounts[asset.schema.schema_name]+1;
            }
            else{
              schemaCounts[asset.schema.schema_name] = 1;
            }
          }
       
        }
        
      }
    );
    return schemaCounts;
  }