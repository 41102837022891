import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { VINTYNIFTYS_ACCOUNT } from "../../utils";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  checkDestakeTransfer,
  checkStakeTransfer,
  fetchUserAssets,
  setCheckStakeTransferResult,
} from "../../store/actions/userAssets";
import { fetchPlayers } from "../../store/actions/gameData";

type DestakePlayerProps = {
  showDialog: boolean;
  assetId: string;
  onDestaked(): void;
};

const START: string = "START";
const ERROR: string = "ERROR";
const SUCCESS: string = "SUCCESS";
const LOADINGASSETS: string = "LOADINGASSETS";
const LOADINGPLAYERS: string = "LOADINGPLAYERS";

export const DestakePlayer: FC<DestakePlayerProps> = ({
  showDialog,
  assetId,
  onDestaked: onDestaked,
}) => {
  const { data: destakeResult } = useSelector(
    (state: RootState) => state.destakeTransferResult
  );
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets, isFetching: isFetchingAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData, isFetching: isFetchingPlayers } = useSelector(
    (state: RootState) => state.gameData
  );
  const { data: schemaInfo } = useSelector(
    (state: RootState) => state.schemaInfo
  );
  const [status, setStatus] = useState(START);
  const [pollRefresh, setPollRefresh] = useState(false);
  const ual = useUAL() as any;
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!pollRefresh) {
      return;
    }
    if (destakeResult != null) {
      if (destakeResult.transaction_id == "SUCCESS") {
        setStatus(LOADINGASSETS);
        setPollRefresh(false);
        dispatch(fetchUserAssets(userData.account, schemaInfo));
        return;
      }
      if (destakeResult.transaction_id == "FAILED") {
        setPollRefresh(false);
        setStatus(ERROR);
        return;
      }
    }
    if (pollRefresh) {
      setTimeout(() => {
        dispatch(checkDestakeTransfer(userData.account, assetId));
      }, 1000);
    }
  }, [pollRefresh, destakeResult]);

  useEffect(() => {
    if (status != LOADINGASSETS) {
      return;
    }
    if (!isFetchingAssets) {
      console.log("Now fetching players");
      setStatus(LOADINGPLAYERS);
      dispatch(fetchPlayers(userAssets));
    }
  }, [isFetchingAssets]);

  useEffect(() => {
    if (status != LOADINGPLAYERS) {
      return;
    }
    if (!isFetchingPlayers) {
      console.log("we're done");
      setStatus(SUCCESS);
    }
  }, [isFetchingPlayers]);

  useEffect(() => {
    const transferAsset = async () => {
      try {
        const activeUser = ual["activeUser"];
        const result = await activeUser.signTransaction(
          {
            actions: [
              {
                account: VINTYNIFTYS_ACCOUNT,
                name: "destake",
                authorization: [
                  {
                    actor: activeUser["accountName"],
                    permission: activeUser.requestPermission,
                  },
                ],
                data: {
                  owner: activeUser["accountName"],
                  assetId: [parseInt(assetId)],
                },
              },
            ],
          },
          {
            blocksBehind: 3,
            expireSeconds: 300,
          }
        );

        if (result.error != null) {
          setStatus(ERROR);
        } else {
          // move to polling to see if the asset is back in our ownership
          setPollRefresh(true);
        }
      } catch (e: any) {}
    };

    if (showDialog) {
      setStatus(START);
      // now launch atomic assets transaction
      transferAsset();
    }
  }, [showDialog]);

  const onClose = () => {
    onDestaked();
    setPollRefresh(false);
    dispatch(setCheckStakeTransferResult({ transaction_id: null }));
  };

  const renderDisplay = () => {
    if (status == ERROR) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "60px" }} />
          <span style={{ fontSize: "14px", textAlign: "center" }}>
            Oh dear, something went wrong :(
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == SUCCESS) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "60px" }} />
          <span style={{ fontSize: "14px", textAlign: "center" }}>
            He's back, the fans will be pleased :)
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            size="small"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
        <div style={{ marginTop: "20px" }}>
          He's coming back to {userData.club}...
        </div>
      </div>
    );
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
          }}
        >
          {renderDisplay()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
