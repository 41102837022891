import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { TOURNAMENTS_ROUTE } from "../utils/constants";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";

const Tournaments = () => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);

  {
    /* { field: 'name', headerName: 'Name', flex:1, sortable:false, disableColumnMenu:true},
    { field: 'startDate', headerName:'Starts', sortable:false, disableColumnMenu:true},
    { field: 'endDate', headerName:'Ends', sortable:false, disableColumnMenu:true },
    { field: 'teamCount', headerName:'No.Teams', sortable:false, disableColumnMenu:true},
    { field: 'gamesCount', headerName:'No.Games',sortable:false, disableColumnMenu:true},
    { field: 'buyIn', headerName:'Buy In', sortable:false, disableColumnMenu:true},
    { field: 'prize', headerName:'Prize', sortable:false, disableColumnMenu:true},
    { field: 'manager', headerName:'Winner', sortable:false, disableColumnMenu:true},
    { field: 'expired', headerName:'Expired', sortable:false, disableColumnMenu:true},
    { field: 'active', headerName:'Active', sortable:false, disableColumnMenu:true},
    { field: 'id', headerName: 'Join', sortable:false, disableColumnMenu:true, } */
  }

  const getTeamName = (teamId: number): string => {
    if (gameData.userTeams != null) {
      let team = gameData.userTeams[teamId];
      if (team != null) {
        return team.name;
      }
    }
    if (gameData.opponentTeams != null) {
      let team = gameData.opponentTeams[teamId];
      if (team != null) {
        return team.name;
      }
    }
    return "";
  };

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={TOURNAMENTS_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  margin: "10px 20px",
                }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  src={"/images/tourno-icon.svg"}
                  width="42"
                  height="42"
                />
                Tournaments &nbsp;
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    backgroundColor: "#001636",
                  }}
                >
                  {/* COLUMN HEADER */}
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      height: "35px",
                      borderBottom: "1px solid #99ff32",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <span>NAME</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                      }}
                    >
                      <span>STARTS</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                      }}
                    >
                      <span>ENDS</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                      }}
                    >
                      <span>TEAMS</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                      }}
                    >
                      <span>GAMES</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                      }}
                    >
                      <span>BUY IN</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                      }}
                    >
                      <span>PRIZE</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "150px",
                      }}
                    >
                      <span>WINNER</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100px",
                      }}
                    >
                      <span>MANAGER</span>
                    </div>
                  </div>
                  {gameData.tournaments != null &&
                    gameData.tournaments.map((item) => (
                      <div
                        style={{
                          display: "flex",
                          color: "white",
                        }}
                      >
                        <div
                          style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            marginLeft: "5px",
                            borderLeft: "1px solid #22395a",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                          }}
                        >
                          <span style={{ marginLeft: "5px" }}>{item.name}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "200px",
                          }}
                        >
                          <span>{item.startDate.toDateString()}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "200px",
                          }}
                        >
                          <span>{item.endDate.toDateString()}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "80px",
                          }}
                        >
                          <span>{item.teamCount}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "80px",
                          }}
                        >
                          <span>{item.gamesCount}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "80px",
                          }}
                        >
                          <span>{item.buyIn}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "80px",
                          }}
                        >
                          <span>{item.prize}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            width: "150px",
                          }}
                        >
                          <span>{getTeamName(item.winningTeam)}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                            borderRight: "1px solid #22395a",
                            width: "100px",
                            marginRight: "5px",
                          }}
                        >
                          <span>{item.manager}</span>
                        </div>
                      </div>
                    ))}
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      gap: "5px",
                      height: "2px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Tournaments;
