import { toSvg } from "jdenticon";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import moment from "moment";

export interface AvatarProps {
  size: number;
  userName: string;
}

export const Avatar: FC<AvatarProps> = ({ size, userName }) => {
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const [avatar, setAvatar] = useState("");
  const [avatarExists, setAvatarExists] = useState(false);

  useEffect(() => {
    checkImageExists();
    const svgString = toSvg(userName ? userName : userData.userName, size);
    const base64 = Buffer.from(svgString).toString("base64");
    setAvatar(base64);
  }, []);

  const getAvatar = () => {
    const imageUrl =
      "https://service.vintageniftys.io/avatars/" +
      userName +
      "-avatar.png?random=" +
      moment().unix();
    return imageUrl;
  };

  const checkImageExists = () => {
    try {
      console.log("CHECKING IMAGE");
      var img = new Image();
      img.onload = () => {
        setAvatarExists(true);
      };
      img.onerror = () => {};
      img.src = getAvatar();
    } catch {
      return false;
    }
  };

  const renderAvatar = () => {
    if (userData.userName == userName && userData.avatarUrl) {
      return (
        <img
          width={size}
          height={size}
          src={userData.avatarUrl}
          alt={userData.userName}
        />
      );
    } else {
      if (!avatarExists) {
        return (
          <img
            width={size}
            height={size}
            src={`data:image/svg+xml;base64,${avatar}`}
            alt={userData.userName}
          />
        );
      }
      return (
        <img
          width={size}
          height={size}
          src={getAvatar()}
          alt={userData.userName}
        />
      );
    }
  };

  return renderAvatar();
};
