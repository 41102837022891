export const SET_UAL_USER = "RECEIVE_USER";

export const REQUEST_USER = "REQUEST_USER";
export const RECEIVE_USER = "RECEIVE_USER";
export const ERROR_USER = "ERROR_USER";

export const REQUEST_VINTY_USER = "REQUEST_VINTY_USER";
export const RECEIVE_VINTY_USER = "RECEIVE_VINTY_USER";
export const ERROR_VINTY_USER = "ERROR_VINTY_USER";

export const REQUEST_AIRGRAB_VINTY = "REQUEST_AIRGRAB_VINTY";
export const RECEIVE_AIRGRAB_VINTY = "RECEIVE_AIRGRAB_VINTY";
export const ERROR_AIRGRAB_VINTY = "ERROR_AIRGRAB_VINTY";

export const REQUEST_ALL_USERS = "REQUEST_ALL_USERS";
export const RECEIVE_ALL_USERS = "RECEIVE_ALL_USERS";
export const ERROR_ALL_USERS = "ERROR_ALL_USERS";


export const REQUEST_UPDATEAVATAR = "REQUEST_UPDATEAVATAR";
export const RECEIVE_UPDATEAVATAR = "RECEIVE_UPDATEAVATAR";
export const ERROR_UPDATEAVATAR = "ERROR_UPDATEAVATAR";

export const REQUEST_USER_WAX_BALANCE = "REQUEST_USER_WAX_BALANCE";
export const RECEIVE_USER_WAX_BALANCE = "RECEIVE_USER_WAX_BALANCE";
export const ERROR_USER_WAX_BALANCE = "ERROR_USER_WAX_BALANCE";

export const REQUEST_ACCOUNT_INFO = "REQUEST_ACCOUNT_INFO";
export const RECEIVE_ACCOUNT_INFO = "RECEIVE_ACCOUNT_INFO";
export const ERROR_ACCOUNT_INFO = "ERROR_ACCOUNT_INFO";

export const REQUEST_USER_VINTY_BALANCE = "REQUEST_USER_VINTY_BALANCE";
export const RECEIVE_USER_VINTY_BALANCE = "RECEIVE_USER_VINTY_BALANCE";
export const ERROR_USER_VINTY_BALANCE = "ERROR_USER_VINTY_BALANCE";

export const REQUEST_USER_REGISTRATION = "REQUEST_USER_REGISTRATION";
export const RECEIVE_USER_REGISTRATION = "RECEIVE_USER_REGISTRATION";
export const ERROR_USER_REGISTRATION = "ERROR_USER_REGISTRATION";

export const REQUEST_CONFIG = "REQUEST_CONFIG";
export const RECEIVE_CONFIG = "RECEIVE_CONFIG";
export const UPDATE_LOCKED_STATUS = "UPDATE_LOCKED_STATUS";
export const UPDATE_MATCHES_LOCKED_STATUS = "UPDATE_MATCHES_LOCKED_STATUS";
export const UPDATE_MAX_TEAMS = "UPDATE_MAX_TEAMS";
export const ERROR_CONFIG = "ERROR_CONFIG";

export const REQUEST_USER_TEAMS = "REQUEST_USER_TEAMS";
export const RECEIVE_USER_TEAMS = "RECEIVE_USER_TEAMS";
export const ERROR_USER_TEAMS = "ERROR_USER_TEAMS";
export const RECEIVE_USER_PLAYERS = "RECEIVE_USER_PLAYERS";

export const REQUEST_SCHEMA_INFO = "REQUEST_SCHEMA_INFO";
export const RECEIVE_SCHEMA_INFO = "RECEIVE_SCHEMA_INFO";
export const ERROR_SCHEMA_INFO = "ERROR_SCHEMA_INFO";

export const REQUEST_USER_ASSETS = "REQUEST_USER_ASSETS";
export const RECEIVE_USER_ASSETS = "RECEIVE_USER_ASSETS";
export const ERROR_USER_ASSETS = "ERROR_USER_ASSETS";
export const REQUEST_SINGLE_USER_ASSET = "REQUEST_SINGLE_USER_ASSET";
export const RECEIVE_SINGLE_USER_ASSET = "RECEIVE_SINGLE_USER_ASSET";
export const ERROR_SINGLE_USER_ASSET = "ERROR_SINGLE_USER_ASSET";
export const UPDATE_USER_ASSET = "UPDATE_USER_ASSET";

export const USER_LOGOUT = "USER_LOGOUT";

export const REQUEST_TACTICS = "REQUEST_TACTICS";
export const RECEIVE_TACTICS = "RECEIVE_TACTICS";
export const ERROR_TACTICS = "ERROR_TACTICS";

export const REQUEST_PLAYERS = "REQUEST_PLAYERS";
export const RECEIVE_PLAYERS = "RECEIVE_PLAYERS";
export const ERROR_PLAYERS = "ERROR_PLAYERS";
export const RECEIVE_SINGLE_PLAYER = "UPDATE_SINGLE_PLAYER";

export const REQUEST_MATCHES = "REQUEST_MATCHES";
export const RECEIVE_MATCHES = "RECEIVE_MATCHES";
export const ERROR_MATCHES = "ERROR_MATCHES";

export const REQUEST_TOURNAMENTS = "REQUEST_TOURNAMENTS";
export const RECEIVE_TOURNAMENTS = "RECEIVE_TOURNAMENTS";
export const ERROR_TOURNAMENTS = "ERROR_TOURNAMENTS";

export const REQUEST_TOURNAMENT_BOARD = "REQUEST_TOURNAMENT_BOARD";
export const RECEIVE_TOURNAMENT_BOARD = "RECEIVE_TOURNAMENT_BOARD";
export const ERROR_TOURNAMENT_BOARD = "ERROR_TOURNAMENT_BOARD";


export const REQUEST_TOURNAMENT_ENTER = "REQUEST_TOURNAMENT_ENTER";
export const ENTER_TOURNAMENT_RESULT = "ENTER_TOURNAMENT_RESULT";
export const ERROR_TOURNAMENT_ENTER = "ERROR_TOURNAMENT_ENTER";

export const REQUEST_CREATE_TEAM = "REQUEST_CREATE_TEAM";
export const RECEIVE_CREATE_TEAM = "RECEIVE_CREATE_TEAM";
export const ERROR_CREATE_TEAM = "ERROR_CREATE_TEAM";
export const SAVE_TEAM_RESULT = "SAVE_TEAM_RESULT";
export const DELETE_TEAM_RESULT = "DELETE_TEAM_RESULT";
export const UPDATE_TEAM_READYSTATUS = "UPDATE_TEAM_READYSTATUS";

export const REQUEST_USER_ATTRIBUTE_CARDS = "REQUEST_USER_ATTRIBUTE_CARDS";
export const RECEIVE_USER_ATTRIBUTE_CARDS = "RECEIVE_USER_ATTRIBUTE_CARDS";
export const ERROR_USER_ATTRIBUTE_CARDS = "ERROR_USER_ATTRIBUTE_CARDS";

export const REQUEST_BURN_ATTRIBUTE_CARD = "REQUEST_BURN_ATTRIBUTE_CARD";
export const ERROR_BURN_ATTRIBUTE_CARD = "ERROR_BURN_ATTRIBUTE_CARD";
export const BURN_ATTRIBUTE_CARD_RESULT = "BURN_ATTRIBUTE_CARD_RESULT";

export const START_MATCH_RESULT = "START_MATCH_RESULT";

export const REQUEST_OPPONENT_ASSETS = "REQUEST_OPPONENT_ASSETS";
export const RECEIVE_OPPONENT_ASSETS = "RECEIVE_OPPONENT_ASSETS";
export const ERROR_OPPONENT_ASSETS = "ERROR_OPPONENT_ASSETS";
export const RESET_OPPONENT_ASSETS = "RESET_OPPONENT_ASSETS";

export const CHALLENGE_OPPONENT = "CHALLENGE_OPPONENT";
export const BOOST_PLAYER = "BOOST_PLAYER";

export const REQUEST_SEND_TOKENS = "REQUEST_SEND_TOKENS";
export const SEND_TOKENS_RESULT = "SEND_TOKENS_RESULT";
export const ERROR_SEND_TOKENS = "ERROR_SEND_TOKENS";

export const REQUEST_STAKED_PLAYERS = "REQUEST_STAKED_PLAYERS";
export const RECEIVE_STAKED_PLAYERS = "RECEIVE_STAKED_PLAYERS";
export const ERROR_STAKED_PLAYERS = "ERROR_STAKED_PLAYERS";

export const REQUEST_STAKED_CLAIMS = "REQUEST_STAKED_CLAIMS";
export const RECEIVE_STAKED_CLAIMS = "RECEIVE_STAKED_CLAIMS";
export const ERROR_STAKED_CLAIMS = "ERROR_STAKED_CLAIMS";

export const REQUEST_CHECK_STAKE_TRANSFER = "REQUEST_CHECK_STAKE_TRANSFER";
export const SET_STAKE_TRANSFER_RESULT = "SET_STAKE_TRANSFER_RESULT";
export const ERROR_STAKE_TRANSFER_ERROR = "ERROR_STAKE_TRANSFER_ERROR";

export const REQUEST_CHECK_DESTAKE_TRANSFER = "REQUEST_CHECK_DESTAKE_TRANSFER";
export const SET_DESTAKE_TRANSFER_RESULT = "SET_DESTAKE_TRANSFER_RESULT";
export const ERROR_DESTAKE_TRANSFER_ERROR = "ERROR_DESTAKE_TRANSFER_ERROR";

export const REQUEST_CARDS_CONFIG = "REQUEST_CARDS_CONFIG";
export const RECEIVE_CARDS_CONFIG = "RECEIVE_CARDS_CONFIG";
export const ERROR_CARDS_CONFIG = "ERROR_CARDS_CONFIG";