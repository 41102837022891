import { configureStore } from '@reduxjs/toolkit';
import { JsonRpc } from 'eosjs';
import { AnyAction, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, {ThunkAction} from 'redux-thunk';
import { ApiMiddleware } from '../types/ApiMiddleware';
import { atomicAssetsInstance } from '../utils/atomicAssetsInstance';
import { WAX_URL } from '../utils/constants';
import { waxInstance } from '../utils/waxInstance';
import { webServiceInstance } from '../utils/webServiceInstance';
import rootReducer, { RootState } from './reducers';

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    ApiMiddleware,
    AnyAction
>;

export interface InitialState<T = undefined>{
    isFetching: boolean,
    error: string | undefined,
    data: T
};

export interface ReducerAction<T = undefined> extends AnyAction{
    payload:{
        data: T;
    };
    error?: string;
};



//export type AppDispatch = typeof store.dispatch

export default (preloadedState?: RootState)=>{
    
    const apiMiddleware: ApiMiddleware = {
        webService: webServiceInstance,
        atomicAssets: atomicAssetsInstance,
        waxRpc: waxInstance
    };

    const store = configureStore({
        reducer: rootReducer,
        middleware:  (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk:{
                extraArgument: apiMiddleware
            },
            serializableCheck: false
        }),
        preloadedState: preloadedState
    });
    return store;
}