import { Player } from "../types/Player";

export const calculatePlayerFitnessPercentage = (fitness : number) : number =>{
    let percent = 0;
    if(fitness == 0){
        percent = 100;
    }
    else{
        const timeToFF = new Date(0);
        timeToFF.setUTCSeconds(fitness);
        const diff =  timeToFF.getTime() - new Date().getTime();
        if(diff > 0){
            // so 1hr to replenish 10%, which means 6mins for each 1%.
            // 25% is 2.5 hours.
            const perCentDec = diff/1000/60/6;
            percent = 100 - perCentDec;
            //console.log(`${fitness} - ${timeToFF.getTime()} - ${perCentDec} - ${percent}`);
        }
        else{
            percent = 100;
        }
    }
    percent = Math.round(percent);
    return percent;
}

export const calculateTeamFitnessPercentage = (players : {[id: number] : Player | null }, lineup : number[]) : number =>{
    let total = 0;
    
    for(var i =0;i<lineup.length;i++){
        const assetId = lineup[i];
        if(assetId == 0){
            continue;
        }
        const player = players[assetId];
        if(player == null){
            continue;
        }
        if(player.full_fitness_time == 0){
            total += 100;
        }
        else{
            const date = new Date(0);
            date.setUTCSeconds(player.full_fitness_time);
            const diff =  date.getTime() - new Date().getTime();
            if(diff > 0){
            const perCentDec = diff/1000/60/6;
            total += (100 - perCentDec);
            }
            else{
            total+=100;
            }
        }
    }
    total /= 11;
    return Math.round(total);
}