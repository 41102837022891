import { Button, useMediaQuery, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { Team } from "../../types/Team";
import { Tournament } from "../../types/Tournament";
import { EnterTourno } from "./EnterTourno";
import { JoinTourno } from "./JoinTourno";
import CelebrationIcon from "@mui/icons-material/Celebration";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

export const TournamentBanner: FC = () => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );

  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [showJoinDialog, setShowJoinDialog] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(0);
  const [myTeam, setMyTeam] = useState<Team | null>(null);
  const [showEnterTournDialog, setShowEnterTournDialog] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    //find active tournament - if any
    if (gameData.tournaments != null) {
      let tournament = gameData.tournaments.find((t) => t.active);
      if (tournament != null) {
        setTournament(tournament);
      }
    }

    // Get your team in the tourament
    if (gameData.tournamentPositions != null) {
      //console.log(gameData.tournamentPositions);
      let pos = gameData.tournamentPositions.find(
        (t) => t.manager === userData.userName
      );
      if (pos != null && gameData.userTeams != null) {
        let team = gameData.userTeams[pos.teamId];
        setMyTeam(team);
      }
    }
  }, [gameData]);

  // Show Join tourno dialog
  const joinTourno = () => {
    setShowJoinDialog(true);
  };

  // fired when they have closed the Enter Tourno dialog
  const onEnterTourno = () => {
    setShowEnterTournDialog(false);
  };

  const onJoinTourno = (teamId: number) => {
    if (teamId > 0) {
      setSelectedTeamId(teamId);
      setShowEnterTournDialog(true);
    }
    setShowJoinDialog(false);
  };

  const showWinner = () => {
    if (
      gameData.tournamentPositions != null &&
      gameData.tournamentPositions.length > 0 &&
      gameData.opponentTeams != null
    ) {
      return (
        <>
          <EmojiEventsIcon />
          <span style={{ fontSize: "16px" }}>
            and...the winner is <b>{gameData.tournamentPositions[0].manager}</b>
            !
          </span>
          <EmojiEventsIcon />
        </>
      );
    } else {
      return (
        <span style={{ fontSize: "16px" }}>That's it! It's finished!</span>
      );
    }
  };

  // Renderer callback with condition
  const renderer = (props: CountdownRenderProps) => {
    if (props.completed) {
      // Render a completed state
      return showWinner();
    } else {
      return (
        <>
          <div style={{ fontSize: "20px", fontWeight: "bold", width: "300px" }}>
            <span>
              {props.days} days {props.hours}h {props.minutes}m {props.seconds}s
            </span>
          </div>
        </>
      );
    }
  };

  const displayWinnerClass = (): string => {
    if (tournament != null && tournament.expired) {
      return "winner";
    }
    return "";
  };

  const renderDisplay = () => {
    if (tournament == null) {
      return (
        <div
          style={{
            padding: "5px",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "14px" }}>
            There are no active tournaments at the moment
          </span>
        </div>
      );
    } else {
      // check if it has started?
      if (tournament.startDate > new Date()) {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                {tournament.name}
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                IS OPEN!
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <span style={{ marginLeft: "10px" }}>STARTS in ...</span>
              <Countdown date={tournament.startDate} renderer={renderer} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5px",
                gap: "10px",
                border: "1px solid #22395a",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div>
                  <span>Prize: </span>
                  <span style={{ fontWeight: "bold" }}>{tournament.prize}</span>
                  <img
                    src="/images/VNTY-icon-red.png"
                    alt="VNTY Token"
                    style={{ marginLeft: "5px", width: "16px" }}
                  />
                  {/* <img
                    src="/images/wax.png"
                    alt=""
                    style={{ marginLeft: "5px", width: "16px" }}
                  /> */}
                </div>
                <div>
                  <span>Buy In: </span>
                  <span style={{ fontWeight: "bold" }}>{tournament.buyIn}</span>
                  <img
                    src="/images/VNTY-icon-red.png"
                    alt="VNTY Token"
                    style={{ marginLeft: "5px", width: "16px" }}
                  />
                  {/* <img
                    src="/images/wax.png"
                    alt=""
                    style={{ marginLeft: "5px", width: "16px" }}
                  /> */}
                </div>
                <div>
                  <span>Max Teams: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.teamCount}
                  </span>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div>
                  <span>Matches Per Team: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.gamesCount}
                  </span>
                </div>
                <div>
                  <span>Remaining Spaces: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.teamCount -
                      gameData.tournamentPositions?.length!}
                  </span>
                </div>
                <div>
                  <span>Unlock Limit: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.unlockLimit}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        // the tourno is running.
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                {tournament.name}
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                IS {tournament.expired ? "OVER!" : "ON!"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <span style={{ marginLeft: "10px" }}>
                {!tournament.expired ? "ENDS in ..." : ""}
              </span>
              <Countdown date={tournament.endDate} renderer={renderer} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5px",
                gap: "10px",
                border: "1px solid #22395a",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div>
                  <span>Prize: </span>
                  <span style={{ fontWeight: "bold" }}>{tournament.prize}</span>
                  <img
                    src="/images/VNTY-icon-red.png"
                    alt="VNTY Token"
                    style={{ marginLeft: "5px", width: "16px" }}
                  />
                </div>
                <div>
                  <span>Buy In: </span>
                  <span style={{ fontWeight: "bold" }}>{tournament.buyIn}</span>
                  <img
                    src="/images/VNTY-icon-red.png"
                    alt="VNTY Token"
                    style={{ marginLeft: "5px", width: "16px" }}
                  />
                </div>
                <div>
                  <span>Max Teams: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.teamCount}
                  </span>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <div>
                  <span>Matches Per Team: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.gamesCount}
                  </span>
                </div>
                <div>
                  <span>Remaining Spaces: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.teamCount -
                      gameData.tournamentPositions?.length!}
                  </span>
                </div>
                <div>
                  <span>Unlock Limit: </span>
                  <span style={{ fontWeight: "bold" }}>
                    {tournament.unlockLimit}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="col-md-12 mt-5">
        <div
          className="card col-12"
          style={{
            backgroundColor: "#000b1d",
            color: "#fff",
            border: "1px solid #99ff32",
          }}
        >
          <div className="card-body">
            <h5 className="card-title">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    id="tourno"
                    style={{ marginRight: "10px" }}
                    src={"/images/tourno-icon.svg"}
                    width="42"
                    height="42"
                    alt="Current Tournament"
                  />
                  Battle Tournament
                </div>
                {tournament != null && myTeam == null && !tournament.expired && (
                  <Button
                    className="green-btn"
                    variant="contained"
                    style={{ display: "flex" }}
                    onClick={joinTourno}
                  >
                    Join
                  </Button>
                )}
              </div>
            </h5>
          </div>
          <div className="list-group list-group-flush">
            <div
              className={displayWinnerClass()}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "5px",
                padding: "5px",
                fontSize: "12px",
              }}
            >
              {renderDisplay()}
            </div>
          </div>
        </div>
      </div>
      <JoinTourno showDialog={showJoinDialog} onJoinTourno={onJoinTourno} />
      <EnterTourno
        teamId={selectedTeamId}
        showDialog={showEnterTournDialog}
        onEnterTourno={onEnterTourno}
      />
    </>
  );
};
