import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { createRef, FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Cropper, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../../css/style.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import UndoIcon from "@mui/icons-material/Undo";
import { webServiceInstance } from "../../utils/webServiceInstance";
import DoneIcon from "@mui/icons-material/Done";
import { useUpdateAvatar } from "../../utils/checkUserAvatar";

type AvatarUploaderProps = {
  showDialog: boolean;
  onAvatarUpdated(): void;
};

const INITIAL = "INITIAL";
const SAVING_AVATAR = "SAVING_AVATAR";
const SAVE_COMPLETE = "SAVE_COMPLETE";

export const AvatarUploader: FC<AvatarUploaderProps> = ({
  showDialog,
  onAvatarUpdated,
}) => {
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const cropperRef = createRef<ReactCropperElement>();
  const fileRef = createRef<HTMLInputElement>();
  const [uploaded, setUploaded] = useState(null as string | null);
  const [cropped, setCropped] = useState(null as string | null);
  const [uploadStatus, setUploadStatus] = useState(INITIAL);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [error, setError] = useState("");
  const [updateAvatar, setUpdateAvatar] = useState(false);
  const avatar = useUpdateAvatar(updateAvatar);

  useEffect(() => {
    const saveAvatar = async () => {
      try {
        console.log(formData);
        const result = await webServiceInstance.post("/profile", formData);

        console.log(result);

        if (result == null || result.data.toString() != "GOOD") {
          setError(
            "Damn :( An error occurred while saving your avatar. Call ghostbusters...sorry VN!"
          );
        } else {
          setTimeout(() => {
            setUploadStatus(SAVE_COMPLETE);
            setUpdateAvatar(true);
          }, 500);
        }
      } catch (e: any) {
        console.log(e);
        setError(
          "Damn :( An error occurred while saving your avatar. Call ghostbusters...sorry VN!"
        );
      }
    };

    if (uploadStatus == SAVING_AVATAR) {
      saveAvatar();
    }
  }, [uploadStatus]);

  const onFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      file2Base64(file).then((base64) => {
        setUploaded(base64);
      });
    }
  };

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || "");
      reader.onerror = (error) => reject(error);
    });
  };

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setCropped(cropper.getCroppedCanvas({ width: 50, height: 50 }).toDataURL());
    cropper
      .getCroppedCanvas({
        width: 50,
        height: 50,
        imageSmoothingEnabled: false,
        imageSmoothingQuality: "high",
      })
      .toBlob((blob: any) => {
        const data = new FormData();
        data.append("avatar", blob, userData.userName + "-avatar.png");
        setFormData(data);
        console.log(blob);
        console.log(data);
      });
  };

  const onClose = () => {
    onAvatarUpdated();
    setError("");
    setCropped(null);
    setUploaded(null);
    setUploadStatus(INITIAL);
    setFormData(null);
    setUpdateAvatar(false);
  };

  const onUndo = () => {
    setFormData(null);
    setCropped(null);
    setUploaded(null);
  };

  const onUpload = () => {
    setUploadStatus(SAVING_AVATAR);
  };

  const renderDisplay = () => {
    if (error) {
      return (
        <>
          <span style={{ textAlign: "center" }}>{error}</span>
          <Button variant="contained" className="green-btn" onClick={onClose}>
            OK
          </Button>
        </>
      );
    }
    if (uploadStatus == INITIAL) {
      return uploaded ? (
        <>
          <Cropper
            src={uploaded}
            style={{ height: 200, width: 200 }}
            autoCropArea={1}
            aspectRatio={1}
            viewMode={3}
            guides={false}
            ref={cropperRef}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",

              width: "60px",
              border: "2px dotted #99ff32",
              borderRadius: "2px",
            }}
          >
            {!cropped && <img width={50} height={50} />}
            {cropped && <img src={cropped} alt="Cropped!" />}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <IconButton onClick={onUndo} size="small" className="blue-btn">
              <UndoIcon />
            </IconButton>
            <Button onClick={onCrop} className="blue-btn">
              Crop
            </Button>
            {cropped && (
              <Button className="green-btn" onClick={onUpload}>
                Save
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileRef}
            onChange={onFileInputChange}
            accept="image/png,image/jpeg,image/gif"
          />
          <Button
            className="green-btn"
            size="small"
            onClick={() => fileRef.current?.click()}
          >
            Upload
          </Button>
        </>
      );
    } else if (uploadStatus == SAVING_AVATAR) {
      return (
        <>
          <CircularProgress color="secondary" />
          <span>Saving...</span>
        </>
      );
    } else if (uploadStatus == SAVE_COMPLETE) {
      return (
        <>
          <DoneIcon color="secondary" sx={{ fontSize: "100px" }} />
          <Button variant="contained" className="green-btn" onClick={onClose}>
            OK
          </Button>
        </>
      );
    }
  };

  return (
    <Dialog open={showDialog} maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        Update Avatar
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            width: "260px",
            height: "350px",
          }}
        >
          {renderDisplay()}
        </div>
      </DialogContent>
    </Dialog>
  );
};
