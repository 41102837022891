import { combineReducers } from "redux";
import { userSession } from "./userSession";
import { appConfig } from "./config";
import { schemaInfo } from "./schemaInfo";
import { userAssets } from "./userAssets";
import * as actionTypes from '../actions/types';
import { gameData } from "./gameData";
import { burnAttributeCardResult, deleteTeamResult, enterTournoResult, saveTeamResult, startMatchResult, sendTokensResult, airgrabResult, stakeTransferResult, destakeTransferResult } from "./transactions";
import { challengeOpponent } from "./challengeOpponent";
import { boostPlayer } from "./boostPlayer";

const appReducer = combineReducers({userSession, appConfig, gameData, schemaInfo, userAssets, saveTeamResult, deleteTeamResult, burnAttributeCardResult, enterTournoResult, startMatchResult, challengeOpponent, boostPlayer, sendTokensResult, airgrabResult, stakeTransferResult, destakeTransferResult});

const rootReducer = (state: any, action: any) =>{
    if(action.type == actionTypes.USER_LOGOUT){
        return appReducer(undefined, action); 
    }
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;