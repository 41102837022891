import { FC } from "react";
import { MyTeamFormationProps } from "../props";
import { Templates } from "../../../inventory";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { TeamDetails } from "./teamDetails";

export const MyTeam541: FC<MyTeamFormationProps> = ({ team }) => {
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const getImagePath = (assetId: number): string => {
    if (assetData.assets == null) {
      return "images/placeholder_player.png";
    }
    var asset = assetData.assets[assetId];
    if (asset == null) {
      return "images/placeholder_player.png";
    }
    return "images/" + Templates[asset.template!.template_id].imgPath;
  };
  return (
    <div
      className="col-xxl-4 col-xl-6  col-lg-6 col-md-12 team-dash"
      style={{ padding: "5px" }}
    >
      <div
        className="row shadow"
        style={{
          background: "#fff",
          margin: "0px",
          padding: "2px",
          backgroundColor: "#000b1d",
          color: "#fff",
          border: "1px solid #99ff32",
        }}
      >
        <div
          className="col-xxl-6 col-xl-6 col-md-6 team-dash"
          style={{
            padding: "0",
          }}
        >
          <div className="th-pitch-wrapper">
            <div className="th-row">
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front">
                      {" "}
                      <img src={getImagePath(team.lineup[10])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-row">
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front">
                      {" "}
                      <img src={getImagePath(team.lineup[6])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front">
                      {" "}
                      <img src={getImagePath(team.lineup[7])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front">
                      {" "}
                      <img src={getImagePath(team.lineup[8])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front">
                      {" "}
                      <img src={getImagePath(team.lineup[9])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-row">
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front-sm">
                      {" "}
                      <img src={getImagePath(team.lineup[1])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front-sm">
                      {" "}
                      <img src={getImagePath(team.lineup[2])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front-sm">
                      {" "}
                      <img src={getImagePath(team.lineup[3])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front-sm">
                      {" "}
                      <img src={getImagePath(team.lineup[4])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front-sm">
                      {" "}
                      <img src={getImagePath(team.lineup[5])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-row">
              <div className="column">
                <div className="th-player-column">
                  <div className="th-card-box">
                    <div className="th-flip-box-front">
                      {" "}
                      <img src={getImagePath(team.lineup[0])} />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TeamDetails team={team} />
      </div>
    </div>
  );
};
