import { Template } from "../types/Template";

export const ATTRIBUTE_BOOST_CARD_100 = 607896;
export const ATTRIBUTE_BOOST_CARD_250 = 607898;
export const ATTRIBUTE_BOOST_CARD_500 = 607899;

export const Templates: { [id: string] : Template; } = {
    // series1 - Common
    '262476':	{id:'262476', name:'Alan Harrington', no:'1A', imgPath:'Series1/Common/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '262482':	{id:'262482', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Common/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '262486':	{id:'262486', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Common/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '262488':	{id:'262488', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Common/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '262490':	{id:'262490', name:'Billy Liddell', no:'3A', imgPath:'Series1/Common/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '262493':	{id:'262493', name:'The Accountant', no:'3B', imgPath:'Series1/Common/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '262497':	{id:'262497', name:'Bobby Robson', no:'4A', imgPath:'Series1/Common/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '262498':	{id:'262498', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Common/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '262502':	{id:'262502', name:'Denis Law', no:'5A', imgPath:'Series1/Common/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '262503':	{id:'262503', name:'The King', no:'5B', imgPath:'Series1/Common/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '262504':	{id:'262504', name:'George Best', no:'6A', imgPath:'Series1/Common/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '262505':	{id:'262505', name:'El Beatle', no:'6B', imgPath:'Series1/Common/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '262506':	{id:'262506', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Common/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '262507':	{id:'262507', name:'Wor Jackie', no:'7B', imgPath:'Series1/Common/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '262508':	{id:'262508', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Common/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '262509':	{id:'262509', name:'Greavsie', no:'8B', imgPath:'Series1/Common/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '262511':	{id:'262511', name:'John Molyneux', no:'9A', imgPath:'Series1/Common/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '262513':	{id:'262513', name:'Big John', no:'9B', imgPath:'Series1/Common/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '262516':	{id:'262516', name:'Len Shackleton', no:'10A', imgPath:'Series1/Common/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '262517':	{id:'262517', name:'The Clown Prince', no:'10B', imgPath:'Series1/Common/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '262521':	{id:'262521', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Common/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '262524':	{id:'262524', name:'Hurstie', no:'11B', imgPath:'Series1/Common/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '262525':	{id:'262525', name:'Cliff Jones', no:'12A', imgPath:'Series1/Common/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '262526':	{id:'262526', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Common/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263796':	{id:'263796', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Common/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '262529':	{id:'262529', name:'The Terrier', no:'13B', imgPath:'Series1/Common/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '262620':	{id:'262620', name:'Roy Shiner', no:'14A', imgPath:'Series1/Common/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '262622':	{id:'262622', name:'The Islander', no:'14B', imgPath:'Series1/Common/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '262623':	{id:'262623', name:'John Wheeler', no:'15A', imgPath:'Series1/Common/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '262705':	{id:'262705', name:'Ole Captain', no:'15B', imgPath:'Series1/Common/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '262624':	{id:'262624', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Common/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '262626':	{id:'262626', name:'The Salesman', no:'16B', imgPath:'Series1/Common/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '262629':	{id:'262629', name:'Brian Clough', no:'17A', imgPath:'Series1/Common/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '262631':	{id:'262631', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Common/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '262634':	{id:'262634', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Common/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '262636':	{id:'262636', name:'The TV Icon', no:'18B', imgPath:'Series1/Common/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '262642':	{id:'262642', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Common/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '262643':	{id:'262643', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Common/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '262646':	{id:'262646', name:'Dave Mackay', no:'20A', imgPath:'Series1/Common/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '262649':	{id:'262649', name:'Iron Man', no:'20B', imgPath:'Series1/Common/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '262652':	{id:'262652', name:'Tommy Best', no:'21A', imgPath:'Series1/Common/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '262664':	{id:'262664', name:'Besty', no:'21B', imgPath:'Series1/Common/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '262707':	{id:'262707', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Common/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '262710':	{id:'262710', name:'The Ambassador', no:'22B', imgPath:'Series1/Common/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '262712':	{id:'262712', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Common/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '262719':	{id:'262719', name:'Big Mal', no:'23B', imgPath:'Series1/Common/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '262721':	{id:'262721', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Common/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '262722':	{id:'262722', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Common/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '262724':	{id:'262724', name:'Peter McParland', no:'25A', imgPath:'Series1/Common/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '262726':	{id:'262726', name:'Peter The Great', no:'25B', imgPath:'Series1/Common/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    
    
    // uncommon
    '263715':	{id:'263715', name:'Alan Harrington', no:'1A', imgPath:'Series1/Uncommon/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '263716':	{id:'263716', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Uncommon/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '263717':	{id:'263717', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Uncommon/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '263719':	{id:'263719', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Uncommon/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '263720':	{id:'263720', name:'Billy Liddell', no:'3A', imgPath:'Series1/Uncommon/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '263721':	{id:'263721', name:'The Accountant', no:'3B', imgPath:'Series1/Uncommon/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '263722':	{id:'263722', name:'Bobby Robson', no:'4A', imgPath:'Series1/Uncommon/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '263723':	{id:'263723', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Uncommon/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '263724':	{id:'263724', name:'Denis Law', no:'5A', imgPath:'Series1/Uncommon/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263725':	{id:'263725', name:'The King', no:'5B', imgPath:'Series1/Uncommon/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263726':	{id:'263726', name:'George Best', no:'6A', imgPath:'Series1/Uncommon/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263727':	{id:'263727', name:'El Beatle', no:'6B', imgPath:'Series1/Uncommon/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263728':	{id:'263728', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Uncommon/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '263730':	{id:'263730', name:'Wor Jackie', no:'7B', imgPath:'Series1/Uncommon/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '263732':	{id:'263732', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Uncommon/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '263733':	{id:'263733', name:'Greavsie', no:'8B', imgPath:'Series1/Uncommon/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '263735':	{id:'263735', name:'John Molyneux', no:'9A', imgPath:'Series1/Uncommon/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '263736':	{id:'263736', name:'Big John', no:'9B', imgPath:'Series1/Uncommon/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '263738':	{id:'263738', name:'Len Shackleton', no:'10A', imgPath:'Series1/Uncommon/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '263739':	{id:'263739', name:'The Clown Prince', no:'10B', imgPath:'Series1/Uncommon/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '263741':	{id:'263741', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Uncommon/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '263742':	{id:'263742', name:'Hurstie', no:'11B', imgPath:'Series1/Uncommon/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '263744':	{id:'263744', name:'Cliff Jones', no:'12A', imgPath:'Series1/Uncommon/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263745':	{id:'263745', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Uncommon/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263798':	{id:'263798', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Uncommon/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '263750':	{id:'263750', name:'The Terrier', no:'13B', imgPath:'Series1/Uncommon/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '263751':	{id:'263751', name:'Roy Shiner', no:'14A', imgPath:'Series1/Uncommon/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '263754':	{id:'263754', name:'The Islander', no:'14B', imgPath:'Series1/Uncommon/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '263755':	{id:'263755', name:'John Wheeler', no:'15A', imgPath:'Series1/Uncommon/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '263757':	{id:'263757', name:'Ole Captain', no:'15B', imgPath:'Series1/Uncommon/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '263758':	{id:'263758', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Uncommon/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '263760':	{id:'263760', name:'The Salesman', no:'16B', imgPath:'Series1/Uncommon/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '263764':	{id:'263764', name:'Brian Clough', no:'17A', imgPath:'Series1/Uncommon/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '263765':	{id:'263765', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Uncommon/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '263767':	{id:'263767', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Uncommon/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '263769':	{id:'263769', name:'The TV Icon', no:'18B', imgPath:'Series1/Uncommon/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '263771':	{id:'263771', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Uncommon/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '263773':	{id:'263773', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Uncommon/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '263774':	{id:'263774', name:'Dave Mackay', no:'20A', imgPath:'Series1/Uncommon/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '263776':	{id:'263776', name:'Iron Man', no:'20B', imgPath:'Series1/Uncommon/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '263778':	{id:'263778', name:'Tommy Best', no:'21A', imgPath:'Series1/Uncommon/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '263780':	{id:'263780', name:'Besty', no:'21B', imgPath:'Series1/Uncommon/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '263781':	{id:'263781', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Uncommon/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263782':	{id:'263782', name:'The Ambassador', no:'22B', imgPath:'Series1/Uncommon/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263783':	{id:'263783', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Uncommon/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '263784':	{id:'263784', name:'Big Mal', no:'23B', imgPath:'Series1/Uncommon/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '263786':	{id:'263786', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Uncommon/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '263789':	{id:'263789', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Uncommon/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '263790':	{id:'263790', name:'Peter McParland', no:'25A', imgPath:'Series1/Uncommon/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '263791':	{id:'263791', name:'Peter The Great', no:'25B', imgPath:'Series1/Uncommon/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},


    // limited
    '263800':	{id:'263800', name:'Alan Harrington', no:'1A', imgPath:'Series1/Limited/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '263801':	{id:'263801', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Limited/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '263802':	{id:'263802', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Limited/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '263803':	{id:'263803', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Limited/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '263806':	{id:'263806', name:'Billy Liddell', no:'3A', imgPath:'Series1/Limited/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '263807':	{id:'263807', name:'The Accountant', no:'3B', imgPath:'Series1/Limited/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '263809':	{id:'263809', name:'Bobby Robson', no:'4A', imgPath:'Series1/Limited/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '263810':	{id:'263810', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Limited/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '263811':	{id:'263811', name:'Denis Law', no:'5A', imgPath:'Series1/Limited/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263812':	{id:'263812', name:'The King', no:'5B', imgPath:'Series1/Limited/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263813':	{id:'263813', name:'George Best', no:'6A', imgPath:'Series1/Limited/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263814':	{id:'263814', name:'El Beatle', no:'6B', imgPath:'Series1/Limited/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263815':	{id:'263815', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Limited/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '263816':	{id:'263816', name:'Wor Jackie', no:'7B', imgPath:'Series1/Limited/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '263823':	{id:'263823', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Limited/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '263824':	{id:'263824', name:'Greavsie', no:'8B', imgPath:'Series1/Limited/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '263825':	{id:'263825', name:'John Molyneux', no:'9A', imgPath:'Series1/Limited/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '263826':	{id:'263826', name:'Big John', no:'9B', imgPath:'Series1/Limited/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '263827':	{id:'263827', name:'Len Shackleton', no:'10A', imgPath:'Series1/Limited/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '263828':	{id:'263828', name:'The Clown Prince', no:'10B', imgPath:'Series1/Limited/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '263829':	{id:'263829', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Limited/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '263830':	{id:'263830', name:'Hurstie', no:'11B', imgPath:'Series1/Limited/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '263831':	{id:'263831', name:'Cliff Jones', no:'12A', imgPath:'Series1/Limited/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263832':	{id:'263832', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Limited/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263833':	{id:'263833', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Limited/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '263834':	{id:'263834', name:'The Terrier', no:'13B', imgPath:'Series1/Limited/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '263835':	{id:'263835', name:'Roy Shiner', no:'14A', imgPath:'Series1/Limited/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '263836':	{id:'263836', name:'The Islander', no:'14B', imgPath:'Series1/Limited/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '263837':	{id:'263837', name:'John Wheeler', no:'15A', imgPath:'Series1/Limited/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '263838':	{id:'263838', name:'Ole Captain', no:'15B', imgPath:'Series1/Limited/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '263839':	{id:'263839', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Limited/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '263929':	{id:'263929', name:'The Salesman', no:'16B', imgPath:'Series1/Limited/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '263930':	{id:'263930', name:'Brian Clough', no:'17A', imgPath:'Series1/Limited/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '263931':	{id:'263931', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Limited/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '263932':	{id:'263932', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Limited/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '263933':	{id:'263933', name:'The TV Icon', no:'18B', imgPath:'Series1/Limited/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '263934':	{id:'263934', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Limited/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '263935':	{id:'263935', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Limited/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '263936':	{id:'263936', name:'Dave Mackay', no:'20A', imgPath:'Series1/Limited/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '263937':	{id:'263937', name:'Iron Man', no:'20B', imgPath:'Series1/Limited/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '263938':	{id:'263938', name:'Tommy Best', no:'21A', imgPath:'Series1/Limited/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '263940':	{id:'263940', name:'Besty', no:'21B', imgPath:'Series1/Limited/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '263941':	{id:'263941', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Limited/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263942':	{id:'263942', name:'The Ambassador', no:'22B', imgPath:'Series1/Limited/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263944':	{id:'263944', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Limited/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '263945':	{id:'263945', name:'Big Mal', no:'23B', imgPath:'Series1/Limited/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '263946':	{id:'263946', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Limited/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '263947':	{id:'263947', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Limited/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '263948':	{id:'263948', name:'Peter McParland', no:'25A', imgPath:'Series1/Limited/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '263949':	{id:'263949', name:'Peter The Great', no:'25B', imgPath:'Series1/Limited/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    

    // relic
    '263951':	{id:'263951', name:'Alan Harrington', no:'1A', imgPath:'Series1/Relic/1a.png', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '263952':	{id:'263952', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Relic/1b.png', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '263953':	{id:'263953', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Relic/2a.png', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '263954':	{id:'263954', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Relic/2b.png', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '263955':	{id:'263955', name:'Billy Liddell', no:'3A', imgPath:'Series1/Relic/3a.png', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '263956':	{id:'263956', name:'The Accountant', no:'3B', imgPath:'Series1/Relic/3b.png', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '263957':	{id:'263957', name:'Bobby Robson', no:'4A', imgPath:'Series1/Relic/4a.png', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '263958':	{id:'263958', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Relic/4b.png', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '263959':	{id:'263959', name:'Denis Law', no:'5A', imgPath:'Series1/Relic/5a.png', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263960':	{id:'263960', name:'The King', no:'5B', imgPath:'Series1/Relic/5b.png', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263961':	{id:'263961', name:'George Best', no:'6A', imgPath:'Series1/Relic/6a.png', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263962':	{id:'263962', name:'El Beatle', no:'6B', imgPath:'Series1/Relic/6b.png', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263963':	{id:'263963', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Relic/7a.png', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '263964':	{id:'263964', name:'Wor Jackie', no:'7B', imgPath:'Series1/Relic/7b.png', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '263965':	{id:'263965', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Relic/8a.png', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '263966':	{id:'263966', name:'Greavsie', no:'8B', imgPath:'Series1/Relic/8b.png', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '263967':	{id:'263967', name:'John Molyneux', no:'9A', imgPath:'Series1/Relic/9a.png', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '263968':	{id:'263968', name:'Big John', no:'9B', imgPath:'Series1/Relic/9b.png', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '263969':	{id:'263969', name:'Len Shackleton', no:'10A', imgPath:'Series1/Relic/10a.png', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '263970':	{id:'263970', name:'The Clown Prince', no:'10B', imgPath:'Series1/Relic/10b.png', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '263971':	{id:'263971', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Relic/11a.png', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '263972':	{id:'263972', name:'Hurstie', no:'11B', imgPath:'Series1/Relic/11b.png', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '263973':	{id:'263973', name:'Cliff Jones', no:'12A', imgPath:'Series1/Relic/12a.png', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263974':	{id:'263974', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Relic/12b.png', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '263976':	{id:'263976', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Relic/13a.png', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '263977':	{id:'263977', name:'The Terrier', no:'13B', imgPath:'Series1/Relic/13b.png', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '263978':	{id:'263978', name:'Roy Shiner', no:'14A', imgPath:'Series1/Relic/14a.png', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '263979':	{id:'263979', name:'The Islander', no:'14B', imgPath:'Series1/Relic/14b.png', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '263980':	{id:'263980', name:'John Wheeler', no:'15A', imgPath:'Series1/Relic/15a.png', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '263981':	{id:'263981', name:'Ole Captain', no:'15B', imgPath:'Series1/Relic/15b.png', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '263982':	{id:'263982', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Relic/16a.png', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '263983':	{id:'263983', name:'The Salesman', no:'16B', imgPath:'Series1/Relic/16b.png', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '263984':	{id:'263984', name:'Brian Clough', no:'17A', imgPath:'Series1/Relic/17a.png', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '263985':	{id:'263985', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Relic/17b.png', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '263986':	{id:'263986', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Relic/18a.png', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '263987':	{id:'263987', name:'The TV Icon', no:'18B', imgPath:'Series1/Relic/18b.png', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '263988':	{id:'263988', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Relic/19a.png', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '263989':	{id:'263989', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Relic/19b.png', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '263990':	{id:'263990', name:'Dave Mackay', no:'20A', imgPath:'Series1/Relic/20a.png', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '263991':	{id:'263991', name:'Iron Man', no:'20B', imgPath:'Series1/Relic/20b.png', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '263992':	{id:'263992', name:'Tommy Best', no:'21A', imgPath:'Series1/Relic/21a.png', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '263993':	{id:'263993', name:'Besty', no:'21B', imgPath:'Series1/Relic/21b.png', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '263994':	{id:'263994', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Relic/22a.png', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263996':	{id:'263996', name:'The Ambassador', no:'22B', imgPath:'Series1/Relic/22b.png', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '263999':	{id:'263999', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Relic/23a.png', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264000':	{id:'264000', name:'Big Mal', no:'23B', imgPath:'Series1/Relic/23b.png', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264004':	{id:'264004', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Relic/24a.png', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264007':	{id:'264007', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Relic/24b.png', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264012':	{id:'264012', name:'Peter McParland', no:'25A', imgPath:'Series1/Relic/25a.png', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '264015':	{id:'264015', name:'Peter The Great', no:'25B', imgPath:'Series1/Relic/25b.png', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    

    // rare
    '264023':	{id:'264023', name:'Alan Harrington', no:'1A', imgPath:'Series1/Rare/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264024':	{id:'264024', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Rare/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264025':	{id:'264025', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Rare/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264026':	{id:'264026', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Rare/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264027':	{id:'264027', name:'Billy Liddell', no:'3A', imgPath:'Series1/Rare/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264028':	{id:'264028', name:'The Accountant', no:'3B', imgPath:'Series1/Rare/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264030':	{id:'264030', name:'Bobby Robson', no:'4A', imgPath:'Series1/Rare/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264035':	{id:'264035', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Rare/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264036':	{id:'264036', name:'Denis Law', no:'5A', imgPath:'Series1/Rare/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264038':	{id:'264038', name:'The King', no:'5B', imgPath:'Series1/Rare/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264041':	{id:'264041', name:'George Best', no:'6A', imgPath:'Series1/Rare/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264042':	{id:'264042', name:'El Beatle', no:'6B', imgPath:'Series1/Rare/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264043':	{id:'264043', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Rare/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264046':	{id:'264046', name:'Wor Jackie', no:'7B', imgPath:'Series1/Rare/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264101':	{id:'264101', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Rare/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264103':	{id:'264103', name:'Greavsie', no:'8B', imgPath:'Series1/Rare/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264104':	{id:'264104', name:'John Molyneux', no:'9A', imgPath:'Series1/Rare/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264105':	{id:'264105', name:'Big John', no:'9B', imgPath:'Series1/Rare/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264106':	{id:'264106', name:'Len Shackleton', no:'10A', imgPath:'Series1/Rare/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264107':	{id:'264107', name:'The Clown Prince', no:'10B', imgPath:'Series1/Rare/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264108':	{id:'264108', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Rare/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264109':	{id:'264109', name:'Hurstie', no:'11B', imgPath:'Series1/Rare/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264110':	{id:'264110', name:'Cliff Jones', no:'12A', imgPath:'Series1/Rare/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264111':	{id:'264111', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Rare/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264112':	{id:'264112', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Rare/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264113':	{id:'264113', name:'The Terrier', no:'13B', imgPath:'Series1/Rare/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264114':	{id:'264114', name:'Roy Shiner', no:'14A', imgPath:'Series1/Rare/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264115':	{id:'264115', name:'The Islander', no:'14B', imgPath:'Series1/Rare/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264116':	{id:'264116', name:'John Wheeler', no:'15A', imgPath:'Series1/Rare/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264117':	{id:'264117', name:'Ole Captain', no:'15B', imgPath:'Series1/Rare/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264118':	{id:'264118', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Rare/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264119':	{id:'264119', name:'The Salesman', no:'16B', imgPath:'Series1/Rare/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264120':	{id:'264120', name:'Brian Clough', no:'17A', imgPath:'Series1/Rare/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264122':	{id:'264122', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Rare/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264123':	{id:'264123', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Rare/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264124':	{id:'264124', name:'The TV Icon', no:'18B', imgPath:'Series1/Rare/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264125':	{id:'264125', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Rare/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264126':	{id:'264126', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Rare/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264127':	{id:'264127', name:'Dave Mackay', no:'20A', imgPath:'Series1/Rare/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264128':	{id:'264128', name:'Iron Man', no:'20B', imgPath:'Series1/Rare/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264130':	{id:'264130', name:'Tommy Best', no:'21A', imgPath:'Series1/Rare/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264131':	{id:'264131', name:'Besty', no:'21B', imgPath:'Series1/Rare/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264132':	{id:'264132', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Rare/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264133':	{id:'264133', name:'The Ambassador', no:'22B', imgPath:'Series1/Rare/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264134':	{id:'264134', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Rare/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264135':	{id:'264135', name:'Big Mal', no:'23B', imgPath:'Series1/Rare/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264136':	{id:'264136', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Rare/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264137':	{id:'264137', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Rare/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264138':	{id:'264138', name:'Peter McParland', no:'25A', imgPath:'Series1/Rare/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '264139':	{id:'264139', name:'Peter The Great', no:'25B', imgPath:'Series1/Rare/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},


    // epic
    '264142':	{id:'264142', name:'Alan Harrington', no:'1A', imgPath:'Series1/Epic/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264143':	{id:'264143', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Epic/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264144':	{id:'264144', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Epic/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264146':	{id:'264146', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Epic/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264147':	{id:'264147', name:'Billy Liddell', no:'3A', imgPath:'Series1/Epic/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264148':	{id:'264148', name:'The Accountant', no:'3B', imgPath:'Series1/Epic/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264149':	{id:'264149', name:'Bobby Robson', no:'4A', imgPath:'Series1/Epic/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264150':	{id:'264150', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Epic/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264151':	{id:'264151', name:'Denis Law', no:'5A', imgPath:'Series1/Epic/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264152':	{id:'264152', name:'The King', no:'5B', imgPath:'Series1/Epic/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264153':	{id:'264153', name:'George Best', no:'6A', imgPath:'Series1/Epic/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264154':	{id:'264154', name:'El Beatle', no:'6B', imgPath:'Series1/Epic/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264155':	{id:'264155', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Epic/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264156':	{id:'264156', name:'Wor Jackie', no:'7B', imgPath:'Series1/Epic/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264157':	{id:'264157', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Epic/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264158':	{id:'264158', name:'Greavsie', no:'8B', imgPath:'Series1/Epic/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264159':	{id:'264159', name:'John Molyneux', no:'9A', imgPath:'Series1/Epic/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264160':	{id:'264160', name:'Big John', no:'9B', imgPath:'Series1/Epic/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264161':	{id:'264161', name:'Len Shackleton', no:'10A', imgPath:'Series1/Epic/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264162':	{id:'264162', name:'The Clown Prince', no:'10B', imgPath:'Series1/Epic/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264163':	{id:'264163', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Epic/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264164':	{id:'264164', name:'Hurstie', no:'11B', imgPath:'Series1/Epic/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264165':	{id:'264165', name:'Cliff Jones', no:'12A', imgPath:'Series1/Epic/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264166':	{id:'264166', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Epic/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264167':	{id:'264167', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Epic/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264168':	{id:'264168', name:'The Terrier', no:'13B', imgPath:'Series1/Epic/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264169':	{id:'264169', name:'Roy Shiner', no:'14A', imgPath:'Series1/Epic/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264170':	{id:'264170', name:'The Islander', no:'14B', imgPath:'Series1/Epic/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264171':	{id:'264171', name:'John Wheeler', no:'15A', imgPath:'Series1/Epic/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264172':	{id:'264172', name:'Ole Captain', no:'15B', imgPath:'Series1/Epic/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264173':	{id:'264173', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Epic/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264174':	{id:'264174', name:'The Salesman', no:'16B', imgPath:'Series1/Epic/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264175':	{id:'264175', name:'Brian Clough', no:'17A', imgPath:'Series1/Epic/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264176':	{id:'264176', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Epic/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264177':	{id:'264177', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Epic/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264178':	{id:'264178', name:'The TV Icon', no:'18B', imgPath:'Series1/Epic/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264179':	{id:'264179', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Epic/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264180':	{id:'264180', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Epic/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264181':	{id:'264181', name:'Dave Mackay', no:'20A', imgPath:'Series1/Epic/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264182':	{id:'264182', name:'Iron Man', no:'20B', imgPath:'Series1/Epic/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264183':	{id:'264183', name:'Tommy Best', no:'21A', imgPath:'Series1/Epic/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264184':	{id:'264184', name:'Besty', no:'21B', imgPath:'Series1/Epic/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264185':	{id:'264185', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Epic/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264186':	{id:'264186', name:'The Ambassador', no:'22B', imgPath:'Series1/Epic/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264187':	{id:'264187', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Epic/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264188':	{id:'264188', name:'Big Mal', no:'23B', imgPath:'Series1/Epic/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264189':	{id:'264189', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Epic/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264190':	{id:'264190', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Epic/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264191':	{id:'264191', name:'Peter McParland', no:'25A', imgPath:'Series1/Epic/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '264192':	{id:'264192', name:'Peter The Great', no:'25B', imgPath:'Series1/Epic/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},

    // legendary
    '264258':	{id:'264258', name:'Alan Harrington', no:'1A', imgPath:'Series1/Legendary/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264259':	{id:'264259', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Legendary/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264260':	{id:'264260', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Legendary/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264261':	{id:'264261', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Legendary/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264262':	{id:'264262', name:'Billy Liddell', no:'3A', imgPath:'Series1/Legendary/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264263':	{id:'264263', name:'The Accountant', no:'3B', imgPath:'Series1/Legendary/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264264':	{id:'264264', name:'Bobby Robson', no:'4A', imgPath:'Series1/Legendary/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264265':	{id:'264265', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Legendary/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264266':	{id:'264266', name:'Denis Law', no:'5A', imgPath:'Series1/Legendary/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264267':	{id:'264267', name:'The King', no:'5B', imgPath:'Series1/Legendary/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264268':	{id:'264268', name:'George Best', no:'6A', imgPath:'Series1/Legendary/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264269':	{id:'264269', name:'El Beatle', no:'6B', imgPath:'Series1/Legendary/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264270':	{id:'264270', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Legendary/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264272':	{id:'264272', name:'Wor Jackie', no:'7B', imgPath:'Series1/Legendary/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264273':	{id:'264273', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Legendary/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264274':	{id:'264274', name:'Greavsie', no:'8B', imgPath:'Series1/Legendary/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264275':	{id:'264275', name:'John Molyneux', no:'9A', imgPath:'Series1/Legendary/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264276':	{id:'264276', name:'Big John', no:'9B', imgPath:'Series1/Legendary/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264277':	{id:'264277', name:'Len Shackleton', no:'10A', imgPath:'Series1/Legendary/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264278':	{id:'264278', name:'The Clown Prince', no:'10B', imgPath:'Series1/Legendary/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264279':	{id:'264279', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Legendary/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264280':	{id:'264280', name:'Hurstie', no:'11B', imgPath:'Series1/Legendary/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264281':	{id:'264281', name:'Cliff Jones', no:'12A', imgPath:'Series1/Legendary/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264282':	{id:'264282', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Legendary/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264283':	{id:'264283', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Legendary/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264284':	{id:'264284', name:'The Terrier', no:'13B', imgPath:'Series1/Legendary/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264285':	{id:'264285', name:'Roy Shiner', no:'14A', imgPath:'Series1/Legendary/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264286':	{id:'264286', name:'The Islander', no:'14B', imgPath:'Series1/Legendary/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264287':	{id:'264287', name:'John Wheeler', no:'15A', imgPath:'Series1/Legendary/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264288':	{id:'264288', name:'Ole Captain', no:'15B', imgPath:'Series1/Legendary/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264290':	{id:'264290', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Legendary/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264291':	{id:'264291', name:'The Salesman', no:'16B', imgPath:'Series1/Legendary/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264292':	{id:'264292', name:'Brian Clough', no:'17A', imgPath:'Series1/Legendary/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264293':	{id:'264293', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Legendary/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264294':	{id:'264294', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Legendary/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264297':	{id:'264297', name:'The TV Icon', no:'18B', imgPath:'Series1/Legendary/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264301':	{id:'264301', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Legendary/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264305':	{id:'264305', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Legendary/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264310':	{id:'264310', name:'Dave Mackay', no:'20A', imgPath:'Series1/Legendary/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264314':	{id:'264314', name:'Iron Man', no:'20B', imgPath:'Series1/Legendary/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264315':	{id:'264315', name:'Tommy Best', no:'21A', imgPath:'Series1/Legendary/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264316':	{id:'264316', name:'Besty', no:'21B', imgPath:'Series1/Legendary/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264317':	{id:'264317', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Legendary/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264318':	{id:'264318', name:'The Ambassador', no:'22B', imgPath:'Series1/Legendary/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264319':	{id:'264319', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Legendary/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264320':	{id:'264320', name:'Big Mal', no:'23B', imgPath:'Series1/Legendary/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264321':	{id:'264321', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Legendary/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264322':	{id:'264322', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Legendary/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264323':	{id:'264323', name:'Peter McParland', no:'25A', imgPath:'Series1/Legendary/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '264324':	{id:'264324', name:'Peter The Great', no:'25B', imgPath:'Series1/Legendary/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    
    //mythical
    '264329':	{id:'264329', name:'Alan Harrington', no:'1A', imgPath:'Series1/Mythical/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264330':	{id:'264330', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Mythical/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '264331':	{id:'264331', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Mythical/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264332':	{id:'264332', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Mythical/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '264334':	{id:'264334', name:'Billy Liddell', no:'3A', imgPath:'Series1/Mythical/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264335':	{id:'264335', name:'The Accountant', no:'3B', imgPath:'Series1/Mythical/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '264336':	{id:'264336', name:'Bobby Robson', no:'4A', imgPath:'Series1/Mythical/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264338':	{id:'264338', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Mythical/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '264339':	{id:'264339', name:'Denis Law', no:'5A', imgPath:'Series1/Mythical/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264340':	{id:'264340', name:'The King', no:'5B', imgPath:'Series1/Mythical/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264341':	{id:'264341', name:'George Best', no:'6A', imgPath:'Series1/Mythical/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264342':	{id:'264342', name:'El Beatle', no:'6B', imgPath:'Series1/Mythical/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264343':	{id:'264343', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Mythical/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264344':	{id:'264344', name:'Wor Jackie', no:'7B', imgPath:'Series1/Mythical/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '264345':	{id:'264345', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Mythical/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264346':	{id:'264346', name:'Greavsie', no:'8B', imgPath:'Series1/Mythical/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '264347':	{id:'264347', name:'John Molyneux', no:'9A', imgPath:'Series1/Mythical/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264348':	{id:'264348', name:'Big John', no:'9B', imgPath:'Series1/Mythical/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '264349':	{id:'264349', name:'Len Shackleton', no:'10A', imgPath:'Series1/Mythical/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264350':	{id:'264350', name:'The Clown Prince', no:'10B', imgPath:'Series1/Mythical/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '264352':	{id:'264352', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Mythical/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264353':	{id:'264353', name:'Hurstie', no:'11B', imgPath:'Series1/Mythical/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '264355':	{id:'264355', name:'Cliff Jones', no:'12A', imgPath:'Series1/Mythical/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264356':	{id:'264356', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Mythical/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '264357':	{id:'264357', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Mythical/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264358':	{id:'264358', name:'The Terrier', no:'13B', imgPath:'Series1/Mythical/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '264359':	{id:'264359', name:'Roy Shiner', no:'14A', imgPath:'Series1/Mythical/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264360':	{id:'264360', name:'The Islander', no:'14B', imgPath:'Series1/Mythical/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '264362':	{id:'264362', name:'John Wheeler', no:'15A', imgPath:'Series1/Mythical/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264364':	{id:'264364', name:'Ole Captain', no:'15B', imgPath:'Series1/Mythical/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '264365':	{id:'264365', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Mythical/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264367':	{id:'264367', name:'The Salesman', no:'16B', imgPath:'Series1/Mythical/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '264368':	{id:'264368', name:'Brian Clough', no:'17A', imgPath:'Series1/Mythical/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264369':	{id:'264369', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Mythical/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '264370':	{id:'264370', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Mythical/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264371':	{id:'264371', name:'The TV Icon', no:'18B', imgPath:'Series1/Mythical/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '264372':	{id:'264372', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Mythical/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264374':	{id:'264374', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Mythical/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '264375':	{id:'264375', name:'Dave Mackay', no:'20A', imgPath:'Series1/Mythical/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264379':	{id:'264379', name:'Iron Man', no:'20B', imgPath:'Series1/Mythical/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '264381':	{id:'264381', name:'Tommy Best', no:'21A', imgPath:'Series1/Mythical/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264383':	{id:'264383', name:'Besty', no:'21B', imgPath:'Series1/Mythical/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '264384':	{id:'264384', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Mythical/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264387':	{id:'264387', name:'The Ambassador', no:'22B', imgPath:'Series1/Mythical/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '264389':	{id:'264389', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Mythical/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264396':	{id:'264396', name:'Big Mal', no:'23B', imgPath:'Series1/Mythical/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '264397':	{id:'264397', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Mythical/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264399':	{id:'264399', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Mythical/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '264402':	{id:'264402', name:'Peter McParland', no:'25A', imgPath:'Series1/Mythical/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '264403':	{id:'264403', name:'Peter The Great', no:'25B', imgPath:'Series1/Mythical/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},


    // celestial
    '265876':	{id:'265876', name:'Alan Harrington', no:'1A', imgPath:'Series1/Celestial/1a.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '271938':	{id:'271938', name:'The Great Bluebird', no:'1B', imgPath:'Series1/Celestial/1b.jpg', position:'Defender', country:'Wales', caps:11, club:'Cardiff City', special:'', preferredFormation:''},
    '271939':	{id:'271939', name:'Dennis Viollet', no:'2A', imgPath:'Series1/Celestial/2a.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '271940':	{id:'271940', name:'Busby Babe Den', no:'2B', imgPath:'Series1/Celestial/2b.jpg', position:'Forward', country:'England', caps:2, club:'Manchester United', special:'', preferredFormation:''},
    '271942':	{id:'271942', name:'Billy Liddell', no:'3A', imgPath:'Series1/Celestial/3a.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '271943':	{id:'271943', name:'The Accountant', no:'3B', imgPath:'Series1/Celestial/3b.jpg', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '271944':	{id:'271944', name:'Bobby Robson', no:'4A', imgPath:'Series1/Celestial/4a.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '271945':	{id:'271945', name:'Bobby Five-0', no:'4B', imgPath:'Series1/Celestial/4b.jpg', position:'Forward', country:'England', caps:20, club:'West Bromich Albion', special:'Manager', preferredFormation:''},
    '271946':	{id:'271946', name:'Denis Law', no:'5A', imgPath:'Series1/Celestial/5a.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '271947':	{id:'271947', name:'The King', no:'5B', imgPath:'Series1/Celestial/5b.jpg', position:'Forward', country:'Scotland', caps:55, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '271948':	{id:'271948', name:'George Best', no:'6A', imgPath:'Series1/Celestial/6a.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '271949':	{id:'271949', name:'El Beatle', no:'6B', imgPath:'Series1/Celestial/6b.jpg', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '271950':	{id:'271950', name:'Jackie Milburn', no:'7A', imgPath:'Series1/Celestial/7a.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '271951':	{id:'271951', name:'Wor Jackie', no:'7B', imgPath:'Series1/Celestial/7b.jpg', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '271952':	{id:'271952', name:'Jimmy Greaves', no:'8A', imgPath:'Series1/Celestial/8a.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '271953':	{id:'271953', name:'Greavsie', no:'8B', imgPath:'Series1/Celestial/8b.jpg', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '271954':	{id:'271954', name:'John Molyneux', no:'9A', imgPath:'Series1/Celestial/9a.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '271955':	{id:'271955', name:'Big John', no:'9B', imgPath:'Series1/Celestial/9b.jpg', position:'Defender', country:'England', caps:0, club:'Liverpool', special:'', preferredFormation:''},
    '271956':	{id:'271956', name:'Len Shackleton', no:'10A', imgPath:'Series1/Celestial/10a.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '271957':	{id:'271957', name:'The Clown Prince', no:'10B', imgPath:'Series1/Celestial/10b.jpg', position:'Forward', country:'England', caps:5, club:'Sunderland', special:'', preferredFormation:''},
    '271959':	{id:'271959', name:'Geoff Hurst', no:'11A', imgPath:'Series1/Celestial/11a.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '271960':	{id:'271960', name:'Hurstie', no:'11B', imgPath:'Series1/Celestial/11b.jpg', position:'Forward', country:'England', caps:49, club:'West Ham', special:'WC66', preferredFormation:''},
    '271962':	{id:'271962', name:'Cliff Jones', no:'12A', imgPath:'Series1/Celestial/12a.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '271963':	{id:'271963', name:'Welsh Wizard', no:'12B', imgPath:'Series1/Celestial/12b.jpg', position:'Midfielder', country:'Wales', caps:59, club:'Tottenham Hotspurs', special:'', preferredFormation:'3-1-4-2'},
    '271965':	{id:'271965', name:'Wilbur Cush', no:'13A', imgPath:'Series1/Celestial/13a.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '271966':	{id:'271966', name:'The Terrier', no:'13B', imgPath:'Series1/Celestial/13b.jpg', position:'Defender', country:'Northern Ireland', caps:26, club:'Leeds United', special:'', preferredFormation:''},
    '271968':	{id:'271968', name:'Roy Shiner', no:'14A', imgPath:'Series1/Celestial/14a.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '271969':	{id:'271969', name:'The Islander', no:'14B', imgPath:'Series1/Celestial/14b.jpg', position:'Forward', country:'England', caps:0, club:'Sheffield Wednesday', special:'', preferredFormation:'5-4-1'},
    '271970':	{id:'271970', name:'John Wheeler', no:'15A', imgPath:'Series1/Celestial/15a.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '271971':	{id:'271971', name:'Ole Captain', no:'15B', imgPath:'Series1/Celestial/15b.jpg', position:'Midfielder', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},
    '271972':	{id:'271972', name:'Stuart Grenville Williams', no:'16A', imgPath:'Series1/Celestial/16a.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '271973':	{id:'271973', name:'The Salesman', no:'16B', imgPath:'Series1/Celestial/16b.jpg', position:'Defender', country:'Wales', caps:43, club:'West Bromich Albion', special:'', preferredFormation:'4-4-2-Diamond'},
    '271974':	{id:'271974', name:'Brian Clough', no:'17A', imgPath:'Series1/Celestial/17a.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '271976':	{id:'271976', name:'Old Big \'Ead', no:'17B', imgPath:'Series1/Celestial/17b.jpg', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},
    '271977':	{id:'271977', name:'Jimmy Hill', no:'18A', imgPath:'Series1/Celestial/18a.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '271978':	{id:'271978', name:'The TV Icon', no:'18B', imgPath:'Series1/Celestial/18b.jpg', position:'Midfielder', country:'England', caps:0, club:'Fulham', special:'Manager', preferredFormation:''},
    '271979':	{id:'271979', name:'Nat Lofthouse', no:'19A', imgPath:'Series1/Celestial/19a.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '271980':	{id:'271980', name:'Lion of Vienna', no:'19B', imgPath:'Series1/Celestial/19b.jpg', position:'Forward', country:'England', caps:33, club:'Bolton Wanderers', special:'', preferredFormation:''},
    '271981':	{id:'271981', name:'Dave Mackay', no:'20A', imgPath:'Series1/Celestial/20a.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '271982':	{id:'271982', name:'Iron Man', no:'20B', imgPath:'Series1/Celestial/20b.jpg', position:'Defender', country:'Scotland', caps:22, club:'Heart of Midlothian', special:'', preferredFormation:'4-3-2-1'},
    '271984':	{id:'271984', name:'Tommy Best', no:'21A', imgPath:'Series1/Celestial/21a.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '271986':	{id:'271986', name:'Besty', no:'21B', imgPath:'Series1/Celestial/21b.jpg', position:'Forward', country:'Wales', caps:0, club:'Chester', special:'', preferredFormation:'4-4-1-1'},
    '271987':	{id:'271987', name:'Bobby Charlton', no:'22A', imgPath:'Series1/Celestial/22a.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '271988':	{id:'271988', name:'The Ambassador', no:'22B', imgPath:'Series1/Celestial/22b.jpg', position:'Midfielder', country:'England', caps:106, club:'Manchester United', special:'United Trinity', preferredFormation:''},
    '271989':	{id:'271989', name:'Malcolm Allison', no:'23A', imgPath:'Series1/Celestial/23a.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '271990':	{id:'271990', name:'Big Mal', no:'23B', imgPath:'Series1/Celestial/23b.jpg', position:'Defender', country:'England', caps:0, club:'West Ham', special:'', preferredFormation:''},
    '271991':	{id:'271991', name:'Peter Broadbent', no:'24A', imgPath:'Series1/Celestial/24a.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '271992':	{id:'271992', name:'The Ballet Dancer', no:'24B', imgPath:'Series1/Celestial/24b.jpg', position:'Forward', country:'England', caps:7, club:'Wolverhampton Wanderers', special:'', preferredFormation:''},
    '271993':	{id:'271993', name:'Peter McParland', no:'25A', imgPath:'Series1/Celestial/25a.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},
    '271994':	{id:'271994', name:'Peter The Great', no:'25B', imgPath:'Series1/Celestial/25b.jpg', position:'Midfielder', country:'Northern Ireland', caps:34, club:'Aston Villa', special:'', preferredFormation:''},


    

    // Emotion
    '441645' : {id:'441645',name:'Bert Trautmann',imgPath:'Emotion/bert.jpg',no:'eM-1', position:'Goalkeeper', country:'Germany', caps:0, club:'Manchester City', special:'International', preferredFormation:''},
    '454123' : {id:'454123', name:'Antonio Rattin', imgPath:'Emotion/antonio.jpg',no:'eM-2', position:'Midfielder', country:'Argentina', caps:34, club:'Boca Juniors', special:'International', preferredFormation:''},
    '473420' : {id:'473420', name:'Dixie Dean', imgPath:'Emotion/dixie.jpg',no:'eM-3', position:'Forward', country:'England', caps:16, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '485799' : {id:'485799', name:'Bobby Moore', imgPath:'Emotion/bobby.jpg',no:'eM-4', position:'Defender', country:'England', caps:108, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '496918' : {id:'496918', name:'Gordon Banks', imgPath:'Emotion/gordon.jpg',no:'eM-5', position:'Goalkeeper', country:'England', caps:73, club:'Leicester City', special:'WC66', preferredFormation:''},

    // Enforcers Base
    '412243' : {id:'412243',name:'Frank Barson',imgPath:'TheEnforcers/Frank-Barson-Base.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412378' : {id:'412378',name:'Willie Woodburn',imgPath:'TheEnforcers/Willie-Woodburn-Base.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412664' : {id:'412664',name:'Norman Hunter',imgPath:'TheEnforcers/Norman-hunter-Base.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '412675' : {id:'412675',name:'Ron "Chopper" Harris',imgPath:'TheEnforcers/Ron-Chopper-Harris-Base.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413260' : {id:'413260',name:'Wilf Copping',imgPath:'TheEnforcers/Wilf-Copping-Base.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413270' : {id:'413270',name:'Tommy Smith',imgPath:'TheEnforcers/Tommy-Smith-Base.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // light bruising
    '412258' : {id:'412258',name:'Frank Barson - Lightly Bruised',imgPath:'TheEnforcers/Frank-Barson-light-bruising.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412380' : {id:'412380',name:'Willie Woodburn - Lightly Bruised',imgPath:'TheEnforcers/Willie-Woodburn-light-bruising.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412665' : {id:'412665',name:'Norman Hunter - Lightly Bruised',imgPath:'TheEnforcers/Norman-hunter-light-bruising.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413246' : {id:'413246',name:'Ron "Chopper" Harris - Lightly Bruised',imgPath:'TheEnforcers/Ron-Chopper-Harris-light-bruising.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413261' : {id:'413261',name:'Wilf Copping - Lightly Bruised',imgPath:'TheEnforcers/Wilf-Copping-light-bruising.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413275' : {id:'413275',name:'Tommy Smith - Lightly Bruised',imgPath:'TheEnforcers/Tommy-Smith-Light-bruising.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // yellow card
    '412363' : {id:'412363',name:'Frank Barson - Yellow Card',imgPath:'TheEnforcers/Frank-Barson-yellow-card.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412381' : {id:'412381',name:'Willie Woodburn - Yellow Card',imgPath:'TheEnforcers/Willie-Woodburn-yellow-card.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412668' : {id:'412668',name:'Norman Hunter - Yellow Card',imgPath:'TheEnforcers/Norman-hunter-Yellow-Card.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413248' : {id:'413248',name:'Ron "Chopper" Harris - Yellow Card',imgPath:'TheEnforcers/Ron-Chopper-Harris-Yellow-Card.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413262' : {id:'413262',name:'Wilf Copping - Yellow Card',imgPath:'TheEnforcers/Wilf-Copping-Yellow-Card.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413277' : {id:'413277',name:'Tommy Smith - Yellow Card',imgPath:'TheEnforcers/Tommy-Smith-Yellow-Card.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // terry butcher
    '412365' : {id:'412365',name:'Frank Barson - The Terry Butcher',imgPath:'TheEnforcers/Frank-Barson-The-Terry-Butcher.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412382' : {id:'412382',name:'Willie Woodburn - The Terry Butcher',imgPath:'TheEnforcers/Willie-Woodburn-The-Terry-Butcher.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412669' : {id:'412669',name:'Norman Hunter - The Terry Butcher',imgPath:'TheEnforcers/Norman-hunter-The-Terry-Butcher.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413253' : {id:'413253',name:'Ron "Chopper" Harris - The Terry Butcher',imgPath:'TheEnforcers/Ron-Chopper-Harris-The-Terry-Butcher.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413263' : {id:'413263',name:'Wilf Copping - The Terry Butcher',imgPath:'TheEnforcers/Wilf-Copping-The-Terry-Butcher.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413279' : {id:'413279',name:'Tommy Smith - The Terry Butcher',imgPath:'TheEnforcers/Tommy-Smith-The-Terry-Butcher.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // red card
    '412366' : {id:'412366',name:'Frank Barson - Red Card',imgPath:'TheEnforcers/Frank-Barson-red-card.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412383' : {id:'412383',name:'Willie Woodburn - Red Card',imgPath:'TheEnforcers/Willie-Woodburn-Red-Card.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412670' : {id:'412670',name:'Norman Hunter - Red Card',imgPath:'TheEnforcers/Norman-hunter-Red-Card.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413255' : {id:'413255',name:'Ron "Chopper" Harris - Red Card',imgPath:'TheEnforcers/Ron-Chopper-Harris-Red-Card.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413264' : {id:'413264',name:'Wilf Copping - Red Card',imgPath:'TheEnforcers/Wilf-Copping-Red-Card.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413282' : {id:'413282',name:'Tommy Smith - Red Card',imgPath:'TheEnforcers/Tommy-Smith-Red-Card.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // locked up 
    '412367' : {id:'412367',name:'Frank Barson - Locked Up',imgPath:'TheEnforcers/Frank-Barson-Red-Card-Locked-Up.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412384' : {id:'412384',name:'Willie Woodburn - Locked Up',imgPath:'TheEnforcers/Willie-Woodburn-Red-Card-Locked-up.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412671' : {id:'412671',name:'Norman Hunter - Locked Up',imgPath:'TheEnforcers/Norman-hunter-Locked-up.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413256' : {id:'413256',name:'Ron "Chopper" Harris - Locked Up',imgPath:'TheEnforcers/Ron-Chopper-Harris-Locked-up.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413265' : {id:'413265',name:'Wilf Copping - Locked Up',imgPath:'TheEnforcers/Wilf-Copping-Locked-up.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413284' : {id:'413284',name:'Tommy Smith - Locked Up',imgPath:'TheEnforcers/Tommy-Smith-Locked-up.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // halo
    '412368' : {id:'412368',name:'Frank Barson - Halo',imgPath:'TheEnforcers/Frank-Barson-halo.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412385' : {id:'412385',name:'Willie Woodburn - Halo',imgPath:'TheEnforcers/Willie-Woodburn-Halo.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412672' : {id:'412672',name:'Norman Hunter - Halo',imgPath:'TheEnforcers/Norman-hunter-Halo.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413257' : {id:'413257',name:'Ron "Chopper" Harris - Halo',imgPath:'TheEnforcers/Ron-Chopper-Harris-Halo.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413266' : {id:'413266',name:'Wilf Copping - Halo',imgPath:'TheEnforcers/Wilf-Copping-Halo.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413286' : {id:'413286',name:'Tommy Smith - Halo',imgPath:'TheEnforcers/Tommy-Smith-Halo.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // motm
    '412370' : {id:'412370',name:'Frank Barson - Man of the Match',imgPath:'TheEnforcers/Frank-Barson-MotM.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '412386' : {id:'412386',name:'Willie Woodburn - Man of the Match',imgPath:'TheEnforcers/Willie-Woodburn-MotM.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '412673' : {id:'412673',name:'Norman Hunter - Man of the Match',imgPath:'TheEnforcers/Norman-hunter-MotM.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '413259' : {id:'413259',name:'Ron "Chopper" Harris - Man of the Match',imgPath:'TheEnforcers/Ron-Chopper-Harris-MotM.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '413269' : {id:'413269',name:'Wilf Copping - Man of the Match',imgPath:'TheEnforcers/Wilf-Copping-MotM.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '413287' : {id:'413287',name:'Tommy Smith - Man of the Match',imgPath:'TheEnforcers/Tommy-Smith-MotM.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // red mist
    '415151' : {id:'415151',name:'Frank Barson - The Red Mist',imgPath:'TheEnforcers/Frank-Barson-Red-Mist.jpg',no:'EN-1A', position:'Defender', country:'England', caps:1, club:'Aston Villa', special:'', preferredFormation:''},
    '415152' : {id:'415152',name:'Willie Woodburn - The Red Mist',imgPath:'TheEnforcers/Willie-Woodburn-Red-Mist.jpg',no:'EN-2A', position:'Defender', country:'Scotland', caps:24, club:'Rangers', special:'', preferredFormation:''},
    '415153' : {id:'415153',name:'Norman Hunter - The Red Mist',imgPath:'TheEnforcers/Norman-hunter-Red-Mist.jpg',no:'EN-3A', position:'Defender', country:'England', caps:28, club:'Leeds United', special:'', preferredFormation:''},
    '415154' : {id:'415154',name:'Ron "Chopper" Harris - The Red Mist',imgPath:'TheEnforcers/Ron-Chopper-Harris-Red-Mist.jpg',no:'EN-4A', position:'Defender', country:'England', caps:0, club:'Chelsea', special:'', preferredFormation:''},
    '415155' : {id:'415155',name:'Wilf Copping - The Red Mist',imgPath:'TheEnforcers/Wilf-Copping-Red-Mist.jpg',no:'EN-5A', position:'Defender', country:'England', caps:20, club:'Arsenal', special:'', preferredFormation:''},
    '415156' : {id:'415156',name:'Tommy Smith - The Red Mist',imgPath:'TheEnforcers/Tommy-Smith-Red-Mist.jpg',no:'EN-6A', position:'Defender', country:'England', caps:1, club:'Liverpool', special:'', preferredFormation:''},

    // promo players
    '313956' : {id:'313956',name:'Jackie Milburn',imgPath:'PromoPlayers/7a-Platinum.jpg', no:'7A', position:'Forward', country:'England', caps:13, club:'Newcastle United', special:'', preferredFormation:''},
    '317356' : {id:'317356',name:'Billy Liddell',imgPath:'PromoPlayers/Billy-Liddell.jpg', no:'3A', position:'Forward', country:'Scotland', caps:29, club:'Liverpool', special:'', preferredFormation:''},
    '327729' : {id:'327729',name:'Jimmy Cantrell',imgPath:'PromoPlayers/Jimmy-Cantrell.jpg', no:'26A', position:'Forward', country:'England', caps:0, club:'Tottenham Hotspurs', special:'VN Family', preferredFormation:''},
    '607019' : {id:'607019',name:'Tom Finney',imgPath:'PromoPlayers/tom-finney-stamp.png', no:'7A', position:'Midfielder', country:'England', caps:0, club:'Preston North End', special:'', preferredFormation:''},


    //Transfer 
    '445044' : {id:'445044',name:'Jimmy Greaves',imgPath:'Transfer/8a.jpg', no:'8A', position:'Forward', country:'England', caps:57, club:'Chelsea', special:'', preferredFormation:''},
    '432831' : {id:'432831',name:'George Best',imgPath:'Transfer/6a.jpg', no:'6A', position:'Midfielder', country:'Northern Ireland', caps:37, club:'Manchester United', special:'United Trinity', preferredFormation:''},

    // Manager
    '462907' : {id:'462907',name:'Brian Clough',imgPath:'Manager/17a.jpg', no:'17A', position:'Forward', country:'England', caps:2, club:'Middlesborough', special:'Manager', preferredFormation:''},

    // Ghoulkeepers
    '615194' : {id:'615194',name:'Gorgon Banks',imgPath:'Specials/gorgon.jpg', no:'1', position:'Goalkeeper', country:'England', caps:0, club:'Leicester City', special:'Ghoulkeeper', preferredFormation:''},
    '615205' : {id:'615205',name:'Dino "IT" Zoff',imgPath:'Specials/dino.jpg', no:'2', position:'Goalkeeper', country:'Italy', caps:0, club:'Juventus', special:'Ghoulkeeper', preferredFormation:''},
    '615207' : {id:'615207',name:'"Bony" Trautmann',imgPath:'Specials/bony.jpg', no:'3', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'Ghoulkeeper', preferredFormation:''},
    '615208' : {id:'615208',name:'Lev "Black Spider" Yashin',imgPath:'Specials/lev.jpg', no:'4', position:'Goalkeeper', country:'Soviet Union', caps:2, club:'Dynamo Moscow', special:'Ghoulkeeper', preferredFormation:''},

    // Series2:
    // Common
    '611270' : {id:'611270', name:'Bernhard Trautmann', imgPath:'Series2/common/s2-1a.jpg', no:'1A', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '611271' : {id:'611271', name:'Bert', imgPath:'Series2/common/s2-1b.jpg', no:'1B', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '611462' : {id:'611462', name:'Bobby Moore', imgPath:'Series2/common/s2-2a.jpg', no:'2A', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '611463' : {id:'611463', name:'Tubby', imgPath:'Series2/common/s2-2b.jpg', no:'2B', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '611464' : {id:'611464', name:'Danny Blanchflower', imgPath:'Series2/common/s2-3a.jpg', no:'3A', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '611465' : {id:'611465', name:'The Journalist', imgPath:'Series2/common/s2-3b.jpg', no:'3B', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '611466' : {id:'611466', name:'Eddie Hapgood', imgPath:'Series2/common/s2-4a.jpg', no:'4A', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '611468' : {id:'611468', name:'Happy', imgPath:'Series2/common/s2-4b.jpg', no:'4B', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '611469' : {id:'611469', name:'Stanley Matthews', imgPath:'Series2/common/s2-5a.jpg', no:'5A', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '611471' : {id:'611471', name:'The Magician', imgPath:'Series2/common/s2-5b.jpg', no:'5B', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '611479' : {id:'611479', name:'Horatio Carter', imgPath:'Series2/common/s2-6a.jpg', no:'6A', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '611492' : {id:'611492', name:'Raich', imgPath:'Series2/common/s2-6b.jpg', no:'6B', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '611493' : {id:'611493', name:'Tom Finney', imgPath:'Series2/common/s2-7a.jpg', no:'7A', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '611494' : {id:'611494', name:'The Preston Plumber', imgPath:'Series2/common/s2-7b.jpg', no:'7B', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '611495' : {id:'611495', name:'Roy Brown', imgPath:'Series2/common/s2-8a.jpg', no:'8A', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '611496' : {id:'611496', name:'Bomber', imgPath:'Series2/common/s2-8b.jpg', no:'8B', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '611497' : {id:'611497', name:'Gordon Banks', imgPath:'Series2/common/s2-9a.jpg', no:'9A', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '611498' : {id:'611498', name:'Banks Of England', imgPath:'Series2/common/s2-9b.jpg', no:'9B', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '611500' : {id:'611500', name:'William Ralph Dean', imgPath:'Series2/common/s2-10a.jpg', no:'10A', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '611501' : {id:'611501', name:'Dixie', imgPath:'Series2/common/s2-10b.jpg', no:'10B', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '611502' : {id:'611502', name:'Jimmy Cantrell', imgPath:'Series2/common/s2-11a.jpg', no:'11A', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},
    '611503' : {id:'611503', name:'The Gent', imgPath:'Series2/common/s2-11b.jpg', no:'11B', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},

    // Limited
    '611913' : {id:'611913', name:'Bernhard Trautmann', imgPath:'Series2/vintageprint/s2-1a.jpg', no:'1A', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '611915' : {id:'611915', name:'Bert', imgPath:'Series2/vintageprint/s2-1b.jpg', no:'1B', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '611916' : {id:'611916', name:'Bobby Moore', imgPath:'Series2/vintageprint/s2-2a.jpg', no:'2A', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '611917' : {id:'611917', name:'Tubby', imgPath:'Series2/vintageprint/s2-2b.jpg', no:'2B', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '611918' : {id:'611918', name:'Danny Blanchflower', imgPath:'Series2/vintageprint/s2-3a.jpg', no:'3A', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '611919' : {id:'611919', name:'The Journalist', imgPath:'Series2/vintageprint/s2-3b.jpg', no:'3B', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '611921' : {id:'611921', name:'Eddie Hapgood', imgPath:'Series2/vintageprint/s2-4a.jpg', no:'4A', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '611922' : {id:'611922', name:'Happy', imgPath:'Series2/vintageprint/s2-4b.jpg', no:'4B', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '611945' : {id:'611945', name:'Stanley Matthews', imgPath:'Series2/vintageprint/s2-5a.jpg', no:'5A', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '611947' : {id:'611947', name:'The Magician', imgPath:'Series2/vintageprint/s2-5b.jpg', no:'5B', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '611950' : {id:'611950', name:'Horatio Carter', imgPath:'Series2/vintageprint/s2-6a.jpg', no:'6A', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '611952' : {id:'611952', name:'Raich', imgPath:'Series2/vintageprint/s2-6b.jpg', no:'6B', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '611957' : {id:'611957', name:'Tom Finney', imgPath:'Series2/vintageprint/s2-7a.jpg', no:'7A', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '611961' : {id:'611961', name:'The Preston Plumber', imgPath:'Series2/vintageprint/s2-7b.jpg', no:'7B', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '611966' : {id:'611966', name:'Roy Brown', imgPath:'Series2/vintageprint/s2-8a.jpg', no:'8A', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '611971' : {id:'611971', name:'Bomber', imgPath:'Series2/vintageprint/s2-8b.jpg', no:'8B', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '611974' : {id:'611974', name:'Gordon Banks', imgPath:'Series2/vintageprint/s2-9a.jpg', no:'9A', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '611976' : {id:'611976', name:'Banks Of England', imgPath:'Series2/vintageprint/s2-9b.jpg', no:'9B', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '611981' : {id:'611981', name:'William Ralph Dean', imgPath:'Series2/vintageprint/s2-10a.jpg', no:'10A', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '611984' : {id:'611984', name:'Dixie', imgPath:'Series2/vintageprint/s2-10b.jpg', no:'10B', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '611986' : {id:'611986', name:'Jimmy Cantrell', imgPath:'Series2/vintageprint/s2-11a.jpg', no:'11A', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},
    '611989' : {id:'611989', name:'The Gent', imgPath:'Series2/vintageprint/s2-11b.jpg', no:'11B', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},

    // Rare
    '612224' : {id:'612224', name:'Bernhard Trautmann', imgPath:'Series2/arcade/s2-1a.jpg', no:'1A', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '612226' : {id:'612226', name:'Bert', imgPath:'Series2/arcade/s2-1b.jpg', no:'1B', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '612240' : {id:'612240', name:'Bobby Moore', imgPath:'Series2/arcade/s2-2a.jpg', no:'2A', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '612242' : {id:'612242', name:'Tubby', imgPath:'Series2/arcade/s2-2b.jpg', no:'2B', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '612243' : {id:'612243', name:'Danny Blanchflower', imgPath:'Series2/arcade/s2-3a.jpg', no:'3A', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '612264' : {id:'612264', name:'The Journalist', imgPath:'Series2/arcade/s2-3b.jpg', no:'3B', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '612265' : {id:'612265', name:'Eddie Hapgood', imgPath:'Series2/arcade/s2-4a.jpg', no:'4A', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '612275' : {id:'612275', name:'Happy', imgPath:'Series2/arcade/s2-4b.jpg', no:'4B', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '612276' : {id:'612276', name:'Stanley Matthews', imgPath:'Series2/arcade/s2-5a.jpg', no:'5A', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '612277' : {id:'612277', name:'The Magician', imgPath:'Series2/arcade/s2-5b.jpg', no:'5B', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '612279' : {id:'612279', name:'Horatio Carter', imgPath:'Series2/arcade/s2-6a.jpg', no:'6A', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '612280' : {id:'612280', name:'Raich', imgPath:'Series2/arcade/s2-6b.jpg', no:'6B', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '612281' : {id:'612281', name:'Tom Finney', imgPath:'Series2/arcade/s2-7a.jpg', no:'7A', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '612282' : {id:'612282', name:'The Preston Plumber', imgPath:'Series2/arcade/s2-7b.jpg', no:'7B', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '612283' : {id:'612283', name:'Roy Brown', imgPath:'Series2/arcade/s2-8a.jpg', no:'8A', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '612287' : {id:'612287', name:'Bomber', imgPath:'Series2/arcade/s2-8b.jpg', no:'8B', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '612288' : {id:'612288', name:'Gordon Banks', imgPath:'Series2/arcade/s2-9a.jpg', no:'9A', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '612290' : {id:'612290', name:'Banks Of England', imgPath:'Series2/arcade/s2-9b.jpg', no:'9B', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '612291' : {id:'612291', name:'William Ralph Dean', imgPath:'Series2/arcade/s2-10a.jpg', no:'10A', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '612292' : {id:'612292', name:'Dixie', imgPath:'Series2/arcade/s2-10b.jpg', no:'10B', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '612293' : {id:'612293', name:'Jimmy Cantrell', imgPath:'Series2/arcade/s2-11a.jpg', no:'11A', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},
    '612294' : {id:'612294', name:'The Gent', imgPath:'Series2/arcade/s2-11b.jpg', no:'11B', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},

    // Epic
    '612470' : {id:'612470', name:'Bernhard Trautmann', imgPath:'Series2/popart/s2-1a.jpg', no:'1A', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '612471' : {id:'612471', name:'Bert', imgPath:'Series2/popart/s2-1b.jpg', no:'1B', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '612472' : {id:'612472', name:'Bobby Moore', imgPath:'Series2/popart/s2-2a.jpg', no:'2A', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '612546' : {id:'612546', name:'Tubby', imgPath:'Series2/popart/s2-2b.jpg', no:'2B', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '612548' : {id:'612548', name:'Danny Blanchflower', imgPath:'Series2/popart/s2-3a.jpg', no:'3A', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '612549' : {id:'612549', name:'The Journalist', imgPath:'Series2/popart/s2-3b.jpg', no:'3B', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '612550' : {id:'612550', name:'Eddie Hapgood', imgPath:'Series2/popart/s2-4a.jpg', no:'4A', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '612551' : {id:'612551', name:'Happy', imgPath:'Series2/popart/s2-4b.jpg', no:'4B', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '612553' : {id:'612553', name:'Stanley Matthews', imgPath:'Series2/popart/s2-5a.jpg', no:'5A', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '612555' : {id:'612555', name:'The Magician', imgPath:'Series2/popart/s2-5b.jpg', no:'5B', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '612556' : {id:'612556', name:'Horatio Carter', imgPath:'Series2/popart/s2-6a.jpg', no:'6A', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '612558' : {id:'612558', name:'Raich', imgPath:'Series2/popart/s2-6b.jpg', no:'6B', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '612560' : {id:'612560', name:'Tom Finney', imgPath:'Series2/popart/s2-7a.jpg', no:'7A', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '612561' : {id:'612561', name:'The Preston Plumber', imgPath:'Series2/popart/s2-7b.jpg', no:'7B', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '612562' : {id:'612562', name:'Roy Brown', imgPath:'Series2/popart/s2-8a.jpg', no:'8A', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '612564' : {id:'612564', name:'Bomber', imgPath:'Series2/popart/s2-8b.jpg', no:'8B', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '612565' : {id:'612565', name:'Gordon Banks', imgPath:'Series2/popart/s2-9a.jpg', no:'9A', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '612566' : {id:'612566', name:'Banks Of England', imgPath:'Series2/popart/s2-9b.jpg', no:'9B', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '612567' : {id:'612567', name:'William Ralph Dean', imgPath:'Series2/popart/s2-10a.jpg', no:'10A', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '612568' : {id:'612568', name:'Dixie', imgPath:'Series2/popart/s2-10b.jpg', no:'10B', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '612569' : {id:'612569', name:'Jimmy Cantrell', imgPath:'Series2/popart/s2-11a.jpg', no:'11A', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},
    '612570' : {id:'612570', name:'The Gent', imgPath:'Series2/popart/s2-11b.jpg', no:'11B', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},
    
    // Legendary
    '612574' : {id:'612574', name:'Bernhard Trautmann', imgPath:'Series2/toyfigure/s2-1a.jpg', no:'1A', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '612575' : {id:'612575', name:'Bert', imgPath:'Series2/toyfigure/s2-1b.jpg', no:'1B', position:'Goalkeeper', country:'Germany', caps:2, club:'Manchester City', special:'International', preferredFormation:''},
    '612576' : {id:'612576', name:'Bobby Moore', imgPath:'Series2/toyfigure/s2-2a.jpg', no:'2A', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '612577' : {id:'612577', name:'Tubby', imgPath:'Series2/toyfigure/s2-2b.jpg', no:'2B', position:'Defender', country:'England', caps:2, club:'West Ham', special:'WC66', preferredFormation:'3-4-3'},
    '612578' : {id:'612578', name:'Danny Blanchflower', imgPath:'Series2/toyfigure/s2-3a.jpg', no:'3A', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '612579' : {id:'612579', name:'The Journalist', imgPath:'Series2/toyfigure/s2-3b.jpg', no:'3B', position:'Midfielder', country:'Northern Ireland', caps:2, club:'Tottenham Hotspurs', special:'Manager', preferredFormation:''},
    '612580' : {id:'612580', name:'Eddie Hapgood', imgPath:'Series2/toyfigure/s2-4a.jpg', no:'4A', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '612582' : {id:'612582', name:'Happy', imgPath:'Series2/toyfigure/s2-4b.jpg', no:'4B', position:'Defender', country:'England', caps:2, club:'Arsenal', special:'', preferredFormation:''},
    '612583' : {id:'612583', name:'Stanley Matthews', imgPath:'Series2/toyfigure/s2-5a.jpg', no:'5A', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '612584' : {id:'612584', name:'The Magician', imgPath:'Series2/toyfigure/s2-5b.jpg', no:'5B', position:'Midfielder', country:'England', caps:2, club:'Blackpool', special:'', preferredFormation:'5-4-1'},
    '612585' : {id:'612585', name:'Horatio Carter', imgPath:'Series2/toyfigure/s2-6a.jpg', no:'6A', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '612586' : {id:'612586', name:'Raich', imgPath:'Series2/toyfigure/s2-6b.jpg', no:'6B', position:'Forward', country:'England', caps:2, club:'Sunderland', special:'', preferredFormation:''},
    '612587' : {id:'612587', name:'Tom Finney', imgPath:'Series2/toyfigure/s2-7a.jpg', no:'7A', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '612588' : {id:'612588', name:'The Preston Plumber', imgPath:'Series2/toyfigure/s2-7b.jpg', no:'7B', position:'Midfielder', country:'England', caps:2, club:'Preston North End', special:'', preferredFormation:''},
    '612589' : {id:'612589', name:'Roy Brown', imgPath:'Series2/toyfigure/s2-8a.jpg', no:'8A', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '612601' : {id:'612601', name:'Bomber', imgPath:'Series2/toyfigure/s2-8b.jpg', no:'8B', position:'Forward', country:'England', caps:2, club:'Stoke City', special:'', preferredFormation:''},
    '612602' : {id:'612602', name:'Gordon Banks', imgPath:'Series2/toyfigure/s2-9a.jpg', no:'9A', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '612603' : {id:'612603', name:'Banks Of England', imgPath:'Series2/toyfigure/s2-9b.jpg', no:'9B', position:'Goalkeeper', country:'England', caps:2, club:'Leicester City', special:'WC66', preferredFormation:''},
    '612604' : {id:'612604', name:'William Ralph Dean', imgPath:'Series2/toyfigure/s2-10a.jpg', no:'10A', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '612605' : {id:'612605', name:'Dixie', imgPath:'Series2/toyfigure/s2-10b.jpg', no:'10B', position:'Forward', country:'England', caps:2, club:'Everton', special:'', preferredFormation:'4-4-2'},
    '612606' : {id:'612606', name:'Jimmy Cantrell', imgPath:'Series2/toyfigure/s2-11a.jpg', no:'11A', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},
    '612607' : {id:'612607', name:'The Gent', imgPath:'Series2/toyfigure/s2-11b.jpg', no:'11B', position:'Forward', country:'England', caps:2, club:'Tottenham Hotspurs', special:'', preferredFormation:''},

     // World Cup Legends1
     // COMMON
     '623743' : {id:'623743',name:'Franz Beckenbauer',imgPath:'WCLegends/Common/1A.jpg', no:'1A', position:'Defender', country:'Germany', caps:2, club:'Bayern Munich', special:'', preferredFormation:'3-1-4-2'},
     '640227' : {id:'640227',name:'Zbigniew Boniek',imgPath:'WCLegends/Common/3A.jpg', no:'3A', position:'Midfielder', country:'Poland', caps:80, club:'Widzew Łódź', special:'', preferredFormation:'4-4-2-Diamond'},
     '644567' : {id:'644567',name:'Diego Maradona',imgPath:'WCLegends/Common/4A.jpg', no:'4A', position:'Midfielder', country:'Argentina', caps:91, club:'Napoli', special:'', preferredFormation:'4-4-1-1'},
     '644570' : {id:'644570',name:'Michel Platini',imgPath:'WCLegends/Common/5A.jpg', no:'5A', position:'Midfielder', country:'France', caps:72, club:'Juventus', special:'', preferredFormation:'4-3-2-1'},

     // RARE
     '625896' : {id:'625896',name:'Pele',imgPath:'WCLegends/Rare/2A.png', no:'2A', position:'Forward', country:'Brazil', caps:2, club:'Santos', special:'', preferredFormation:'5-4-1'},
     
};

