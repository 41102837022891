import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { FC, useEffect, useRef, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import Button from "@mui/material/Button";
import { addTournament, updateTournament } from "../../store/actions/config";
import { useUAL } from "../../utils/useUAL";
import { useDispatch, useSelector } from "react-redux";
import { Tournament } from "../../types/Tournament";
import Switch from "@mui/material/Switch";

interface TournamentProps {
  showDialog: boolean;
  tournament?: Tournament | null;
  onTournoUpdated(): void;
}

export const EditTournament: FC<TournamentProps> = ({
  showDialog,
  tournament,
  onTournoUpdated: onTournoUpdated,
}) => {
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment());
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
  const [tournoName, setTournoName] = useState("");
  const [teamsCount, setTeamsCount] = useState("");
  const [gamesCount, setGamesCount] = useState("");
  const [active, setActive] = useState(false);
  const [buyIn, setBuyIn] = useState("");
  const [prize, setPrize] = useState("");
  const [unlockLimit, setUnlockLimit] = useState("");
  const ual = useUAL() as any;
  const nameField = useRef<HTMLInputElement>();
  const teamsCountField = useRef<HTMLInputElement>();
  const gamesCountField = useRef<HTMLInputElement>();
  const buyInField = useRef<HTMLInputElement>();
  const prizeField = useRef<HTMLInputElement>();
  const unlockLimitField = useRef<HTMLInputElement>();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (tournament != null) {
      setTournoName(tournament.name);
      setStartDate(moment(tournament.startDate));
      setEndDate(moment(tournament.endDate));
      setTeamsCount(tournament.teamCount.toString());
      setGamesCount(tournament.gamesCount.toString());
      setBuyIn(tournament.buyIn.toString());
      setPrize(tournament.prize.toString());
      setActive(tournament.active);
      setUnlockLimit(tournament.unlockLimit.toString());
    }
  }, [tournament]);

  const onClose = () => {
    onTournoUpdated();
  };

  const startDateChange = (value: moment.Moment | null) => {
    setStartDate(value);
  };

  const endDateChange = (value: moment.Moment | null) => {
    setEndDate(value);
  };

  const onSave = () => {
    const activeUser = ual["activeUser"];
    if (tournament != null) {
      dispatch(
        updateTournament(
          activeUser,
          tournament.id,
          nameField.current?.value!,
          startDate?.unix()!,
          endDate?.unix()!,
          parseInt(teamsCountField.current?.value!),
          parseInt(gamesCountField.current?.value!),
          parseInt(buyInField.current?.value!),
          parseInt(prizeField.current?.value!),
          active,
          parseInt(unlockLimitField.current?.value!)
        )
      );
    } else {
      dispatch(
        addTournament(
          activeUser,
          nameField.current?.value!,
          startDate?.unix()!,
          endDate?.unix()!,
          parseInt(teamsCountField.current?.value!),
          parseInt(gamesCountField.current?.value!),
          parseInt(buyInField.current?.value!),
          parseInt(prizeField.current?.value!),
          active,
          parseInt(unlockLimitField.current?.value!)
        )
      );
    }

    onTournoUpdated();
  };

  const onActiveChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setActive(checked);
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "350px",
            flexDirection: "column",
            gap: "10px",
            height: "500px",
          }}
        >
          <div>
            <TextField
              inputRef={nameField}
              value={tournoName}
              onChange={(e) => setTournoName(e.target.value)}
              size="small"
              label="Name"
              variant="outlined"
            />
          </div>
          <div>
            <DateTimePicker
              label="Start Date"
              value={startDate}
              onChange={startDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div>
            <DateTimePicker
              label="End Date"
              value={endDate}
              onChange={endDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div>
            <TextField
              inputRef={teamsCountField}
              value={teamsCount}
              onChange={(e) => setTeamsCount(e.target.value)}
              size="small"
              label="Teams"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              inputRef={gamesCountField}
              value={gamesCount}
              onChange={(e) => setGamesCount(e.target.value)}
              size="small"
              label="Games"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              inputRef={buyInField}
              value={buyIn}
              onChange={(e) => setBuyIn(e.target.value)}
              size="small"
              label="Buy In"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              inputRef={prizeField}
              value={prize}
              onChange={(e) => setPrize(e.target.value)}
              size="small"
              label="Prize"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              inputRef={unlockLimitField}
              value={unlockLimit}
              onChange={(e) => setUnlockLimit(e.target.value)}
              size="small"
              label="Unlock Limit"
              variant="outlined"
            />
          </div>
          <div>
            Active{" "}
            <Switch
              checked={active}
              color="secondary"
              onChange={onActiveChanged}
            />
          </div>
          <div>
            <Button
              size="small"
              sx={{ width: "50px" }}
              variant="outlined"
              onClick={() => onSave()}
            >
              save
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
