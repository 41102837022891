import * as React from "react";
import { MARKETPLACE_ROUTE } from "../utils/constants";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";
import "@neftyblocks/market";

interface NeftyMarketProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  collection: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "neftyblocks-market": NeftyMarketProps;
    }
  }
}

const Marketplace = () => {
  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={MARKETPLACE_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4" style={{ marginTop: "5px" }}>
              <neftyblocks-market collection="vintagenftys" />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Marketplace;
