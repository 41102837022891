import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { HtmlTooltip } from "../utils/toolTip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";

type JoinTournoProps = {
  showDialog: boolean;
  onJoinTourno(teamId: number): void;
};

export const JoinTourno: FC<JoinTournoProps> = ({
  showDialog,
  onJoinTourno,
}) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [selectedTeamId, setSelectedTeamId] = useState(0);
  const [hasLockedTeams, setHasLockedTeams] = useState(false);

  useEffect(() => {
    // check if user has any locked teams
    if (gameData.userTeams != null) {
      let lockedTeams = Object.values(gameData.userTeams).reduce(
        (acc, currentValue) => acc || currentValue.locked.toString() === "1",
        false
      );
      setHasLockedTeams(lockedTeams);
      console.log("has locked teams " + lockedTeams);
    }
  }, []);

  const teamSelected = (event: SelectChangeEvent) => {
    if (event.target.value != null) {
      let teamId = parseInt(event.target.value.toString());
      setSelectedTeamId(teamId);
    }
  };

  // Fired when user has selected a team - show Enter Tourno dialog
  const enterTournoClick = () => {
    if (selectedTeamId > 0) {
      onJoinTourno(selectedTeamId);
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        onJoinTourno(-1);
      }}
    >
      <DialogContent>
        <DialogContentText>
          {hasLockedTeams && (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <span>Choose your team</span>
              <HtmlTooltip
                arrow
                placement="right-end"
                title={
                  <div style={{ width: "175px" }}>
                    Only teams that have been 'locked' will be displayed. You
                    can only join the tournament if you have 'locked' your team.
                  </div>
                }
              >
                <HelpOutlineIcon fontSize="small" color="secondary" />
              </HtmlTooltip>
            </div>
          )}
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            width: "250px",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          {!hasLockedTeams &&
            "You can only join a tournament if you have a locked team"}
          {hasLockedTeams && (
            <FormControl fullWidth>
              <InputLabel size="small">Team</InputLabel>
              <Select
                onChange={teamSelected}
                label="Team"
                size="small"
                sx={{
                  width: "100%",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #99ff32",
                  },
                  "&:hover": {
                    "&& fieldset": {
                      border: "1px solid #99ff32",
                    },
                  },
                }}
              >
                {
                  // Only show teams that have been locked by the user
                  gameData.userTeams &&
                    Object.entries(gameData.userTeams)
                      .filter(([key, value]) => value.locked)
                      .map(([key, value]) => (
                        <MenuItem value={key}>{value.name}</MenuItem>
                      ))
                }
              </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="blue-btn"
            size="small"
            onClick={() => {
              onJoinTourno(-1);
            }}
          >
            Cancel
          </Button>
          {hasLockedTeams && (
            <Button
              className="green-btn"
              size="small"
              onClick={enterTournoClick}
              autoFocus
            >
              Ok
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};
