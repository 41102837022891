import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../store/reducers";
import { Tactic } from "../types/Tactic";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PageviewIcon from "@mui/icons-material/Pageview";
import ScienceIcon from "@mui/icons-material/Science";
import {
  ATTACK_SCORE,
  DEFENCE_SCORE,
  FORMATION_3142,
  FORMATION_343,
  FORMATION_352,
  FORMATION_4321,
  FORMATION_433,
  FORMATION_4411,
  FORMATION_442,
  FORMATION_442_DIAMOND,
  FORMATION_451,
  FORMATION_532,
  FORMATION_541,
  GOALKEEPER_SCORE,
  MIDFIELD_SCORE,
  MYTEAMS_ROUTE,
} from "../utils/constants";
import {
  Formation3142,
  Formation343,
  Formation352,
  Formation4321,
  Formation433,
  Formation4411,
  Formation442,
  Formation442Diamond,
  Formation451,
  Formation532,
  Formation541,
} from "./formations";
import { Header, HeaderHandle } from "./components/Header";
import { NavBar } from "./components/NavBar";
import {
  calculateTeamAttributeValues,
  TotalAttributeScoreData,
} from "../utils/calculateTeamTotal";
import { Team } from "../types/Team";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HtmlTooltip } from "./utils/toolTip";
import { Templates } from "../inventory";
import { sortAttributes } from "../utils/sortAttributes";
import { getKeyFrames } from "../utils/Theme";
import { PlayerSearch } from "./components/PlayerSearch";
import { OnboardPlayer } from "./components/OnboardPlayer";
import { SaveTeam } from "./components/SaveTeam";
import { LockTeam } from "./components/LockTeam";
import { fetchTeams } from "../store/actions/gameData";
import { UpgradePlayer } from "./components/UpgradePlayer";
import { useTourHelp } from "../utils/helpTour";
import { Tournament } from "../types/Tournament";
import { TournamentPosition } from "../types/TournamentPosition";
import { UpgradePlayer2 } from "./components/UpgradePlayer2";

type TeamSetupProps = {
  teamId: number;
};

const TeamSetup = () => {
  const teamState = useLocation().state as TeamSetupProps;
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: gameData, error: gameError } = useSelector(
    (state: RootState) => state.gameData
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [formation, setFormation] = useState<Tactic>({
    tactid_id: 0,
    desc: "4-4-2",
  });
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [teamError, setTeamError] = useState("");
  const [editTeam, setEditTeam] = useState<Team | null>(null);
  const [lineup, setLineup] = useState([] as number[]);
  const [playerIndex, setPlayerIndex] = useState(0);
  const [playerPosition, setPlayerPosition] = useState("");
  const [swapPlayer, setSwapPlayer] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const [newAsset, setNewAsset] = useState<IAsset | null>(null);
  const [attributeTotals, setAttributeTotals] =
    useState<TotalAttributeScoreData>({
      chemistryMultipliers: [],
      groupScores: {},
      playerScores: [],
      totalCaps: 0,
      totalScore: 0,
      playerFitness: [],
    });
  const [selectedAsset, setSelectedAsset] = useState<{
    asset: IAsset | null;
    outOfPosition: boolean;
  }>({ asset: null, outOfPosition: false });
  const [selectedAssetAttributes, setSelectedAssetAttributes] = useState(
    [] as string[]
  );
  const [teamName, setTeamName] = useState("");
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showConfirmReadyDialog, setShowConfirmReadyDialog] = useState(false);
  const [showLockDialog, setShowLockDialog] = useState(false);
  const [isTeamLocked, setIsTeamLocked] = useState(false);
  const [playerScore, setPlayerScore] = useState(0);
  const [playerChemistry, setPlayerChemistry] = useState(0);
  const [playerFitness, setPlayerFitness] = useState(0);
  const [showUpgradePlayer, setShowUpgradePlayer] = useState(false);
  const [upgradeAsset, setUpgradeAsset] = useState<IAsset | null>(null);
  const [unlockTeamEnabled, setUnlockTeamEnabled] = useState(true);
  const [teamInTournament, setTeamInTournament] = useState(false);

  // local array that keeps track of removing/adding players
  const [scrubbingLineup, setScrubbingLineup] = useState([] as number[]);

  const teamNameField = useRef<HTMLInputElement>();
  const header = useRef<HeaderHandle>(null);
  const { startHelpTour } = useTourHelp();

  /*
        TODO: 
        1. "edit mode"
            if a "team" is passed along with props then we're editing,
            otherwise we're adding a new team.

    */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Check if we're editing a team
  useEffect(() => {
    if (teamState != null) {
      if (gameData.userTeams != null) {
        let team = gameData.userTeams[teamState.teamId];
        setEditTeam(team);

        // save current locked status.
        setIsTeamLocked(team.locked);

        // set initial selected player to GK
        if (team.lineup.length == 0) {
          setLineup([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        } else {
          setLineup([...team.lineup]);
        }
        let formation = gameData.tactics![team.tacticId];
        setFormation(formation);
        setTeamName(team.name);
        calculateTeamScores(formation.desc, team.lineup);

        // find active tournament - if any
        if (gameData.tournaments != null) {
          let tournament = gameData.tournaments.find((t) => t.active);
          if (tournament != null) {
            // Get your team in the tourament
            if (gameData.tournamentPositions != null) {
              let pos = gameData.tournamentPositions.find(
                (t) => t.manager === userData.userName
              );
              if (pos != null) {
                if (team.teamId == pos.teamId) {
                  // check if team has been unlocked >= than limit count for the tournament
                  // NOTE: if unlockLimit is 0 then there is NO limit to how many times a user can unlock.
                  if (
                    tournament.unlockLimit > 0 &&
                    pos.unlockCount >= tournament.unlockLimit
                  ) {
                    setUnlockTeamEnabled(false);
                  }

                  // Is it the one you're editing
                  setTeamInTournament(true);
                }
              }
            }
          }
        }
      }
    } else {
      setLineup([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }, [teamState, gameData]);

  const setInitialPlayer = (team: Team) => {
    // find first non-zero player in array - otherwise show 0 i.e. nothing.
    let firstPlayer: number = 0;
    if (team.lineup.length > 0) {
      firstPlayer = team.lineup.findIndex((val) => val != 0);
    }
    if (firstPlayer > 0 && userAssets.assets != null) {
      let asset = userAssets.assets[firstPlayer];
    }
  };

  const formationChange = (event: SelectChangeEvent) => {
    // if locked then we do nothing
    if (isTeamLocked) {
      return;
    }
    let tacticId = parseInt(event.target.value.toString());
    if (gameData.tactics != null) {
      let tempFormation = gameData.tactics[tacticId];
      setFormation(tempFormation);

      let editLineup = lineup;
      setLineup([...editLineup]);
      calculateTeamScores(tempFormation.desc, editLineup);
    }
  };

  // calculates the TAV for the team
  const calculateTeamScores = (
    formationDesc: string,
    tempLineup: number[]
  ): TotalAttributeScoreData | null => {
    if (userAssets.assets != null && gameData.players != null) {
      let scores = calculateTeamAttributeValues(
        gameData.players,
        userAssets.assets,
        formationDesc,
        tempLineup
      );
      setAttributeTotals(scores);
      return scores;
    }
    return null;
  };

  const renderFormation = () => {
    switch (formation.desc) {
      case FORMATION_343:
        return (
          <Formation343
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_352:
        return (
          <Formation352
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_433:
        return (
          <Formation433
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_442:
        return (
          <Formation442
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_442_DIAMOND:
        return (
          <Formation442Diamond
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_451:
        return (
          <Formation451
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_532:
        return (
          <Formation532
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_541:
        return (
          <Formation541
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_3142:
        return (
          <Formation3142
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_4321:
        return (
          <Formation4321
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      case FORMATION_4411:
        return (
          <Formation4411
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
      default:
        return (
          <Formation442
            chemistry={attributeTotals.chemistryMultipliers}
            swapped={swapPlayer}
            lineup={lineup}
            isTeamLocked={isTeamLocked}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        );
    }
  };

  // Fired when user selects add or swap in the Formation editor
  const onPlayerAdd = (index: number, position: string) => {
    // show dialog
    setPlayerIndex(index);
    setPlayerPosition(position);
    setShowSearch(true);
  };

  const onPlayerMove = (dragIndex: number, hoverIndex: number) => {
    //console.log(dragIndex + ' ' + hoverIndex);
    let editLineup = lineup;
    let movingAssetId = editLineup[dragIndex];
    editLineup[dragIndex] = editLineup[hoverIndex];
    editLineup[hoverIndex] = movingAssetId;
    setLineup([...editLineup]);
    calculateTeamScores(formation.desc, editLineup);
  };

  // Fired when a player is removed from the lineup
  const onPlayerRemove = (asset: IAsset) => {
    // find player in lineup and remove

    // NOW - everything in javascript is value typed, regardless of the type of the value
    // if you do something to the value or assign it it will take on the type of that action.
    // the lineup is actually coming from the get_table_rows call so it will contain strings
    var index = -1;
    for (let i = 0; i < lineup.length; i++) {
      if (lineup[i] == parseInt(asset.asset_id)) {
        index = i;
      }
    }
    if (index != -1) {
      setSelectedAsset({ asset: null, outOfPosition: false });

      let editLineup = lineup;
      editLineup[index] = 0;
      setLineup(editLineup);
      calculateTeamScores(formation.desc, editLineup);

      // add to the scrub array so we can keep track
      let scrubList = scrubbingLineup;
      scrubList.push(parseInt(asset.asset_id));
      console.log(scrubList);
      setScrubbingLineup(scrubList);
    }
  };

  // Fired when a player is in the incorrect position
  const onInvalidPlayer = (asset: IAsset) => {};

  // Fired when the user clicks the 'level up' button on the player
  const onPlayerLevelUp = () => {
    if (selectedAsset.asset != null) {
      setUpgradeAsset(selectedAsset.asset);
      setShowUpgradePlayer(true);
    }
  };

  // Triggered when a player has been upgraded/boosted.
  const onPlayerUpgraded = (updatedAsset: IAsset | null) => {
    setShowUpgradePlayer(false);
    if (updatedAsset) {
      updateLineup(updatedAsset);
    }
  };

  // Fired when user hovers (actually clicks) over a card in the formation editor - display attributes on screen.
  const onPlayerHover = (
    index: number,
    asset: IAsset,
    position: string,
    outOfPosition: boolean
  ) => {
    setPlayerScore(attributeTotals.playerScores[index]);
    setPlayerChemistry(attributeTotals.chemistryMultipliers[index]);
    setPlayerFitness(attributeTotals.playerFitness[index]);
    setSelectedAsset({ asset: asset, outOfPosition: outOfPosition });
    setPlayerIndex(index);
    setPlayerPosition(position);
    let keys = sortAttributes(
      Object.keys(asset.mutable_data),
      asset.mutable_data.Position,
      true
    );
    setSelectedAssetAttributes(keys);
  };

  // Fired when user selects add or swap in the Formation editor
  const onSwapPlayer = () => {
    // show dialog
    setSwapPlayer(selectedAsset.asset != null);
    setShowSearch(true);
  };

  // Triggered when the user selects a player from the Search screen.
  const onPlayerSelected = (asset: IAsset) => {
    setShowSearch(false);
    if (asset != null) {
      // If player is new to the game then display onboarding modal
      if (Object.keys(asset.mutable_data).length == 0) {
        setNewAsset(asset);
        setShowOnBoarding(true);
      } else {
        // otherwise update team lineup
        updateLineup(asset);
      }

      // remove from scrub list if added previously.
      let index = scrubbingLineup.indexOf(parseInt(asset.asset_id));
      if (index >= 0) {
        let newList = scrubbingLineup;
        newList.splice(index, 1);
        console.log(newList);
        setScrubbingLineup(newList);
      }
    }
  };

  // Triggered once a new player has been onboarded from the Search screen.
  const onPlayerOnboarded = (playerIsReady: boolean) => {
    setShowOnBoarding(false);
    if (playerIsReady && newAsset != null && userAssets.assets != null) {
      let asset = userAssets.assets[parseInt(newAsset.asset_id)];
      updateLineup(asset);
      setNewAsset(null);
    }
  };

  // updates the line up and score table.
  const updateLineup = (asset: IAsset) => {
    let editLineup = lineup;
    editLineup[playerIndex] = parseInt(asset.asset_id);
    setLineup(editLineup);
    let scores = calculateTeamScores(formation.desc, editLineup);

    setSelectedAsset({
      asset: asset,
      outOfPosition: asset.mutable_data.Position != playerPosition,
    });

    let keys = sortAttributes(
      Object.keys(asset.mutable_data),
      asset.mutable_data.Position,
      true
    );
    console.log("KEYS COUNT " + keys.length);
    setSelectedAssetAttributes(keys);

    if (scores != null) {
      setPlayerScore(scores.playerScores[playerIndex]);
      setPlayerChemistry(scores.chemistryMultipliers[playerIndex]);
      setPlayerFitness(scores.playerFitness[playerIndex]);
    }
  };

  const getTactic = (): number => {
    let tactic =
      gameData.tactics != null ? gameData.tactics[formation.tactid_id] : null;
    return tactic != null ? tactic.tactid_id : 0;
  };

  const renderTeamName = () => {
    // We allow team name edit if the team is not in an active tournament

    if (editTeam == null || !teamInTournament) {
      return (
        <TextField
          required
          inputRef={teamNameField}
          value={teamName}
          onChange={(e) => updateTeamName(e.target.value)}
          size="small"
          name="teamNameInput"
          label="Team name"
          variant="outlined"
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #99ff32",
            },
            "&:hover": {
              "&& fieldset": {
                border: "1px solid #99ff32",
              },
            },
          }}
        />
      );
    } else {
      return (
        <span style={{ fontWeight: "bold" }}>
          <b>{editTeam.name}</b>
        </span>
      );
    }
  };

  const updateTeamName = (value: string) => {
    setTeamName(value);
  };

  const updateTeam = () => {
    if (teamNameField.current?.value == "") {
      setShowError(true);
      setTeamError("Please enter a name for your team.");
      teamNameField.current.focus();
      return;
    }

    let tactic =
      gameData.tactics != null ? gameData.tactics[formation.tactid_id] : null;
    if (tactic == null) {
      setShowError(true);
      setTeamError("Could not find formation tactic. Something is up!");
      return;
    }

    setShowSaveDialog(true);
  };

  const toggleTeamReadiness = () => {
    // show confirm dialog if lineup is not 100% complete AND the team isn't locked.
    const numEmptySlots = countItems(lineup, 0);
    if (numEmptySlots > 0 && editTeam != null && !isTeamLocked) {
      setShowConfirmReadyDialog(true);
    } else {
      setShowLockDialog(true);
    }
  };

  function countItems(arr: number[], item: number) {
    var count = 0,
      i;
    while ((i = arr.indexOf(item, i)) != -1) {
      ++count;
      ++i;
    }
    return count;
  }

  function getSelectedAssetAttributeValue(value: any, outOfPosition: boolean) {
    if (isNaN(value)) {
      return value;
    }

    // first apply fitness reduction to value
    let score = (playerFitness / 100) * value;
    // apply 10% reduction if out of position
    return outOfPosition ? Math.round((90 / 100) * score) : Math.round(score);
  }

  const getImagePath = (asset: IAsset | null): string => {
    if (asset == null) {
      return "images/placeholder_player.png";
    }
    return "images/" + Templates[asset.template!.template_id].imgPath;
  };

  const viewAsset = (assetId: string) => {
    window.open("https://wax.atomichub.io/explorer/asset/" + assetId, "_blank");
  };

  const getSelectedAssetInfo = (asset: IAsset | null) => {
    if (asset != null) {
      return (
        <Link
          onClick={() => {
            viewAsset(asset.asset_id);
          }}
        >
          {asset.name}
          <span style={{ display: "block" }}>
            {asset.data.Rarity} - Lvl {asset.mutable_data.Level}
          </span>
        </Link>
      );
    }
  };

  const onTeamSaved = () => {
    setShowSaveDialog(false);
  };

  const onLockTeam = (locked: boolean) => {
    console.log("locked:" + locked);
    setIsTeamLocked(locked);
    setShowLockDialog(false);
    dispatch(fetchTeams(userData, userAssets));
  };

  const getSelectedAssetFitness = (asset: IAsset | null) => {
    if (asset != null) {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
              justifyContent: "center",
            }}
          >
            <Grid container width={"100px"}>
              <Grid xs item>
                <LinearProgress
                  variant="determinate"
                  value={playerFitness}
                  sx={{
                    height: 5,
                    borderRadius: 60,
                    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                    "& .MuiLinearProgress-bar1Determinate": {
                      animation:
                        getKeyFrames(playerFitness) + " 3s normal forwards",
                      ...(playerFitness <= 25 && {
                        background:
                          "linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(177, 0, 0, 1) 100%)",
                      }),
                      ...(playerFitness > 25 &&
                        playerFitness <= 50 && {
                          background:
                            "linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 183, 26, 1) 50%)",
                        }),
                      ...(playerFitness > 50 &&
                        playerFitness <= 75 && {
                          background:
                            "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                        }),
                      ...(playerFitness > 75 && {
                        background:
                          "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                      }),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <span>Fitness {playerFitness}%</span>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
              justifyContent: "center",
            }}
          >
            <Grid container width={"100px"}>
              <Grid xs item>
                <LinearProgress
                  variant="determinate"
                  value={0}
                  sx={{
                    height: 5,
                    borderRadius: 60,
                    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <span>Fitness {0}%</span>
        </>
      );
    }
  };

  const getSelectedAssetChemistry = (
    asset: IAsset | null,
    chemistry: number
  ) => {
    if (asset == null) {
      return "N/A";
    } else {
      return (
        <>
          <span>x {chemistry} &nbsp;</span>
          {chemistry > 1 && (
            <HtmlTooltip
              arrow
              placement="right"
              title={
                <div style={{ width: "100px" }}>
                  {getChemistryDesc(chemistry)}
                </div>
              }
            >
              <ScienceIcon
                fontSize="small"
                sx={{
                  ...(chemistry > 1 && chemistry < 1.2 && { color: "#b8c2cc" }),
                  ...(chemistry >= 1.2 &&
                    chemistry < 1.5 && { color: "#4dc0b5" }),
                  ...(chemistry >= 1.5 &&
                    chemistry < 2 && { color: "#38c172" }),
                  ...(chemistry >= 2 &&
                    chemistry < 2.5 && { color: "#ffed4a" }),
                  ...(chemistry >= 2.5 &&
                    chemistry < 3 && { color: "#f6993f" }),
                  ...(chemistry >= 3 && { color: "#e3342f" }),
                }}
              />
            </HtmlTooltip>
          )}
        </>
      );
    }
  };

  const getChemistryDesc = (chem: number): string => {
    if (chem > 1 && chem < 1.2) {
      return "Oh there's a little bit of chemistry going on...";
    }
    if (chem >= 1.2 && chem < 1.5) {
      return "Nice, this player seems happy in this position!";
    }
    if (chem >= 1.5 && chem < 2) {
      return "This player is gelling well with the rest of the team, good stuff!";
    }
    if (chem >= 2 && chem < 2.5) {
      return "Playing well and consistently, can they get any better?!";
    }
    if (chem >= 2.5 && chem < 3) {
      return "There seems to be no stopping this player!";
    }
    if (chem >= 3) {
      return "This player is ON FIRE, top of their game!";
    }
    return "";
  };

  const startHelp = (page: string) => {
    if (header.current) {
      header.current.closeMenu();
    }

    startHelpTour(page);
  };

  const isLockBottonDisabled = (): boolean => {
    // First we check if the user has unlocked too many times
    // i.e. unlock count >= the unlock limit for the tournament
    if (!unlockTeamEnabled) {
      // They have the hit the limit BUT on the last unlock we need to allow them to "lock" the team so they can play
      if (!isTeamLocked) {
        return false;
      }
      return true;
    }
    return editTeam != null ? false : true;
  };

  return (
    <>
      <PlayerSearch
        showDialog={showSearch}
        onPlayerSelected={onPlayerSelected}
        lineup={lineup}
        scrubbList={scrubbingLineup}
        showPlayers={true}
      />
      <OnboardPlayer
        showDialog={showOnBoarding}
        position={playerPosition}
        asset={newAsset}
        onPlayerOnboarded={onPlayerOnboarded}
      />
      <SaveTeam
        showDialog={showSaveDialog}
        lineup={lineup}
        team={editTeam}
        teamName={
          editTeam == null || !teamInTournament
            ? teamNameField.current?.value!
            : editTeam.name
        }
        tacticId={getTactic()}
        onSave={onTeamSaved}
      />
      <LockTeam
        showDialog={showLockDialog}
        team={editTeam}
        isTeamLocked={isTeamLocked}
        onLock={onLockTeam}
      />
      <UpgradePlayer2
        showDialog={showUpgradePlayer}
        asset={upgradeAsset!}
        onPlayerUpgraded={onPlayerUpgraded}
      />
      <Header ref={header} />
      <div id="layoutSidenav">
        <NavBar onHelp={startHelp} selectedMenuItem={MYTEAMS_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container teamedit-bg">
              <div className="row g-0">
                <div className="col-1 pitch-positions">
                  <div className="st">ST</div>
                  <div className="md">MD</div>
                  <div className="df">DF</div>
                  <div className="gk">GK</div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 pitch-width">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "4px",
                      marginTop: "-50px",
                    }}
                  >
                    <div
                      className="teamName"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#fff",
                        margin: "0px 0px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        id="teamsetupintro"
                        style={{ marginRight: "10px" }}
                        src={"/images/teams-icon.svg"}
                        width="42"
                        height="42"
                      />
                      {renderTeamName()}
                    </div>
                    <div className="">
                      <FormControl sx={{ width: "200px" }} fullWidth>
                        <InputLabel size="small">Formation</InputLabel>
                        <Select
                          onChange={formationChange}
                          value={formation.tactid_id.toString()}
                          label="Formation"
                          size="small"
                          sx={{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #99ff32",
                            },
                            "&:hover": {
                              "&& fieldset": {
                                border: "1px solid #99ff32",
                              },
                            },
                          }}
                        >
                          {gameData.tactics &&
                            Object.entries(gameData.tactics).map(
                              ([key, value]) => (
                                <MenuItem value={key}>{value.desc}</MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <DndProvider backend={HTML5Backend}>
                    {renderFormation()}
                  </DndProvider>
                </div>

                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                  <div
                    className="card"
                    style={{
                      width: "100%",
                      backgroundColor: "#000b1d",
                      color: "#fff",
                      borderRight: "2px solid #22395A",
                      borderLeft: "2px solid #22395A",
                      zIndex: "2",
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title">
                        <div style={{ padding: "4px", textAlign: "center" }}>
                          SELECTED PLAYER
                        </div>
                      </h5>
                    </div>
                    <div className="list-group list-group-flush">
                      <div className="column">
                        <div className="player-columnLG">
                          <div className="card-boxLG">
                            <div className="flip-boxLG">
                              <div className="flip-box-innerLG">
                                <div className="flip-box-frontLG">
                                  <img
                                    id="playerfocus"
                                    src={getImagePath(selectedAsset.asset)}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <br></br>
                            {getSelectedAssetFitness(selectedAsset.asset)}
                          </div>
                        </div>
                      </div>
                      {/* <!--end player---> */}
                    </div>
                    <div className="row2 selectPlayerName">
                      <div
                        className="col-6 col-md-6"
                        style={{ textAlign: "center", padding: "2px 10px" }}
                      >
                        <Button
                          className="green-btn"
                          disabled={selectedAsset.asset == null || isTeamLocked}
                          variant="contained"
                          onClick={onPlayerLevelUp}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          Boost
                        </Button>
                      </div>
                      <div
                        className="col-6 col-md-6"
                        style={{ textAlign: "center", padding: "2px 10px" }}
                      >
                        <Button
                          className="blue-btn"
                          disabled={selectedAsset.asset == null || isTeamLocked}
                          variant="contained"
                          onClick={onSwapPlayer}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          {selectedAsset.asset ? "Swap" : "Add Player"}
                        </Button>
                      </div>
                    </div>
                    <table className="playerstats-table" width="100%">
                      <tr>
                        <th colSpan={2} style={{ padding: "2px" }}>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "3px",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              {selectedAsset.asset
                                ? selectedAsset.asset.data.name
                                : "N/A"}
                            </div>
                            <div>
                              {selectedAsset.asset &&
                                selectedAsset.asset.data.Rarity}{" "}
                              Lvl -{" "}
                              {selectedAsset.asset
                                ? selectedAsset.asset.mutable_data.Level
                                : "N/A"}{" "}
                              <IconButton
                                disabled={selectedAsset.asset == null}
                                onClick={() =>
                                  viewAsset(selectedAsset.asset!.asset_id)
                                }
                                aria-label="delete"
                                component="label"
                              >
                                <PageviewIcon
                                  color="secondary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </div>
                          </div>
                        </th>
                      </tr>

                      {selectedAsset.asset != null ? (
                        selectedAssetAttributes.map((item) => (
                          <tr>
                            <td className="td-title" width="40%">
                              {item}
                            </td>
                            <td className="td-values">
                              {getSelectedAssetAttributeValue(
                                selectedAsset.asset!.mutable_data[item],
                                selectedAsset.outOfPosition
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="td-title" width="40%">
                              &nbsp;
                            </td>
                            <td className="td-values">&nbsp;</td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <td className="td-title totalleft" width="30%">
                          Chemistry{" "}
                        </td>
                        <td className="totalright">
                          {getSelectedAssetChemistry(
                            selectedAsset.asset,
                            playerChemistry
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="td-title totalleft" width="30%">
                          TOTAL
                        </td>
                        <td className="totalright">
                          {selectedAsset.asset ? playerScore : "N/A"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 teamedit3Col">
                  <Button
                    className="green-btn"
                    disabled={isTeamLocked}
                    style={{ width: "100%" }}
                    variant="contained"
                    sx={{ marginTop: "10px" }}
                    onClick={updateTeam}
                  >
                    update team
                  </Button>
                  <HtmlTooltip
                    arrow
                    placement="left"
                    title={
                      <div style={{ width: "200px" }}>
                        {/* Show unlock message */}
                        {isTeamLocked
                          ? "If you unlock the team then opponents won't be able to challenge you and vice versa. You can modify the team once it has been unlocked."
                          : 'Ready to play? If so click here and "lock" the team. Once a team is locked you are able to play against others and vice versa. The team cannot be modified while it is locked.'}
                      </div>
                    }
                  >
                    <Button
                      className="red-btn"
                      /* Can lock/unlock if you're editing a team. */
                      disabled={isLockBottonDisabled()}
                      endIcon={isTeamLocked ? <LockIcon /> : <LockOpenIcon />}
                      onClick={toggleTeamReadiness}
                      style={{ width: "100%" }}
                      variant="contained"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#cc0000",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#990000",
                          color: "#fff",
                        },
                      }}
                    >
                      {isTeamLocked ? "Unlock team" : "ready to play"}
                    </Button>
                  </HtmlTooltip>
                  <div id="lockteam" style={{ height: "1px" }} />
                  <table
                    className="attributes-table"
                    width="100%"
                    style={{
                      marginTop: "0px",
                      border: "1px solid #99ff32",
                    }}
                  >
                    <tr>
                      <th colSpan={2}>team total attribute values</th>
                    </tr>
                    <tr>
                      <td className="td-title" width="50%">
                        ST
                      </td>
                      <td className="td-values">
                        {attributeTotals.groupScores[ATTACK_SCORE]}
                      </td>
                    </tr>
                    <tr>
                      <td className="td-title" width="50%">
                        MF
                      </td>
                      <td className="td-values">
                        {attributeTotals.groupScores[MIDFIELD_SCORE]}
                      </td>
                    </tr>
                    <tr>
                      <td className="td-title" width="50%">
                        DF/GK
                      </td>
                      <td className="td-values">
                        {attributeTotals.groupScores[DEFENCE_SCORE] +
                          attributeTotals.groupScores[GOALKEEPER_SCORE]}
                      </td>
                    </tr>
                    <tr>
                      <td className="td-title totalleft" width="50%">
                        TOTAL
                      </td>
                      <td className="totalright">
                        {attributeTotals.totalScore}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default TeamSetup;
