import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';
import { BoostPlayer } from "../../types/BoostPlayer";


const initialState: InitialState<BoostPlayer> = {
    isFetching: true,
    error: undefined,
    data:{
        asset:null
    }
};

export const boostPlayer = (
    state = initialState,
    action: ReducerAction<BoostPlayer>
)=>{
    switch(action.type){
        case actionTypes.BOOST_PLAYER:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}