import React, { FC, useEffect, useState } from "react";
import {
  DEFENDER,
  FORWARD,
  GOALKEEPER,
  MIDFIELDER,
} from "../../utils/constants";
import { PlayerDetails } from "./playerDetails";
import { FormationProps } from "./props";

export const Formation352: FC<FormationProps> = ({
  lineup,
  isTeamLocked,
  chemistry,
  swapped,
  onPlayerAdd,
  onPlayerHover,
  onInvalidPlayer,
  onPlayerRemove,
  onPlayerMove,
}) => {
  return (
    <div className="pitch-wrapper">
      {/* Attack Row */}
      <div className="row2">
        <div className="column">
          <div className="empty-column">
            {/* <!--empty col-->
                    
                <!--empty col end--> src={`images/${Templates[asset.template!.template_id].imgPath}?w=67&h=100&fit=crop&auto=format`} */}
          </div>
        </div>
        <div className="column">
          <PlayerDetails
            index={9}
            chemistry={chemistry[9]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={FORWARD}
            assetId={lineup[9]}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={10}
            chemistry={chemistry[10]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={FORWARD}
            assetId={lineup[10]}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <div className="empty-column">
            {/* <!--empty col-->
                            
                        <!--empty col end--> */}
          </div>
        </div>
      </div>

      {/* <!--Midfield row---> */}
      <div className="row2">
        <div className="column">
          <PlayerDetails
            index={4}
            chemistry={chemistry[4]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={MIDFIELDER}
            assetId={lineup[4]}
            onPlayerHover={onPlayerHover}
            useSmallBox={true}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={5}
            chemistry={chemistry[5]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={MIDFIELDER}
            assetId={lineup[5]}
            onPlayerHover={onPlayerHover}
            useSmallBox={true}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={6}
            chemistry={chemistry[6]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={MIDFIELDER}
            assetId={lineup[6]}
            onPlayerHover={onPlayerHover}
            useSmallBox={true}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={7}
            chemistry={chemistry[7]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={MIDFIELDER}
            assetId={lineup[7]}
            onPlayerHover={onPlayerHover}
            useSmallBox={true}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={8}
            chemistry={chemistry[8]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={MIDFIELDER}
            assetId={lineup[8]}
            onPlayerHover={onPlayerHover}
            useSmallBox={true}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
      </div>

      {/* <!-- Defenders row---> */}
      <div className="row2">
        <div className="column">
          <PlayerDetails
            index={1}
            chemistry={chemistry[1]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={DEFENDER}
            assetId={lineup[1]}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={2}
            chemistry={chemistry[2]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={DEFENDER}
            assetId={lineup[2]}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
        <div className="column">
          <PlayerDetails
            index={3}
            chemistry={chemistry[3]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={DEFENDER}
            assetId={lineup[3]}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
      </div>

      {/* <!--Goal Keeper---> */}
      <div className="row2">
        <div className="column">
          <PlayerDetails
            index={0}
            chemistry={chemistry[0]}
            isTeamLocked={isTeamLocked}
            swapped={swapped}
            position={GOALKEEPER}
            assetId={lineup[0]}
            onPlayerHover={onPlayerHover}
            onInvalidPlayer={onInvalidPlayer}
            onPlayerRemove={onPlayerRemove}
            onPlayerMove={onPlayerMove}
            onPlayerAdd={onPlayerAdd}
          />
        </div>
      </div>
    </div>
  );
};
