export const ATOMIC_ASSETS_URL = "https://aa-wax-public1.neftyblocks.com";
// https://wax-public1.neftyblocks.com/v1/
//export const ATOMIC_ASSETS_URL = "https://wax.api.atomicassets.io";
// http://aa-wax-public1.neftyblocks.com/
//export const ATOMIC_ASSETS_URL = "https://wax.greymass.com";

// STAGING URLS
//export const WAX_URL = "https://waxtestnet.greymass.com";
//export const WAX_DOMAIN = "waxtestnet.greymass.com";
//export const SMARTCONTRACT_ACCOUNT = "vintagenftys";

// PROD
export const WAX_URL = "https://wax.greymass.com";
export const WAX_DOMAIN = "wax.greymass.com";
export const VINTYNIFTYS_ACCOUNT = "vintagenftyx";
export const ATOMICASSETS_ACCOUNT = "atomicassets";
export const VINTYTOKENS_ACCOUNT = "vintytokensx";
export const VINTYSTAKES_ACCOUNT = "vintystakesx";
export const EOSIO_ACCOUNT = "eosio.token";
export const VINTY = "VNTY";
export const WAX = "WAX";

export const WEB_SERVICE_URL = "http://service.vintageniftys.io/";
export const APP_NAME = 'vintageniftys';
export const COLLECTION_NAME = 'vintagenftys';

// Used UAL
export const ACTIVE_USER = "activeUser";

export const INITIALISE_SESSION = 'INITIALISE_SESSION';
export const LOAD_SCHEMA_COUNTS = 'LOAD_SCHEMA_COUNTS';
export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOAD_USER_TEAMS = 'LOAD_USER_TEAMS';
export const LOAD_TACTICS = 'LOAD_TACTICS';
export const LOAD_PLAYERS = 'LOAD_PLAYERS';
export const LOAD_MATCHES = 'LOAD_MATCHES';
export const LOAD_TOURNAMENTS = 'LOAD_TOURNAMENTS';
export const LOAD_COMPLETE = 'LOAD_COMPLETE';

// schema names
export const SERIES1 = "series1";
export const SERIES2 = "series2";
export const EMOTION = "emotion";
export const PROMOPLAYERS = "promoplayers";
export const ENFORCERS = "enforcers";
export const SPECIALS = "specials";
export const WCLEGENDS1 = "wclegends1";

// onboarding player messages
export const STRETCHING = "...he's stretching...";
export const BRYLCREEM = "...applying brylcreem...";
export const CLEAN_BOOTS = "...cleaning boots...";
export const CIGGY = "...havin' a quick smoke...";
export const PREP_TALK = "...final words with the gaffer...";
export const WALK = "...walking down the tunnel...";


// positions
export const GOALKEEPER = "Goalkeeper";
export const DEFENDER = "Defender";
export const MIDFIELDER = "Midfielder";
export const FORWARD = "Forward";

// formations
export const FORMATION_343 = "3-4-3";
export const FORMATION_352 = "3-5-2";
export const FORMATION_442 = "4-4-2";
export const FORMATION_433 = "4-3-3";
export const FORMATION_442_DIAMOND = "4-4-2-Diamond";
export const FORMATION_451 = "4-5-1";
export const FORMATION_532 = "5-3-2";
export const FORMATION_541 = "5-4-1";
export const FORMATION_3142 = "3-1-4-2";
export const FORMATION_4321 = "4-3-2-1";
export const FORMATION_4411 = "4-4-1-1";

export const GOALKEEPER_SCORE = "GOALKEEPER_SCORE";
export const DEFENCE_SCORE = "DEFENCE_SCORE";
export const MIDFIELD_SCORE = "MIDFIELD_SCORE";
export const ATTACK_SCORE = "ATTACK_SCORE";

export const DragItemTypes = {
    PLAYER: 'player'
}   


// ROUTES
export const DASHBOARD_ROUTE = "/dashboard";
export const MYTEAMS_ROUTE = "/myteams";
export const TEAMSETUP_ROUTE = "/teamsetup";
export const MARKETPLACE_ROUTE = "/marketplace";
export const MYPLAYERS_ROUTE = "/myplayers";
export const ADMIN_ROUTE = "/admin";
export const LEAGUETABLE_ROUTE = "/leaguetable";
export const TOURNAMENTS_ROUTE = "/tournaments";
export const CARDS_ROUTE = "/cards";
export const PLAY_MATCH = "/playmatch";
export const TEST_TRANSACTION = "/TEST_TRANSACTION";

// Configured schemas
export const ACTIVE_SCHEMAS = [SERIES1,SERIES2,EMOTION,ENFORCERS,PROMOPLAYERS,WCLEGENDS1,SPECIALS];

export const INITIALISE_SESSION_MESSAGES = ['Painting those lines','Inflating the balls','Hanging the nets up'];   
export const LOAD_SCHEMA_COUNTS_MESSAGES = ["Cleaning the dressing room","Mowing the pitch","Checking pitch irrigation"];
export const LOAD_ASSETS_MESSAGES = ["Checking first aid box","Cleaning boots","Erecting corner flags"];
export const LOAD_USER_TEAMS_MESSAGES = ["Oiling those turnstiles", "Sweeping the terraces", "Cookin' footy scran"];
export const LOAD_TACTICS_MESSAGES = ['Printing tickets','Polishing silveware',"Talkin' to the press"];
export const LOAD_PLAYERS_MESSAGES = ['Fetching bucket and sponge','Pouring the tea','Looking for the 12th man...'];
export const LOAD_TOURNAMENTS_MESSAGES = ['Checking formations','Finalising tactics','Obstructing referees'];
export const LOAD_MATCHES_MESSAGES = ['Cleaning dugout',"Counting shinpads", "Fetching the ice"];