import { ChallengeOpponent } from "../../types/ChallengeOpponent";
import * as actionTypes from './types';


export const setChallengeOpponent = (data: ChallengeOpponent) =>{
   
    return{
        type: actionTypes.CHALLENGE_OPPONENT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};