import Stack from "@mui/material/Stack";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DeleteTeam } from "../components/DeleteTeam";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { getKeyFrames } from "../../utils/Theme";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { calculateTeamTAVScore } from "../../utils/calculateTeamTotal";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Team } from "../../types/Team";
import { calculateTeamFitnessPercentage } from "../../utils/calculateFitness";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { HtmlTooltip } from "./toolTip";

export const TeamsNoRowsOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      You don't have any teams{" "}
      <SentimentVeryDissatisfiedIcon color="secondary" fontSize="small" />{" "}
      Create one and get amongst it!
    </Stack>
  );
};

export const teamColumns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1, sortable: false },
  {
    field: "tav_id",
    headerName: "TAV",
    align: "center",
    headerAlign: "center",
    width: 50,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<number>) => (
      <TotalAttributeValueColumn teamId={params.value!} />
    ),
  },
  {
    field: "tacticId",
    headerName: "Tactic",
    align: "center",
    headerAlign: "center",
    width: 60,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<number>) => (
      <TacticDesc tacticId={params.value!} />
    ),
  },
  {
    field: "win",
    headerName: "W",
    align: "center",
    headerAlign: "center",
    minWidth: 20,
    width: 32,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "lose",
    headerName: "L",
    align: "center",
    headerAlign: "center",
    minWidth: 20,
    width: 32,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "draw",
    headerName: "D",
    align: "center",
    headerAlign: "center",
    minWidth: 20,
    width: 32,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "points",
    headerName: "PTS",
    align: "center",
    headerAlign: "center",
    minWidth: 20,
    width: 50,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "teamId",
    headerName: "Avg. Fitness",
    headerAlign: "center",
    width: 120,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<number>) => (
      <TeamFitnessColumn width={100} teamId={params.value!} />
    ),
  },
  {
    field: "locked",
    headerName: "Locked",
    headerAlign: "center",
    width: 70,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<boolean>) => (
      <LockedColumn locked={params.value!} />
    ),
  },
  {
    field: "id",
    headerName: "Actions",
    headerAlign: "center",
    width: 110,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<number>) => (
      <ActionPanel teamId={params.value!} />
    ),
  },
];

export const teamColumnsNew: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1, sortable: false },
  {
    field: "tav_id",
    headerName: "TAV",
    align: "center",
    headerAlign: "center",
    width: 50,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<number>) => (
      <TotalAttributeValueColumn teamId={params.value!} />
    ),
  },
  {
    field: "id",
    headerName: "Actions",
    headerAlign: "center",
    width: 110,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<number>) => (
      <ActionPanel teamId={params.value!} />
    ),
  },
];

export type TacticDescProps = {
  tacticId: number;
};

export const TacticDesc: FC<TacticDescProps> = ({ tacticId }) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);

  return (
    <span>
      {gameData.tactics != null ? gameData.tactics[tacticId].desc : ""}
    </span>
  );
};

export type ActionPanelProps = {
  teamId: number;
};

const ActionPanel: FC<ActionPanelProps> = ({ teamId }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openDeleteTrans, setOpenDeleteTrans] = useState(false);

  const showDialog = () => {
    setOpen(true);
  };

  const editTeam = () => {
    navigate("/teamsetup", { state: { teamId } });
  };

  const deleteTeam = () => {
    setOpen(false);
    setOpenDeleteTrans(true);
  };

  const onDelete = () => {
    setOpenDeleteTrans(false);
  };

  const cancel = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <DeleteTeam
        showDialog={openDeleteTrans}
        teamId={teamId}
        onDelete={onDelete}
      />
      <Button
        onClick={editTeam}
        className="green-btn"
        variant="contained"
        size="small"
      >
        Edit
      </Button>
      <IconButton sx={{ marginRight: "-10px" }} onClick={showDialog}>
        <DeleteIcon fontSize="medium" color="secondary" />
      </IconButton>
      <Dialog open={open} onClose={cancel}>
        <DialogTitle>Delete team?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action is irreversible, are you sure you want to do this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteTeam} autoFocus>
            Ok
          </Button>
          <Button onClick={cancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export type TeamFitnessColumnProps = {
  teamId: number;
  width: number;
};

export const TeamFitnessColumn: FC<TeamFitnessColumnProps> = ({
  teamId,
  width,
}) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [fitnessPercent, setFitnessPercent] = useState<number>(0);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let team: Team | null = null;
    // locate team
    if (gameData.userTeams != null) {
      team = gameData.userTeams[teamId];
    }
    if (team == null) {
      if (gameData.opponentTeams != null) {
        team = gameData.opponentTeams[teamId];
      }
    }

    if (team != null) {
      calcFitnessPercentage(team.lineup);
    }
  }, [gameData.userTeams, gameData.opponentTeams]);

  const calcFitnessPercentage = (lineup: number[]) => {
    if (gameData.players != null) {
      let total = calculateTeamFitnessPercentage(gameData.players, lineup);
      setFitnessPercent(Math.round(total));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        gap: "3px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          marginLeft: mobile ? "5px" : "0px",
        }}
      >
        {/* ={mobile ? "70px" : "100px"} */}
        <Grid container width={width}>
          <Grid xs item>
            <LinearProgress
              variant="determinate"
              value={fitnessPercent}
              sx={{
                height: 5,
                borderRadius: 60,
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                "& .MuiLinearProgress-bar1Determinate": {
                  animation:
                    getKeyFrames(fitnessPercent) + " 3s normal forwards",
                  ...(fitnessPercent <= 25 && {
                    background:
                      "linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(177, 0, 0, 1) 100%)",
                  }),
                  ...(fitnessPercent > 25 &&
                    fitnessPercent <= 50 && {
                      background:
                        "linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 183, 26, 1) 50%)",
                    }),
                  ...(fitnessPercent > 50 &&
                    fitnessPercent <= 75 && {
                      background:
                        "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                    }),
                  ...(fitnessPercent > 75 && {
                    background:
                      "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                  }),
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
      <span style={{ fontSize: "10px" }}>{fitnessPercent}%</span>
    </div>
  );
};

export type LockedColumnProps = {
  locked: boolean;
};

export const LockedColumn: FC<LockedColumnProps> = ({ locked }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {locked ? (
        <HtmlTooltip
          title={
            <div style={{ width: "100px" }}>
              This team is locked and loaded and ready to play! Note, you can't
              edit while locked.
            </div>
          }
        >
          <LockIcon color="secondary" fontSize="medium" />
        </HtmlTooltip>
      ) : (
        <HtmlTooltip
          title={
            <div style={{ width: "100px" }}>
              This team is open and can be edited. You can't play while
              unlocked.
            </div>
          }
        >
          <LockOpenIcon color="error" fontSize="medium" />
        </HtmlTooltip>
      )}
    </div>
  );
};

export type TotalAttributeValueColumnProps = {
  teamId: number;
};

export const TotalAttributeValueColumn: FC<TotalAttributeValueColumnProps> = ({
  teamId,
}) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (
      gameData.userTeams != null &&
      assetData.assets != null &&
      gameData.players != null
    ) {
      let team = gameData.userTeams[teamId];
      if (team != null) {
        let formation = gameData.tactics![team.tacticId];
        let total = calculateTeamTAVScore(
          gameData.players,
          assetData.assets,
          formation.desc,
          team.lineup
        );
        setTotal(total);
      }
    }
  }, [gameData.userTeams, gameData.players]);

  return <span>{total}</span>;
};
