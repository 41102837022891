import { Player } from "../types/Player";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { Team } from "../types/Team";
import { calculateTeamAttributeValues, calculateTeamTAVScore, TotalAttributeScoreData } from "./calculateTeamTotal";
import { Tactic } from "../types/Tactic";
import { randomIntFromInterval } from "./upgradeMutableAttributes";
import { calculateTeamFitnessPercentage } from "./calculateFitness";

export interface GameResult{
    homeTAV: TotalAttributeScoreData,
    awayTAV : TotalAttributeScoreData,
    homeScore : number,
    awayScore : number,
    points : number
}

type DrawResult = {
    isDraw : boolean;
    score : number;
}

type Score = {
    winner:number;
    loser:number;
}


const calculateDraw = (percentDiff : number) : DrawResult =>{
    /*
        %diff range	probability
        0> & <=5	0.2
        5> & <=10	0.1
        10> & <=50	0.05
        50> & <=100	0.02
        >100	0.01
    */
    let drawResult : DrawResult={
        isDraw : false,
        score: 0
    };
    let rng = randomIntFromInterval(1, 100);
    //console.log("Random no: " + rng);
    // 20% 
    if(percentDiff > 0 && percentDiff <= 5 && rng <= 20){
        drawResult.isDraw = true;
        drawResult.score = randomIntFromInterval(0,3);
    }
    // 10%
    if(percentDiff > 5 && percentDiff <= 10 && rng <= 10){
        drawResult.isDraw = true;
        drawResult.score = randomIntFromInterval(0,2);
    }
    // 5%
    if(percentDiff > 10 && percentDiff <= 50 && rng <= 5){
        drawResult.isDraw = true;
        drawResult.score = randomIntFromInterval(0,1);
    }
    // 1%
    if(percentDiff > 50 && percentDiff <= 100 && rng == 1){
        drawResult.isDraw = true;
        drawResult.score = randomIntFromInterval(0,1);
    }
    return drawResult;
}

const calculatePoints = (fitness : number) : number =>{
    // opponent team fitness is 100% - 5pts
    // 75% - 100% - 4pt.
    // 50 - 74 - 3pt
    // 25 - 49 - 2pt
    // 1 - 24 - 1pt.
    if(fitness == 100){
        return 5;
    }
    else if(fitness >= 75 && fitness < 100){
        return 4;
    }
    else if(fitness >= 50 && fitness < 75){
        return 3;
    }
    else if(fitness >= 35 && fitness < 50){
        return 2;
    }
    return 1;
}

const calculateScores = (percentDiff : number) : Score =>{
    // %diff range	max goals for winning side	max goals losing side
    // 0> & <=5	    2	1
    // 5> & <=10	3	2
    // 10> & <=50	4	3
    // 50> & <=100	7	6
    // >100	        10	4
    let score : Score={
        winner : 0,
        loser: 0
    };
    if(percentDiff >= 0 && percentDiff <= 5){
        score.winner = randomIntFromInterval(1,2);
        score.loser = score.winner == 1 ? 0 : randomIntFromInterval(0,score.winner-1);
    }
    else if(percentDiff > 5 && percentDiff <= 10){
        score.winner = randomIntFromInterval(1,3);  
        score.loser = score.winner == 1 ? 0 : randomIntFromInterval(0,score.winner-1);  
    }
    else if(percentDiff > 10 && percentDiff <= 50){
        score.winner = randomIntFromInterval(1,4);  
        score.loser = score.winner == 1 ? 0 : randomIntFromInterval(0,score.winner-1);  
    }
    else if(percentDiff > 50 && percentDiff <= 100){
        score.winner = randomIntFromInterval(1,7);   
        score.loser = score.winner == 1 ? 0 : randomIntFromInterval(0,score.winner-1);  
    }
    else if(percentDiff > 100){
        score.winner = randomIntFromInterval(1,10);   
        score.loser = score.winner == 1 ? 0 : randomIntFromInterval(0,score.winner-1);  
    }
    return score;
}

export const calculateMatchResult = (players: {[id: number] : Player | null }, homeTeamAssets: {[id: number] : IAsset}, awayTeamAssets:{[id: number] : IAsset},  tactics: {[id: number] : Tactic}, homeTeam : Team, awayTeam : Team) : GameResult => {

    // calc TAV per team.
    let homeTeamTAVTotals = calculateTeamAttributeValues(players, homeTeamAssets, tactics[homeTeam.tacticId].desc, homeTeam.lineup);
    let awayTavTAVTotals = calculateTeamAttributeValues(players, awayTeamAssets, tactics[awayTeam.tacticId].desc, awayTeam.lineup);
    let opponentTeamFitness = calculateTeamFitnessPercentage(players, awayTeam.lineup);

    let homeTavScore = homeTeamTAVTotals.totalScore;
    let awayTavScore = awayTavTAVTotals.totalScore;
    let homeScore = 0;
    let awayScore = 0;
    let points = 0;

    // check for outright draw - 1pt per team, score is 0-0.
    if(homeTavScore == awayTavScore){
        points = 1;
    }
    else{
         // calc per diff
        let percentDiff = Math.abs(homeTavScore - awayTavScore)/( (homeTavScore+awayTavScore)/2)*100;
        //console.log("percentDiff: " + percentDiff + ", home: " + homeTavScore + " away: " + awayTavScore);

        // check if we have a draw
        let drawResult = calculateDraw(percentDiff);
        if(drawResult.isDraw){
            homeScore = drawResult.score;
            awayScore = drawResult.score;
            points = 1;
        }
        else{
            points = calculatePoints(opponentTeamFitness);
            let scores = calculateScores(percentDiff);
            // home win
            if(homeTavScore > awayTavScore){
                homeScore = scores.winner;
                awayScore = scores.loser;
            }
            else{
                //away win
                homeScore = scores.loser;
                awayScore = scores.winner;
            }
        }
       
    }

    let gameResult : GameResult={
        homeTAV: homeTeamTAVTotals,
        awayTAV: awayTavTAVTotals,
        homeScore : homeScore,
        awayScore : awayScore,
        points : points
    }
    return gameResult;
}