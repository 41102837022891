import { Button, useMediaQuery, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setChallengeOpponent } from "../../store/actions/challengeOpponent";
import { RootState } from "../../store/reducers";
import { Team } from "../../types/Team";
import { Tournament } from "../../types/Tournament";
import { TournamentPosition } from "../../types/TournamentPosition";
import {
  DASHBOARD_ROUTE,
  LEAGUETABLE_ROUTE,
  PLAY_MATCH,
  TEST_TRANSACTION,
} from "../../utils/constants";
import { TeamFitnessColumn } from "../utils/teamGrid";

import {
  ChallengeTeam,
  Position,
  TeamName,
} from "../utils/tournamentBoardGrid";
import { Avatar } from "./Avatar";

export const LegendsLeagueSummary: FC = () => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: challengeOpponent } = useSelector(
    (state: RootState) => state.challengeOpponent
  );
  const [myPosition, setMyPosition] = useState<TournamentPosition | null>(null);
  const [emptySlots, setEmptySlots] = useState<number[] | null>(null);
  const [activeTournament, setActiveTournament] = useState<Tournament | null>(
    null
  );
  const [myTeam, setMyTeam] = useState<Team | null>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [showMatchDialog, setShowMatchDialog] = useState(false);
  // const [opponentTeamId, setOpponentTeamId] = useState(0);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    //find active tournament - if any
    if (gameData.tournaments != null) {
      let tournament = gameData.tournaments.find((t) => t.active);
      if (tournament != null) {
        setActiveTournament(tournament);
      }
    }

    if (gameData.tournamentPositions != null) {
      // determine position
      let pos = gameData.tournamentPositions.find(
        (t) => t.manager === userData.userName
      );
      if (pos) {
        setMyPosition(pos);
      }
      //determine my team
      if (pos != null && gameData.userTeams != null) {
        let team = gameData.userTeams[pos.teamId];
        setMyTeam(team);
      }

      let numPositions = 0;
      if (gameData.tournamentPositions.length > 1) {
        numPositions = gameData.tournamentPositions.slice(0, 5).length;
      }
      let numRemaining = 5 - numPositions;
      if (numRemaining != 0) {
        let remainingPos: number[] = [];
        for (let i = numPositions + 1; i <= 5; i++) {
          remainingPos.push(i);
        }
        setEmptySlots(remainingPos);
      }
    }
  }, [gameData.tournamentPositions, gameData.tournaments]);

  // Fired when you challenge an opponent
  useEffect(() => {
    if (challengeOpponent.teamId != null) {
      // setOpponentTeamId(challengeOpponent.teamId);
      //setShowMatchDialog(true);

      navigate(PLAY_MATCH, {
        state: {
          opponentTeamId: challengeOpponent.teamId,
          sourcePage: DASHBOARD_ROUTE,
        },
      });
    }
  }, [challengeOpponent]);

  // fired when the user has closed the match window
  const onMatchFinished = () => {
    //setShowMatchDialog(false);
    // clear opponent id
    //dispatch(setChallengeOpponent({ teamId: null }));
    // refetch teams
    //dispatch(fetchTeams(userData, userAssets));
  };

  const getPointsColour = (position: number): string => {
    if (position == 1 || position == 2) {
      return "#2dcd72";
    }
    return position == 3 ? "#dfb145" : "#fff";
  };

  const getWinLoseColour = (winOrLose: string, points: number): string => {
    if (winOrLose == "W") {
      return points > 0 ? "#2dcd72" : "white";
    }
    return points > 0 ? "#e53d49" : "white";
  };

  return (
    <>
      {/* <MatchResult
        opponentTeamId={opponentTeamId}
        showDialog={showMatchDialog}
        onMatchFinished={onMatchFinished}
      /> */}
      <div className="col-md-12 mt-5">
        <div
          className="card col-12"
          style={{
            backgroundColor: "#000b1d",
            color: "#fff",
            border: "1px solid #99ff32",
          }}
        >
          <div className="card-body">
            <h5 className="card-title">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  id="league"
                  style={{ marginRight: "10px" }}
                  src={"/images/Legends-icon.svg"}
                  width="42"
                  height="42"
                  alt="Vintage Niftys Boost Legends League"
                />
                Legends League
              </div>
            </h5>
          </div>
          <div className="list-group list-group-flush" style={{ height: "" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "0px",
                padding: "5px",
                fontSize: "12px",
              }}
            >
              {/* COLUMN HEADER */}
              <div
                style={{
                  display: "flex",
                  color: "white",
                  height: "35px",
                  alignItems: "center",
                  background: "#000B1DA3",
                  borderBottom: "1px solid #99ff32",
                }}
              >
                <div className="db-lt-pos">
                  <span>{mobile ? "POS" : "POS"}</span>
                </div>
                <div style={{ width: "8%" }}></div>
                <div className="db-lt-teamname">
                  <span>TEAM</span>
                </div>
                <div className="db-lt-wld">
                  <span>W</span>
                </div>
                <div className="db-lt-wld">
                  <span>L</span>
                </div>
                <div className="db-lt-wld">
                  <span>D</span>
                </div>
                <div className="db-lt-points">
                  <span>PTS</span>
                </div>
                <div className="db-lt-play">
                  <span>PLAY</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {gameData.tournamentPositions != null &&
                  gameData.tournamentPositions.slice(0, 5).map((item) => (
                    <div className="db-lt-row" style={{ display: "flex" }}>
                      <div
                        className="db-lt-pos"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Position
                          width={30}
                          height={30}
                          posId={item.position}
                        />
                      </div>
                      <div
                        className="db-lt-avatar"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginLeft: "3px",
                          alignItems: "center",
                          borderLeft: " 1px solid #22395a",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        <Avatar userName={item.manager} size={32} />
                      </div>
                      <div
                        className="db-lt-teamname"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: mobile ? "5px" : "0px",
                          }}
                        >
                          <TeamName teamId={item.id} />
                        </div>

                        <TeamFitnessColumn teamId={item.id} width={50} />
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          color: getWinLoseColour("W", item.win),
                        }}
                      >
                        {item.win}
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          color: getWinLoseColour("L", item.lose),
                        }}
                      >
                        {item.lose}
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        {item.draw}
                      </div>
                      <div
                        className="db-lt-points"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          color: getPointsColour(item.position),
                        }}
                      >
                        {item.points}
                      </div>
                      <div
                        className="db-lt-play"
                        style={{
                          display: "flex",

                          alignItems: "center",
                          justifyContent: "center",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          borderRight: "1px solid #22395a",
                        }}
                      >
                        <ChallengeTeam teamId={item.teamId} />
                      </div>
                    </div>
                  ))}
                {emptySlots &&
                  emptySlots.map((i) => (
                    <div className="db-lt-row" style={{ display: "flex" }}>
                      <div
                        className="db-lt-pos"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Position width={30} height={30} posId={i} />
                      </div>
                      <div
                        className="db-lt-avatar"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "3px",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          borderLeft: "1px solid #22395a",
                        }}
                      >
                        &nbsp;
                      </div>
                      <div
                        className="db-lt-teamname"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        {" "}
                        -----
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        0
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        0
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        0
                      </div>
                      <div
                        className="db-lt-points"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        0
                      </div>
                      <div
                        className="db-lt-play"
                        style={{
                          display: "flex",

                          alignItems: "center",
                          justifyContent: "center",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          borderRight: "1px solid #22395a",
                        }}
                      >
                        <ChallengeTeam teamId={-1} />
                      </div>
                    </div>
                  ))}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "65px",
                  border: "1px solid #456797",
                  background: "rgba(69, 103, 151, 0.51)",
                }}
              >
                {myPosition != null && (
                  <>
                    <div style={{ marginLeft: "10px" }}>YOUR RANK</div>
                    <div className="db-lt-row" style={{ display: "flex" }}>
                      <div
                        className="db-lt-pos"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginLeft: "2px",
                          alignItems: "center",
                        }}
                      >
                        <Position
                          width={30}
                          height={30}
                          posId={myPosition.position}
                        />
                      </div>
                      <div
                        className="db-lt-avatar"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderLeft: " 1px solid #22395a",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        <Avatar userName={myPosition.manager} size={32} />
                      </div>
                      <div
                        className="db-lt-teamname"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: mobile ? "5px" : "0px",
                          }}
                        >
                          <TeamName teamId={myPosition.id} />
                        </div>

                        <TeamFitnessColumn teamId={myPosition.id} width={50} />
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          color: getWinLoseColour("W", myPosition.win),
                        }}
                      >
                        {myPosition.win}
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          color: getWinLoseColour("L", myPosition.lose),
                        }}
                      >
                        {myPosition.lose}
                      </div>
                      <div
                        className="db-lt-wld"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                        }}
                      >
                        {myPosition.draw}
                      </div>
                      <div
                        className="db-lt-points"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          color: getPointsColour(myPosition.position),
                        }}
                      >
                        {myPosition.points}
                      </div>
                      <div
                        className="db-lt-play"
                        style={{
                          display: "flex",

                          alignItems: "center",
                          justifyContent: "center",
                          borderTop: "1px solid #22395a",
                          borderBottom: "1px solid #22395a",
                          borderRight: "1px solid #22395a",
                        }}
                      >
                        <ChallengeTeam teamId={myPosition.teamId} />
                      </div>
                    </div>
                  </>
                )}
                {myPosition == null && (
                  <>
                    <div style={{ marginLeft: "10px" }}>YOUR RANK</div>
                    {activeTournament != null &&
                      myTeam == null &&
                      !activeTournament.expired && (
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            gap: "20px",
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", marginLeft: "10px" }}
                          >
                            You need to be in it, to win it!
                          </span>
                        </div>
                      )}
                    {(activeTournament == null || activeTournament.expired) && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "2px",
                          gap: "20px",
                        }}
                      >
                        <span>
                          There is no tournament running at the moment
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="blue-btn"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate(LEAGUETABLE_ROUTE);
                  }}
                >
                  See full league
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
