import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/reducers";
import { Tactic } from "../../../types/Tactic";
import { calculateTeamFitnessPercentage } from "../../../utils/calculateFitness";
import {
  calculateTeamAttributeValues,
  TotalAttributeScoreData,
} from "../../../utils/calculateTeamTotal";
import {
  ATTACK_SCORE,
  DEFENCE_SCORE,
  GOALKEEPER_SCORE,
  MIDFIELD_SCORE,
  TEAMSETUP_ROUTE,
} from "../../../utils/constants";
import { getKeyFrames } from "../../../utils/Theme";
import { MyTeamFormationProps } from "../props";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import { DeleteTeam } from "../../components/DeleteTeam";

export const TeamDetails: FC<MyTeamFormationProps> = ({ team }) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [tactic, setTactic] = useState<Tactic | null>(null);
  const [attributeTotals, setAttributeTotals] =
    useState<TotalAttributeScoreData>({
      chemistryMultipliers: [],
      groupScores: {},
      playerScores: [],
      totalCaps: 0,
      totalScore: 0,
      playerFitness: [],
    });
  const [fitnessPercent, setFitnessPercent] = useState<number>(0);
  const navigate = useNavigate();
  const [openDeleteTeam, setOpenDeleteTeam] = useState(false);
  const [disableDeleteTeam, setDisableDeleteTeam] = useState(false);

  useEffect(() => {
    let tactic = gameData.tactics![team.tacticId];
    setTactic(tactic);
    calculateTeamScores(tactic.desc, team.lineup);
    calcFitnessPercentage(team.lineup);

    if (gameData.tournaments != null) {
      let tournament = gameData.tournaments.find((t) => t.active);
      if (tournament != null) {
        // Get your team in the tourament
        if (gameData.tournamentPositions != null) {
          let pos = gameData.tournamentPositions.find(
            (t) => t.manager === userData.userName
          );
          if (pos != null) {
            if (team.teamId == pos.teamId) {
              // Is it the one you're editing
              setDisableDeleteTeam(true);
            }
          }
        }
      }
    }
  }, [team, gameData]);

  const calculateTeamScores = (formationDesc: string, tempLineup: number[]) => {
    if (assetData.assets != null && gameData.players != null) {
      let scores = calculateTeamAttributeValues(
        gameData.players,
        assetData.assets,
        formationDesc,
        tempLineup
      );
      setAttributeTotals(scores);
    }
  };

  const calcFitnessPercentage = (lineup: number[]) => {
    if (gameData.players != null) {
      let total = calculateTeamFitnessPercentage(gameData.players, lineup);
      setFitnessPercent(Math.round(total));
    }
  };

  const editTeam = () => {
    let teamId = team.id;
    navigate(TEAMSETUP_ROUTE, { state: { teamId } });
  };

  const onDelete = () => {
    setOpenDeleteTeam(false);
  };

  const deleteTeam = () => {
    setOpenDeleteTeam(true);
  };

  return (
    <>
      <DeleteTeam
        showDialog={openDeleteTeam}
        teamId={team.teamId}
        onDelete={onDelete}
      />
      <div
        className="col-xxl-6 col-xl-6 col-md-6 card team-dash"
        style={{
          padding: "0",
          color: "#fff",
        }}
      >
        <ul className="list-group list-group-flush" style={{ padding: "10px" }}>
          <li
            className="list-group-item"
            style={{
              padding: "0px",
              textAlign: "center",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {team.name}
          </li>
          <li
            className="list-group-item"
            style={{
              padding: "0px",
              textAlign: "center",
              fontWeight: "normal",
              background: "#000b1d",
              color: "#fff",
            }}
          >
            {tactic?.desc}
          </li>
          <li
            className="list-group-item"
            style={{
              padding: "0",
              textAlign: "center",
              color: "#fff",
            }}
          >
            <li
              className="list-groups-item"
              style={{
                fontSize: "11px",
                padding: "10px 5px",
                listStyle: "none",
              }}
            >
              TOTAL ATTRIBUTES VALUES
            </li>
            <ul style={{ listStyle: "none", padding: "0" }}>
              <li
                className="list-groups-item"
                style={{
                  padding: "5px",
                  textAlign: "center",
                  background: "#001636",
                  color: "#fff",
                  fontSize: "14px",
                  marginBottom: "2px",
                }}
              >
                Strikers: {attributeTotals.groupScores[ATTACK_SCORE]}
              </li>
              <li
                className="list-groups-item"
                style={{
                  padding: "5px",
                  textAlign: "center",
                  background: "#001636",
                  color: "#fff",
                  fontSize: "14px",
                  marginBottom: "2px",
                }}
              >
                Midfielders: {attributeTotals.groupScores[MIDFIELD_SCORE]}
              </li>
              <li
                className="list-groups-item"
                style={{
                  padding: "5px",
                  textAlign: "center",
                  background: "#001636",
                  color: "#fff",
                  fontSize: "14px",
                  marginBottom: "2px",
                }}
              >
                Defenders: {attributeTotals.groupScores[DEFENCE_SCORE]}
              </li>
              <li
                className="list-groups-item"
                style={{
                  padding: "5px",
                  textAlign: "center",
                  background: "#001636",
                  color: "#fff",
                  fontSize: "14px",
                  marginBottom: "2px",
                }}
              >
                Goalkeeper: {attributeTotals.groupScores[GOALKEEPER_SCORE]}
              </li>
              <li
                className="list-groups-item"
                style={{
                  padding: "0",
                  textAlign: "center",
                  background: "#fff",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                TOTAL: {attributeTotals.totalScore}
              </li>
            </ul>
          </li>
          <li
            className="list-group-item"
            style={{
              padding: "5px",
              textAlign: "center",
              background: "#001636",
              color: "#fff",
              fontSize: "14px",
              marginBottom: "2px",
            }}
          >
            Win-Draw-Lose: {team.win}-{team.draw}-{team.lose}
          </li>
          <li
            className="list-group-item"
            style={{
              padding: "5px",
              textAlign: "center",
              background: "#001636",
              color: "#fff",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                gap: "3px",
              }}
            >
              <div
                style={{
                  width: "75%",
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <Grid container width={"100%"}>
                  <Grid xs item>
                    <LinearProgress
                      variant="determinate"
                      value={fitnessPercent}
                      sx={{
                        height: 5,
                        borderRadius: 60,
                        boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                        "& .MuiLinearProgress-bar1Determinate": {
                          animation:
                            getKeyFrames(fitnessPercent) +
                            " 3s normal forwards",
                          ...(fitnessPercent <= 25 && {
                            background:
                              "linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(177, 0, 0, 1) 100%)",
                          }),
                          ...(fitnessPercent > 25 &&
                            fitnessPercent <= 50 && {
                              background:
                                "linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 183, 26, 1) 50%)",
                            }),
                          ...(fitnessPercent > 50 &&
                            fitnessPercent <= 75 && {
                              background:
                                "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                            }),
                          ...(fitnessPercent > 75 && {
                            background:
                              "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                          }),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <span style={{ width: "25%", fontSize: "10px" }}>
                {fitnessPercent}%
              </span>
            </div>
          </li>
          <li
            className="list-group-item"
            style={{
              padding: "5px",
              gap: "10px",
              display: "flex",
              textAlign: "center",
              background: "#000b1d",
              color: "#fff",
            }}
          >
            <button
              className="btn green-btn"
              type="submit"
              style={{ width: "80%" }}
              onClick={() => editTeam()}
            >
              EDIT TEAM{" "}
            </button>
            <button
              className="btn red-btn"
              type="submit"
              disabled={disableDeleteTeam}
              style={{
                width: "20%",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => deleteTeam()}
            >
              <DeleteForeverOutlined sx={{ color: "white" }} />{" "}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};
