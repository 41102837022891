import Stack from "@mui/material/Stack";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import { TeamFitnessColumn } from "./teamGrid";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import IconButton from "@mui/material/IconButton";
import { Team } from "../../types/Team";
import { setChallengeOpponent } from "../../store/actions/challengeOpponent";
import { calculateTeamFitnessPercentage } from "../../utils/calculateFitness";
import { Tournament } from "../../types/Tournament";
import { Button, Link, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TEAMSETUP_ROUTE } from "../../utils/constants";
import { Match } from "../../types/Match";
import BlockIcon from "@mui/icons-material/Block";

export const LeaderBoardNoRows = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No teams have the joined the tournament{" "}
      <SentimentNeutralIcon color="secondary" fontSize="small" />
    </Stack>
  );
};

export const tournoBoardColumns: GridColDef[] = [
  {
    field: "position",
    headerName: "Pos",
    minWidth: 50,
    width: 30,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<number>) => (
      <Position width={30} height={30} posId={params.value!} />
    ),
  },
  {
    field: "id",
    headerName: "Name",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<number>) => (
      <TeamName teamId={params.value!} />
    ),
  },
  {
    field: "manager",
    headerName: "Manager",
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "win",
    headerName: "W",
    minWidth: 10,
    width: 32,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lose",
    headerName: "L",
    minWidth: 10,
    width: 32,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "draw",
    headerName: "D",
    minWidth: 10,
    width: 32,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "points",
    headerName: "PTS",
    minWidth: 10,
    width: 50,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lineUpTeamId",
    headerName: "Fitness",
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams<number>) => (
      <TeamFitnessColumn width={100} teamId={params.value!} />
    ),
  },
  {
    field: "teamId",
    headerName: "Play!",
    headerAlign: "center",
    align: "center",
    width: 55,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<number>) => (
      <ChallengeTeam teamId={params.value!} />
    ),
  },
];

export const tournoBoardColumnsNew: GridColDef[] = [
  {
    field: "position",
    headerName: "Pos",
    minWidth: 50,
    width: 30,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<number>) => (
      <Position width={30} height={30} posId={params.value!} />
    ),
  },
  {
    field: "id",
    headerName: "Name",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<number>) => (
      <TeamName teamId={params.value!} />
    ),
  },
  {
    field: "points",
    headerName: "PTS",
    minWidth: 10,
    width: 50,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
];

export const Position: FC<{ posId: number; width: number; height: number }> = ({
  posId,
  width,
  height,
}) => {
  const getColour = () => {
    switch (posId) {
      // green
      case 1:
      case 2:
        return "#17674c";
      // yellow
      case 3:
      case 4:
      case 5:
      case 6:
        return "#676433";
      // red
      default:
        return "#652b3f";
    }
  };

  const getBorderColour = () => {
    switch (posId) {
      case 1:
      case 2:
        return "#2dcd72";
      case 3:
      case 4:
      case 5:
      case 6:
        return "#dfb145";
      default:
        return "#e53d49";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        padding: "2px",
        width: width,
        height: height,
        borderRadius: "4px",
        border: "2px solid " + getBorderColour(),
        backgroundColor: getColour(),
      }}
    >
      <span>{posId}</span>
    </div>
  );
};

export const TeamName: FC<{ teamId: number }> = ({ teamId }) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [teamName, setTeamName] = useState("");
  const [isMyTeam, setIsMyTeam] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (gameData.userTeams != null) {
      let team = gameData.userTeams[teamId];
      if (team != null) {
        setIsMyTeam(true);
        setTeamName(team.name);
        return;
      }
    }
    if (gameData.opponentTeams) {
      let team = gameData.opponentTeams[teamId];
      if (team != null) {
        setTeamName(team.name);
      }
    }
  }, [teamId]);

  const editTeam = () => {
    navigate(TEAMSETUP_ROUTE, { state: { teamId } });
  };

  const renderDisplay = () => {
    if (isMyTeam) {
      return (
        <Link onClick={editTeam} color="secondary">
          {teamName}
        </Link>
      );
    }
    return (
      <span
        style={{
          wordBreak: "break-all",
        }}
      >
        {teamName}
      </span>
    );
  };

  return <div>{renderDisplay()}</div>;
};

export const ChallengeTeam: FC<{ teamId: number }> = ({ teamId }) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );

  const [myTeam, setMyTeam] = useState<Team | null>(null);
  const [opponentTeam, setOpponentTeam] = useState<Team | null>(null);
  const [myMatchCount, setMyMatchCount] = useState(0);
  const [opponentMatchCount, setOpponentMatchCount] = useState(0);
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMyTeam, setIsMyTeam] = useState(false);

  const dispatch = useDispatch<any>();

  // You can challenge if you have entered the tourno and you team is not locked
  useEffect(() => {
    let matches: Match[] | null = null;
    //find active tournament - if any
    if (gameData.tournaments != null) {
      let tournament = gameData.tournaments.find((t) => t.active);
      if (tournament != null) {
        if (gameData.matches != null) {
          matches = gameData.matches[tournament.id];
        }
        setTournament(tournament);
      }
    }

    // get your team.
    if (gameData.tournamentPositions != null) {
      let pos = gameData.tournamentPositions.find(
        (t) => t.manager === userData.userName
      );
      if (pos != null && gameData.userTeams != null) {
        let team = gameData.userTeams[pos.teamId];
        setIsMyTeam(team.teamId == teamId);
        setMyTeam(team);

        // now get number of matches played for you
        if (matches != null && team != null) {
          let myMatchCount = matches.filter(
            (m) => m.hometeam_id == team.id || m.awayteam_id == team.id
          ).length;
          setMyMatchCount(myMatchCount);
        }
      }
    }
    if (gameData.opponentTeams != null) {
      let oppTeam = gameData.opponentTeams[teamId];
      setOpponentTeam(oppTeam);
      // now get number of matches played for the opponent.
      if (matches != null && oppTeam != null) {
        let oppMatchCount = matches.filter(
          (m) => m.hometeam_id == oppTeam.id || m.awayteam_id == oppTeam.id
        ).length;
        setOpponentMatchCount(oppMatchCount);
      }
    }
  }, [teamId]);

  const launchMatchDialog = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    dispatch(setChallengeOpponent({ teamId: teamId }));
  };

  const checkPlayability = () => {
    if (tournament == null) {
      return false;
    }
    // has the tournament started? if not, can't play
    if (tournament.startDate > new Date()) {
      //console.log(tournament.startDate);
      return false;
    }

    // TEST - REMOVE THIS
    // if (
    //   userData.account == "bnaf4.wam" ||
    //   userData.account == "53ub2.wam" ||
    //   userData.account == "ihhyw.wam"
    // ) {
    //   return true;
    // }

    // expired
    if (tournament.expired) {
      return false;
    }

    // not in the tourno, can't play.
    if (myTeam == null) {
      return false;
    }

    // can't find opponent???
    if (opponentTeam == null) {
      return false;
    }

    // the team is your team? can't play
    if (myTeam.teamId == teamId) {
      return false;
    }
    // if your team unlocked? can't play
    if (!myTeam.locked) {
      return false;
    }
    // can't play an opponent if they're NOT locked
    if (opponentTeam != null && !opponentTeam.locked) {
      return false;
    }

    // check fitness
    if (gameData.players != null) {
      // Check your team fitness, if < 25% then you can't play.
      let myTeamPercent = calculateTeamFitnessPercentage(
        gameData.players,
        myTeam.lineup
      );
      if (myTeamPercent < 25) {
        return false;
      }
      // check opponent team fitness, if < 25% then you can't play.
      let opponentTeamPercent = calculateTeamFitnessPercentage(
        gameData.players,
        opponentTeam.lineup
      );
      if (opponentTeamPercent < 25) {
        return false;
      }
    }

    // now check no of your matches, if > than
    if (myMatchCount >= tournament.gamesCount) {
      return false;
    }
    if (opponentMatchCount >= tournament.gamesCount) {
      return false;
    }

    // fair game now, go for it
    return true;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {checkPlayability() ? (
        mobile ? (
          <IconButton
            onClick={launchMatchDialog}
            color="primary"
            aria-label="edit"
            component="label"
          >
            <PlayCircleIcon
              color="secondary"
              sx={{ fontSize: "24px", color: mobile ? "orange" : "#99ff32" }}
            />
          </IconButton>
        ) : (
          <Button
            onClick={launchMatchDialog}
            className="orange-btn"
            size="small"
          >
            Play
          </Button>
        )
      ) : isMyTeam ? (
        ""
      ) : (
        <BlockIcon color="error" />
      )}
    </div>
  );
};

/*
export interface TournamentPosition{
    teamId : number;
    manager : string;
    win : number;
    lose : number;
    draw : number;
    points : number;
};*/
