import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAvatar } from "../store/actions/userSession";
import { RootState } from "../store/reducers";



export const useUpdateAvatar = (checkAvatar : boolean) => {
    const {
        data: user,
        error,
        isFetching,
      } = useSelector((state: RootState) => state.userSession);
    const[avatarExists, setAvatarExists] = useState(false);
    const[url, setUrl] = useState('');
    const dispatch = useDispatch<any>();

    useEffect(()=>{
        if(checkAvatar){
            console.log("Checking for user avatar");
            checkUserAvatar();
        }
    },[checkAvatar]);

    useEffect(()=>{
        if(avatarExists){
            console.log("User avatar exists, updating user state");
            dispatch(updateAvatar(user.account, url));
        }
    },[avatarExists]);

    const checkUserAvatar = () => {
        const imageUrl =
        "https://service.vintageniftys.io/avatars/" +
        user.userName +
        "-avatar.png?random=" +
        moment().unix();
        var img = new Image();
        img.onload = function () {
            setAvatarExists(true);
            setUrl(imageUrl);
        };
        // img.onerror = function () {
        //   /setUserAvatarExists(false);
        // };
        img.src = imageUrl;
      }

}