import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setChallengeOpponent } from "../store/actions/challengeOpponent";
import { RootState } from "../store/reducers";
import {
  LEAGUETABLE_ROUTE,
  PLAY_MATCH,
  TEST_TRANSACTION,
} from "../utils/constants";
import { Avatar } from "./components/Avatar";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";
import { TeamFitnessColumn } from "./utils/teamGrid";
import { ChallengeTeam, Position, TeamName } from "./utils/tournamentBoardGrid";

const LeagueTable = () => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  //const [opponentTeamId, setOpponentTeamId] = useState(0);
  //const [showMatchDialog, setShowMatchDialog] = useState(false);
  const { data: challengeOpponent } = useSelector(
    (state: RootState) => state.challengeOpponent
  );
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  // Fired when you challenge an opponent
  useEffect(() => {
    if (challengeOpponent.teamId != null) {
      //setOpponentTeamId(challengeOpponent.teamId);
      //setShowMatchDialog(true);
      navigate(PLAY_MATCH, {
        state: {
          opponentTeamId: challengeOpponent.teamId,
          sourcePage: LEAGUETABLE_ROUTE,
        },
      });
    }
  }, [challengeOpponent]);

  // fired when the user has closed the match window
  const onMatchFinished = () => {
    //setShowMatchDialog(false);
    // clear opponent id
    //dispatch(setChallengeOpponent({ teamId: null }));
  };

  const getWinLoseColour = (winOrLose: string, points: number): string => {
    if (winOrLose == "W") {
      return points > 0 ? "#2dcd72" : "white";
    }
    return points > 0 ? "#e53d49" : "white";
  };

  return (
    <>
      {/* <MatchResult
        opponentTeamId={opponentTeamId}
        showDialog={showMatchDialog}
        onMatchFinished={onMatchFinished}
      /> */}
      <Header />
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={LEAGUETABLE_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  margin: "10px 20px",
                }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  src={"/images/Legends-icon.svg"}
                  width="42"
                  height="42"
                />
                Legends League &nbsp;
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    backgroundColor: "#001636",
                  }}
                >
                  {/* COLUMN HEADER */}
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      height: "35px",
                      borderBottom: "1px solid #99ff32",
                    }}
                  >
                    <div className="leagueTablePositionCol">
                      <span>{mobile ? "POS" : "POSITION"}</span>
                    </div>
                    <div style={{ width: "40px" }}></div>
                    <div className="leagueTableTeamNameCol">
                      <span>TEAM</span>
                    </div>
                    {!mobile && (
                      <div className="leagueTableManagerCol">
                        <span>MANAGER</span>
                      </div>
                    )}
                    <div className="leagueTableWLD">
                      <span>W</span>
                    </div>
                    <div className="leagueTableWLD">
                      <span>L</span>
                    </div>
                    <div className="leagueTableWLD">
                      <span>D</span>
                    </div>
                    <div className="leagueTablePTS">
                      <span>PTS</span>
                    </div>
                    {!mobile && (
                      <div className="leagueTableFitness">
                        <span>FITNESS</span>
                      </div>
                    )}
                    <div className="leagueTablePlay">
                      <span>PLAY</span>
                    </div>
                  </div>
                  {gameData.tournamentPositions != null &&
                    gameData.tournamentPositions.map((item) => (
                      <div
                        style={{
                          display: "flex",
                          color: "white",
                          height: "40px",
                        }}
                      >
                        <div className="leagueTablePositionCol">
                          <div
                            style={{
                              width: "30px",
                            }}
                          >
                            <Position
                              width={30}
                              height={30}
                              posId={item.position}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderLeft: " 1px solid #22395a",
                            borderTop: "1px solid #22395a",
                            borderBottom: "1px solid #22395a",
                          }}
                        >
                          <Avatar userName={item.manager} size={32} />
                        </div>
                        <div className="leagueTableTeamNameColCell">
                          <div style={{ marginLeft: mobile ? "5px" : "0px" }}>
                            <TeamName teamId={item.id} />
                          </div>
                          {mobile && (
                            <TeamFitnessColumn
                              width={70}
                              teamId={item.lineUpTeamId}
                            />
                          )}
                        </div>
                        {!mobile && (
                          <div className="leagueTableManagerColCell">
                            {item.manager}
                          </div>
                        )}
                        <div
                          className="leagueTableWLDCell"
                          style={{
                            color: getWinLoseColour("W", item.win),
                          }}
                        >
                          {item.win}
                        </div>
                        <div
                          className="leagueTableWLDCell"
                          style={{
                            color: getWinLoseColour("L", item.win),
                          }}
                        >
                          {item.lose}
                        </div>
                        <div className="leagueTableWLDCell">{item.draw}</div>
                        <div className="leagueTablePTSCell">{item.points}</div>
                        {!mobile && (
                          <div className="leagueTableFitnessCell">
                            <TeamFitnessColumn
                              width={100}
                              teamId={item.lineUpTeamId}
                            />
                          </div>
                        )}
                        <div className="leagueTablePlayCell">
                          <ChallengeTeam teamId={item.teamId} />
                        </div>
                      </div>
                    ))}
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      gap: "5px",
                      height: "1px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default LeagueTable;
