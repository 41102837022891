import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { UpgradePlayerProps } from "./UpgradePlayer";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Templates } from "../../inventory";
import {
  getUpgradedTav,
  upgradeMutableAttributes,
} from "../../utils/upgradeMutableAttributes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { getUpgradeCost } from "../../utils/upgradePlayerCost";
import { fetchVintyBalance, sendTokens } from "../../store/actions/userSession";
import { useUAL } from "../../utils/useUAL";
import { VINTY, VINTYTOKENS_ACCOUNT } from "../../utils";
import { webServiceInstance } from "../../utils/webServiceInstance";
import { fetchSingleAsset2 } from "../../store/actions/userAssets";
import { fetchPlayers } from "../../store/actions/gameData";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";

const START = "START";
const INITIALISE_ERROR = "INITIALISE_ERROR";
const COST_ERROR = "COST_ERROR";
const CANT_AFFORD = "CANT_AFFORD";
const TRANSFER_FUNDS = "TRANSFER_FUNDS";
const TRANSFER_ERROR = "TRANSFER_ERROR";
const UPGRADE_ASSET = "UPGRADE_ASSET";
const UPGRADE_ERROR = "UPGRADE_ERROR";
const SUCCESS = "SUCCESS";
const LOADINGPLAYERS: string = "LOADINGPLAYERS";

export const UpgradePlayer2: FC<UpgradePlayerProps> = ({
  showDialog,
  asset,
  onPlayerUpgraded,
}) => {
  const { data: gameData, isFetching: isFetchingPlayers } = useSelector(
    (state: RootState) => state.gameData
  );
  const { data: userData, isFetching: isFetchingAssets } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [status, setStatus] = useState(START);
  const [upgradeCost, setUpgradeCost] = useState("");
  const dispatch = useDispatch<any>();
  const theme = useTheme();
  const ual = useUAL() as any;
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [newAttributes, setNewAttributes] = useState<
    { key: string; value: (string | number)[] }[] | null
  >(null);
  const { data: tranResult } = useSelector(
    (state: RootState) => state.sendTokensResult
  );
  const [pollRefresh, setPollRefresh] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [updatedAsset, setUpdatedAsset] = useState<IAsset | null>(null);

  useEffect(() => {
    if (showDialog) {
      if (asset) {
        setStatus(START);

        let level = parseInt(asset.mutable_data.Level);
        if (!Number.isNaN(level)) {
          console.log("Current player level: " + level);
          setCurrentLevel(level);
        } else {
          setStatus(INITIALISE_ERROR);
          return;
        }

        const attributes = upgradeMutableAttributes(asset, 100);
        setNewAttributes(attributes);

        try {
          if (asset) {
            const vintyBalance = userData.vintyBalance
              ? userData.vintyBalance
              : 0;
            const value = getUpgradeCost(asset, gameData);
            if (value > vintyBalance) {
              setStatus(CANT_AFFORD);
            }
            setUpgradeCost(value.toFixed(2));
          }
        } catch (e: any) {
          setStatus(COST_ERROR);
        }
      }
    }
  }, [showDialog]);

  useEffect(() => {
    if (!pollRefresh) {
      return;
    }
    if (userAssets.assets != null && asset != null) {
      let tmpAsset = userAssets.assets[parseInt(asset.asset_id)];
      // check staked assets
      if (tmpAsset == null && userAssets.stakedAssets != null) {
        tmpAsset = userAssets.stakedAssets[parseInt(asset.asset_id)];
      }
      if (tmpAsset) {
        console.log("Checking if the player has been levelled up");
        let level = parseInt(tmpAsset.mutable_data.Level);
        if (Number.isNaN(level)) {
          setStatus(UPGRADE_ERROR);
          setPollRefresh(false);
          return;
        }

        if (level == currentLevel) {
          setTimeout(() => {
            console.log("Player is still not ready, refresh player");
            dispatch(fetchSingleAsset2(tmpAsset.asset_id));
          }, 3000);
        } else {
          console.log("Player is ready. Level: " + level);
          setPollRefresh(false);
          setUpdatedAsset(tmpAsset);
          setStatus(LOADINGPLAYERS);
          dispatch(fetchPlayers(userAssets));
        }
      }
    }
  }, [userAssets.assets, pollRefresh]);

  useEffect(() => {
    if (status != LOADINGPLAYERS) {
      return;
    }
    if (!isFetchingPlayers) {
      console.log("we're done");
      setStatus(SUCCESS);
    }
  }, [isFetchingPlayers]);

  useEffect(() => {
    const updateAsset = async (
      attributes: { key: string; value: (string | number)[] }[] | null
    ) => {
      try {
        const result = await webServiceInstance.post("/updateassetdata", {
          assetId: asset.asset_id,
          owner: asset.owner,
          attributes: attributes,
        });
        console.log(result);
        if (result.toString() == "ERROR") {
          setStatus(UPGRADE_ERROR);
        } else {
          setTimeout(() => {
            dispatch(fetchVintyBalance(userData.account));
            setPollRefresh(true);
          }, 1500);
        }
      } catch (e: any) {
        setStatus(UPGRADE_ERROR);
      }
    };

    if (status == TRANSFER_FUNDS) {
      dispatch(
        sendTokens(
          ual["activeUser"],
          VINTYTOKENS_ACCOUNT,
          upgradeCost,
          VINTY,
          `Upgrade ${asset?.name} to level ${getNextLevel()}`
        )
      );
    }
    if (status == UPGRADE_ASSET) {
      console.log("upgrade asset");
      updateAsset(newAttributes);
    }
  }, [status]);

  // Once transfer of token is succesful then we can upgrade asset
  useEffect(() => {
    if (tranResult.transaction_id != null) {
      if (tranResult.transaction_id == "ERROR") {
        setStatus(TRANSFER_ERROR);
      } else {
        setStatus(UPGRADE_ASSET);
      }
    }
  }, [tranResult.transaction_id]);

  const getPlayersTAV = (): number => {
    return asset ? getUpgradedTav(asset, 100) : 0;
  };

  const getNextLevel = () => {
    if (newAttributes) {
      const levelData = newAttributes.find((item) => item.key == "Level");
      if (levelData) {
        return levelData.value[1];
      }
    }
    return "";
  };

  const startUpgrade = () => {
    // send funds then upgrade card
    setStatus(TRANSFER_FUNDS);
  };

  const onClose = () => {
    onPlayerUpgraded(updatedAsset);
  };

  const renderDisplay = () => {
    if (status == START || status == CANT_AFFORD) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <div>
            <span>Upgrade '{asset?.name}' to level </span>
            <span style={{ fontWeight: "bold", color: "#99ff32" }}>
              {getNextLevel()}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "start",
            }}
          >
            {asset != null && (
              <img
                style={{ width: "50% !important" }}
                src={
                  "images/" + Templates[asset?.template!.template_id!].imgPath
                }
              />
            )}
            <div className="playerSearchAttributes card">
              <div
                className="card-body2"
                style={{
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2px",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{asset?.name}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  padding: "1px",
                }}
              >
                <span className="playerSearchAttributeLabel">Rarity</span>
                <span>{asset?.data.Rarity}</span>
              </div>
              {newAttributes &&
                Object.entries(newAttributes).map(([index, value]) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      padding: "1px",
                    }}
                  >
                    <span className="playerSearchAttributeLabel">
                      {Object.values(value)[0]}
                    </span>
                    <span
                      style={{
                        fontWeight:
                          Object.values(value)[0] == "Level"
                            ? "bold"
                            : "default",
                        color:
                          Object.values(value)[0] == "Level"
                            ? "#99ff32"
                            : "#fff",
                      }}
                    >
                      {Object.values(value)[1][1]}
                    </span>
                  </div>
                ))}
              <div className="playerSearchTotalBox">
                <span className="playerSearchTotalLabel">TOTAL TAV</span>
                <span style={{ fontWeight: "bold", color: "#99ff32" }}>
                  {getPlayersTAV()}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Gonna cost you&nbsp;</span>
              <span style={{ fontWeight: "bold", color: "#99ff32" }}>
                {upgradeCost}
              </span>
              <img
                src="/images/VNTY-icon-red.png"
                alt="VNTY Token"
                style={{ marginLeft: "5px", height: "24px", width: "24px" }}
              />
            </div>
            {status == START && (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button className="blue-btn" onClick={() => onClose()}>
                  NOPE
                </Button>
                <Button className="green-btn" onClick={() => startUpgrade()}>
                  DO IT!
                </Button>
              </div>
            )}
            {status == CANT_AFFORD && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <span>Ah mate, sorry you can't afford it :(</span>
                <Button className="green-btn" onClick={() => onClose()}>
                  OK
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }
    if (
      status == TRANSFER_FUNDS ||
      status == LOADINGPLAYERS ||
      status == UPGRADE_ASSET
    ) {
      return (
        <div
          style={{
            display: "flex",
            height: "200px",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <CircularProgress color="secondary" />
          <span>Upgrading player...</span>
        </div>
      );
    }
    if (status == TRANSFER_ERROR) {
      return (
        <div
          style={{
            display: "flex",
            height: "200px",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center" }}>
            Sigh...the player went on awol! Get onto VN asap!
          </span>
          <Button className="green-btn" onClick={() => onClose()}>
            OK
          </Button>
        </div>
      );
    }
    if (status == UPGRADE_ERROR) {
      return (
        <div
          style={{
            display: "flex",
            height: "200px",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center", fontSize: "14px" }}>
            Ok it's serious! Looks like the agent has pulled a fast one. Call VN
            asap!
          </span>
          <Button className="green-btn" onClick={() => onClose()}>
            OK
          </Button>
        </div>
      );
    }
    if (status == SUCCESS) {
      return (
        <div
          style={{
            display: "flex",
            height: "200px",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center", fontSize: "14px" }}>
            Upgrade complete! He's like a new man :)
          </span>
          <Button className="green-btn" onClick={() => onClose()}>
            OK
          </Button>
        </div>
      );
    }
  };

  return (
    <Dialog open={showDialog} maxWidth="sm" onClose={onClose}>
      <DialogContent>{renderDisplay()}</DialogContent>
    </Dialog>
  );
};
