import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchConfig,
  toggleAppLocked,
  toggleMatchesLocked,
  updateMaxTeamsConfig,
} from "../store/actions/config";
import { fetchAllUsers, fetchTournaments } from "../store/actions/gameData";
import { RootState } from "../store/reducers";

import { useUAL } from "../utils/useUAL";

import { atomicAssetsInstance } from "../utils/atomicAssetsInstance";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { sortAttributes } from "../utils/sortAttributes";
import {
  resetMutableAttributes,
  resetPlayerPosition,
} from "../utils/upgradeMutableAttributes";
import { webServiceInstance } from "../utils/webServiceInstance";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";
import { ADMIN_ROUTE } from "../utils/constants";
import { EditTournament } from "./components/EditTournament";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Fade,
  Switch,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { tournamentColumns } from "./utils/adminTournoGrid";
import { CustomPagination } from "../types/Grid";
import { Templates } from "../inventory";
import { userColumns } from "./utils/adminUserGrid";

const Admin = () => {
  const { data: appConfig } = useSelector(
    (state: RootState) => state.appConfig
  );
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const dispatch = useDispatch<any>();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [maxTeams, setMaxTeams] = useState("0");
  const [resetAsset, setResetAsset] = useState<IAsset | null>(null);
  const [resetAssetId, setResetAssetId] = useState("0");
  const [attributeResetValue, setAttributeResetValue] = useState("0");
  const ual = useUAL() as any;
  const maxTeamsField = useRef<HTMLInputElement>();
  const resetAssetIdField = useRef<HTMLInputElement>();
  const attributeValueField = useRef<HTMLInputElement>();
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const [tabPage, setTabPage] = useState("tournaments");
  const [sortedAttributes, setSortedAttributes] = useState([] as string[]);

  const tournamentRows: GridRowsProp = [
    ...(gameData.tournaments != undefined ? gameData.tournaments : []),
  ];

  const userRows: GridRowsProp = [
    ...(gameData.users != undefined ? gameData.users : []),
  ];

  useEffect(() => {
    if (appConfig != null && appConfig.maxTeams != undefined) {
      setMaxTeams(appConfig.maxTeams.toString());
    }
    dispatch(fetchAllUsers());
    dispatch(fetchTournaments());
    dispatch(fetchConfig());

    // every 30s
    let handle = setInterval(() => {
      dispatch(fetchConfig());
      dispatch(fetchTournaments());
    }, 30000);

    return () => {
      if (handle != null) {
        clearInterval(handle);
      }
    };
  }, []);

  const addTournament = () => {
    setShowDialog(true);
  };

  const onLockAppChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const activeUser = ual["activeUser"];
    dispatch(toggleAppLocked(activeUser, checked));
  };

  const onLockMatchesChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const activeUser = ual["activeUser"];
    dispatch(toggleMatchesLocked(activeUser, checked));
  };

  const changeMaxTeams = (value: string) => {
    setMaxTeams(value);
  };

  const updateMaxTeams = () => {
    const activeUser = ual["activeUser"];
    dispatch(updateMaxTeamsConfig(activeUser, parseInt(maxTeams)));
  };

  const onTournoSave = () => {
    setShowDialog(false);
    dispatch(fetchTournaments());
  };

  const changeAssetId = (value: string) => {
    setResetAssetId(value);
  };

  const changeAttributeValue = (value: string) => {
    setAttributeResetValue(value);
  };

  const loadAsset = async () => {
    let asset = await atomicAssetsInstance.getAsset(resetAssetId);
    if (asset != null) {
      setResetAsset(asset);
      let keys = sortAttributes(
        Object.keys(asset.mutable_data),
        asset.mutable_data.Position,
        false
      );
      setSortedAttributes(keys);
    }
  };

  const onResetAsset = async () => {
    if (resetAsset != null) {
      let values = new Array<number>(6);
      values.fill(parseInt(attributeResetValue));
      let attributes = resetMutableAttributes(
        resetAsset.mutable_data.Position,
        parseInt(resetAsset.mutable_data.Level),
        values
      );
      const result = await webServiceInstance.post("/updateassetdata", {
        assetId: resetAsset?.asset_id,
        owner: resetAsset.owner,
        attributes: attributes,
      });
      console.log(result);
    }
  };

  const resetPosition = async () => {
    if (resetAsset != null) {
      let attributes = resetPlayerPosition(resetAsset);
      console.log(attributes);
      const result = await webServiceInstance.post("/updateassetdata", {
        assetId: resetAsset?.asset_id,
        owner: resetAsset.owner,
        attributes: attributes,
      });
      console.log(result);
    }
  };

  const viewAsset = () => {
    window.open(
      "https://wax.atomichub.io/explorer/asset/" + resetAsset?.asset_id,
      "_blank"
    );
  };

  const navToDashboard = () => {
    navigate("/dashboard");
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabPage(newValue);
  };

  const isSelected = (tabName: string): boolean => {
    return tabName == tabPage;
  };

  return (
    <>
      <EditTournament showDialog={showDialog} onTournoUpdated={onTournoSave} />
      <Header />
      <Box>
        <Collapse in={showError}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ m: 2 }}
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
          >
            {errorMessage}
          </Alert>
        </Collapse>
      </Box>
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={ADMIN_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div>
                <Box sx={{ width: "100%", marginBottom: "30px" }}>
                  <Tabs
                    value={tabPage}
                    onChange={handleChange}
                    sx={{
                      "& .MuiTabs-indicator": { backgroundColor: "#99ff32" },
                    }}
                  >
                    <Tab value="tournaments" label="Tournaments" />
                    <Tab value="assetreset" label="Asset Reset" />
                    <Tab value="users" label="Users" />
                    <Tab value="config" label="Config" />
                  </Tabs>
                </Box>
                <Fade in={isSelected("tournaments")}>
                  <Collapse in={isSelected("tournaments")}>
                    <div className="flex-grid">
                      <div>
                        <h2
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <div>Tournaments</div>
                          </div>{" "}
                          <Button variant="outlined" onClick={addTournament}>
                            Add
                          </Button>
                        </h2>
                        <div style={{ height: 250, width: "100%" }}>
                          <DataGrid
                            headerHeight={25}
                            rows={tournamentRows}
                            columns={tournamentColumns}
                            rowHeight={30}
                            hideFooterSelectedRowCount={true}
                            pageSize={20}
                            pagination
                            components={{ Pagination: CustomPagination }}
                            rowsPerPageOptions={[5]}
                          />
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Fade>
                <Fade in={isSelected("assetreset")}>
                  <Collapse in={isSelected("assetreset")}>
                    <div
                      className="flex-grid"
                      style={{ flexDirection: "column", gap: "20px" }}
                    >
                      <div>
                        <h2
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <div>Reset Asset</div>
                          </div>{" "}
                        </h2>
                        <div style={{ width: "100%" }}>
                          <div style={{ display: "flex" }}>
                            <TextField
                              inputRef={resetAssetIdField}
                              value={resetAssetId}
                              onChange={(e) => changeAssetId(e.target.value)}
                              name="assetIdInput"
                              size="small"
                              label="Asset ID"
                              variant="outlined"
                              sx={{
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "1px solid #99ff32",
                                  },
                                "&:hover": {
                                  "&& fieldset": {
                                    border: "1px solid #99ff32",
                                  },
                                },
                              }}
                            />{" "}
                            <TextField
                              inputRef={attributeValueField}
                              value={attributeResetValue}
                              onChange={(e) =>
                                changeAttributeValue(e.target.value)
                              }
                              name="Value"
                              size="small"
                              label="Value"
                              variant="outlined"
                              sx={{
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "1px solid #99ff32",
                                  },
                                "&:hover": {
                                  "&& fieldset": {
                                    border: "1px solid #99ff32",
                                  },
                                },
                              }}
                            />{" "}
                            <Button
                              variant="outlined"
                              onClick={loadAsset}
                              style={{ marginLeft: "10px" }}
                            >
                              Load
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={onResetAsset}
                              style={{ marginLeft: "10px" }}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={resetPosition}
                              style={{ marginLeft: "10px" }}
                            >
                              Reset Position Only
                            </Button>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            gap: "20px",
                            color: "#fff",
                          }}
                        >
                          Owner: <b>{resetAsset?.owner}</b>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            gap: "20px",
                          }}
                        >
                          <div style={{}}>
                            {resetAsset && (
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={viewAsset}
                                src={
                                  "images/" +
                                  Templates[resetAsset.template!.template_id!]
                                    .imgPath
                                }
                              />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "10px",
                              flexDirection: "column",
                              marginTop: "3px",
                              marginLeft: "3px",
                              marginRight: "3px",
                              color: "#fff",
                            }}
                          >
                            {resetAsset &&
                              sortedAttributes.map((item) => (
                                <div
                                  style={{
                                    display: "flex",
                                    lineHeight: "30px",
                                  }}
                                >
                                  <div style={{ flex: "1" }}>
                                    <span style={{ marginLeft: "10px" }}>
                                      {item}
                                    </span>
                                  </div>
                                  <div style={{ flex: "1" }}>
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {resetAsset.mutable_data[item]}
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Fade>
                <Fade in={isSelected("users")}>
                  <Collapse in={isSelected("users")}>
                    <div className="flex-grid">
                      <div>
                        <h2
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <div>Users</div>
                          </div>{" "}
                        </h2>
                        <div style={{ height: 500, width: "100%" }}>
                          <DataGrid
                            headerHeight={25}
                            rows={userRows}
                            columns={userColumns}
                            rowHeight={50}
                            hideFooterSelectedRowCount={true}
                            pageSize={20}
                            pagination
                            components={{ Pagination: CustomPagination }}
                            rowsPerPageOptions={[50]}
                          />
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Fade>
                <Fade in={isSelected("config")}>
                  <Collapse in={isSelected("config")}>
                    <div className="flex-grid">
                      <div>
                        <h2 style={{ display: "flex", gap: "5px" }}>
                          <div>Config settings</div>
                        </h2>
                        <div
                          style={{ display: "flex", height: 50, width: "100%" }}
                        >
                          <div>
                            <span style={{ color: "#fff" }}>Lock App</span>{" "}
                            <Switch
                              checked={appConfig.appLocked}
                              color="secondary"
                              onChange={onLockAppChanged}
                            />
                          </div>
                          <div>
                            <span style={{ color: "#fff" }}>Lock Matches</span>{" "}
                            <Switch
                              checked={appConfig.matchesLocked}
                              color="secondary"
                              onChange={onLockMatchesChanged}
                            />
                          </div>
                          <div>
                            <TextField
                              inputRef={maxTeamsField}
                              value={maxTeams}
                              onChange={(e) => changeMaxTeams(e.target.value)}
                              name="maxTeamsInput"
                              size="small"
                              label="Max Teams"
                              variant="outlined"
                              sx={{
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "1px solid #99ff32",
                                  },
                                "&:hover": {
                                  "&& fieldset": {
                                    border: "1px solid #99ff32",
                                  },
                                },
                              }}
                            />{" "}
                            <Button variant="outlined" onClick={updateMaxTeams}>
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Fade>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Admin;
