import { Button } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FC, useState } from "react";
import { SendTokens } from "../components/SendTokens";

export const userColumns: GridColDef[] = [
  { field: "account", headerName: "User" },
  { field: "userName", headerName: "User Name" },
  { field: "club", headerName: "Club" },
  { field: "ground", headerName: "Ground" },
  {
    field: "id",
    headerName: "actions",
    width: 150,
    renderCell: (params: GridRenderCellParams<string>) => (
      <SendTokensButton account={params.value!} />
    ),
  },
];

export const SendTokensButton: FC<{ account: string }> = ({ account }) => {
  const [showDialog, setShowDialog] = useState(false);
  const onSend = () => {
    setShowDialog(false);
  };
  return (
    <>
      <SendTokens account={account} showDialog={showDialog} onSend={onSend} />
      <div style={{ padding: "2px" }}>
        <Button
          className="green-btn"
          size="small"
          onClick={() => setShowDialog(true)}
        >
          Send tokens
        </Button>
      </div>
    </>
  );
};
