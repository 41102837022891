import { createTheme } from "@mui/material/styles";

export const getKeyFrames = (value : number) =>{
  if(value <= 25){
    return "load25";
  }
  else if(value > 25 && value <= 50){
    return "load50";
  }
  else if(value > 50 && value <= 75){
    return "load75";
  }
  else if(value > 75){
    return "load100";
  }
  return "load0";
}

export const siteTheme = createTheme({
    palette:{
      primary:{
        main:'#22395a',
        dark:'#000b1d',
        light:'#fff'
      },  
      secondary:{
        main:'#99ff32'
      },
      background:{
        default:'#001636',
        paper:'#001636'
      },
      text:{
        primary:'#fff'
      },
      
    },
    typography:{
      fontFamily:"'Montserrat', sans-serif"
    },
    components: {
      // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
      MuiDataGrid: {
        styleOverrides: {
          root: {
              fontSize: '10px',
              fontFamily: 'Montserrat',
              color: '#fff',
              border: '1px solid #22395a',
             
          },
          footerContainer:{
            borderTop:'0px solid #22395a',
            backgroundColor:'#000b1d',
            padding:'5px',
            borderRadius:'0px 0px 5px 5px',
          },
          sortIcon:{
            
            borderRadius:10,
            color:'#99ff32',
            '&:hover':{
              backgroundColor:'#22395a',
            }
          },
          menu:{
            border:'1px solid #22395a', 
          },
          // enuIcon:{
          //   borderRadius:100,
      
          //   '&:hover':{
          //     backgroundColor:'#22395a',
          //   }
          // },
          menuIconButton:{
            color:'#99ff32',
          },
          iconSeparator:{
              display:'none',
          },
          columnHeaderTitle:{
              fontWeight:'600',
              textTransform:'uppercase',
          },
          columnHeader:{
              borderRight:'1px solid #22395a',
              backgroundColor:'#000b1d',
          },
          cell:{
              borderTop:'2px solid #001636',
              borderBottom:'2px solid #001636',
              borderRight:'1px solid #001636',
              backgroundColor:'#22395a',
          },
          panel:{
            border:'1px solid #22395a',
            
          },
        },
      },
      MuiPagination:{
        styleOverrides:{
          root:{
            //color: '#fff',
            //backgroundColor:'red'
          },
          outlined:{
            color:'#fff',
            backgroundColor:'transparent',
          }
        }
      },
      MuiPaginationItem: {
        styleOverrides:{
          root:{
            color: '#fff',
            border:'1px solid #fff'
          }
        }
      },
      MuiMenuItem:{
        styleOverrides:{
          root:{
            '&:hover':{
              color:'#99ff32',
              backgroundColor:'#22395a',
            }
          }
        }
      },
      MuiIconButton:{
        styleOverrides:{
          root:{
            color:'#fff',
            '&:hover':{
              backgroundColor:'#22395a',
            }
          }
        }
      },
      // MuiAlert:{
      //   styleOverrides:{
      //     root:{
      //       color:'#fff'
      //     }
      //   }
      // },
      // MuiDialog:{
       // styleOverrides:{
         // root:{
         // backgroundColor: '#000b1d',
         // border:'solid 1px #99ff32',
         // borderRadius:'5px'
        //  }
        // }
      // },
      // MuiDialogContent:{
      //   styleOverrides:{
      //     root:{
      //       color:'#fff'
      //     }
      //   }
      // },
      MuiDialogContentText:{
        styleOverrides:{
          root:{
            color: '#bdbdbd'
          },
        }
      },
      MuiInputLabel:{
        styleOverrides:{
         root:{
          color:'#fff',
          "&.Mui-focused": {
            "color": "#99ff32"
          }
         },
        }
      },
      MuiInput:{
        styleOverrides:{
          root:{
            color:'#99ff32',
            '&::before':{
              borderBottom:'1px solid #22395a'
            }
          },
          underline:{
            '&::after': {
              border: '1px solid #99ff32'
            },
          }
        }
      },  
      MuiButton:{
        styleOverrides:{
          root:{
            borderColor:'#72dd07',
            borderWidth:'1px',
            '&:hover':{
              backgroundColor: '#99ff32',
              color:'#000b1d',
              borderColor:'#99ff32',
              borderWidth:'1px',
            }
          },
          outlined:{
            color:'#99ff32'
          },
          text:{
            color:'#99ff32'
          },
          contained:{
            color:'#000b1d',
            backgroundColor:'#99ff32',
            '&:hover':{
              color:'#000b1d',
              backgroundColor:'#72dd07'
            }
          }
        },
       
      },
      MuiOutlinedInput:{
        styleOverrides:{
          notchedOutline:{
             borderColor: '#72dd07',
          }
        }
      },
      MuiSelect:{
        styleOverrides:{
          icon:{
            fill:'#fff'
          },
          
        },
      },
      MuiMenu: {
       styleOverrides:{
        list:{
          backgroundColor: "#000b1d",
         
        }
       }
      },
      MuiLinearProgress:{
        styleOverrides:{
          colorPrimary:{
            backgroundColor:'#337f83'
          },
         
          bar:{
            backgroundColor:'#72dd07',
            height:'5px',
          }
        }
      },
      MuiImageListItemBar:{
        styleOverrides:{
          title:{
            fontSize:'10px',
            fontWeight:'bold',
            textAlign:'center'
           
          },
          subtitle:{
            fontSize:'8px',
            textAlign:'center',
            marginBottom:'5px'
          }
        }
      },
      MuiLink:{
        styleOverrides:{
          root:{
            '&:hover':{
              cursor: 'pointer'
            }
          }
        }
      },
      MuiTab:{
        styleOverrides:{
          root:{
            color:'#fff',
            '&:hover': {
              color: '#99ff32',
              opacity: 1,
            },
            '&.Mui-selected': {
              color: '#99ff32',
            },
          },
        },
      },
      MuiCssBaseline:{
        styleOverrides:{
          body: {
            scrollbarColor: "#99ff32 #000b1d",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#000b1d",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 5,
              backgroundColor: "#99ff32",
              minHeight: 24,
              border: "3px solid #000b1d",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
              backgroundColor: "#99ff32",
            },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
              backgroundColor: "#99ff32",
            },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#99ff32",
            },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#99ff32",
            },
          },
        }
      },
    },
  });