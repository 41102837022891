import { UserAssets } from "../../types/UserAssets";
import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';

const initialState: InitialState<UserAssets> = {
    isFetching: true,
    error: undefined,
    data:{
       assets:undefined,
       attributeCards:undefined,
       opponentAssets:undefined,
       assetsByPosition:undefined,
       starPlayer:undefined
    }
};

export const userAssets = (
    state = initialState,
    action: ReducerAction<UserAssets>
)=>{
    switch(action.type){
        case actionTypes.REQUEST_USER_ASSETS:
        case actionTypes.REQUEST_SINGLE_USER_ASSET:
        case actionTypes.REQUEST_USER_ATTRIBUTE_CARDS:
        case actionTypes.REQUEST_OPPONENT_ASSETS:
        case actionTypes.REQUEST_STAKED_CLAIMS:
        case actionTypes.REQUEST_CHECK_STAKE_TRANSFER:
        case actionTypes.REQUEST_CHECK_DESTAKE_TRANSFER:
            return{
                ...state,
                isFetching: true,
                error: undefined
            };
        case actionTypes.RECEIVE_USER_ASSETS:
            return{
                ...state,
                data: action.payload.data,
                isFetching: false,
                error: undefined,
            };
        case actionTypes.ERROR_USER_ASSETS:
        case actionTypes.ERROR_SINGLE_USER_ASSET:
        case actionTypes.ERROR_USER_ATTRIBUTE_CARDS:
        case actionTypes.ERROR_OPPONENT_ASSETS:
        case actionTypes.ERROR_STAKED_CLAIMS:
        case actionTypes.ERROR_STAKE_TRANSFER_ERROR:
        case actionTypes.ERROR_DESTAKE_TRANSFER_ERROR:
            return{
                ...state,
                error:action.error,
                isFetching:false,
            };
        case actionTypes.RECEIVE_SINGLE_USER_ASSET:
        case actionTypes.UPDATE_USER_ASSET:
            return{
                ...state,
                data:{
                    ...state.data,
                    assets:{
                        ...state.data.assets,
                        [Object.keys(action.payload.data.assets!)[0]] : Object.values(action.payload.data.assets!)[0]
                    }
                    
                }
            };
        case actionTypes.RECEIVE_STAKED_CLAIMS:
            return{
                ...state,
                data:{
                    ...state.data,
                    claimedStakes:action.payload.data.claimedStakes
                }
            };
        case actionTypes.RECEIVE_USER_ATTRIBUTE_CARDS:
            return{
                ...state,
                data:{
                    ...state.data,
                    attributeCards: action.payload.data.attributeCards
                }
            };
        case actionTypes.RECEIVE_OPPONENT_ASSETS:
        case actionTypes.RESET_OPPONENT_ASSETS:
            return{
                ...state,
                data:{
                    ...state.data,
                    opponentAssets: action.payload.data.opponentAssets
                }
            };
        default:
            return state;
    }
};