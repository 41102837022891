import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useUAL } from "../../utils/useUAL";
import * as actionTypes from "../../store/actions/types";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fetchConfig } from "../../store/actions/config";
import PersonIcon from "@mui/icons-material/Person";
import {
  fetchMatches,
  fetchTeams,
  fetchTournaments,
  fetchTournoPositions,
} from "../../store/actions/gameData";
import {
  MYPLAYERS_ROUTE,
  TEAMSETUP_ROUTE,
  VINTY,
  WAX,
} from "../../utils/constants";
import { Avatar } from "./Avatar";
import { AvatarUploader } from "./AvatarUploader";
import { fetchUserAssets } from "../../store/actions/userAssets";
import {
  fetchVintyBalance,
  fetchWaxBalance,
  formatTokenAmount,
} from "../../store/actions/userSession";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ShieldIcon from "@mui/icons-material/Shield";
import StadiumIcon from "@mui/icons-material/Stadium";
import { LinearProgress } from "@mui/material";

export type HeaderHandle = {
  closeMenu: () => void;
};
type Props = {};

export const Header = forwardRef<HeaderHandle, Props>((props, ref) => {
  const ual = useUAL() as any;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const [showAvatarUploader, setShowAvatarUploader] = useState(false);

  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: appConfig } = useSelector(
    (state: RootState) => state.appConfig
  );
  const { data: schemaInfo } = useSelector(
    (state: RootState) => state.schemaInfo
  );
  const { data: user } = useSelector((state: RootState) => state.userSession);

  useImperativeHandle(ref, () => ({
    closeMenu() {
      if (isMobile) {
        document.body.classList.toggle("sb-sidenav-toggled");
      }
    },
  }));

  // Get app config and check if app is locked
  useEffect(() => {
    // Get for every page other than TEAMSETUP - as we changing state players the pitch when creating a new team
    if (
      location.pathname == TEAMSETUP_ROUTE ||
      location.pathname == MYPLAYERS_ROUTE
    ) {
      return;
    }

    // every 30s _ COME BACK TO THIS
    // remember the data is cached and old i.e. captured by state-closure
    //
    let handle = setInterval(() => {
      dispatch(fetchWaxBalance(user.account));
      dispatch(fetchVintyBalance(user.account));
      dispatch(fetchConfig());
      dispatch(fetchUserAssets(user.account, schemaInfo));
    }, 20000);

    return () => {
      if (handle != null) {
        clearInterval(handle);
      }
    };
  }, []);

  useEffect(() => {
    if (location.pathname == MYPLAYERS_ROUTE) {
      return;
    }

    dispatch(fetchWaxBalance(user.account));
    dispatch(fetchVintyBalance(user.account));
    dispatch(fetchTeams(userData, userAssets));
    dispatch(fetchTournaments());
    dispatch(fetchTournoPositions());
    dispatch(fetchMatches());
  }, [userAssets]);

  useEffect(() => {
    if (isMobile) {
      console.log("mobile");
      if (location.pathname == TEAMSETUP_ROUTE) {
        return;
      }
      // add short delay
      setTimeout(() => {
        // Only toggle if it exists already
        if (document.body.classList.contains("sb-sidenav-toggled")) {
          document.body.classList.toggle("sb-sidenav-toggled");
        }
      }, 200);
    }
    dispatch(fetchUserAssets(user.account, schemaInfo));
  }, []);

  // if app is under maintenance then go back to login screen.
  useEffect(() => {
    if (appConfig.appLocked && !userData.isAdmin) {
      console.log("locked");
      navigate("/");
    }
  }, [appConfig.appLocked]);

  useEffect(() => {
    if (ual.activeUser == null) {
      // user is logged out take them back to login
      console.log("user is logged out.");
      dispatch({ type: actionTypes.USER_LOGOUT });
      navigate("/");
    }
  }, [ual.activeUser]);

  const toggleSideNav = () => {
    document.body.classList.toggle("sb-sidenav-toggled");
    // localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
  };

  function getWaxBalance(showPrecision: boolean) {
    if (!userData.waxBalance) {
      return 0;
    }
    const balance = showPrecision
      ? userData.waxBalance
      : userData.waxBalance.toFixed(0);
    return balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getVintyBalance(showPrecision: boolean) {
    if (!userData.vintyBalance) {
      return 0;
    }
    const balance = showPrecision
      ? userData.vintyBalance
      : userData.vintyBalance.toFixed(0);
    return balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getVintyPrecision = () => {
    const balance = getVintyBalance(true);
    const formattedBalance = formatTokenAmount(
      balance.toString(),
      VINTY,
      false
    );
    const balanceParts = formattedBalance.split(".");
    return balanceParts[1];
  };

  const getVintyWhole = () => {
    const balance = getVintyBalance(true);
    const formattedBalance = formatTokenAmount(
      balance.toString(),
      VINTY,
      false
    );
    const balanceParts = formattedBalance.split(".");
    return balanceParts[0];
  };

  const getWaxPrecision = () => {
    const balance = getWaxBalance(true);
    const formattedBalance = formatTokenAmount(balance.toString(), WAX, false);
    const balanceParts = formattedBalance.split(".");
    return balanceParts[1];
  };

  const getWaxWhole = () => {
    const balance = getWaxBalance(true);
    const formattedBalance = formatTokenAmount(balance.toString(), WAX, false);
    const balanceParts = formattedBalance.split(".");
    return balanceParts[0];
  };

  const logout = (e: React.MouseEvent) => {
    e.preventDefault();
    ual.logout();
  };

  const onAvatarUpdated = () => {
    setShowAvatarUploader(false);
  };

  const getResourceValue = (value: number | undefined): string => {
    console.log(value);
    if (!value) {
      return "<1%";
    }
    const roundedValue = Math.round(value);
    if (roundedValue == 0) {
      return "<1%";
    }
    return `${roundedValue}%`;
  };

  return (
    <>
      <AvatarUploader
        showDialog={showAvatarUploader}
        onAvatarUpdated={onAvatarUpdated}
      />
      <nav className="sb-topnav navbar navbar-expand navbar-dark">
        {/* <!-- Navbar Brand--> */}
        <a className="navbar-brand ps-3" href="index.html">
          <img
            src={"/images/Legends League Logo-sm.png"}
            alt="LEGENDS LEAGUE"
          />
        </a>
        {/* <!-- Sidebar Toggle--> */}
        <button
          className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
          id="sidebarToggle"
          onClick={toggleSideNav}
        >
          <i className="fas fa-bars"></i>
        </button>

        <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
          <div
            className="input-group"
            style={{
              fontSize: "0.8rem",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img
              src="/images/VNTY-icon-red.png"
              alt="VNTY Token"
              style={{ width: "25px" }}
            />
            <span style={{ color: "#fff" }}>
              &nbsp; &nbsp;
              <b>{getVintyBalance(false)}</b> VNTY
            </span>
            &nbsp;
          </div>
          <div
            className="input-group"
            style={{ fontSize: "0.8rem", alignItems: "center" }}
          >
            <img src="/images/wax.png" alt="" style={{ width: "25px" }} />
            <span style={{ color: "#fff" }}>
              &nbsp; &nbsp;
              <b>{getWaxBalance(false)}</b> WAX
            </span>
            &nbsp;
          </div>
        </div>
        {/* <!-- Navbar--> */}
        <div className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Avatar size={32} userName={userData.userName!} />
              &nbsp;
              {userData.account}
            </a>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
              style={{
                border: "1px solid #99ff32",
                borderRadius: "5px",
                backgroundColor: "#001636",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowAvatarUploader(true)}
                  >
                    <Avatar size={60} userName={userData.userName!} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                      {userData.account}
                    </span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowAvatarUploader(true)}
                    >
                      Edit Avatar
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid #22395a",
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",

                      flex: 1,
                    }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Resources
                    </span>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <span style={{ width: "30px" }}>CPU</span>
                      <div
                        style={{
                          alignItems: "center",
                          flex: 1,
                          display: "flex",
                          gap: "3px",
                          marginLeft: "5px",
                        }}
                      >
                        <span style={{ fontSize: "10px", width: "15%" }}>
                          {getResourceValue(userData.cpu)}
                        </span>
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            flex: 1,
                            marginLeft: "5px",
                            height: "8px",
                            borderRadius: 60,
                            "& .MuiLinearProgress-bar1Determinate": {
                              backgroundColor: "#29DCB6",
                              height: "8px",
                            },
                          }}
                          value={userData.cpu ? Math.round(userData.cpu) : 0}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span style={{ width: "30px" }}>NET</span>
                      <div
                        style={{
                          alignItems: "center",
                          flex: 1,
                          display: "flex",
                          gap: "3px",
                          marginLeft: "5px",
                        }}
                      >
                        <span style={{ fontSize: "10px", width: "15%" }}>
                          {getResourceValue(userData.net)}
                        </span>
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            flex: 1,
                            marginLeft: "5px",
                            height: "8px",
                            borderRadius: 60,
                            "& .MuiLinearProgress-bar1Determinate": {
                              backgroundColor: "#FAC685",
                              height: "8px",
                            },
                          }}
                          value={userData.net ? Math.round(userData.net) : 0}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span style={{ width: "30px" }}>RAM</span>
                      <div
                        style={{
                          alignItems: "center",
                          flex: 1,
                          display: "flex",
                          gap: "3px",
                          marginLeft: "5px",
                        }}
                      >
                        <span style={{ fontSize: "10px", width: "15%" }}>
                          {getResourceValue(userData.ram)}
                        </span>
                        <LinearProgress
                          variant="determinate"
                          sx={{
                            flex: 1,
                            marginLeft: "5px",
                            height: "8px",
                            borderRadius: 60,
                            "& .MuiLinearProgress-bar1Determinate": {
                              backgroundColor: "#FF684D",
                              height: "8px",
                            },
                          }}
                          value={userData.ram ? Math.round(userData.ram) : 0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid #22395a",
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      fontSize: "12px",
                      flex: 1,
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Balance</span>
                    <div
                      style={{
                        display: "flex",

                        flex: 1,
                      }}
                    >
                      <div style={{ width: "25px" }}>
                        <img
                          src="/images/wax.png"
                          alt=""
                          style={{ width: "16px" }}
                        />
                      </div>
                      <span style={{ fontWeight: "bold", color: "#99ff32" }}>
                        WAX
                      </span>
                      <div
                        style={{
                          fontWeight: "bold",

                          flex: 1,
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <span>{getWaxBalance(false)}</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "25px" }}>
                        <img
                          src="/images/VNTY-icon-red.png"
                          alt=""
                          style={{ width: "16px" }}
                        />
                      </div>
                      <span style={{ fontWeight: "bold", color: "#99ff32" }}>
                        VNTY
                      </span>
                      <div
                        style={{
                          fontWeight: "bold",

                          flex: 1,
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <span>{getVintyWhole()}</span>
                          <span style={{ fontSize: "10px", color: "grey" }}>
                            .{getVintyPrecision()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid #22395a",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      flex: 1,
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {userData.userName}
                    </span>
                    <div>
                      <ShieldIcon color="secondary" />
                      <span style={{ marginLeft: "5px" }}>{userData.club}</span>
                    </div>
                    <div>
                      <StadiumIcon color="secondary" />
                      <span style={{ marginLeft: "5px" }}>
                        {userData.ground}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid #22395a",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",

                      flex: 1,
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>My VN NFTs</span>
                    <div
                      style={{
                        display: "flex",

                        flex: 1,
                      }}
                    >
                      <span>Total</span>
                      <div
                        style={{
                          fontWeight: "bold",

                          flex: 1,
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <span>
                            {userAssets.assets
                              ? Object.keys(userAssets.assets).length
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span>Staked</span>
                      <div
                        style={{
                          fontWeight: "bold",

                          flex: 1,
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <span>
                            {userAssets.stakedAssets
                              ? Object.keys(userAssets.stakedAssets).length
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <a
                className="dropdown-item"
                style={{
                  cursor: "pointer",
                  borderBottomLeftRadius: "3px",
                  borderBottomRightRadius: "3px",
                }}
                onClick={logout}
              >
                <ExitToAppIcon />
                Sign out
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
});
