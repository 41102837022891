import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { EditTournament } from "../components/EditTournament";
import { RootState } from "../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Tournament } from "../../types/Tournament";
import { fetchTournaments } from "../../store/actions/gameData";
import { deleteTournament } from "../../store/actions/config";
import { useUAL } from "../../utils/useUAL";
import FlagIcon from "@mui/icons-material/Flag";
import CheckIcon from "@mui/icons-material/Check";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { CloseTourno } from "../components/CloseTourno";
import CloseIcon from "@mui/icons-material/Close";

export const tournamentColumns: GridColDef[] = [
  { field: "name", headerName: "Name", width: 150 },
  { field: "startDate", headerName: "Start", width: 150 },
  { field: "endDate", headerName: "Ends", width: 150 },
  { field: "teamCount", headerName: "Teams", width: 60 },
  { field: "gamesCount", headerName: "Games", width: 60 },
  { field: "unlockLimit", headerName: "Unlock Limit" },
  { field: "buyIn", headerName: "Buy In", width: 60 },
  { field: "prize", headerName: "Prize", width: 50 },
  { field: "winningTeam", headerName: "Winning Team" },
  { field: "manager", headerName: "Manager", width: 80 },
  {
    field: "active",
    headerName: "Is Running",
    width: 80,
    renderCell: (params: GridRenderCellParams<boolean>) => (
      <IsRunningStatus active={params.value!} />
    ),
  },
  {
    field: "expired",
    headerName: "Expired",
    width: 60,
    renderCell: (params: GridRenderCellParams<boolean>) => (
      <IsExpired active={params.value!} />
    ),
  },
  {
    field: "id",
    headerName: "Actions",
    width: 150,
    renderCell: (params: GridRenderCellParams<number>) => (
      <ActionPanel tournamentId={params.value!} />
    ),
  },
];

export type ActionPanelProps = {
  tournamentId: number;
};

export const IsRunningStatus: FC<{ active: boolean }> = ({ active }) => {
  const renderIcon = () => {
    if (active) {
      return <CheckIcon color="secondary" fontSize="small" />;
    } else {
      return <NotInterestedIcon color="error" fontSize="small" />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      {renderIcon()}
    </div>
  );
};

export const IsExpired: FC<{ active: boolean }> = ({ active }) => {
  const renderIcon = () => {
    if (active) {
      return <CheckIcon color="secondary" fontSize="small" />;
    } else {
      return <CloseIcon color="error" fontSize="small" />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      {renderIcon()}
    </div>
  );
};

export const ActionPanel: FC<ActionPanelProps> = ({ tournamentId }) => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [open, setOpen] = useState(false);
  const [openCloseTourno, setOpenCloseTourno] = useState(false);
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const dispatch = useDispatch<any>();
  const ual = useUAL() as any;

  useEffect(() => {
    let tournament = gameData.tournaments?.find((t) => t.id == tournamentId);
    if (tournament != null) {
      setTournament(tournament);
    }
  }, [tournamentId]);

  const editTournament = () => {
    setOpen(true);
  };

  const onTournoSave = () => {
    setOpen(false);
    dispatch(fetchTournaments());
  };

  const delTournament = () => {
    const activeUser = ual["activeUser"];
    dispatch(deleteTournament(activeUser, tournamentId));
  };

  const closeOutTourno = () => {
    setOpenCloseTourno(true);
  };

  const onTournoClosed = () => {
    setOpenCloseTourno(false);
    dispatch(fetchTournaments());
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "2px",
      }}
    >
      <EditTournament
        tournament={tournament}
        showDialog={open}
        onTournoUpdated={onTournoSave}
      />
      <CloseTourno
        tournament={tournament}
        showDialog={openCloseTourno}
        onTournoClosed={onTournoClosed}
      />
      <IconButton
        onClick={editTournament}
        color="primary"
        aria-label="edit"
        component="label"
      >
        <EditOutlinedIcon color="secondary" fontSize="small" />
      </IconButton>
      <IconButton onClick={delTournament} aria-label="delete" component="label">
        <DeleteOutlineOutlinedIcon color="secondary" fontSize="small" />
      </IconButton>
      <IconButton
        onClick={closeOutTourno}
        aria-label="closetourno"
        component="label"
      >
        <FlagIcon color="secondary" fontSize="small" />
      </IconButton>
    </div>
  );
};
