import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmotionRarities,
  EnforcersRarities,
  PromoRarties,
  Series1Rarities,
  Series2Rarities,
  SpecialRarities,
  Templates,
  WorldCupRarities,
} from "../inventory";
import { RootState } from "../store/reducers";
import {
  EMOTION,
  ENFORCERS,
  MYPLAYERS_ROUTE,
  PROMOPLAYERS,
  SERIES1,
  SERIES2,
  SPECIALS,
  WCLEGENDS1,
} from "../utils/constants";
import { sortAttributes } from "../utils/sortAttributes";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";
import { Player } from "../types/Player";
import StarIcon from "@mui/icons-material/Star";
import { HtmlTooltip } from "./utils/toolTip";
import { debounce } from "lodash";
import GroupsIcon from "@mui/icons-material/Groups";
import { StakePlayer } from "./components/StakePlayer";
import { DestakePlayer } from "./components/DestakePlayer";
import { ClaimStake } from "./components/ClaimStake";
import { UpgradePlayer2 } from "./components/UpgradePlayer2";
import { fetchVintyBalance } from "../store/actions/userSession";
import { ClaimAll } from "./components/ClaimAll";
import { PlayerSearch } from "./components/PlayerSearch";
import { OnboardPlayer } from "./components/OnboardPlayer";
import { AddPlayer } from "./components/AddPlayer";

type PlayerAsset = {
  player: Player;
  asset: IAsset;
};

const MyPlayers = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [upgradeAsset, setUpgradeAsset] = useState<IAsset | null>(null);
  const [showUpgradePlayer, setShowUpgradePlayer] = useState(false);
  const [positions, setPositions] = useState<{ [key: string]: number }>({});
  const [selectedSchema, setSelectedSchema] = useState("");
  const [selectedRarity, setSelectedRarity] = useState("");
  const [showStakeDialog, setShowStakeDialog] = useState(false);
  const [showPlayerSearch, setShowPlayerSearch] = useState(false);
  const [showDestakeDialog, setShowDestakeDialog] = useState(false);
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const [showClaimDialog, setShowClaimDialog] = useState(false);
  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [showAllClaimDialog, setShowAllClaimDialog] = useState(false);
  const [claimPlayerAssetId, setClaimPlayerAssetId] = useState(0);
  const [stakeAssetId, setStakeAssetId] = useState("");
  const [destakeAssetId, setDestakeAssetId] = useState("");
  const [rarities, setRarities] = useState([] as string[]);
  const [schemaCounts, setSchemaCounts] = useState<{
    [name: string]: number;
  }>({});
  const [searchText, setSearchText] = useState("");
  const { data: schemaData } = useSelector(
    (state: RootState) => state.schemaInfo
  );
  const { data: assetData } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [searchResults, setSearchResults] = useState([] as PlayerAsset[]);
  const [prefilteredPlayerAssets, setPrefilteredPlayerAssets] = useState(
    [] as PlayerAsset[]
  );
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const [selectedPosition, setSelectedPosition] = useState("");
  const [lockedPlayers, setLockedPlayers] = useState([] as number[]);
  const [showStaked, setShowStaked] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [numClaimsAvailable, setNumClaimsAvailable] = useState(0);
  const [newAsset, setNewAsset] = useState<IAsset | null>(null);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    //find active tournament - if any
    if (gameData.tournaments != null) {
      let tournament = gameData.tournaments.find((t) => t.active);
      // check if && tournament.expired
      if (tournament != null) {
        if (gameData.tournamentPositions != null) {
          // get your position in tourno
          let pos = gameData.tournamentPositions.find(
            (t) => t.manager === userData.userName
          );
          // get you team and if locked then we can't upgrade player
          if (pos != null && gameData.userTeams != null) {
            let team = gameData.userTeams[pos.teamId];
            if (team.locked) {
              setLockedPlayers(team.lineup);
            }
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (gameData.players != null) {
      let playerAssets: PlayerAsset[] = [];
      let assetPositions: { [key: string]: number } = {};
      let tmpSchemaCounts: { [name: string]: number } = {};
      let numClaims = 0;
      // create a new array with player and asset together
      let players = Object.values(gameData.players).reduce((arr, player) => {
        if (assetData.assets != null) {
          let asset = assetData.assets[player.asset_id];

          if (asset == null && assetData.stakedAssets != null) {
            asset = assetData.stakedAssets[player.asset_id];
          }
          if (asset != null) {
            arr.push({ asset: asset, player: player });

            if (
              player.staked &&
              player.nextClaim != null &&
              player.nextClaim < 0
            ) {
              numClaims++;
            }

            let template = Templates[asset.template?.template_id!];
            if (template != null) {
              if (assetPositions[template.position]) {
                assetPositions[template.position] =
                  assetPositions[template.position] + 1;
              } else {
                assetPositions[template.position] = 1;
              }
            }

            if (tmpSchemaCounts[asset.schema.schema_name]) {
              tmpSchemaCounts[asset.schema.schema_name] =
                tmpSchemaCounts[asset.schema.schema_name] + 1;
            } else {
              tmpSchemaCounts[asset.schema.schema_name] = 1;
            }
          }
        }

        return arr;
      }, playerAssets);

      setNumClaimsAvailable(numClaims);

      // now sort by highest TAV
      players.sort((a, b) => b.player.tav! - a.player.tav!);

      // If the user has performed a search then we need to keep track
      // so when they stake/destake/claim/boost then the player result state is maintained i.e. not refresh and lost filters
      if (searchInitiated) {
        setPrefilteredPlayerAssets(players);
        setPositions(assetPositions);
        setSchemaCounts(tmpSchemaCounts);

        debouncedSearch(
          searchText,
          players,
          selectedPosition,
          selectedSchema,
          selectedRarity,
          showStaked
        );
      } else {
        setSearchResults(players);
        setPrefilteredPlayerAssets(players);
        setPositions(assetPositions);
        setSchemaCounts(tmpSchemaCounts);
      }
    }
  }, [gameData.players, assetData.assets]);

  const debouncedSearch = useRef(
    debounce((name, playerAssets, position, schema, rarity, staked) => {
      try {
        setSearchInitiated(true);
        let searchName = name.toLowerCase();
        setSearchText(searchName);

        if (playerAssets == null || playerAssets.length == 0) {
          return;
        }

        let results = (playerAssets as PlayerAsset[]).filter((item) => {
          let res = true;
          if (searchName != "") {
            res = item.asset.name.toLowerCase().startsWith(searchName);
          }
          if (position != "all" && position != "") {
            res = res == true && item.asset.mutable_data.Position == position;
          }
          if (schema != "all" && schema != "") {
            console.log(res);
            res = res == true && item.asset.schema.schema_name == schema;
          }
          if (rarity != "") {
            res = res == true && item.asset.data.Rarity == rarity;
          }
          if (staked) {
            if (!item.player.staked) {
              res = false;
            }
          }
          // // filter on position
          // if(asset.mutable_data.Position != undefined){
          //     res = (res == true) && asset.mutable_data.Position == position;
          // }
          return res;
        });
        if (results != null) {
          results.sort((a, b) => b.player.tav! - a.player.tav!);
          setSearchResults(results);
        }
      } catch (e) {
        alert(e);
      }
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const getPlayersTAV = (asset: IAsset): number => {
    if (gameData.players != null) {
      let player = gameData.players[parseInt(asset.asset_id)];
      return player != null && player.tav != null ? Math.floor(player.tav) : 0;
    }
    return 0;
  };

  const viewAsset = (assetId: string) => {
    window.open("https://wax.atomichub.io/explorer/asset/" + assetId, "_blank");
  };

  // Fired when the user clicks the 'level up' button on the player
  const boostPlayer = (asset: IAsset) => {
    setUpgradeAsset(asset);
    setShowUpgradePlayer(true);
  };

  // Triggered when a player has been upgraded/boosted.
  const onPlayerUpgraded = (asset: IAsset | null) => {
    setShowUpgradePlayer(false);
  };

  const isStarPlayer = (asset: IAsset): boolean => {
    if (assetData.starPlayer) {
      return assetData.starPlayer.asset_id == asset.asset_id;
    }
    return false;
  };

  const schemaChange = (event: SelectChangeEvent) => {
    let schema = event.target.value as string;
    setSelectedSchema(schema);
    // reset rarity
    setSelectedRarity("");

    if (schema != "") {
      switch (schema) {
        case "all":
          setRarities([]);
          break;
        case SERIES1:
          setRarities(Series1Rarities);
          break;
        case SERIES2:
          setRarities(Series2Rarities);
          break;
        case EMOTION:
          setRarities(EmotionRarities);
          break;
        case ENFORCERS:
          setRarities(EnforcersRarities);
          break;
        case PROMOPLAYERS:
          setRarities(PromoRarties);
          break;
        case SPECIALS:
          setRarities(SpecialRarities);
          break;
        case WCLEGENDS1:
          setRarities(WorldCupRarities);
          break;
      }
      debouncedSearch(
        searchText,
        prefilteredPlayerAssets,
        selectedPosition,
        schema,
        "",
        showStaked
      );
    }
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //alert(e.target.value);
    debouncedSearch(
      e.target.value,
      prefilteredPlayerAssets,
      selectedPosition,
      selectedSchema,
      selectedRarity,
      showStaked
    );
  };

  const positionChange = (event: SelectChangeEvent) => {
    let position = event.target.value as string;
    setSelectedPosition(position);
    debouncedSearch(
      searchText,
      prefilteredPlayerAssets,
      position,
      selectedSchema,
      selectedRarity,
      showStaked
    );
  };

  const rarityChange = (event: SelectChangeEvent) => {
    let rarity = event.target.value as string;
    if (rarity != "") {
      setSelectedRarity(rarity);
      debouncedSearch(
        searchText,
        prefilteredPlayerAssets,
        selectedPosition,
        selectedSchema,
        rarity,
        showStaked
      );
    }
  };

  const getTotalPlayers = (): number => {
    return Object.values(schemaCounts).reduce((a, b) => a + b, 0);
  };

  const isPlayerLocked = (assetId: string): boolean => {
    if (lockedPlayers != null && lockedPlayers.length > 0) {
      return lockedPlayers.find((a) => a == parseInt(assetId)) != undefined;
    }
    return false;
  };

  const stakeAsset = (assetId: string) => {
    // stake and check vintystakes to ensure asset is there (in table)
    setShowStakeDialog(true);
    setStakeAssetId(assetId);
  };

  const onStaked = () => {
    setShowStakeDialog(false);
    setStakeAssetId("");
  };

  const onDestaked = () => {
    setShowDestakeDialog(false);
    setDestakeAssetId("");
  };

  const destakeAsset = (assetId: string) => {
    setShowDestakeDialog(true);
    setDestakeAssetId(assetId);
  };

  const renderStakeButton = (player: Player, asset: IAsset) => {
    if (isPlayerLocked(asset.asset_id)) {
      return;
    }
    if (player.staked) {
      return (
        <Button
          className="blue-btn"
          variant="contained"
          size="small"
          onClick={() => destakeAsset(asset.asset_id)}
        >
          Destake
        </Button>
      );
    } else {
      return (
        <Button
          className="blue-btn"
          variant="contained"
          size="small"
          onClick={() => stakeAsset(asset.asset_id)}
        >
          Stake
        </Button>
      );
    }
  };

  const renderClaimButton = (player: Player) => {
    //
    if (player.staked) {
      if (player.nextClaim != null) {
        if (player.nextClaim < 0) {
          return (
            <Button
              className="green-btn"
              size="small"
              variant="contained"
              onClick={() => claimStake(player.asset_id)}
            >
              Claim
            </Button>
          );
        } else {
          const hours = Math.trunc(player.nextClaim / 1000 / 3600);
          const mins = Math.trunc(((player.nextClaim / 1000) % 3600) / 60);
          let nextClaim: string = "";
          if (hours < 0) {
            nextClaim = `${mins}m`;
          } else {
            nextClaim = `${hours}h ${mins}m`;
          }
          return (
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                background: "#99ff32",
                padding: "2px",
                borderRadius: "5px",
              }}
            >
              Next claim: {nextClaim}
            </div>
          );
        }
      }
    }
  };

  const claimStake = (assetId: number) => {
    setClaimPlayerAssetId(assetId);
    setShowClaimDialog(true);
  };

  const claimAll = () => {
    setShowAllClaimDialog(true);
  };

  const onClaim = () => {
    setShowClaimDialog(false);
    dispatch(fetchVintyBalance(userData.account));
  };

  const onClaimAll = () => {
    setShowAllClaimDialog(false);
    dispatch(fetchVintyBalance(userData.account));
  };

  const onSwitchStaked = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    console.log("here");
    setShowStaked(checked);
    debouncedSearch(
      searchText,
      prefilteredPlayerAssets,
      selectedPosition,
      selectedSchema,
      selectedRarity,
      checked
    );
  };

  const signPlayer = () => {
    setShowPlayerSearch(true);
  };

  const onPlayerSelected = (asset: IAsset) => {
    setShowPlayerSearch(false);
    if (asset != null) {
      // if asset has attributes but is not a player, then show AddPlayer
      // other show onboarding screen
      setNewAsset(asset);
      if (Object.keys(asset.mutable_data).length == 0) {
        setShowOnBoarding(true);
      } else {
        setShowAddPlayer(true);
      }
    }
  };

  // Triggered once a new player has been onboarded from the Search screen.
  const onPlayerOnboarded = (playerIsReady: boolean) => {
    setShowOnBoarding(false);
    if (playerIsReady && newAsset) {
      setShowAddPlayer(true);
    }
  };

  const onPlayerAdded = () => {
    setShowAddPlayer(false);
  };

  return (
    <>
      <PlayerSearch
        showDialog={showPlayerSearch}
        onPlayerSelected={onPlayerSelected}
        showPlayers={false}
      />
      <OnboardPlayer
        showDialog={showOnBoarding}
        position={
          newAsset ? Templates[newAsset.template?.template_id!].position : ""
        }
        asset={newAsset}
        onPlayerOnboarded={onPlayerOnboarded}
      />
      <AddPlayer
        showDialog={showAddPlayer}
        asset={newAsset}
        onPlayerAdded={onPlayerAdded}
      />
      <ClaimAll showDialog={showAllClaimDialog} onClaim={onClaimAll} />
      <ClaimStake
        assetId={claimPlayerAssetId}
        showDialog={showClaimDialog}
        onClaim={onClaim}
      />
      <DestakePlayer
        showDialog={showDestakeDialog}
        assetId={destakeAssetId}
        onDestaked={onDestaked}
      />
      <StakePlayer
        showDialog={showStakeDialog}
        assetId={stakeAssetId}
        onStaked={onStaked}
      />
      <UpgradePlayer2
        showDialog={showUpgradePlayer}
        asset={upgradeAsset!}
        onPlayerUpgraded={onPlayerUpgraded}
      />
      <Header />
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={MYPLAYERS_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  margin: "10px 20px",
                }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  src={"/images/players-icon.svg"}
                  width="42"
                  height="42"
                />
                <span>My Players ({getTotalPlayers()})</span>
                <Button
                  sx={{ marginLeft: "10px" }}
                  className="green-btn"
                  onClick={() => signPlayer()}
                >
                  Sign Player
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "10px",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <TextField
                  onChange={onTextChange}
                  label="Search Player Name"
                  type="username"
                  size="small"
                  sx={{
                    width: mobile ? "400px" : "275px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #99ff32",
                    },
                    "&:hover": {
                      "&& fieldset": {
                        border: "1px solid #99ff32",
                      },
                    },
                  }}
                />
                <FormControl sx={{ width: mobile ? "400px" : "160px" }}>
                  <InputLabel size="small">Position</InputLabel>
                  <Select
                    onChange={positionChange}
                    defaultValue="all"
                    label="Schemas"
                    size="small"
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #99ff32",
                      },
                      "&:hover": {
                        "&& fieldset": {
                          border: "1px solid #99ff32",
                        },
                      },
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {positions &&
                      Object.entries(positions).map(([key, value]) => (
                        <MenuItem value={key}>
                          {key} ({value})
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: mobile ? "400px" : "178px" }}>
                  <InputLabel size="small">Schemas</InputLabel>
                  <Select
                    onChange={schemaChange}
                    label="Schemas"
                    defaultValue="all"
                    size="small"
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #99ff32",
                      },
                      "&:hover": {
                        "&& fieldset": {
                          border: "1px solid #99ff32",
                        },
                      },
                    }}
                  >
                    <MenuItem value="all">All Schemas</MenuItem>
                    {schemaCounts &&
                      Object.entries(schemaCounts).map(([key, value]) => (
                        <MenuItem value={key}>
                          {key} ({value})
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: mobile ? "400px" : "200px" }}>
                  <InputLabel size="small">Rarity</InputLabel>
                  <Select
                    onChange={rarityChange}
                    label="Rarity"
                    size="small"
                    defaultValue=""
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #99ff32",
                      },
                      "&:hover": {
                        "&& fieldset": {
                          border: "1px solid #99ff32",
                        },
                      },
                    }}
                  >
                    {rarities.map((r) => (
                      <MenuItem value={r}>{r}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="stake-switch">
                  <span style={{ color: "#fff" }}>All</span>
                  <Switch color="secondary" onChange={onSwitchStaked} />
                  <span style={{ color: "#fff" }}>Staked</span>
                </div>
                {numClaimsAvailable > 0 && (
                  <Button
                    className="green-btn"
                    size="small"
                    variant="contained"
                    onClick={() => claimAll()}
                  >
                    Claim All ({numClaimsAvailable})
                  </Button>
                )}
              </div>
              <div
                className="playercolgap"
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "10px",
                }}
              >
                {searchResults.map(
                  (item, index) =>
                    item.player.templateId &&
                    Templates[item.player.templateId] && (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: "column",
                        }}
                      >
                        {isStarPlayer(item.asset) && (
                          <HtmlTooltip
                            arrow
                            placement="right"
                            title={
                              <div>This is your star player...on fire!!!</div>
                            }
                          >
                            <StarIcon
                              sx={{
                                fontSize: "40px",
                                position: "absolute",
                                color: "gold",
                                zIndex: "1",
                              }}
                            />
                          </HtmlTooltip>
                        )}
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => viewAsset(item.asset.asset_id)}
                          className="playerSearchImage"
                          src={
                            "images/" +
                            Templates[item.player.templateId].imgPath
                          }
                          loading="lazy"
                        />
                        <div className="playerSearchButtonsBox2">
                          {renderClaimButton(item.player)}
                        </div>

                        <div className="playerSearchAttributes card">
                          <div
                            className="card-body2"
                            style={{
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "2px",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {item.asset.name}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              padding: "1px",
                            }}
                          >
                            <span className="playerSearchAttributeLabel">
                              Rarity
                            </span>
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {item.asset.data.Rarity}
                            </span>
                          </div>
                          {sortAttributes(
                            Object.keys(item.asset.mutable_data),
                            item.asset.mutable_data.Position,
                            false
                          ).map((val) => (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                padding: "1px",
                              }}
                            >
                              <span className="playerSearchAttributeLabel">
                                {val}
                              </span>
                              <span
                                style={{
                                  fontWeight:
                                    val == "Level" ? "bold" : "default",
                                  color: val == "Level" ? "#99ff32" : "#fff",
                                }}
                              >
                                {item.asset.mutable_data[val]}
                              </span>
                            </div>
                          ))}
                          <div className="playerSearchTotalBox">
                            <span className="playerSearchTotalLabel">
                              TOTAL TAV
                            </span>
                            <span
                              style={{ fontWeight: "bold", color: "#99ff32" }}
                            >
                              {getPlayersTAV(item.asset)}
                            </span>
                          </div>
                          <div className="playerSearchButtonsBox">
                            {renderStakeButton(item.player, item.asset)}
                            {isPlayerLocked(item.asset.asset_id) ? (
                              <HtmlTooltip
                                arrow
                                title={
                                  <div style={{ width: "150px" }}>
                                    This player is currently in an active team.
                                    He can't be staked or boosted :( soz
                                  </div>
                                }
                              >
                                <GroupsIcon
                                  color="secondary"
                                  sx={{ fontSize: "40px" }}
                                />
                              </HtmlTooltip>
                            ) : (
                              <Button
                                className="green-btn disabledbtn"
                                disabled={isPlayerLocked(item.asset.asset_id)}
                                size="small"
                                variant="contained"
                                onClick={() => boostPlayer(item.asset)}
                              >
                                Boost
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default MyPlayers;
