import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { deleteTeam, fetchTeams } from "../../store/actions/gameData";
import { setDeleteTeamResult } from "../../store/actions/transactions";
import { deleteTeamResult } from "../../store/reducers/transactions";
import Button from "@mui/material/Button";
import { DialogTitle } from "@mui/material";
import { Team } from "../../types/Team";

type DeleteTeamProps = {
  showDialog: boolean;
  teamId: number;
  onDelete(): void;
};

const PROMPT: string = "PROMPT";
const START_DELETE: string = "START_DELETE";
const DELETE_SUCCESS: string = "DELETE_SUCCESS";
const DELETE_ERROR: string = "DELETE_ERROR";

export const DeleteTeam: FC<DeleteTeamProps> = ({
  showDialog,
  teamId,
  onDelete,
}) => {
  const dispatch = useDispatch<any>();

  const [team, setTeam] = useState<Team | null>(null);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: deleteResult } = useSelector(
    (state: RootState) => state.deleteTeamResult
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );

  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [status, setStatus] = useState(PROMPT);
  const ual = useUAL() as any;

  useEffect(() => {
    if (showDialog) {
      setStatus(PROMPT);
    }
  }, [showDialog]);

  useEffect(() => {
    if (gameData.userTeams != null) {
      setTeam(gameData.userTeams[teamId]);
    }
  }, [teamId]);

  const deleteTeamOnClick = () => {
    setStatus(START_DELETE);
    const activeUser = ual["activeUser"];
    dispatch(deleteTeam(userData, activeUser, teamId));
  };

  const onClose = () => {
    onDelete();
    dispatch(fetchTeams(userData, userAssets));
    dispatch(setDeleteTeamResult({ transaction_id: null }));
  };

  useEffect(() => {
    if (deleteResult.transaction_id != null) {
      setTimeout(() => {
        if (deleteResult.transaction_id == "ERROR") {
          setStatus(DELETE_ERROR);
          return;
        }
        setStatus(DELETE_SUCCESS);
      }, 1500);
    }
  }, [deleteResult]);

  const renderDisplay = () => {
    if (status == START_DELETE) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" />
          <div style={{ marginTop: "20px" }}>Deleting team...</div>
        </div>
      );
    }
    if (status == DELETE_SUCCESS) {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "60px" }} />
          <span>All good</span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == DELETE_ERROR) {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "60px" }} />

          <span>Ah man, an error occurred.</span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    return (
      <>
        <span>Are you sure?</span>
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button className="blue-btn" onClick={() => onClose()}>
            No
          </Button>
          <Button className="green-btn" onClick={() => deleteTeamOnClick()}>
            Yup
          </Button>
        </div>
      </>
    );
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
          }}
        >
          {renderDisplay()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
