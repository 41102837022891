import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkVintyAccountExists,
  fetchAccountInfo,
  fetchUser,
  fetchVintyBalance,
  fetchWaxBalance,
  registerUser,
} from "../store/actions/userSession";
import { useUAL } from "../utils/useUAL";
import { useNavigate } from "react-router-dom";
import { fetchConfig } from "../store/actions/config";
import { RootState } from "../store/reducers";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import { getErrorMessage } from "../utils/errorCodes";
import * as constants from "../utils/constants";
import { fetchSchemaInfo } from "../store/actions/schemaInfo";
import LinearProgress from "@mui/material/LinearProgress";
import { fetchUserAssets } from "../store/actions/userAssets";
import * as actionTypes from "../store/actions/types";
import {
  fetchCardsConfig,
  fetchMatches,
  fetchTactics,
  fetchTeams,
  fetchTournaments,
  fetchTournoPositions,
} from "../store/actions/gameData";
import CircularProgress from "@mui/material/CircularProgress";
import { isMobile, useMobileOrientation } from "react-device-detect";
import moment from "moment";
import { useUpdateAvatar } from "../utils/checkUserAvatar";
import { randomIntFromInterval } from "../utils/upgradeMutableAttributes";

/* LOGIN PROCEDURE

    1. Sign with wallet.
    2. Then attempt to get user from table.
    3. If present to step 5.
    4. If not present - display registration screen - register user and then 2 again.
    5. Initialise session - get users balance
    6. load account info (schema counts)
    7. load all owner assets from collections.
    8. load game data, teams, matches etc.
    9. Once complete, go to dashboard
*/

const Login = () => {
  const dispatch = useDispatch<any>();
  const { data } = useSelector((state: RootState) => state.appConfig);
  const {
    data: user,
    error,
    isFetching,
  } = useSelector((state: RootState) => state.userSession);
  const { data: schemaInfo, error: schemaLoadingError } = useSelector(
    (state: RootState) => state.schemaInfo
  );
  const { data: userAssets, error: userAssetsError } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData, error: gameDataError } = useSelector(
    (state: RootState) => state.gameData
  );

  // const { isLandscape, isPortrait } = useMobileOrientation();

  const [showRegistrationError, setShowRegistrationError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [showLoadingError, setShowLoadingError] = useState(false);

  const [userName, setUserName] = useState("");
  const [club, setClub] = useState("");
  const [ground, setGround] = useState("");
  const [loadingState, setLoadingState] = useState("");
  const [loadingError, setLoadingError] = useState("");
  const [progressValue, setProgressValue] = useState(0);
  const [showReady, setShowReady] = useState(false);
  const [quickLogin, setQuickLogin] = useState(false);
  const [checkAvatar, setCheckAvatar] = useState(false);
  const ual = useUAL() as any;
  const navigate = useNavigate();
  const avatar = useUpdateAvatar(checkAvatar);

  //Once logged in update state with UAL account/wallet name
  useEffect(() => {
    if (ual.activeUser) {
      // *** WHITELIST SECTION ***
      // If user is not in list then log out ASAP
      // let waxWallet = ual.activeUser["accountName"];
      // switch (waxWallet) {
      //   // vintage niftys wax wallet
      //   case "bnaf4.wam":
      //   // Ian
      //   case "ihhyw.wam":
      //   // marcin siegel
      //   case "i1hay.wam":
      //   // VN admin
      //   case "vintagenftyx":
      //   // noel
      //   case "53ub2.wam":
      //   // mrfunny
      //   case "iuoxg.wam":
      //   // El bebo
      //   case "2wabk.wam":
      //   // pnvc
      //   case "zyhn2.c.wam":
      //   // cam burns
      //   case "p2ruw.wam":
      //   // mike
      //   case "qjpf4.wam":
      //   // Odin
      //   case "dzbc4.wam":
      //   // Sarah
      //   case "grao..c.wam":
      //   // Neil
      //   case "5iyho.wam":
      //   // jhongztig | laaw.wam
      //   case "laaw.wam":
      //   // crazy chef
      //   case "5ayj2.wam":
      //   // beatristic
      //   case "aelbo.wam":
      //   // waxantikilla
      //   case "waxantikilla":
      //     dispatch(fetchUser(ual.activeUser.accountName));
      //     break;
      //   default:
      //     ual.logout();
      // }

      // now attempt to get user.
      //console.log("get user");
      dispatch(fetchUser(ual.activeUser.accountName));
      dispatch(fetchAccountInfo(ual.activeUser.accountName));
    }
  }, [ual.activeUser]);

  // Get app config and check if app is locked
  useEffect(() => {
    dispatch(fetchConfig());
  }, []);

  // display user registration transaction error
  useEffect(() => {
    if (error) {
      setShowRegistrationError(true);
    }
  }, [error]);

  // Once user has logged on and exists then we start loading our data.
  useEffect(() => {
    if (quickLogin) {
      playGame();
    }

    if (user.exists) {
      if (user.waxBalance != undefined) {
        if (loadingState == constants.INITIALISE_SESSION) {
          setLoadingState(constants.LOAD_SCHEMA_COUNTS);
        }
      } else {
        setLoadingState(constants.INITIALISE_SESSION);
      }
    }
  }, [user]);

  // Once schema counts are loaded fire off fetching of user assets
  useEffect(() => {
    if (schemaInfo.schemaCounts != undefined) {
      setLoadingState(constants.LOAD_ASSETS);
    }
  }, [schemaInfo.schemaCounts]);

  // Once users assets have loaded start loading game data
  useEffect(() => {
    if (userAssets.assets != undefined) {
      setLoadingState(constants.LOAD_TACTICS);
    }
  }, [userAssets]);

  // Game loading state
  useEffect(() => {
    if (gameData.tournaments != undefined) {
      setShowReady(true);
      setLoadingState(constants.LOAD_COMPLETE);
      return;
    }
    if (gameData.matches != undefined) {
      setLoadingState(constants.LOAD_TOURNAMENTS);
      return;
    }
    if (gameData.userTeams != undefined) {
      setLoadingState(constants.LOAD_MATCHES);
      return;
    }
    if (gameData.tactics != undefined) {
      setLoadingState(constants.LOAD_USER_TEAMS);
      return;
    }
  }, [gameData]);

  // data loading
  useEffect(() => {
    if (loadingState == constants.INITIALISE_SESSION) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(10);
        dispatch(fetchWaxBalance(user.account));
        dispatch(fetchVintyBalance(user.account));
        dispatch(checkVintyAccountExists(user.account));
      }, 500);
    } else if (loadingState == constants.LOAD_SCHEMA_COUNTS) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(20);
        dispatch(fetchCardsConfig());
        dispatch(fetchSchemaInfo(user.account));
        // Now we fire off the check to see if the users profile avatar exists
        setCheckAvatar(true);
      }, 500);
    } else if (loadingState == constants.LOAD_ASSETS) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(40);
        dispatch(fetchUserAssets(user.account, schemaInfo));
      }, 500);
    } else if (loadingState == constants.LOAD_TACTICS) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(60);
        dispatch(fetchTactics());
      }, 500);
    } else if (loadingState == constants.LOAD_USER_TEAMS) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(70);
        dispatch(fetchTeams(user, userAssets));
      }, 500);
    } else if (loadingState == constants.LOAD_MATCHES) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(85);
        dispatch(fetchMatches());
      }, 500);
    } else if (loadingState == constants.LOAD_TOURNAMENTS) {
      setTimeout(() => {
        console.log(loadingState);
        setProgressValue(100);
        dispatch(fetchTournaments());
        dispatch(fetchTournoPositions());
      }, 500);
    }
  }, [loadingState]);

  /* LOADING ERROR HANDLING */
  useEffect(() => {
    if (schemaLoadingError) {
      setLoadingError("There was an error loading schema counts");
      setShowLoadingError(true);
    }
    if (userAssetsError) {
      setLoadingError("There was an error loading user assets");
      setShowLoadingError(true);
    }
    if (gameDataError) {
      setLoadingError("There was an error loading game data");
      setShowLoadingError(true);
    }
  }, [schemaLoadingError, userAssetsError, gameDataError]);

  const login = () => {
    try {
      ual.showModal();
    } catch (e) {
      // TODO: show error modal?
    }
  };

  const playGame = () => {
    try {
      navigate(constants.DASHBOARD_ROUTE);
    } catch (e) {}
  };

  const logout = () => {
    console.log("logout");
    setShowReady(false);
    setLoadingState("");
    setLoadingError("");
    ual.logout();
    dispatch({ type: actionTypes.USER_LOGOUT });
  };

  const cancelRegistration = () => {
    logout();
  };

  const register = () => {
    try {
      setShowRegistrationError(false);
      if (!userName || !club) {
        setValidationError(true);
        return;
      }
      const activeUser = ual["activeUser"];

      dispatch(registerUser(activeUser, userName, club, ground));
    } catch (e) {
      console.log("Login error " + e);
    }
  };

  const getLoadingMessage = () => {
    let rng = randomIntFromInterval(0, 2);
    if (loadingState == constants.INITIALISE_SESSION) {
      return constants.INITIALISE_SESSION_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_SCHEMA_COUNTS) {
      return constants.LOAD_SCHEMA_COUNTS_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_ASSETS) {
      return constants.LOAD_ASSETS_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_USER_TEAMS) {
      return constants.LOAD_USER_TEAMS_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_TACTICS) {
      return constants.LOAD_TACTICS_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_PLAYERS) {
      return constants.LOAD_PLAYERS_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_TOURNAMENTS) {
      return constants.LOAD_TOURNAMENTS_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_MATCHES) {
      return constants.LOAD_MATCHES_MESSAGES[rng];
    } else if (loadingState == constants.LOAD_COMPLETE) {
      return "Boom! We're done, ready?";
    }
    return "Loading";
  };

  const renderLoadingPhase = () => {
    return (
      <div
        className="login-bg-nologo"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          className="login-preload"
          sx={{
            p: 3,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            justifyContent: "center",
            border: "1px solid #99FF32",
            borderRadius: "5px",
          }}
        >
          <Box>
            <Collapse in={showLoadingError}>
              <Alert
                variant="filled"
                severity="error"
                sx={{ m: 2 }}
                onClose={() => {
                  setShowLoadingError(false);
                }}
              >
                {loadingError}
              </Alert>
            </Collapse>
          </Box>
          <Box sx={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={"/images/pre-loader.gif"}
                width="215"
                height="264"
                alt="Vintage Niftys Legends League"
              />{" "}
            </div>
            <LinearProgress variant="determinate" value={progressValue} />
          </Box>
          <Box sx={{ color: "#fff", fontSize: "14px" }}>
            {getLoadingMessage()}
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button variant="text" className="blue-btn" onClick={logout}>
              Cancel
            </Button>
            {showReady && (
              <Button
                variant="contained"
                className="green-btn"
                onClick={playGame}
              >
                Let's go!
              </Button>
            )}
          </Box>
        </Box>
      </div>
    );
  };

  const activeUser = ual["activeUser"];

  // if (isMobile && isPortrait) {
  //   return <div>fefwfwe</div>;
  // }

  if (data?.appLocked) {
    // Allow admin account in though
    if (user != null && user.isAdmin) {
      return renderLoadingPhase();
    } else {
      return (
        <div>
          <div>VN is under maintenance</div>
        </div>
      );
    }
  } else {
    if (activeUser) {
      // fetching - show spinner
      // fetching false and user doesn't exist
      // fetching false and user exists

      if (isFetching) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Box>
              <CircularProgress color="secondary" />
            </Box>
          </div>
        );
      } else {
        // Display User Registration - user has logged in but has not registered with us.
        // Toggle true to test reg screen
        if (!user.exists) {
          return (
            <div
              className="login-bg-nologo"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div style={{ zIndex: "99", marginBottom: "-70px" }}>
                <img
                  src={"/images/Legends-League-Logo.png"}
                  width="139"
                  height="166"
                  alt="Vintage Niftys Legends League"
                />{" "}
              </div>
              <Box
                className="login-box"
                sx={{ p: 3, border: "1px solid #99FF32" }}
              >
                <FormControl>
                  <Box
                    sx={{
                      pb: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Welcome to Vintage Niftys Legends League!
                  </Box>
                  <Box>
                    <Collapse in={validationError}>
                      <Alert
                        variant="filled"
                        severity="error"
                        sx={{ m: 2 }}
                        onClose={() => {
                          setValidationError(false);
                        }}
                      >
                        Please enter missing fields!
                      </Alert>
                    </Collapse>
                    <Collapse in={showRegistrationError}>
                      <Alert
                        variant="filled"
                        severity="error"
                        sx={{ m: 2 }}
                        onClose={() => {
                          setShowRegistrationError(false);
                        }}
                      >
                        {getErrorMessage(error)}
                      </Alert>
                    </Collapse>
                  </Box>
                  <Box sx={{ color: "white" }}>
                    Sign up and get involved with the football legends of old...
                  </Box>
                  <TextField
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    required
                    margin="dense"
                    id="name"
                    label="Username"
                    type="username"
                    fullWidth
                    variant="standard"
                    inputProps={{ style: { textTransform: "lowercase" } }}
                  />
                  <TextField
                    onChange={(e) => {
                      setClub(e.target.value);
                    }}
                    required
                    margin="dense"
                    id="club"
                    label="Club"
                    type="club"
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    onChange={(e) => {
                      setGround(e.target.value);
                    }}
                    margin="dense"
                    id="ground"
                    label="Ground"
                    type="ground"
                    fullWidth
                    variant="standard"
                  />
                  <Box sx={{ fontSize: "10px" }}>
                    Don't worry you can change your club and ground later!
                  </Box>
                  <Box
                    sx={{
                      pt: "20px",
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="green-btn"
                      onClick={register}
                    >
                      Register
                    </Button>
                    <Button
                      variant="text"
                      className="blue-btn"
                      onClick={cancelRegistration}
                    >
                      Cancel
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            </div>
          );
        } else {
          // User logged in and has registered - load data
          return renderLoadingPhase();
        }
      }
    } else {
      // Splash login
      return (
        <div
          className="login-bg-nologo"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            style={{ zIndex: "99", marginBottom: "20px", marginTop: "-200px" }}
          >
            <img
              src={"/images/Legends-League-Logo.png"}
              width="329"
              height="392"
              alt="Vintage Niftys Legends League"
            />{" "}
          </div>
          <Box>
            <button className="login-btn" onClick={login}>
              Login
            </button>
          </Box>
        </div>
      );
    }
  }
};
export default Login;
