import { useMediaQuery, useTheme } from "@mui/material";
import Driver from "driver.js"
import { DASHBOARD_ROUTE, MYTEAMS_ROUTE } from "./constants";


export const useTourHelp = () =>{

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    
    const startHelpTour = (page : string) => {
        const helpSteps = getTourSteps(page);
        if(helpSteps){
            const driver = new Driver();
            driver.defineSteps(helpSteps);
            driver.start();
        }
    }
    
    const getTourSteps = (page : string) : Driver.Step[] | null =>{
        switch(page){
            case DASHBOARD_ROUTE:
                return [
                    {
                      element: "#tourno",
                      popover: {
                        title: "Current Tournament",
                        description:
                          "Displays tournament information. " +
                          "To join you must have a team and you can only have one team per tournament. " +
                          "Remember you want to field a team with a high TAV score (more on this later). The higher the TAV the greater chance of winning matches."
                      },
                    },
                    {
                      element: "#teams",
                      popover: {
                        title: "My Teams",
                        description:
                          "If you have a team in the tournament then it will be displayed here, otherwise it will show the team with the highest TAV (Total Attribute Value) score. The TAV is the sum of all your team players attributes values.",
                      },
                    },
                    {
                      element: "#league",
                      popover: {
                        title: "Legends League",
                        description:
                          "The current leaderboard. This is where you challange an opponent in the tournament. You can only challenge an opponent if " +
                          "<ul>" +
                          "<li>Your team is locked</li>" +
                          "<li>The opponents team is locked</li>" +
                          "<li>Your teams, and opponents, fitness is > 25%</li>" +
                          "<li>You haven't breached the max match limit</li>" +
                          "</ul>" + 
                          "Note: every time you play a match the fitness of each player is reduced by 25% (this is replenished over time)."
                      },
                    },
                    {
                      element: "#players",
                      popover: {
                        title: "My Players",
                        description:
                          "Shows your current star player (the one with the hightest TAV score) and a breakdown of your onboarded players per schema. The TAV <b>Total Attribute Value</b> score, " +
                          "is the sum of the players attribute values.",
                      },
                    },
                    {
                      element: "#boostplayer",
                      popover: {
                        title: "Boost cards",
                        description:
                          "Level up your player and increase their base attributes with our boost cards.",
                      },
                    },
                  ];
            case MYTEAMS_ROUTE:
                return [
                    {
                        element:'#teamsetupintro',
                        popover:{
                            title: "Team Setup",
                            description: "<ul>" +
                            "<li>Choose a team (this can't be changed once set)/li>" +
                            "<li>Select or change your formation</li>" + 
                            "<li>Add, drag, swap or remove players</li></ul>" + 
                            "Note: There is a hidden chemistry system. Some players gel well together, some prefer certain positions and...there are other secret factors!"
                        }
                    },
                    {
                        element:'#playerfocus',
                        popover:{
                            title: "Selected Player",
                            description: "Shows the active player. From here you can swap or boost (level up) your player. Their attributes are displayed along with their chemistry multiplier. " +
                            "This multiplier is applied to the sum of their attribute values."
                        }
                    },
                    {
                        element:'#lockteam',
                        popover:{
                            title: "Ready to play?",
                            position: mobile ? 'bottom' : 'left',
                            description: "To challenge an opponent in a tournament you need to 'lock' your team. Once locked you can't edit your lineup. If you unlock your team then you won't be able" + 
                            "to challenge others, but on the other hand you can't be challenged either. Think of it as a 'timeout' where you can edit your team and introduce some fresh legs!"
                        }
                    }
                ];
            default:
                return null;
        }
    }

    return {startHelpTour: startHelpTour};
}

