import { User } from "../../types/User";
import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';


const initialState: InitialState<User> = {
    isFetching: false,
    error: undefined,
    data:{
        account: '',
        userName: undefined,
        club: undefined,
        ground: undefined,
        waxBalance: undefined,
        exists: false
    }
};

export const userSession = (
    state = initialState,
    action: ReducerAction<User>
)=>{
    switch(action.type){
        case actionTypes.REQUEST_USER:
        case actionTypes.REQUEST_USER_REGISTRATION:
        case actionTypes.REQUEST_UPDATEAVATAR:
        case actionTypes.REQUEST_ACCOUNT_INFO:
        case actionTypes.REQUEST_VINTY_USER:
            return{
                ...state,
                isFetching: true,
                error: undefined
            };
        case actionTypes.RECEIVE_USER:
        case actionTypes.RECEIVE_USER_REGISTRATION:
            return{
                ...state,
                data: action.payload.data,
                isFetching: false,
                error: undefined,
            };
        case actionTypes.REQUEST_USER_WAX_BALANCE:
        case actionTypes.REQUEST_USER_VINTY_BALANCE:
            return{
                ...state,
                error: undefined,
                isFetching: true
            };
        case actionTypes.RECEIVE_USER_WAX_BALANCE:
            return{
                ...state,
                data:{
                    ...state.data,
                    waxBalance: action.payload.data.waxBalance,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_USER_VINTY_BALANCE:
            return{
                ...state,
                data:{
                    ...state.data,
                    vintyBalance: action.payload.data.vintyBalance,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_UPDATEAVATAR:
            return{
                ...state,
                data:{
                    ...state.data,
                    avatarUrl: action.payload.data.avatarUrl,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_VINTY_USER:
            return{
                ...state,
                data:{
                    ...state.data,
                    hasVintyAccount: action.payload.data.hasVintyAccount,
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.RECEIVE_ACCOUNT_INFO:
        {
            return {
                ...state,
                data:{
                    ...state.data,
                    cpu: action.payload.data.cpu,
                    net: action.payload.data.net,
                    ram: action.payload.data.ram
                },
                isFetching: false,
                error: undefined
            }; 
        }
        case actionTypes.ERROR_UPDATEAVATAR:
        case actionTypes.ERROR_USER_WAX_BALANCE:
        case actionTypes.ERROR_USER_VINTY_BALANCE:
        case actionTypes.ERROR_USER_REGISTRATION:
        case actionTypes.ERROR_USER:
        case actionTypes.ERROR_ACCOUNT_INFO:
        case actionTypes.ERROR_VINTY_USER:
            return{
                ...state,
                error:action.error,
                isFetching:false,
            }
        default:
            return state;
    }
};