import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import {  Chain } from 'universal-authenticator-library';
import { APP_NAME, WAX_DOMAIN } from './constants';

// PROD - 1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4
// TEST - f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12
export const ualChain: Chain = {
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    rpcEndpoints: [{
        protocol: 'https',
        host: WAX_DOMAIN,
        port: 443
    }]
  };

export const ualAuthenticators = [new Wax([ualChain]), new Anchor([ualChain], { appName: APP_NAME })];



