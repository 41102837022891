import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { Templates } from "../inventory";
import { Player } from "../types/Player";
import { Template } from "../types/Template";
import { calculatePlayerFitnessPercentage } from "./calculateFitness";
import { ATTACK_SCORE, DEFENCE_SCORE, DEFENDER, FORMATION_3142, FORMATION_343, FORMATION_352, FORMATION_4321, FORMATION_433, FORMATION_4411, FORMATION_442, FORMATION_442_DIAMOND, FORMATION_451, FORMATION_532, FORMATION_541, FORWARD, GOALKEEPER, GOALKEEPER_SCORE, MIDFIELDER, MIDFIELD_SCORE } from "./constants";


export interface TotalAttributeScoreData{
    groupScores : {[positionType: string] : number};
    playerScores : number[];
    chemistryMultipliers : number[];
    totalScore : number;
    totalCaps : number;
    playerFitness : number[]
}

export interface PlayerTAVStats{
    score:number;
    chemistry:number;
    fitness:number;
}

export const calculateTeamTAVScore = (players: {[id: number] : Player | null }, assets: {[id: number] : IAsset}, formation : string, lineup : number[]) : number =>{
    let scoreData = calculateTeamAttributeValues(players, assets, formation, lineup);
    return scoreData.totalScore;
}

/* Total attribute score per position i.e. Defence, Midfield, Forward etc */
export const calculateTeamAttributeValues = (players: {[id: number] : Player | null }, assets: {[id: number] : IAsset}, formation : string, lineup : number[]) : TotalAttributeScoreData => {
    var attributeScores = getEmptyScoreDictionary();
    var playerScores = Array<number>(11);
    var chemistryMultipliers = Array<number>(11);
    var playerFitness = Array<number>(11);

    // prime with default multiplier of 1.
    for(let i = 0;i < chemistryMultipliers.length;i++){
        chemistryMultipliers[i] = 1;
    }

    // Chemistry - STACK UP VALUES
    // ---------
    // 1. Loop through line up and get template.
    // 2. Determine club, country multipliers and check 'special' field and pass array to main to calc (reduce base TAV score by fitness percentage)
    // 3. Calculate player specific multipliers i.e. are they in the correct position, in preferred formation etc.
    // 4. Once all multipliers are stacked up perform final calc.
    
    var playerTemplates = Array<Template>(11);
    for(let i = 0;i < lineup.length;i++){
        let player = assets[lineup[i]];
        if(player == null){
            continue;
        }
        let template = Templates[player.template?.template_id!];
        playerTemplates[i] = template;
    }
    //console.log(playerTemplates);

    /* CLUBS */
    let clubs = playerTemplates.reduce((total,t) =>{
        if(t != null){
            total[t.club] = (total[t.club] || 0) + 1;
            return total;
        }
    }, Object.create(null));
    
    var clubCounts = Object.keys(clubs).map(k => {return {name: k, count: clubs[k]}; }).filter(t => t.count > 1);
    if(clubCounts.length > 0){
        for(let j=0;j < clubCounts.length;j++){
            let indices = getAllClubIndexes(playerTemplates, clubCounts[j].name);
            for(let k=0;k<indices.length;k++){
                chemistryMultipliers[indices[k]] += 0.3;
            }
        }
    }

    /* COUNTRIES */
    let countries = playerTemplates.reduce((total,t) =>{
        if(t != null){
            total[t.country] = (total[t.country] || 0) + 1;
            return total;
        }
    }, Object.create(null));
    var countryCounts = Object.keys(countries).map(k => {return {name: k, count: countries[k]}; }).filter(t => t.count > 1);
    //console.log(countryCounts);
    if(countryCounts.length > 0){
        for(let j=0;j < countryCounts.length;j++){
            let indices = getAllCountryIndexes(playerTemplates, countryCounts[j].name);
            for(let k=0;k<indices.length;k++){
                chemistryMultipliers[indices[k]] += 0.1;
            }
        }
    }
    
    //'Manager','United Trinity','WC66','VN Family', 'Ghoulkeeper'
    let indices = getAllIndexes(playerTemplates, 'United Trinity');
    if(indices.length > 0){
        for(let k=0;k<indices.length;k++){
            chemistryMultipliers[k] += 0.2;
        }
    }

    indices = getAllIndexes(playerTemplates, 'Manager');
    if(indices.length > 0){
        for(let k=0;k<indices.length;k++){
            chemistryMultipliers[k] += 0.6;
        }
    }

    indices = getAllIndexes(playerTemplates, 'WC66');
    if(indices.length > 0){
        for(let k=0;k<indices.length;k++){
            chemistryMultipliers[k] += 0.4;
        }
    }

    indices = getAllIndexes(playerTemplates, 'VN Family');
    if(indices.length > 0){
        for(let k=0;k<indices.length;k++){
            chemistryMultipliers[k] += 0.8;
        }
    }

    indices = getAllIndexes(playerTemplates, 'Ghoulkeeper');
    if(indices.length > 0){
        for(let k=0;k<indices.length;k++){
            chemistryMultipliers[k] += 0.3;
        }
    }
    
    // International

    for(let i = 0;i < lineup.length;i++){
        
        let asset = assets[lineup[i]];
        let player = players[lineup[i]];
        // NOTE:
        // when you first onboard a player into the game i.e when you add the attribute to the asset
        // they are not in the Players table, so their fitness will be 100.
        if(asset == null){
            continue;
        }

        let template = playerTemplates[i];
        
        // check for preferred formation
        if(formation == template.preferredFormation){
            chemistryMultipliers[i] += 0.4;
        }

        let score = 0;
        switch(formation){
            case FORMATION_343:
                score = calculate343(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_352:
                score = calculate352(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_433:
                score = calculate433(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_442:
            case FORMATION_442_DIAMOND:
                score = calculate442(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_451:
                score = calculate451(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_532:
                score = calculate532(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_541:
                score = calculate541(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_3142:
                score = calculate3142(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_4321:
                score = calculate4321(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            case FORMATION_4411:
                score = calculate4411(i, player, asset, template, chemistryMultipliers, attributeScores, playerFitness);
                break;
            default:
                // do nothing
                break;
        }
        playerScores[i] = score;
    }
    
    let data : TotalAttributeScoreData={
        groupScores: attributeScores,
        playerScores: playerScores,
        chemistryMultipliers: chemistryMultipliers,
        totalScore: attributeScores[GOALKEEPER_SCORE]+attributeScores[DEFENCE_SCORE]+attributeScores[MIDFIELD_SCORE]+attributeScores[ATTACK_SCORE],
        playerFitness: playerFitness,
        totalCaps: 0
    }
    //console.log(chemistryMultipliers);
    return data;
}

const calculate4411 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number => {
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i == 4){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 5 || i == 6 || i == 7 || i == 8){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

const calculate4321 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i == 4){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 5 || i == 6 || i == 7){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 8 || i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

const calculate3142 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 4 || i == 5 || i == 6 || i == 7 || i == 8){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

const calculate541 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i == 4 || i == 5){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 6 || i == 7 || i == 8 || i == 9){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

const calculate532 = (i : number, player : Player | null, asset : IAsset,  template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number  =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i == 4 || i == 5){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 6 || i == 7 || i == 8){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

const calculate451 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number  =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i == 4){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 5 || i == 6 || i == 7 || i == 8 || i == 9){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

const calculate433 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i == 4){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 5 || i == 6 || i == 7){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 8 || i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
 }

const calculate352 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 4 || i == 5 || i == 6 || i == 7 || i == 8){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
 }

const calculate343 = (i : number, player : Player | null, asset : IAsset,  template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    switch(i){
        case 0:
            return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
        case 1:
        case 2:
        case 3:
            return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
        case 4:
        case 5:
        case 6:
        case 7:
            return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
        case 8:
        case 9:
        case 10:
            return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
        default:
            break;
    }
   
    return 0;
}

const calculate442 = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    // GK & DF
    if(i == 0){
        return calculateGoalKeeperScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 1 || i == 2 || i == 3 || i== 4){
        return calculateDefenderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 5 || i == 6 || i == 7 || i == 8){
        return calculateMidfielderScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    if(i == 9 || i == 10){
        return calculateForwardScore(i, player, asset, template, chemistryMultipliers, attributeScores, fitness);
    }
    return 0;
}

export const getEmptyScoreDictionary = (): {[positionType: string] : number} => {
    const attributeScores :{[positionType: string] : number} = {}; 
    attributeScores[GOALKEEPER_SCORE] = 0;
    attributeScores[DEFENCE_SCORE] = 0;
    attributeScores[MIDFIELD_SCORE] = 0;
    attributeScores[ATTACK_SCORE] = 0;
    return attributeScores;
}

/*
    applyPositionBoost - only apply chemistry position boost if player is in the correct position. We set this flag to false
    when calling getPlayerScore function when getting their actual stats for their own position
*/

const calculateGoalKeeperScore =  (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    let values = getGoalkeeperValues(player, asset, template);
    fitness[i] = values.fitness;
    chemistryMultipliers[i] += values.chemistry;
    chemistryMultipliers[i] = +chemistryMultipliers[i].toFixed(2);
    let score = Math.round( values.score *  chemistryMultipliers[i]);
    attributeScores[GOALKEEPER_SCORE] += score;
    return score;
}

const calculateDefenderScore = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    let values = getDefenderValues(player, asset, template);
    fitness[i] = values.fitness;
    chemistryMultipliers[i] += values.chemistry;
    chemistryMultipliers[i] = +chemistryMultipliers[i].toFixed(2);
    let score = Math.round( values.score *  chemistryMultipliers[i]);
    attributeScores[DEFENCE_SCORE] += score;
    return score;
}

const calculateMidfielderScore = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    let values = getMidfielderValues(player, asset, template);
    fitness[i] = values.fitness;
    chemistryMultipliers[i] += values.chemistry;
    chemistryMultipliers[i] = +chemistryMultipliers[i].toFixed(2);
    let score = Math.round( values.score *  chemistryMultipliers[i]);
    attributeScores[MIDFIELD_SCORE] += score;
    return score;
}

const calculateForwardScore = (i : number, player : Player | null, asset : IAsset, template: Template, chemistryMultipliers : number[], attributeScores : {[positionType: string] : number}, fitness : number[]) : number =>{
    let values = getForwardValues(player, asset, template);
    fitness[i] = values.fitness;
    chemistryMultipliers[i] += values.chemistry;
    chemistryMultipliers[i] = +chemistryMultipliers[i].toFixed(2);
    let score = Math.round( values.score *  chemistryMultipliers[i]);
    attributeScores[ATTACK_SCORE] += score;
    return score;
}

export const getPlayersScore = (player : Player | null, asset : IAsset, template : Template, applyPositionBoost : boolean) : {score: number, chemistry: number, fitness: number} =>{

    switch(asset.mutable_data.Position)
    {
        case GOALKEEPER:
            return getGoalkeeperValues(player, asset, template, applyPositionBoost);
        case DEFENDER:
            return getDefenderValues(player, asset, template, applyPositionBoost);
        case MIDFIELDER:
            return getMidfielderValues(player, asset, template, applyPositionBoost);
        case FORWARD:
            return getForwardValues(player, asset, template, applyPositionBoost);
        default:
            return {score:0,chemistry:0, fitness:0};
    }

}

const getGoalkeeperValues = (player : Player | null, asset : IAsset, template : Template, applyPositionBoost = true) : {score: number, chemistry: number, fitness : number} => {
    
    if(asset.mutable_data.Position == GOALKEEPER){
        let reflex = parseInt(asset.mutable_data.Reflexes);
        let diving = parseInt(asset.mutable_data.Diving);
        let throwing = parseInt(asset.mutable_data.Throwing);
        let handling = parseInt(asset.mutable_data.Handling);
        let physical = parseInt(asset.mutable_data.Physical);
        let distribution = parseInt(asset.mutable_data.Distribution);
        let score = reflex+diving+throwing+handling+physical+distribution;
        if(player == null){
            return {score:score, chemistry: applyPositionBoost && template.position == GOALKEEPER ? 0.5 : 0, fitness:100};
        }
        let fitnessPerc = calculatePlayerFitnessPercentage(player.full_fitness_time);
        score = (fitnessPerc/100)*score;
        return {score: score, chemistry: applyPositionBoost && template.position == GOALKEEPER ? 0.5 : 0, fitness: fitnessPerc};
    }
    // 10% reduction if out of position.. Get players actual score for their position.
    let scores = getPlayersScore(player, asset, template, false);
    scores.score = Math.round((90/100)*scores.score);
    return scores;
}

const getDefenderValues = (player : Player | null, asset : IAsset, template : Template, applyPositionBoost = true) : {score: number, chemistry: number, fitness : number} => {
   
    if(asset.mutable_data.Position == DEFENDER){
        let tackling = parseInt(asset.mutable_data.Tackling);
        let marking = parseInt(asset.mutable_data.Marking);
        let physical = parseInt(asset.mutable_data.Physical);
        let heading = parseInt(asset.mutable_data.Heading);
        let pace = parseInt(asset.mutable_data.Pace);
        let passing = parseInt(asset.mutable_data.Passing);
        let score = tackling+marking+physical+heading+pace+passing;
        if(player == null){
            return {score:score, chemistry:applyPositionBoost && template.position == DEFENDER ? 0.3 : 0, fitness:100};
        }
        let fitnessPerc = calculatePlayerFitnessPercentage(player.full_fitness_time);
        score = (fitnessPerc/100)*score;
        return {score: score, chemistry: applyPositionBoost && template.position == DEFENDER ? 0.3 : 0, fitness: fitnessPerc};
    }
   
    // 10% reduction if out of position.. Get players actual score for their position.
    let scores = getPlayersScore(player, asset, template, false);
    scores.score = Math.round((90/100)*scores.score);
    return scores;
}

const getMidfielderValues = (player : Player | null, asset : IAsset, template : Template, applyPositionBoost = true) : {score: number, chemistry: number, fitness : number} => {
   
    if(asset.mutable_data.Position == MIDFIELDER){
        let passing = parseInt(asset.mutable_data.Passing);
        let dribbling = parseInt(asset.mutable_data.Dribbling);
        let physical = parseInt(asset.mutable_data.Physical);
        let pace = parseInt(asset.mutable_data.Pace);
        let technique = parseInt(asset.mutable_data.Technique);
        let creativity = parseInt(asset.mutable_data.Creativity);
        let score = passing+dribbling+physical+technique+pace+creativity;
        if(player == null){
            return {score:score, chemistry:applyPositionBoost && template.position == MIDFIELDER ? 0.3 : 0, fitness:100};
        }
        let fitnessPerc = calculatePlayerFitnessPercentage(player.full_fitness_time);
        score = (fitnessPerc/100)*score;
        return {score: score, chemistry: applyPositionBoost && template.position == MIDFIELDER ? 0.3 : 0, fitness: fitnessPerc};
    }
    // 10% reduction if out of position.. Get players actual score for their position.
    let scores = getPlayersScore(player, asset, template, false);
    scores.score = Math.round((90/100)*scores.score);
    return scores;
}

const getForwardValues = (player : Player | null, asset : IAsset, template : Template, applyPositionBoost = true) : {score: number, chemistry: number, fitness : number} => {
   
    if(asset.mutable_data.Position == FORWARD){
        let finishing = parseInt(asset.mutable_data.Finishing);
        let shooting = parseInt(asset.mutable_data.Shooting);
        let heading = parseInt(asset.mutable_data.Heading);
        let physical = parseInt(asset.mutable_data.Physical);
        let pace = parseInt(asset.mutable_data.Pace);
        let passing = parseInt(asset.mutable_data.Passing);
        let score = passing+shooting+physical+heading+pace+finishing;
        if(player == null){
            return {score:score, chemistry:applyPositionBoost && template.position == FORWARD ? 0.3 : 0, fitness:100};
        }
        let fitnessPerc = calculatePlayerFitnessPercentage(player.full_fitness_time);
        score = (fitnessPerc/100)*score;
        return {score: score, chemistry: applyPositionBoost && template.position == FORWARD ? 0.3 : 0, fitness: fitnessPerc};
    }
    // 10% reduction if out of position.. Get players actual score for their position.
    let scores = getPlayersScore(player, asset, template, false);
    scores.score = Math.round((90/100)*scores.score);
    return scores;
}

function getAllClubIndexes(array : Template[], club : string) {
    var indexes = [], i;
    for(i = 0; i < array.length; i++)
        if (array[i] != null && array[i].club === club)
            indexes.push(i);
    return indexes;
}

function getAllCountryIndexes(array : Template[], country : string) {
    var indexes = [], i;
    for(i = 0; i < array.length; i++)
        if (array[i] != null && array[i].country === country)
            indexes.push(i);
    return indexes;
}

function getAllIndexes(array : Template[], value : string) {
    var indexes = [], i;
    for(i = 0; i < array.length; i++)
        if (array[i] != null && array[i].special === value)
            indexes.push(i);
    return indexes;
}