import { ChallengeOpponent } from "../../types/ChallengeOpponent";
import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';


const initialState: InitialState<ChallengeOpponent> = {
    isFetching: true,
    error: undefined,
    data:{
        teamId:null
    }
};

export const challengeOpponent = (
    state = initialState,
    action: ReducerAction<ChallengeOpponent>
)=>{
    switch(action.type){
        case actionTypes.CHALLENGE_OPPONENT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}