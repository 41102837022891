import { Header, HeaderHandle } from "./components/Header";
import { NavBar } from "./components/NavBar";
import { BoostCards } from "./components/BoostCards";
import { MyPlayers } from "./components/MyPlayers";
import { LastFiveMatches } from "./components/LastFiveMatches";
import { LegendsLeagueSummary } from "./components/LegendsLeagueSummary";
import { MyTeams } from "./components/MyTeams";
import { DASHBOARD_ROUTE } from "../utils/constants";
import { TournamentBanner } from "./components/TournamentBanner";
import "driver.js/dist/driver.min.css";
import { useEffect, useRef, useState } from "react";
import { useTourHelp } from "../utils/helpTour";
import { AirgrabVinty } from "./components/AirgrabVinty";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";

const Dashboard = () => {
  const header = useRef<HeaderHandle>(null);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { startHelpTour } = useTourHelp();
  const [showAirGrab, setShowAirGrab] = useState(false);

  useEffect(() => {
    if (!userData.hasVintyAccount) {
      // display the air grab to get vinty
      setShowAirGrab(true);
    }
  }, []);

  const onHelp = (page: string) => {
    if (header.current) {
      header.current.closeMenu();
    }

    startHelpTour(page);
  };

  const onAirGrabbedClosed = () => {
    setShowAirGrab(false);
  };

  return (
    <>
      <AirgrabVinty
        showDialog={showAirGrab}
        onAirGrabbedClose={onAirGrabbedClosed}
      />
      <Header ref={header} />
      <div id="layoutSidenav">
        <NavBar onHelp={onHelp} selectedMenuItem={DASHBOARD_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid px-4 team-dash">
              <div className="container-fluid">
                <div className="row">
                  {/* <!---First Column - double width---> */}
                  <div className="col-md-7 col-lg-6">
                    {/* <!--tourno row---> */}
                    <div className="row">
                      <TournamentBanner />
                    </div>
                    {/* <!--League table row---> */}
                    <div className="row">
                      <LegendsLeagueSummary />
                    </div>
                  </div>
                  {/* <!---Second column---> */}
                  <div className="col-md-5 col-lg-3">
                    {/* <!--my Teams---> */}
                    <div className="row team-dash-r">
                      <MyTeams />
                    </div>
                  </div>
                  {/* <!--- Third Column---> */}
                  <div className="col-md-12 col-lg-3">
                    {/* <!--Last 5 results---> */}
                    <div className="row">
                      <LastFiveMatches />
                    </div>
                    {/* <!--My players---> */}
                    <div className="row">
                      <MyPlayers />
                    </div>

                    {/* <div className="row">
                      <BoostCards />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
