import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { VINTYNIFTYS_ACCOUNT } from "../../utils";
import { RootState } from "../../store/reducers";
import { fetchPlayers } from "../../store/actions/gameData";

type AddPlayerProps = {
  showDialog: boolean;
  asset: IAsset | null;
  onPlayerAdded(): void;
};

const START: string = "START";
const ERROR: string = "ERROR";
const SUCCESS: string = "SUCCESS";

export const AddPlayer: FC<AddPlayerProps> = ({
  showDialog,
  asset,
  onPlayerAdded,
}) => {
  const [status, setStatus] = useState(START);
  const ual = useUAL() as any;
  const dispatch = useDispatch<any>();
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [pollRefresh, setPollRefresh] = useState(false);

  useEffect(() => {
    if (!pollRefresh) {
      return;
    }
    if (gameData.players != null && asset) {
      if (parseInt(asset.asset_id) in gameData.players) {
        setPollRefresh(false);
        setStatus(SUCCESS);
      } else {
        setTimeout(() => {
          dispatch(fetchPlayers(userAssets));
        }, 500);
      }
    }
  }, [pollRefresh, gameData.players]);

  useEffect(() => {
    const addPlayer = async () => {
      try {
        const activeUser = ual["activeUser"];
        const result = await activeUser.signTransaction(
          {
            actions: [
              {
                account: VINTYNIFTYS_ACCOUNT,
                name: "addplayer",
                authorization: [
                  {
                    actor: activeUser["accountName"],
                    permission: activeUser.requestPermission,
                  },
                ],
                data: {
                  user: activeUser["accountName"],
                  assetId: parseInt(asset?.asset_id!),
                },
              },
            ],
          },
          {
            blocksBehind: 3,
            expireSeconds: 300,
          }
        );

        if (result.error != null) {
          setStatus(ERROR);
        } else {
          // refresh players
          dispatch(fetchPlayers(userAssets));
          setPollRefresh(true);
        }
      } catch (e: any) {}
    };

    if (showDialog && asset) {
      setStatus(START);
      addPlayer();
    }
  }, [showDialog]);

  const renderDisplay = () => {
    if (status == ERROR) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "60px" }} />
          <span style={{ fontSize: "14px", textAlign: "center" }}>
            Oh dear, something went wrong :(
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == SUCCESS) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "60px" }} />
          <span style={{ fontSize: "14px", textAlign: "center" }}>
            He's signed on the dotted line! Nice one
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            size="small"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
        <div style={{ marginTop: "20px" }}>Adding player...</div>
      </div>
    );
  };

  const onClose = () => {
    // if (status == "AIRGRAB_SUCCESS") {
    //   dispatch(fetchVintyBalance(userData.account));
    //   dispatch(checkVintyAccountExists(userData.account));
    // }
    onPlayerAdded();
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
          }}
        >
          {renderDisplay()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
