import { Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  airgrabVintyTokens,
  checkVintyAccountExists,
  fetchVintyBalance,
} from "../../store/actions/userSession";

type AirgrabVintyProps = {
  showDialog: boolean;
  onAirGrabbedClose(): void;
};

export const AirgrabVinty: FC<AirgrabVintyProps> = ({
  showDialog,
  onAirGrabbedClose: onAirGrabbed,
}) => {
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: airgrabResult } = useSelector(
    (state: RootState) => state.airgrabResult
  );

  const [status, setStatus] = useState("INITIAL");
  const ual = useUAL() as any;
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (showDialog) {
      setStatus("INITIAL");
    }
  }, [showDialog]);

  const getTokensClick = () => {
    setStatus("AIRGRAB_START");
    const activeUser = ual["activeUser"];
    dispatch(airgrabVintyTokens(activeUser));
  };

  useEffect(() => {
    if (airgrabResult.transaction_id != null) {
      setTimeout(() => {
        if (airgrabResult.transaction_id == "ERROR") {
          setStatus("AIRGRAB_ERROR");
          return;
        }
        setStatus("AIRGRAB_SUCCESS");
      }, 1500);
    }
  }, [airgrabResult]);

  const onClose = () => {
    if (status == "AIRGRAB_SUCCESS") {
      dispatch(fetchVintyBalance(userData.account));
      dispatch(checkVintyAccountExists(userData.account));
    }
    onAirGrabbed();
  };

  const renderDisplay = () => {
    if (status == "AIRGRAB_START") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            height: "400px",
            width: "230px",
          }}
        >
          <CircularProgress color="secondary" />
          <div style={{ marginTop: "20px" }}>Grabbin that Vinty...</div>
        </div>
      );
    }
    if (status == "AIRGRAB_SUCCESS") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            height: "400px",
            width: "230px",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "60px" }} />
          <span>Winning!</span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == "AIRGRAB_ERROR") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            height: "400px",
            width: "230px",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "60px" }} />
          <span style={{ textAlign: "center" }}>
            Summats up, get on the phone to VN!
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          height: "400px",
          width: "230px",
        }}
      >
        <div>
          <span style={{ color: "#99ff32", fontWeight: "bold" }}>VINTY</span>
          <span> has arrived!</span>
        </div>
        <div style={{}}>
          <img
            src="/images/VNTY-icon-red.svg"
            alt=""
            style={{ width: "100px" }}
          />
        </div>
        <span style={{ width: "200px", fontSize: "12px" }}>
          That's right Vintage Niftys very own in-game currency has been
          launched and is ready to go!
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "200px",
          }}
        >
          <span
            style={{ color: "#99ff32", fontWeight: "bold", width: "150px" }}
          >
            Earn
          </span>
          <span style={{ fontSize: "12px" }}>
            Play tournaments, stake, player quests, rewards
          </span>
        </div>
        <div
          style={{ display: "flex", width: "200px", flexDirection: "column" }}
        >
          <span
            style={{ color: "#99ff32", fontWeight: "bold", width: "150px" }}
          >
            Spend
          </span>
          <span style={{ fontSize: "12px" }}>
            Player upgrades, club upgrades, buildings, burger vans, all sorts of
            shiz
          </span>
        </div>
        <Button className="green-btn" onClick={() => getTokensClick()}>
          CLAIM 100 VNTY FOR FREE
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>{renderDisplay()}</DialogContent>
    </Dialog>
  );
};
