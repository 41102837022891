import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTeam,
  fetchTeams,
  updateTeam,
} from "../../store/actions/gameData";
import { RootState } from "../../store/reducers";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Team } from "../../types/Team";
import { setSaveTeamResult } from "../../store/actions/transactions";
import Button from "@mui/material/Button";

type SaveTeamProps = {
  showDialog: boolean;
  teamName: string;
  tacticId: number;
  lineup: number[];
  team: Team | null;
  onSave(): void;
};

export const SaveTeam: FC<SaveTeamProps> = ({
  showDialog,
  teamName,
  tacticId,
  lineup,
  team,
  onSave,
}) => {
  const dispatch = useDispatch<any>();
  const [message, setMessage] = useState("Saving team...");
  const [showLoading, setShowLoading] = useState(true);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: saveResult, error: saveError } = useSelector(
    (state: RootState) => state.saveTeamResult
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [error, setError] = useState(false);
  const ual = useUAL() as any;

  useEffect(() => {
    if (showDialog == true) {
      const activeUser = ual["activeUser"];

      if (team != null) {
        dispatch(
          updateTeam(
            userData,
            activeUser,
            userAssets,
            team.id,
            teamName,
            tacticId,
            lineup
          )
        );
      } else {
        dispatch(createTeam(userData, activeUser, teamName, tacticId, lineup));
      }
    }
  }, [showDialog]);

  const onClose = () => {
    onSave();
    setShowLoading(true);
    setError(false);
    setMessage("Saving team...");
    dispatch(setSaveTeamResult({ transaction_id: null }));

    // REVISIT
    //dispatch(fetchTeams(userData, userAssets));
  };

  useEffect(() => {
    if (saveResult.transaction_id != null) {
      setTimeout(() => {
        // simulate a lag so when we query the blockchain table the data will have been committed

        if (saveResult.transaction_id == "ERROR") {
          setShowLoading(false);
          setMessage("An error occurred, please contact VN!");
          setError(true);
          return;
        }
        setShowLoading(false);
        setMessage("Team saved, let's rock n' roll!");
      }, 1500);
    }
  }, [saveResult]);

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "280px",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            height: "250px",
            padding: "20px",
          }}
        >
          <Fade in={showLoading}>
            <div
              style={{
                flex: 1,
                display: showLoading ? "flex" : "none",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="secondary" />
              <div style={{ marginTop: "20px" }}>{message}</div>
            </div>
          </Fade>
          <Fade in={!showLoading}>
            <div
              style={{
                flex: 1,
                display: showLoading ? "none" : "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!error && (
                <DoneIcon color="secondary" sx={{ fontSize: "100px" }} />
              )}
              {error && <CloseIcon color="error" sx={{ fontSize: "100px" }} />}
              <div
                style={{
                  marginTop: "20px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {message}
                </span>
              </div>
              <div>
                <Button
                  className="green-btn"
                  size="small"
                  variant="outlined"
                  onClick={() => onClose()}
                >
                  OK
                </Button>
              </div>
            </div>
          </Fade>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
