import { FC } from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";
import Admin from "./pages/Admin";
import CardValues from "./pages/CardValues";
import Dashboard from "./pages/Dashboard";
import LeagueTable from "./pages/LeagueTable";
import Login from "./pages/Login";
import Marketplace from "./pages/Marketplace";
import MatchResult from "./pages/MatchResult";
import MyPlayers from "./pages/MyPlayers";
import MyTeams from "./pages/MyTeams";
import TeamSetup from "./pages/TeamSetup";
import TestTransaction from "./pages/TestTransaction";
import Tournaments from "./pages/Tournaments";
import {
  ADMIN_ROUTE,
  CARDS_ROUTE,
  DASHBOARD_ROUTE,
  LEAGUETABLE_ROUTE,
  MARKETPLACE_ROUTE,
  MYPLAYERS_ROUTE,
  MYTEAMS_ROUTE,
  PLAY_MATCH,
  TEAMSETUP_ROUTE,
  TEST_TRANSACTION,
  TOURNAMENTS_ROUTE,
} from "./utils/constants";

const Routes: FC = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<Login />} />
      <Route path={DASHBOARD_ROUTE} element={<Dashboard />} />
      <Route path={MYTEAMS_ROUTE} element={<MyTeams />} />
      <Route path={MARKETPLACE_ROUTE} element={<Marketplace />} />
      <Route path={TEAMSETUP_ROUTE} element={<TeamSetup />} />
      <Route path={MYPLAYERS_ROUTE} element={<MyPlayers />} />
      <Route path={ADMIN_ROUTE} element={<Admin />} />
      <Route path={LEAGUETABLE_ROUTE} element={<LeagueTable />} />
      <Route path={TOURNAMENTS_ROUTE} element={<Tournaments />} />
      <Route path={CARDS_ROUTE} element={<CardValues />} />
      <Route path={PLAY_MATCH} element={<MatchResult />} />
      <Route path={TEST_TRANSACTION} element={<TestTransaction />} />
    </RouterRoutes>
  );
};

export default Routes;
