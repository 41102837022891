import React, { useEffect } from "react";
import { MemoryRouter } from "react-router-dom";
import Routes from "./Routes";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { siteTheme } from "./utils/Theme";
import { UALProvider } from "ual-reactjs-renderer";
import { ualAuthenticators, ualChain } from "./utils/ualConfig";
import { APP_NAME } from "./utils/constants";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "./css/bootstyles.css";
import "./css/style.css";
import "./css/style_bottom_nav.css";

const store = configureStore();

function App() {
  useEffect(() => {
    document.body.classList.add("sb-nav-fixed");
  }, []);

  return (
    <UALProvider
      chains={[ualChain]}
      authenticators={ualAuthenticators}
      appName={APP_NAME}
    >
      <ThemeProvider theme={siteTheme}>
        {/* <CssBaseline enableColorScheme /> */}
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MemoryRouter>
              <Routes />
            </MemoryRouter>
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </UALProvider>
  );
}

export default App;
