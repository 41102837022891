import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useUAL } from "../../utils/useUAL";
import { RootState } from "../../store/reducers";
import { setEnterTournoResult } from "../../store/actions/transactions";
import {
  enterTourno,
  fetchTournoPositions,
} from "../../store/actions/gameData";

type EnterTournoProps = {
  showDialog: boolean;
  teamId: number;
  onEnterTourno(): void;
};

const CHECKTEAM: string = "CHECKTEAM";
const TEAMERROR: string = "TEAMERROR";
const STAKEDASSETS: string = "STAKEDASSETS";
const SUCCESS: string = "SUCCESS";
const ERROR: string = "ERROR";

export const EnterTourno: FC<EnterTournoProps> = ({
  showDialog,
  teamId,
  onEnterTourno,
}) => {
  const dispatch = useDispatch<any>();
  const ual = useUAL() as any;

  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: enterTournoResult, error: enterError } = useSelector(
    (state: RootState) => state.enterTournoResult
  );
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [status, setStatus] = useState(CHECKTEAM);

  useEffect(() => {
    if (showDialog == true) {
      setStatus(CHECKTEAM);
      if (gameData.userTeams && userAssets.assets) {
        let team = gameData.userTeams[teamId];
        if (team) {
          // if asset cannot be found then it's missing or staked, can't join touno.
          for (let i = 0; i < team.lineup.length; i++) {
            let asset = userAssets.assets[team.lineup[i]];
            if (!asset) {
              if (userAssets.stakedAssets) {
                if (team.lineup[i] in userAssets.stakedAssets) {
                  setStatus(STAKEDASSETS);
                  return;
                }
              }
              setStatus(TEAMERROR);
              return;
            }
          }
          // lineup good,
          const activeUser = ual["activeUser"];

          dispatch(enterTourno(activeUser, userData, teamId));
        }
      }
      //setStatus(SUCCESS);
    }
  }, [showDialog]);

  useEffect(() => {
    if (enterTournoResult.transaction_id != null) {
      setTimeout(() => {
        // simulate a lag so when we query the blockchain table the data will have been committed - FINGERS CROSSED!

        if (enterTournoResult.transaction_id == "ERROR") {
          setStatus(ERROR);
          //setShowLoading(false);
          //setMessage("An error occurred, please contact VN!");
          // setError(true);
          return;
        }
        //setShowLoading(false);
        //setMessage("Transaction Id: ");
        setStatus(SUCCESS);
      }, 1500);
    }
  }, [enterTournoResult]);

  const onClose = () => {
    onEnterTourno();

    dispatch(setEnterTournoResult({ transaction_id: null }));

    // REVISIT
    dispatch(fetchTournoPositions());
  };

  const renderDisplay = () => {
    if (status == CHECKTEAM) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" />
          <div style={{ marginTop: "20px" }}>Checking team lineup...</div>
        </div>
      );
    }
    if (status == TEAMERROR) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center" }}>
            The team lineup cannot be resolved.
          </span>
          <Button
            size="small"
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == STAKEDASSETS) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center" }}>
            You can't enter a tourno with staked players.
          </span>
          <Button
            size="small"
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == ERROR) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center" }}>
            Opps an error occurred send a fax to VN.
          </span>
          <Button
            size="small"
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == SUCCESS) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "100px" }} />
          <span style={{ textAlign: "center" }}>All good, you're in!</span>
          <Button
            size="small"
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "250px",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            height: "250px",
          }}
        >
          {renderDisplay()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
