import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { VINTYNIFTYS_ACCOUNT } from "../../utils";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { fetchUserAssets } from "../../store/actions/userAssets";
import { fetchPlayers } from "../../store/actions/gameData";
import { Player } from "../../types/Player";
import { fetchVintyBalance } from "../../store/actions/userSession";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";

type ClaimStakeProps = {
  showDialog: boolean;
  onClaim(): void;
};

const START: string = "START";
const ERROR: string = "ERROR";
const SUCCESS: string = "SUCCESS";
const LOADINGASSETS: string = "LOADINGASSETS";
const LOADINGPLAYERS: string = "LOADINGPLAYERS";

export const ClaimAll: FC<ClaimStakeProps> = ({ showDialog, onClaim }) => {
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets, isFetching: isFetchingAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: gameData, isFetching: isFetchingPlayers } = useSelector(
    (state: RootState) => state.gameData
  );
  const { data: schemaInfo } = useSelector(
    (state: RootState) => state.schemaInfo
  );

  const [status, setStatus] = useState(START);
  const ual = useUAL() as any;
  const dispatch = useDispatch<any>();
  const [claimAmount, setClaimAmount] = useState(0);

  useEffect(() => {
    if (status != LOADINGASSETS) {
      return;
    }
    if (!isFetchingAssets) {
      console.log("Now fetching players");
      setStatus(LOADINGPLAYERS);
      dispatch(fetchPlayers(userAssets));
    }
  }, [isFetchingAssets]);

  useEffect(() => {
    if (status != LOADINGPLAYERS) {
      return;
    }
    if (!isFetchingPlayers) {
      dispatch(fetchVintyBalance(userData.account));
      console.log("we're done");
      setStatus(SUCCESS);
    }
  }, [isFetchingPlayers]);

  useEffect(() => {
    const claimAll = async (assets: number[]) => {
      try {
        const activeUser = ual["activeUser"];
        const result = await activeUser.signTransaction(
          {
            actions: [
              {
                account: VINTYNIFTYS_ACCOUNT,
                name: "claimall",
                authorization: [
                  {
                    actor: activeUser["accountName"],
                    permission: activeUser.requestPermission,
                  },
                ],
                data: {
                  owner: activeUser["accountName"],
                  assetIds: assets,
                },
              },
            ],
          },
          {
            blocksBehind: 3,
            expireSeconds: 300,
          }
        );

        if (result.error != null) {
          setStatus(ERROR);
        } else {
          // refresh assets and players and vinty balance

          setStatus(LOADINGASSETS);
          dispatch(fetchUserAssets(userData.account, schemaInfo));
        }
      } catch (e: any) {}
    };

    if (showDialog) {
      setStatus(START);

      if (
        gameData.players != null &&
        userAssets.stakedAssets != null &&
        gameData.cardConfig != null
      ) {
        let amount = 0;
        let assets: number[] = [];

        Object.entries(gameData.players).forEach(([key, value]) => {
          if (value.staked && value.nextClaim != null && value.nextClaim < 0) {
            let asset = userAssets.stakedAssets![value.asset_id];
            if (asset) {
              assets.push(parseInt(asset.asset_id));
              let cardConfig =
                gameData.cardConfig![parseInt(asset.template?.template_id!)];
              if (cardConfig) {
                amount += cardConfig.reward;
              }
            }
          }
        });
        setClaimAmount(amount);
        console.log(assets);
        claimAll(assets);
      }
    }
  }, [showDialog]);

  const onClose = () => {
    onClaim();
  };

  const renderDisplay = () => {
    if (status == ERROR) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon color="error" sx={{ fontSize: "60px" }} />
          <span style={{ fontSize: "14px", textAlign: "center" }}>
            Oh dear, something went wrong :(
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    if (status == SUCCESS) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoneIcon color="secondary" sx={{ fontSize: "60px" }} />
          <span style={{ fontSize: "14px", textAlign: "center" }}>
            All {claimAmount} claimed! Back of the net!
          </span>
          <Button
            className="green-btn"
            variant="outlined"
            size="small"
            onClick={() => onClose()}
          >
            OK
          </Button>
        </div>
      );
    }
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
        <div style={{ marginTop: "20px" }}>Claiming that vinty...</div>
      </div>
    );
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
          }}
        >
          {renderDisplay()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
