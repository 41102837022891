import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { closeTournament } from "../../store/actions/config";
import { Tournament } from "../../types/Tournament";
import { useUAL } from "../../utils/useUAL";

interface CloseTournamentProps {
  showDialog: boolean;
  tournament?: Tournament | null;
  onTournoClosed(): void;
}

export const CloseTourno: FC<CloseTournamentProps> = ({
  showDialog,
  tournament,
  onTournoClosed,
}) => {
  const [winningTeamId, setWinningTeamId] = useState("");
  const [manager, setManager] = useState("");
  const teamField = useRef<HTMLInputElement>();
  const managerField = useRef<HTMLInputElement>();
  const ual = useUAL() as any;
  const dispatch = useDispatch<any>();

  const onClose = () => {
    onTournoClosed();
  };

  const closeTourno = () => {
    const activeUser = ual["activeUser"];
    if (tournament != null) {
      dispatch(
        closeTournament(
          activeUser,
          tournament.id,
          parseInt(teamField.current?.value!),
          managerField.current?.value!
        )
      );
      onTournoClosed();
    }
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div>
            <TextField
              inputRef={teamField}
              value={winningTeamId}
              onChange={(e) => setWinningTeamId(e.target.value)}
              size="small"
              label="Winning Team Id"
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              inputRef={managerField}
              value={manager}
              onChange={(e) => setManager(e.target.value)}
              size="small"
              label="Manager"
              variant="outlined"
            />
          </div>
          <div>
            <Button
              size="small"
              variant="contained"
              onClick={() => closeTourno()}
            >
              Close Tourno
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
