import { Config } from "../../types/Config";
import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';


const initialState: InitialState<Config> = {
    isFetching: true,
    error: undefined,
    data:{
        appLocked:false,
        matchesLocked:false,
        maxTeams:1
    }
};

export const appConfig = (
    state = initialState,
    action: ReducerAction<Config>
)=>{
    switch(action.type){
        case actionTypes.REQUEST_CONFIG:
            return{
                ...state,
                error: undefined,
                isFetching: true
            };
        case actionTypes.RECEIVE_CONFIG:
            return{
                ...state,
                data: action.payload.data,
                isFetching: false,
                error: undefined
            }
        case actionTypes.UPDATE_LOCKED_STATUS:
            return{
                ...state,
                data: {
                    ...state.data,
                    appLocked: action.payload.data.appLocked
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.UPDATE_MATCHES_LOCKED_STATUS:
            return{
                ...state,
                data: {
                    ...state.data,
                    matchesLocked: action.payload.data.matchesLocked
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.UPDATE_MAX_TEAMS:
            return{
                ...state,
                data: {
                    ...state.data,
                    maxTeams: action.payload.data.maxTeams
                },
                isFetching: false,
                error: undefined
            }
        case actionTypes.ERROR_CONFIG:
            return{
                ...state,
                error:action.error,
                isFetching:false,
            }
        default:
            return state;
    }
};