import { Button } from "@mui/material";
import { CARDS_ROUTE } from "../utils/constants";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";

const CardValues = () => {
  const buyEnforcers = () => {
    window.open("https://neftyblocks.com/c/vintagenftys/drops/95614", "_blank");
  };

  const buyEnforcersAttributes = () => {
    window.open("https://neftyblocks.com/c/vintagenftys/drops/95613", "_blank");
  };

  const buySeries1FT = () => {
    window.open(
      "https://wax.atomichub.io/market?collection_name=vintagenftys&order=asc&sort=price&template_id=272295",
      "_blank"
    );
  };

  const buySeries1HT = () => {
    window.open(
      "https://wax.atomichub.io/market?collection_name=vintagenftys&order=asc&sort=price&template_id=272296",
      "_blank"
    );
  };

  const buySeries2FT = () => {
    window.open(
      "https://neftyblocks.com/collection/vintagenftys/drops/146781",
      "_blank"
    );
  };

  const buySeries2HT = () => {
    window.open(
      "https://neftyblocks.com/collection/vintagenftys/drops/146780",
      "_blank"
    );
  };

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={CARDS_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div>
                <div
                  className="fl-main"
                  style={{
                    gap: "30px",
                    width: "100%",
                    marginTop: "20px",
                  }}
                ></div>

                <div
                  className="fl-main"
                  style={{
                    gap: "30px",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="flex-grid fl-col"
                    style={{
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Series 2 Packs
                        <Button className="blue-btn" variant="text">
                          Learn more
                        </Button>
                      </h2>

                      <div
                        className="flex-grid fl-col"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="emptydiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          <p>Card Weighting</p>
                          <table className="score-table" width="100%">
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "left" }} scope="col">
                                  RARITY
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  VALUE
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  STAKING<br></br>REWARDS
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  FITNESS<br></br>REDUCTION
                                </th>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Common</td>
                                <td style={{ textAlign: "center" }}>250</td>
                                <td style={{ textAlign: "center" }}>10</td>
                                <td style={{ textAlign: "center" }}>25%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Limited</td>
                                <td style={{ textAlign: "center" }}>750</td>
                                <td style={{ textAlign: "center" }}>15</td>
                                <td style={{ textAlign: "center" }}>20.8%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Rare</td>
                                <td style={{ textAlign: "center" }}>1000</td>
                                <td style={{ textAlign: "center" }}>20</td>
                                <td style={{ textAlign: "center" }}>15%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Epic</td>
                                <td style={{ textAlign: "center" }}>1500</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>5.8%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Legendary</td>
                                <td style={{ textAlign: "center" }}>3000</td>
                                <td style={{ textAlign: "center" }}>50</td>
                                <td style={{ textAlign: "center" }}>2.5%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="fl-row">
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{ marginBottom: "10px" }}
                              src={
                                "/images/Vintage-Niftys-Series2-full-time.png"
                              }
                              width="184"
                              height="300"
                              alt="Vintage Niftys Series 2 Full-time pack"
                            />
                            <Button
                              variant="contained"
                              className="green-btn"
                              onClick={buySeries2FT}
                            >
                              Buy Now
                            </Button>
                          </div>
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{ marginBottom: "10px" }}
                              src={
                                "/images/Vintage-Niftys-Series2-half-time.png"
                              }
                              width="184"
                              height="300"
                              alt="Vintage Niftys Series 2 Half-time pack"
                            />
                            <Button
                              variant="contained"
                              className="green-btn"
                              onClick={buySeries2HT}
                            >
                              Buy Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-grid fl-col"
                    style={{
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Enforcers Packs
                        <Button variant="text" className="blue-btn">
                          Learn more
                        </Button>
                      </h2>
                      <div
                        className="flex-grid fl-col"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="emptydiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          <p>Card Weighting</p>
                          <table className="score-table" width="100%">
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "left" }} scope="col">
                                  RARITY
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  VALUE
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  STAKING<br></br>REWARDS
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  FITNESS<br></br>REDUCTION
                                </th>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Base</td>
                                <td style={{ textAlign: "center" }}>100</td>
                                <td style={{ textAlign: "center" }}>5</td>
                                <td style={{ textAlign: "center" }}>29.1</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  Light Bruising
                                </td>
                                <td style={{ textAlign: "center" }}>200</td>
                                <td style={{ textAlign: "center" }}>7</td>
                                <td style={{ textAlign: "center" }}>25%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  Yellow Card
                                </td>
                                <td style={{ textAlign: "center" }}>300</td>
                                <td style={{ textAlign: "center" }}>10</td>
                                <td style={{ textAlign: "center" }}>20.8%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  The Terry Butcher
                                </td>
                                <td style={{ textAlign: "center" }}>500</td>
                                <td style={{ textAlign: "center" }}>15</td>
                                <td style={{ textAlign: "center" }}>16.6</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Red Card</td>
                                <td style={{ textAlign: "center" }}>1000</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>13.3%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Locked Up</td>
                                <td style={{ textAlign: "center" }}>3000</td>
                                <td style={{ textAlign: "center" }}>150</td>
                                <td style={{ textAlign: "center" }}>10%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Halo</td>
                                <td style={{ textAlign: "center" }}>800</td>
                                <td style={{ textAlign: "center" }}>50</td>
                                <td style={{ textAlign: "center" }}>8.3%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>MOTM</td>
                                <td style={{ textAlign: "center" }}>1500</td>
                                <td style={{ textAlign: "center" }}>50</td>
                                <td style={{ textAlign: "center" }}>6.6%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Red Mist</td>
                                <td style={{ textAlign: "center" }}>2000</td>
                                <td style={{ textAlign: "center" }}>75</td>
                                <td style={{ textAlign: "center" }}>5%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="fl-row">
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{ marginBottom: "10px" }}
                              src={"/images/The-Enforcers-main-pack.png"}
                              width="184"
                              height="300"
                              alt="The Enforcers Main Pack"
                            />
                            <Button
                              onClick={buyEnforcers}
                              variant="contained"
                              className="green-btn"
                            >
                              Buy Now
                            </Button>
                          </div>
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{ marginBottom: "10px" }}
                              src={"/images/The-Enforcers-booster-pack-new.png"}
                              width="184"
                              height="300"
                              alt="The Enforcers Boost Pack"
                            />
                            <Button
                              onClick={buyEnforcersAttributes}
                              variant="contained"
                              className="green-btn"
                            >
                              Buy Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-grid fl-col"
                    style={{
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Series 1 Packs
                        <Button variant="text" className="blue-btn">
                          Learn more
                        </Button>
                      </h2>

                      <div
                        className="flex-grid fl-col"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="emptydiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          <p>Card Weighting</p>
                          <table className="score-table" width="100%">
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "left" }} scope="col">
                                  RARITY
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  VALUE
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  STAKING<br></br>REWARDS
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  FITNESS<br></br>REDUCTION
                                </th>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Common</td>
                                <td style={{ textAlign: "center" }}>10</td>
                                <td style={{ textAlign: "center" }}>2</td>
                                <td style={{ textAlign: "center" }}>33.3%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Uncommon</td>
                                <td style={{ textAlign: "center" }}>20</td>
                                <td style={{ textAlign: "center" }}>4</td>
                                <td style={{ textAlign: "center" }}>29.1%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Limited</td>
                                <td style={{ textAlign: "center" }}>40</td>
                                <td style={{ textAlign: "center" }}>6</td>
                                <td style={{ textAlign: "center" }}>25%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Relic</td>
                                <td style={{ textAlign: "center" }}>60</td>
                                <td style={{ textAlign: "center" }}>8</td>
                                <td style={{ textAlign: "center" }}>20.8%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Rare</td>
                                <td style={{ textAlign: "center" }}>100</td>
                                <td style={{ textAlign: "center" }}>10</td>
                                <td style={{ textAlign: "center" }}>16.6%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Epic</td>
                                <td style={{ textAlign: "center" }}>250</td>
                                <td style={{ textAlign: "center" }}>15</td>
                                <td style={{ textAlign: "center" }}>13.3%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Legendary</td>
                                <td style={{ textAlign: "center" }}>500</td>
                                <td style={{ textAlign: "center" }}>20</td>
                                <td style={{ textAlign: "center" }}>10%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Mythical</td>
                                <td style={{ textAlign: "center" }}>1000</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>6.6%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Celestial</td>
                                <td style={{ textAlign: "center" }}>5000</td>
                                <td style={{ textAlign: "center" }}>125</td>
                                <td style={{ textAlign: "center" }}>3.3%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Transfer</td>
                                <td style={{ textAlign: "center" }}>1000</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>16.6%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Manager</td>
                                <td style={{ textAlign: "center" }}>1000</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>3.3%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="fl-row">
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{ marginBottom: "10px" }}
                              src={
                                "/images/Vintage-Niftys-Series1-full-time-package.png"
                              }
                              width="184"
                              height="300"
                              alt="Vintage Niftys Series 1 Full-time pack"
                            />
                            <Button
                              onClick={buySeries1FT}
                              variant="contained"
                              className="green-btn"
                            >
                              Find on Atomic Hub
                            </Button>
                          </div>
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{ marginBottom: "10px" }}
                              src={
                                "/images/Vintage-Niftys-Series1-half-time-package.png"
                              }
                              width="184"
                              height="300"
                              alt="Vintage Niftys Series 1 Half-time pack"
                            />
                            <Button
                              onClick={buySeries1HT}
                              variant="contained"
                              className="green-btn"
                            >
                              Find on Atomic Hub
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="fl-main"
                  style={{
                    gap: "30px",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="flex-grid fl-col"
                    style={{
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        World Cup Legends
                        <Button variant="text" className="blue-btn">
                          Learn more
                        </Button>
                      </h2>
                      <div
                        className="flex-grid fl-col"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="emptydiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "0px",
                            border: "none",
                            justifyContent: "start",
                          }}
                        >
                          <p>Card Weighting</p>
                          <table className="score-table" width="100%">
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "left" }} scope="col">
                                  RARITY
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  VALUE
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  STAKING<br></br>REWARDS
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  FITNESS<br></br>REDUCTION
                                </th>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Common</td>
                                <td style={{ textAlign: "left" }}>750</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>16.6%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Rare</td>
                                <td style={{ textAlign: "left" }}>1000</td>
                                <td style={{ textAlign: "center" }}>50</td>
                                <td style={{ textAlign: "center" }}>13.3%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="fl-row">
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              gap: "10px",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                              }}
                              src={"/images/WCLegends/Common/1A.jpg"}
                              alt="World Cup Legends Cards"
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/623743",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                              }}
                              src={"/images/WCLegends/Common/3A.jpg"}
                              alt="World Cup Legends Cards"
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/640227",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                              }}
                              src={"/images/WCLegends/Common/4A.jpg"}
                              alt="World Cup Legends Cards"
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/644567",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                              }}
                              src={"/images/WCLegends/Common/5A.jpg"}
                              alt="World Cup Legends Cards"
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/644570",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                              }}
                              src={"/images/WCLegends/Rare/2A.png"}
                              alt="World Cup Legends Cards"
                              onClick={() => {
                                window.open(
                                  "https://neftyblocks.com/collection/vintagenftys/drops/145947",
                                  "_blank"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-grid fl-col"
                    style={{
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        e-motion Cards
                        <Button variant="text" className="blue-btn">
                          Learn more
                        </Button>
                      </h2>

                      <div
                        className="flex-grid fl-col"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="emptydiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "0px",
                            border: "none",
                            justifyContent: "start",
                          }}
                        >
                          <p>Card Weighting</p>
                          <table className="score-table" width="100%">
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "left" }} scope="col">
                                  RARITY
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  VALUE
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  STAKING<br></br>REWARDS
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  FITNESS<br></br>REDUCTION
                                </th>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Base</td>
                                <td style={{ textAlign: "left" }}>1500</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>10%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="fl-row">
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              padding: "0px",
                              border: "none",
                              gap: "10px",
                            }}
                          >
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Emotion/antonio.jpg"}
                              width="184"
                              height="300"
                              alt="e-Motion Cards"
                              onClick={() => {
                                window.open(
                                  "https://neftyblocks.com/collection/vintagenftys/drops/107178",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Emotion/bert.jpg"}
                              width="184"
                              height="300"
                              alt="e-Motion Cards"
                              onClick={() => {
                                window.open(
                                  "https://neftyblocks.com/collection/vintagenftys/drops/101470",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Emotion/bobby.jpg"}
                              width="184"
                              height="300"
                              alt="e-Motion Cards"
                              onClick={() => {
                                window.open(
                                  "https://neftyblocks.com/collection/vintagenftys/drops/111829",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Emotion/dixie.jpg"}
                              width="184"
                              height="300"
                              alt="e-Motion Cards"
                              onClick={() => {
                                window.open(
                                  "https://neftyblocks.com/collection/vintagenftys/drops/109890",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Emotion/gordon.jpg"}
                              width="184"
                              height="300"
                              alt="e-Motion Cards"
                              onClick={() => {
                                window.open(
                                  "https://neftyblocks.com/collection/vintagenftys/drops/114812",
                                  "_blank"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex-grid fl-col"
                    style={{
                      gap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Promo / Special
                        <Button variant="text" className="blue-btn">
                          Learn more
                        </Button>
                      </h2>

                      <div
                        className="flex-grid fl-col"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="emptydiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: "0px",
                            border: "none",
                            justifyContent: "start",
                          }}
                        >
                          <p>Card Weighting</p>
                          <table className="score-table" width="100%">
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "left" }} scope="col">
                                  RARITY
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  VALUE
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  STAKING<br></br>REWARDS
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  FITNESS<br></br>REDUCTION
                                </th>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>Base</td>
                                <td style={{ textAlign: "left" }}>500</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>20.8%</td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  Ghoulkeepers
                                </td>
                                <td style={{ textAlign: "left" }}>500</td>
                                <td style={{ textAlign: "center" }}>25</td>
                                <td style={{ textAlign: "center" }}>5%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="fl-row">
                          <div
                            className="emptydiv"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: "10px",
                              flexWrap: "wrap",
                              padding: "0px",
                              border: "none",
                            }}
                          >
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/PromoPlayers/7a-Platinum.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/313956",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/PromoPlayers/Billy-Liddell.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/317356",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/PromoPlayers/Jimmy-Cantrell.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/327729",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/PromoPlayers/tom-finney-stamp.png"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/607019",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Specials/bony.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/615207",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Specials/dino.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/615205",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Specials/gorgon.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/615194",
                                  "_blank"
                                );
                              }}
                            />
                            <img
                              style={{
                                marginBottom: "10px",
                                width: "44px",
                                height: "65px",
                                cursor: "pointer",
                              }}
                              src={"/images/Specials/lev.jpg"}
                              onClick={() => {
                                window.open(
                                  "https://wax.atomichub.io/explorer/template/wax-mainnet/vintagenftys/615208",
                                  "_blank"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default CardValues;
