import { Team } from '../../types/Team';
import { VINTYNIFTYS_ACCOUNT, VINTYSTAKES_ACCOUNT } from '../../utils/constants';
import * as actionTypes from './types';
import { AppThunk } from '../configureStore';
import { GameData } from '../../types/GameData';
import { Tactic } from '../../types/Tactic';
import { Player } from '../../types/Player';
import { Match } from '../../types/Match';
import { User } from '../../types/User';
import { setDeleteTeamResult, setEnterTournoResult, setSaveTeamResult, setStartMatchResult } from './transactions';
import { UserAssets } from '../../types/UserAssets';
import { Tournament } from '../../types/Tournament';
import { TournamentPosition } from '../../types/TournamentPosition';
import { getPlayersScore } from '../../utils/calculateTeamTotal';
import { Templates } from '../../inventory';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { CardConfig } from '../../types/CardConfig';

/* USERS */
export const requestAllUsers = () => {
    return{
        type: actionTypes.REQUEST_ALL_USERS,
        error: undefined
    };
};

export const receiveAllUsers = (data: GameData) => {
    return{
        type: actionTypes.RECEIVE_ALL_USERS,
        payload:{
            data: data
        },
        error: undefined
    };
};

export const erroreAllUsers = (error: any) => {
    return{
        type: actionTypes.ERROR_ALL_USERS,
        error: error
    };
};

export const fetchAllUsers = () : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestAllUsers());
    try{
        const responseRowCount = await api.waxRpc.get_table_by_scope({
            // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            table: 'users',        // Table name 
        });
        let users : User[] = [];
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'users',        // Table name 
            limit: responseRowCount.rows[0].count
           
        });
        if(response.rows.length > 0){
            response.rows.forEach(u => {
                let user : User = {
                    id: u.user,
                    account : u.user,
                    userName : u.username,
                    club : u.clubname,
                    ground : u.ground
                }
                users.push(user);
            });
        }
        console.log("Loaded " + users.length + " users");
        dispatch(receiveAllUsers({users:users}));
    }
    catch(e:any){
        dispatch(erroreAllUsers(e));
    }
}

/* CARDS CONFIG */

export const requestCardsConfig = () => {
    return{
        type: actionTypes.REQUEST_CARDS_CONFIG,
        error: undefined
    };
};

export const receiveCardsConfig = (data: GameData) => {
    return{
        type: actionTypes.RECEIVE_CARDS_CONFIG,
        payload:{
            data: data
        },
        error: undefined
    };
};

export const erroreCardsConfig = (error: any) => {
    return{
        type: actionTypes.ERROR_CARDS_CONFIG,
        error: error
    };
};

export const fetchCardsConfig = () : AppThunk => async(dispatch, _, api) => {
    dispatch(requestCardsConfig());
    try{
        const responseRowCount = await api.waxRpc.get_table_by_scope({
            // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            table: 'cardsconfig',        // Table name 
        });

        const rowCount = responseRowCount.rows[0].count;

        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'cardsconfig',        // Table name 
            limit: rowCount
        });
        const cardsConfig : {[id : number] : CardConfig}={};
        if(response.rows.length > 0){
            response.rows.forEach(c => {
                let card : CardConfig={
                    templateId: c.template_id,
                    reward: c.reward,
                    baseTav: c.base_tav,
                    fitnessMins: c.fitness_mins,
                    cooldown: c.cooldown,
                };
                cardsConfig[c.template_id] = card;
            });
        }
        console.log(`Loaded ${Object.keys(cardsConfig).length} card configs`);
        dispatch(receiveCardsConfig({cardConfig: cardsConfig}));
        
    } catch(e:any){
        dispatch(erroreCardsConfig(e));
    }
}


/* USER TEAMS */

/*
    Load all teams (users and opponents)
    Load all users players (load full table and then cross check the users assets)
*/
export const fetchTeams = (user: User, userAssets : UserAssets): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestUserTeams());
    try{
        /*
            Get row count of players and then use that for the limit on the call to get table rows - MAY NEED TO REVISIT this
        */
        const responseRowCount = await api.waxRpc.get_table_by_scope({
            // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            table: 'teams',        // Table name 
        });

        const rowCount = responseRowCount.rows[0].count;

        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'teams',        // Table name 
            limit: rowCount
            //key_type: 'name',
            //index_position: 2,
            //lower_bound: account, // specify both lower and upper
            //upper_bound: account,
            //reverse: true
        });
        let otherTeams: {[id: number] : Team} = {};
        let userTeams: {[id: number] : Team} = {};
        let players :{[id: number] : Player  | null} ={};
        if(response.rows.length > 0){
            
            response.rows.forEach(t => {
                let team: Team={
                    id: t.team_id,
                    teamId : t.team_id,
                    manager: t.manager,
                    name: t.name,
                    tacticId: t.tactic_id,
                    win: t.win,
                    lose: t.lose,
                    draw: t.draw,
                    points: t.points,
                    locked: t.locked,
                    lineup: t.lineup,
                    tav_id: t.team_id
                };
                if(team.manager == user.userName){
                    userTeams[team.id] = team;
                    // add player ids, player is null for the time being, will be loaded after. 
                    // This is used in the PLayerSearch screen.
                    for(let i = 0;i < t.lineup.length;i++){
                        let assetId = t.lineup[i];
                        if(assetId != 0){
                            players[assetId] = null;
                        }
                    }
                    //console.log(team.name);
                }
                else{
                    otherTeams[team.id] = team;
                }
            });
        }
        console.log("Loaded " + Object.keys(userTeams).length + " user teams");
        console.log("Loaded " + Object.keys(otherTeams).length + " opponent teams");
        
        dispatch(receiveUserTeams({userTeams: userTeams, opponentTeams:otherTeams, tactics: undefined, players: undefined, matches: undefined, teamPlayers:players}));
        
        // now fetch players for all users assets
        dispatch(fetchPlayers(userAssets));
    }
    catch(e){
        dispatch(errorUserTeams(e));
    }
};

export const requestUserTeams = () => {
    return{
        type: actionTypes.REQUEST_USER_TEAMS,
        error: undefined
    };
};

export const receiveUserTeams = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_USER_TEAMS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorUserTeams = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER_TEAMS,
        error: error
    };
};

export const createTeam = (user : User, activeUser: any, teamName : string, tacticId : number, lineup : number[]) : AppThunk => async(dispatch, _, api) =>{
    try{
        console.log('Creating team ' + teamName);
        const result = await activeUser.signTransaction(
            {
                actions: [{
                    account: VINTYNIFTYS_ACCOUNT,
                    name:  'addteam2',
                    authorization: [{
                        actor: activeUser['accountName'],
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        user: activeUser['accountName'],
                        username: user.userName,
                        teamname: teamName,
                        tactic_id: tacticId,
                        lineup : lineup
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30,
            });
        
        if(result.error != null){
            console.log('Team ' + teamName + ' could not be created. Error ' + result.error);
            dispatch(setSaveTeamResult({transaction_id: "ERROR"}));
        }
        else{
            console.log('Team ' + teamName + ' created. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setSaveTeamResult({transaction_id: result.transaction.transaction_id}));
            // re-fetch all teams
            // Sometimes the transaction has yet to be completed on the blockchain when we fetch teams immediately after 
            // so intead of refetching all, we add the team to the state dictionary and trigger a refetch later??
            // Similarly for players we add them to the end of the teamPlayers 
        }
    }
    catch(e){
        console.log('Team ' + teamName + ' could not be created. Exception ' + e);
        dispatch(setSaveTeamResult({transaction_id: "ERROR"}));
    }
}

export const updateTeam = (user : User, activeUser: any, userAssets : UserAssets, teamId: number, teamName : string, tacticId : number, lineup : number[]) : AppThunk => async(dispatch, _, api) =>{
    try{
        console.log('Updating team ' + teamName);
        const result = await activeUser.signTransaction(
            {
                actions: [{
                    account: VINTYNIFTYS_ACCOUNT,
                    name:  'updateteam',
                    authorization: [{
                        actor: activeUser['accountName'],
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        user: activeUser['accountName'],
                        username: user.userName,
                        team_id: teamId,
                        teamname: teamName,
                        tactic_id: tacticId,
                        lineup : lineup
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30,
            });
        
        if(result.error != null){
            console.log('Team ' + teamName + ' could not be updated. Error ' + result.error);
            dispatch(setSaveTeamResult({transaction_id: "ERROR"}));
        }
        else{
            
            console.log('Team ' + teamName + ' updated. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setSaveTeamResult({transaction_id: result.transaction.transaction_id}));
            // re-fetch all teams + players
            //dispatch(fetchTeams(user, userAssets));
        }
    }
    catch(e){
        console.log('Team ' + teamName + ' could not be updated. Exception ' + e);
        dispatch(setSaveTeamResult({transaction_id: "ERROR"}));
    }
}

export const deleteTeam = (user : User, activeUser: any, teamId: number) : AppThunk => async(dispatch, _, api) =>{
    try{
        console.log('Deleting team ' + teamId);
        const result = await activeUser.signTransaction(
            {
                actions: [{
                    account: VINTYNIFTYS_ACCOUNT,
                    name:  'deleteteam',
                    authorization: [{
                        actor: activeUser['accountName'],
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        user: activeUser['accountName'],
                        username: user.userName,
                        team_id: teamId
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30,
            });
        
        if(result.error != null){
            console.log('Team ' + teamId + ' could not be deleted. Error ' + result.error);
            dispatch(setDeleteTeamResult({transaction_id: "ERROR"}));
        }
        else{
            console.log(result);
            console.log('Team ' + teamId + ' deleted. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setDeleteTeamResult({transaction_id: result.transaction.transaction_id}));
        }
    }
    catch(e){
        console.log('Team ' + teamId + ' could not be deleeted. Exception ' + e);
        dispatch(setDeleteTeamResult({transaction_id: "ERROR"}));
    }
}

export const readyTeam = (user : User, activeUser: any, team: Team, ready : boolean) : AppThunk => async(dispatch, _, api) =>{
    try{
        console.log((ready ? 'Locking' : 'Unlocking') + ' team ' + team.id);
        const result = await activeUser.signTransaction(
            {
                actions: [{
                    account: VINTYNIFTYS_ACCOUNT,
                    name:  'readyteam',
                    authorization: [{
                        actor: activeUser['accountName'],
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        user: activeUser['accountName'],
                        username: user.userName,
                        team_id: team.id,
                        ready: ready
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30,
            });
        
        if(result.error != null){
            console.log('Team ' + team.id + ' could not be ' + getReadyStatus(ready) + '. Error ' + result.error);
            dispatch(setSaveTeamResult({transaction_id: "ERROR", error:result.error}));
        }
        else{
            console.log('Team ' + team.id + ' was ' + getReadyStatus(ready) + '. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setSaveTeamResult({transaction_id: result.transaction.transaction_id}));
            
            // let userTeams: {[id: number] : Team} = {};
            // team.locked = ready;
            // userTeams[team.id] = team;

            // update state with ready status for team only - refetch later
            //dispatch(updateTeamReadyStatus({userTeams:userTeams,matches:undefined,opponentTeams:undefined,players:undefined,tactics:undefined,teamPlayers:undefined}));

            // re-fetch all teams ??
            //dispatch(fetchTeams(user));
        }
    }
    catch(e:any){
        console.log('Team ' + team.name + ' could not be updated. Exception ' + e);
        dispatch(setSaveTeamResult({transaction_id: "ERROR"}));
    }
}

const getReadyStatus = (lock : boolean)=>{
    return lock ? 'Locked' : 'Unlocked';
}

export const updateTeamReadyStatus = (data: GameData) =>{
   
    return{
        type: actionTypes.UPDATE_TEAM_READYSTATUS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};


/* TACTIC */

export const requestTactics = () => {
    return{
        type: actionTypes.REQUEST_TACTICS,
        error: undefined
    };
};

export const receiveTactics = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_TACTICS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorTactics = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_TACTICS,
        error: error
    };
};

export const fetchTactics = (): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestTactics());
    try{
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'tactics',        // Table name 
            limit:20
        });
        let tactics :{[id: number] : Tactic} = {};
        if(response.rows.length > 0){
            
            response.rows.forEach(t => {
                let tactic: Tactic={
                    tactid_id: t.tactic_id,
                    desc: t.desc,
                };
                tactics[tactic.tactid_id] = tactic;
            });
        }
        console.log("Loaded " + Object.keys(tactics).length + " tactics");
        dispatch(receiveTactics({userTeams:undefined, tactics: tactics, players: undefined, matches: undefined, opponentTeams: undefined, teamPlayers:undefined}));
    }
    catch(e){
        dispatch(errorTactics(e));
    }
};

/* PLAYERS */
export const requestPlayers = () => {
    return{
        type: actionTypes.REQUEST_PLAYERS,
        error: undefined
    };
};

export const receivePlayers = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_PLAYERS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorPlayers = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_PLAYERS,
        error: error
    };
};

export const fetchPlayers = (userAssets: UserAssets): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestPlayers());
    try{

        if(userAssets.assets == null){
            console.log("Loaded 0 players");
            dispatch(receivePlayers({userTeams:undefined, tactics: undefined, players: {}, matches: undefined, opponentTeams: undefined, teamPlayers: undefined}));
            return;
        }
        /*
            Get row count of players and then use that for the limit on the call to get table rows - MAY NEED TO REVISIT this
        */
        const responseRowCount = await api.waxRpc.get_table_by_scope({
            // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            table: 'players',        // Table name 
        });

        const rowCount = responseRowCount.rows[0].count;

        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'players',        // Table name 
            limit: rowCount
        });

        let players :{[id: number] : Player} ={};
        if(response.rows.length > 0){  
            response.rows.forEach(t => {

                const suspendDate = new Date(0);
                suspendDate.setUTCSeconds(t.suspended_til);

                const injuryDate = new Date(0);
                injuryDate.setUTCSeconds(t.injured_til);

                let player: Player={
                    id: t.asset_id,
                    asset_id : t.asset_id,
                    img_id : t.asset_id,
                    score_id: t.asset_id,
                    fitness_id: t.asset_id,
                    goalkeeper: t.goalkeeper,
                    yellowcards: t.yellowcards,
                    yellowcarded: t.yellowcarded,
                    redcards: t.redcards,
                    redcarded: t.redcarded,
                    suspended_til: suspendDate,
                    injured_til: injuryDate,
                    played: t.played,
                    rating: t.rating,
                    experience: t.experience,
                    full_fitness_time: t.full_fitness_time
                };
                if(userAssets.stakedAssets != null && player.id in userAssets.stakedAssets){
                    player.staked = true;
                }   
                if(userAssets.claimedStakes != null && player.id in userAssets.claimedStakes){
                    player.nextClaim = userAssets.claimedStakes[player.id];
                }
                
                if(userAssets.assets != null){
                    // Now calc players stats, tav, fitness etc
                    // We check if in our assets dictionary i.e. ensure it's our player.
                    let asset = userAssets.assets[t.asset_id];

                    // check stakedAssets
                    if(asset == null && userAssets.stakedAssets != null){
                        asset = userAssets.stakedAssets[t.asset_id];
                    }

                    if(asset != null){
                        let playerStats = getPlayersScore(
                            player,
                            asset,
                            Templates[asset.template?.template_id!],
                            true
                          );
                          player.tav = playerStats.score;
                          player.chemistry = playerStats.chemistry;
                          player.fitnessPerc = playerStats.fitness;
                          player.level = asset.mutable_data != null ? asset.mutable_data.Level : 0;
                          player.templateId = asset.template?.template_id;

                    }  
                }

                players[parseInt(t.asset_id)] = player;
            });
        }
        console.log("Loaded " + Object.keys(players).length + " players");
        dispatch(receivePlayers({userTeams:undefined, tactics: undefined, players: players, matches: undefined, opponentTeams: undefined, teamPlayers: undefined}));
    }
    catch(e){
        console.log(e);
        dispatch(errorPlayers(e));
    }
};

export const updateSinglePlayer = (player : Player, asset : IAsset) :  AppThunk => async(dispatch, _, api) =>{
    try{
        var newPlayer = { ...player };
        let playerStats = getPlayersScore(
            newPlayer,
            asset,
            Templates[asset.template?.template_id!],
            true
          );
          newPlayer.tav = playerStats.score;
          newPlayer.chemistry = playerStats.chemistry;
          newPlayer.fitnessPerc = playerStats.fitness;
          newPlayer.level = asset.mutable_data != null ? asset.mutable_data.Level : 0;
          newPlayer.templateId = asset.template?.template_id;

          let players :{[id: number] : Player} ={};
          players[parseInt(asset.asset_id)] = newPlayer;
          dispatch(receiveSinglePlayer({players:players}));
    }
    catch(e){
        console.log(e);
    }
}

export const receiveSinglePlayer = (data: GameData) =>{
  
    return{
        type: actionTypes.RECEIVE_SINGLE_PLAYER,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

/* MATCHES */
export const requestMatches = () => {
    return{
        type: actionTypes.REQUEST_MATCHES,
        error: undefined
    };
};

export const receiveMatches = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_MATCHES,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorMatches = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_MATCHES,
        error: error
    };
};

export const fetchMatches = (): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestMatches());
    try{
        let matches: {[tournoId: number] : Match[]} = {};
        const responseRowCount = await api.waxRpc.get_table_by_scope({
            // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            table: 'matches',        // Table name 
        });

        if(responseRowCount.rows.length == 0){
            dispatch(receiveMatches({userTeams:undefined, tactics: undefined, players: undefined, matches: matches, opponentTeams: undefined, teamPlayers: undefined}));
            return;
        }

        const rowCount = responseRowCount.rows[0].count;
       
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'matches',        // Table name 
            limit:rowCount
        });
        // matches keyed by tourno id
        
        if(response.rows.length > 0){
            
            response.rows.forEach(t => {

                const created = new Date(0);
                created.setUTCSeconds(t.created);

                if(t.finished){
                    let match: Match={
                        id : t.match_id,
                        tournoId : t.tourno_id,
                        match_id : t.match_id,
                        home_manager : t.home_manager,
                        away_manager : t.away_manager,
                        hometeam_id : t.hometeam_id,
                        awayteam_id : t.awayteam_id,
                        homescore : t.homescore,
                        awayscore : t.awayscore,
                        created : created,
                        bet : t.bet,
                        finished : t.finished,
                    };
    
                    if(!matches[match.tournoId]){
                        let newList : Match[] = [];
                        newList.push(match);
                        matches[match.tournoId] = newList;
                    }
                    else{
                        matches[match.tournoId].push(match);
                    }
                }
            });
        }
        // sort list by most recent first
        //matches.sort((a, b) => (a.created > b.created ? -1 : 1));

        // sort
        for(let key in matches){
            let list = matches[key];
            list.sort((a, b) => (a.created > b.created ? -1 : 1));
        }

        console.log("Loaded " + Object.values(matches).length + " matches");
        dispatch(receiveMatches({userTeams:undefined, tactics: undefined, players: undefined, matches: matches, opponentTeams: undefined, teamPlayers: undefined}));
    }
    catch(e){
        dispatch(errorMatches(e));
    }
};


/* TOURNAMENTS */
export const requestTournaments = () => {
    return{
        type: actionTypes.REQUEST_TOURNAMENTS,
        error: undefined
    };
};

export const receiveTournaments = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_TOURNAMENTS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorTournaments = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_TOURNAMENTS,
        error: error
    };
};

export const fetchTournaments = (): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestTournaments());
    try{
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'tournaments',        // Table name 
        });
        let tournaments: Tournament[] = [];
        if(response.rows.length > 0){
            response.rows.forEach(t => {

                const startDate = new Date(0);
                startDate.setUTCSeconds(t.start_date);

                const endDate = new Date(0);
                endDate.setUTCSeconds(t.end_date);

                let tournament: Tournament={
                    id : t.id,
                    name : t.tourno_name,
                    startDate : startDate,
                    endDate : endDate,
                    teamCount : t.team_count,
                    gamesCount : t.game_count,
                    buyIn : t.buy_in,
                    prize : t.prize,
                    winningTeam : t.winning_team,
                    manager : t.manager,
                    active : t.active,
                    expired: new Date() > endDate, // has it expired? it can still be active
                    unlockLimit : t.unlocklimit,
                };
                tournaments.push(tournament);
            });
        }
        // sort list by most recent first
        tournaments.sort((a, b) => (a.endDate > b.endDate ? -1 : 1));

        console.log("Loaded " + tournaments.length + " tournaments");
        dispatch(receiveTournaments({userTeams:undefined, tactics: undefined, players: undefined, matches: undefined, opponentTeams: undefined, teamPlayers: undefined, tournaments:tournaments}));
    }
    catch(e){
        dispatch(errorTournaments(e));
    }
}

/* TOURNAMENT BOARD */
export const fetchTournoPositions = (): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestTournoPositions());
    try{
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'tournoboard',        // Table name 
        });
        let tournoPositions: TournamentPosition[] = [];
        if(response.rows.length > 0){
            response.rows.forEach(t => {
                let position: TournamentPosition={
                    id: t.team_id,
                    teamId : t.team_id,
                    lineUpTeamId : t.team_id,
                    manager: t.manager,
                    win: t.win,
                    lose: t.lose,
                    draw: t.draw,
                    points : t.points,
                    position:0,
                    unlockCount: t.unlockcount
                };
                tournoPositions.push(position);
            });
        }
        // sort list
        tournoPositions.sort((a, b) => (a.points > b.points ? -1 : 1));
        // position to tournoboard
        for(let i=0;i<tournoPositions.length;i++){
            tournoPositions[i].position = i+1;
        }

        console.log("Loaded " + tournoPositions.length + " tournament positions");
        dispatch(receiveTournoPositions({userTeams:undefined, tactics: undefined, players: undefined, matches: undefined, opponentTeams: undefined, teamPlayers: undefined, tournaments:undefined, tournamentPositions:tournoPositions}));
    }
    catch(e){
        dispatch(errorTournoPositions(e));
    }
}

export const requestTournoPositions = () => {
    return{
        type: actionTypes.REQUEST_TOURNAMENT_BOARD,
        error: undefined
    };
};

export const receiveTournoPositions = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_TOURNAMENT_BOARD,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorTournoPositions = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_TOURNAMENT_BOARD,
        error: error
    };
};

export const enterTourno = (activeUser: any, user : User, teamId : number): AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'entertourno',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                   user: activeUser['accountName'],
                   team_id: teamId,
                   manager: user.userName
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Could not enter tournament. Error ' + result.error);
            dispatch(setEnterTournoResult({transaction_id: "ERROR"}));
        }
        else{
            console.log('Entered tournament. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setEnterTournoResult({transaction_id: result.transaction.transaction_id}));
        }

    }
    catch(e:any){
        console.log('Could not enter tournament. Error ' + e);
        dispatch(setEnterTournoResult({transaction_id: "ERROR"}));
    }
}

export const errorEnterTourno = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_TOURNAMENT_ENTER,
        error: error
    };
};

// ACTION startmatch(name user, name home_manager, name away_manager, uint64_t tourno_id, std::string tourno_name, uint64_t hometeam_id, uint64_t awayteam_id, const checksum256& hash);
export const startMatch = (activeUser : any, user : User, awayManager : string, tournamentId : number, tournamentName : string, homeTeamId : number, awayTeamId : number, hash : string): AppThunk => async(dispatch, _, api) =>{
    try{
        console.log("starting match");
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'startmatch',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                   user: activeUser['accountName'],
                   home_manager: user.userName,
                   away_manager: awayManager,
                   tourno_id: tournamentId,
                   tourno_name: tournamentName,
                   hometeam_id: homeTeamId,
                   awayteam_id: awayTeamId,
                   hash : hash
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 300,
        });

        if(result.error != null){
            dispatch(setStartMatchResult({transaction_id: "ERROR", error:result.error}));
        }
        else{
            console.log('Match started. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setStartMatchResult({transaction_id: result.transaction.transaction_id}));
        }

    }
    catch(e:any){
       
        dispatch(setStartMatchResult({transaction_id: "ERROR",error:e.toString()}));
    }

}

export const fetchStakedPlayers = (activeUser : any, gameData : GameData) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestStakedPlayers());
    try{
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYSTAKES_ACCOUNT,     // Contract that we target      
            scope: VINTYSTAKES_ACCOUNT,        // Account that owns the data   
            table: 'stakes',        // Table name 
            key_type: 'name',
            index_position: 1,
            lower_bound: activeUser['accountName'], // specify both lower and upper
            limit:1000
           // upper_bound: activeUser['accountName'],
            //reverse: true
        });

        if(response.rows.length > 0){
            let players :{[id: number] : Player} = {};
            response.rows.forEach(s => {
                if(gameData.players != null){
                    let tmpPlayer = gameData.players[parseInt(s.asset_id)];
                    if(tmpPlayer != null){
                        var newPlayer = { ...tmpPlayer };
                        newPlayer.staked = true;
                        players[parseInt(s.asset_id)] = newPlayer;
                    }
                }
            });
            
            dispatch(receiveStakedPlayers({players:players}));
        }
    }catch(e:any){
        dispatch(errorStakedPlayers(e));
    }
}

export const requestStakedPlayers = () => {
    return{
        type: actionTypes.REQUEST_STAKED_PLAYERS,
        error: undefined
    };
};

export const receiveStakedPlayers = (data: GameData) =>{
   
    return{
        type: actionTypes.RECEIVE_STAKED_PLAYERS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorStakedPlayers = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_STAKED_PLAYERS,
        error: error
    };
};
