import { SchemaInfo } from "../../types/SchemaInfo";
import { InitialState, ReducerAction } from "../configureStore";
import * as actionTypes from '../actions/types';


const initialState: InitialState<SchemaInfo> = {
    isFetching: true,
    error: undefined,
    data:{
       schemaCounts: undefined
    }
};


export const schemaInfo = (
    state = initialState,
    action: ReducerAction<SchemaInfo>
)=>{
    switch(action.type){
        case actionTypes.REQUEST_SCHEMA_INFO:
            return{
                ...state,
                isFetching: true,
                error: undefined
            };
        case actionTypes.RECEIVE_SCHEMA_INFO:
            return{
                ...state,
                data: action.payload.data,
                isFetching: false,
                error: undefined,
            };
       
        case actionTypes.ERROR_SCHEMA_INFO:
            return{
                ...state,
                error:action.error,
                isFetching:false,
            }
        default:
            return state;
    }
};