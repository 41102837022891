export const Series1Rarities : string[] =[
"Common","Uncommon","Limited","Relic","Rare","Epic","Legendary","Mythical","Celestial","Transfer","Manager"
];

export const Series2Rarities : string[] =[
    "Common","Limited","Rare","Epic","Legendary"
    ];

export const EmotionRarities : string[] =[
    "Base","Promo"
];

export const EnforcersRarities : string[] =[
"Base", "Light Bruising", "Yellow Card", "The Terry Butcher", "Red Card", "Locked Up", "Halo", "MOTM", "Red Mist"
];

export const PromoRarties : string[]=[
    "Special"
];

export const SpecialRarities : string[]=[
    "Ghoulkeepers"
];

export const WorldCupRarities : string[]=[
    "Common","Rare"
];
