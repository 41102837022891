import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchOpponentAssets,
  resetOpponentAssets,
} from "../store/actions/userAssets";
import { RootState } from "../store/reducers";
import { Team } from "../types/Team";
import { Tournament } from "../types/Tournament";
import {
  calculateMatchResult,
  GameResult,
} from "../utils/calculateMatchResult";
import { useUAL } from "../utils/useUAL";
import { webServiceInstance } from "../utils/webServiceInstance";
import ecc from "eosjs-ecc";
import crypto from "crypto-js";
import {
  fetchMatches,
  fetchTeams,
  fetchTournaments,
  fetchTournoPositions,
  startMatch,
} from "../store/actions/gameData";
import { setStartMatchResult } from "../store/actions/transactions";
import { sortAttributes } from "../utils/sortAttributes";
import { HtmlTooltip } from "./utils/toolTip";
import { Templates } from "../inventory";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Fade,
  Grid,
  LinearProgress,
} from "@mui/material";
import { getKeyFrames } from "../utils/Theme";
import { setChallengeOpponent } from "../store/actions/challengeOpponent";

export type MatchResultProps = {
  //showDialog: boolean;
  opponentTeamId: number;
  sourcePage: string;
  //onMatchFinished(): void;
};

const CHALLENGE = "CHALLENGE";
const HOMETEAM_CHECK = "HOMETEAM_CHECK";
const AWAYTEAM_CHECK = "AWAYTEAM_CHECK";
const STARTMATCH = "STARTMATCH";
const PLAYGAME = "PLAYGAME";
const MIDMATCH = "MIDMATCH";
const GAMEFINISHED = "GAMEFINISHED";
const DISPLAYRESULT = "DISPLAYRESULT";

/*
      Check if the opponent is LOCKED.
  
  */

const MatchResult = () => {
  const matchState = useLocation().state as MatchResultProps;
  const dispatch = useDispatch<any>();
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const { data: startMatchResult } = useSelector(
    (state: RootState) => state.startMatchResult
  );
  const [opponentTeam, setOpponentTeam] = useState<Team | null>(null);
  const [myTeam, setMyTeam] = useState<Team | null>(null);
  const [gameStatus, setGameStatus] = useState("");
  const [error, setError] = useState("");
  const [result, setResult] = useState<GameResult | null>(null);
  const ual = useUAL() as any;
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [cipherText, setCipherText] = useState(CHALLENGE);
  const [hash, setHash] = useState("");
  const navigate = useNavigate();
  const [startTransaction, setStartTransaction] = useState(false);

  /*
    Get active tournament
  */
  useEffect(() => {
    if (gameData.tournaments != null) {
      let tournament = gameData.tournaments.find((t) => t.active);
      if (tournament != null) {
        setTournament(tournament);
      }
    }
  }, []);

  /*
      Get opponents team and yours!
  */
  useEffect(() => {
    if (gameData.opponentTeams != null) {
      let oppTeam = gameData.opponentTeams[matchState.opponentTeamId];
      if (oppTeam != null) {
        setOpponentTeam(oppTeam);
      }
    }

    if (gameData.tournamentPositions != null) {
      let pos = gameData.tournamentPositions.find(
        (t) => t.manager === userData.userName
      );
      if (pos != null && gameData.userTeams != null) {
        let team = gameData.userTeams[pos.teamId];
        setMyTeam(team);
      }
    }
    // Now start checking home team - THIS IS FIRED ON START OF PAGE.
    setGameStatus(HOMETEAM_CHECK);
  }, [matchState.opponentTeamId]);

  /*
      When we've got the opponent team, check setup and then move to next state
      which is to show the challenge buttons CANCEL or PLAY
  */
  useEffect(() => {
    if (userAssets.opponentAssets != null && opponentTeam != null) {
      checkAwayTeamLineup();
    }
  }, [userAssets.opponentAssets]);

  /*
    GameStatus 
  */
  useEffect(() => {
    // Call to webservice to log result
    const logResult = async () => {
      try {
        const data = { user: userData.userName, data: cipherText, hash: hash };
        const result = await webServiceInstance.post("/result", data);
        console.log(result);

        if (result.toString() == "ERROR") {
          setError(
            "Damn :( An error occurred while playing the game. Get on that hoot to VN!"
          );
        } else {
          // move to next stage in game
          setGameStatus(MIDMATCH);
        }
      } catch (e: any) {
        setError(
          "Damn :( An error occurred while playing the game. Get on that hoot to VN!"
        );
      }
    };

    // 1. Check home team sheet
    // 2. Check away team sheet (this involves loading opponents assets)
    // 3. start match transaction - calc score
    // 4. simulate lag - call web service.
    // 5. display teams and winner !

    if (gameStatus == HOMETEAM_CHECK) {
      setTimeout(() => {
        console.log(gameStatus);
        checkHomeTeamLineup();
      }, 500);
    } else if (gameStatus == AWAYTEAM_CHECK) {
      if (opponentTeam != null) {
        console.log(gameStatus);
        dispatch(fetchOpponentAssets(opponentTeam?.manager));
      }
    } else if (gameStatus == PLAYGAME) {
      // after 2s timeout call web service
      // this should give enough time for the start transaction to be committed to the blockchain.
      setTimeout(() => {
        console.log(gameStatus);
        logResult();
      }, 2000);
    } else if (gameStatus == MIDMATCH) {
      setTimeout(() => {
        console.log(gameStatus);
        setGameStatus(GAMEFINISHED);
      }, 2000);
    } else if (gameStatus == GAMEFINISHED) {
      // small lag then move to next state
      setTimeout(() => {
        console.log(gameStatus);
        setGameStatus(DISPLAYRESULT);
        //setIsFullScreen(true);
      }, 1500);
    } else if (gameStatus == DISPLAYRESULT) {
      // refresh data
      dispatch(fetchTeams(userData, userAssets));
      dispatch(fetchTournaments());
      dispatch(fetchTournoPositions());
      dispatch(fetchMatches());
    }
  }, [gameStatus]);

  // Check for start transaction error
  useEffect(() => {
    console.log(startMatchResult);
    if (startMatchResult.transaction_id != null) {
      if (startMatchResult.transaction_id == "ERROR") {
        setError(startMatchResult.error!);
      } else {
        console.log(startMatchResult.transaction_id);
        // call web service to log result
        setGameStatus(PLAYGAME);
      }
    }
  }, [startMatchResult.transaction_id]);

  useEffect(() => {
    if (startTransaction) {
      if (
        gameData.players != null &&
        userAssets.assets != null &&
        gameData.tactics != null &&
        myTeam != null &&
        opponentTeam != null &&
        userAssets.opponentAssets != null
      ) {
        //calc result NOW and save
        let gameResult = calculateMatchResult(
          gameData.players,
          userAssets.assets,
          userAssets.opponentAssets,
          gameData.tactics,
          myTeam,
          opponentTeam
        );
        setResult(gameResult);
        //console.log("home score " + gameResult.homeScore);
        //console.log("away score " + gameResult.awayScore);
        //console.log("points " + gameResult.points);
        var ciphertext = crypto.AES.encrypt(
          userData.userName +
            ":" +
            gameResult.homeScore +
            "-" +
            gameResult.awayScore +
            ":" +
            gameResult.points,
          "bollocks"
        ).toString(); //2-2:5', 'bollocks').toString();
        console.log(ciphertext);
        setCipherText(ciphertext);
        var shaHash = ecc.sha256(ciphertext);
        setHash(shaHash);
        console.log(shaHash);
        // TOGGLE THIS TO TEST DISPLAY RESULT.
        //setGameStatus(DISPLAYRESULT);
        //  now set off transaction
        const activeUser = ual["activeUser"];
        dispatch(
          startMatch(
            activeUser,
            userData,
            opponentTeam.manager,
            tournament?.id!,
            tournament?.name!,
            myTeam.id,
            opponentTeam.id,
            shaHash
          )
        );
      }
    }
  }, [startTransaction]);

  const checkHomeTeamLineup = () => {
    if (userAssets != null && userAssets.assets != null && myTeam != null) {
      let isValid = true;
      for (let i = 0; i < myTeam.lineup.length; i++) {
        let asset = userAssets.assets[myTeam.lineup[i]];
        if (asset == null) {
          isValid = false;
          break;
        }
      }

      if (!isValid) {
        setError(
          "Coudn't resolve your team lineup, something isn't right...get on the phone to VN!"
        );
      } else {
        setGameStatus(AWAYTEAM_CHECK);
      }
    } else {
      // show error
      setError(
        "Coudn't resolve your team lineup, something isn't right...get on the blower to VN!"
      );
    }
  };

  const checkAwayTeamLineup = () => {
    if (
      userAssets != null &&
      userAssets.opponentAssets != null &&
      opponentTeam != null
    ) {
      let isValid = true;
      for (let i = 0; i < opponentTeam.lineup.length; i++) {
        let asset = userAssets.opponentAssets[opponentTeam.lineup[i]];
        if (asset == null) {
          isValid = false;
          break;
        }
      }

      if (!isValid) {
        console.log("away team not valid");
        setError(
          "Coudn't resolve " +
            opponentTeam?.manager +
            "'s lineup, something isn't right...get on the phone to VN!"
        );
      } else {
        // Now show challenge
        setGameStatus(CHALLENGE);
      }
    } else {
      setError(
        "Coudn't resolve " +
          opponentTeam?.manager +
          "'s lineup, something isn't right...get on the phone to VN!"
      );
    }
  };

  const startGame = () => {
    setGameStatus(STARTMATCH);
    setStartTransaction(true);
  };

  const onClose = () => {
    //onMatchFinished();
    // clear opponent id
    dispatch(setChallengeOpponent({ teamId: null }));

    setGameStatus(CHALLENGE);
    setError("");
    setCipherText("");
    setHash("");
    //setIsFullScreen(false);
    // reset opponentTeam assets to NULL
    dispatch(resetOpponentAssets());
    // clear tran result
    dispatch(setStartMatchResult({ transaction_id: null }));

    // go back to source page
    navigate(matchState.sourcePage);
  };

  const getMatchStatusMessage = () => {
    if (gameStatus == HOMETEAM_CHECK) {
      return "Checking " + myTeam?.name + "'s teamsheet...";
    }
    if (gameStatus == AWAYTEAM_CHECK) {
      return "Checking " + opponentTeam?.name + "'s teamsheet...";
    }
    if (gameStatus == STARTMATCH) {
      return "And its kick off!";
    }
    if (gameStatus == PLAYGAME) {
      return "Who's gonna win?";
    }
    if (gameStatus == MIDMATCH) {
      return "It's nearly finished...";
    }
    if (gameStatus == GAMEFINISHED) {
      return "The results are in!";
    }
  };

  const getHomePlayerScore = (index: number): number => {
    return result != null ? result.homeTAV.playerScores[index] : 0;
  };

  const getAwayPlayerScore = (index: number): number => {
    return result != null ? result.awayTAV.playerScores[index] : 0;
  };

  const getHomePlayerFitness = (index: number): number => {
    return result != null ? result.homeTAV.playerFitness[index] : 0;
  };

  const getAwayPlayerFitness = (index: number): number => {
    return result != null ? result.awayTAV.playerFitness[index] : 0;
  };

  const renderImage = (index: number, opponent: boolean) => {
    if (userAssets.assets == null || userAssets.opponentAssets == null) {
      return "";
    }
    let asset = opponent
      ? userAssets.opponentAssets[index]
      : userAssets.assets[index];
    let sortedAttributes = sortAttributes(
      Object.keys(asset.mutable_data),
      asset.mutable_data.Position,
      false
    );
    if (asset != null) {
      return (
        <HtmlTooltip
          sx={{}}
          placement="right"
          title={
            <div
              style={{
                display: "flex",
                marginTop: "8px",
                height: "330px",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                width: "500px",
              }}
            >
              <div>
                <img
                  src={
                    "images/" + Templates[asset.template!.template_id!].imgPath
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "250px",
                  border: "1px solid #99ff32",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    height: "50px",
                    borderBottom: "1px solid #99ff32",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <div style={{ marginTop: "4px", fontWeight: "bold" }}>
                    {asset?.name}
                  </div>
                  <div style={{ fontSize: "10px", marginTop: "5px" }}>
                    Asset: {asset?.asset_id}, Rarity: {asset?.data.Rarity}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "10px",
                    flexDirection: "column",
                    marginTop: "3px",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                >
                  {asset &&
                    sortedAttributes.map((item) => (
                      <div style={{ display: "flex", lineHeight: "30px" }}>
                        <div style={{ flex: "1" }}>
                          <span style={{ marginLeft: "10px" }}>{item}</span>
                        </div>
                        <div style={{ flex: "1" }}>
                          <span
                            style={{ marginLeft: "10px", fontWeight: "bold" }}
                          >
                            {asset.mutable_data[item]}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          }
        >
          <img
            style={{
              height: "16px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              cursor: "pointer",
            }}
            src={"images/" + Templates[asset.template!.template_id!].imgPath}
          />
        </HtmlTooltip>
      );
    } else {
      return "";
    }
  };

  const renderDisplay = () => {
    // NEED TO CHECK FOR ERRORS!!!
    if (error) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#000",
            gap: "20px",
            height: "350px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Alert
            variant="filled"
            severity="error"
            sx={{ m: 2 }}
            onClose={() => {
              onClose();
            }}
          >
            {error}
          </Alert>
        </div>
      );
    }

    // tailor display based on game status
    if (gameStatus == CHALLENGE) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#000",
            gap: "20px",
            height: "350px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div className="results-challenge">READY TO CHALLENGE:</div>
          <div className="result-challenge-teamname">
            {opponentTeam != null ? opponentTeam.name : "N/A"}?
          </div>
          <div style={{ display: "flex", gap: "50px" }}>
            <Button
              className="blue-btn"
              size="large"
              sx={{
                width: "100px",
                backgroundColor: "#001636",
                color: "#fff",
                "&:hover": { backgroundColor: "#fff", color: "#22395a" },
              }}
              variant="contained"
              onClick={() => onClose()}
            >
              CANCEL
            </Button>
            <Button
              className="green-btn"
              size="large"
              sx={{
                width: "100px",
                "&:hover": { backgroundColor: "#001636", color: "#9f3" },
              }}
              variant="contained"
              onClick={() => startGame()}
            >
              PLAY
            </Button>
          </div>
        </div>
      );
    } else if (
      gameStatus == HOMETEAM_CHECK ||
      gameStatus == AWAYTEAM_CHECK ||
      gameStatus == STARTMATCH ||
      gameStatus == PLAYGAME ||
      gameStatus == MIDMATCH ||
      gameStatus == GAMEFINISHED
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#000",
            gap: "20px",
            height: "350px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={"/images/pre-loader-white.gif"}
            width="241"
            height="267"
            alt="Game is on"
          />
          <div className="result-challenge-teamname">
            {getMatchStatusMessage()}
          </div>
        </div>
      );
    } else if (gameStatus == DISPLAYRESULT) {
      return (
        <Fade in={true}>
          <div
            className="results-lists"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <div className="team-tables-lists">
              <div className="team1-list">
                <table
                  className="results-table"
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <tbody>
                    <tr>
                      <th style={{ width: "4%" }} scope="col">
                        &nbsp;
                      </th>
                      <th style={{ width: "4%" }} scope="col">
                        &nbsp;
                      </th>
                      <th
                        style={{ width: "46%", textAlign: "left" }}
                        scope="col"
                      >
                        Name
                      </th>
                      <th style={{ width: "31%" }} scope="col">
                        Fitness
                      </th>
                      <th style={{ width: "19%" }} scope="col">
                        TAV
                      </th>
                    </tr>
                    {myTeam?.lineup.map((player, index) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td>{renderImage(player, false)}</td>
                        <td>
                          {userAssets.assets != null
                            ? userAssets.assets[player].name
                            : ""}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              gap: "3px",
                            }}
                          >
                            <div
                              style={{
                                width: "75%",
                                display: "flex",
                                alignItems: "stretch",
                              }}
                            >
                              <Grid container width={"100px"}>
                                <Grid xs item>
                                  <LinearProgress
                                    variant="determinate"
                                    value={getHomePlayerFitness(index)}
                                    sx={{
                                      height: 5,
                                      borderRadius: 60,
                                      boxShadow:
                                        "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                                      "& .MuiLinearProgress-bar1Determinate": {
                                        animation:
                                          getKeyFrames(
                                            getHomePlayerFitness(index)
                                          ) + " 3s normal forwards",
                                        ...(getHomePlayerFitness(index) <=
                                          25 && {
                                          background:
                                            "linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(177, 0, 0, 1) 100%)",
                                        }),
                                        ...(getHomePlayerFitness(index) > 25 &&
                                          getHomePlayerFitness(index) <= 50 && {
                                            background:
                                              "linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 183, 26, 1) 50%)",
                                          }),
                                        ...(getHomePlayerFitness(index) > 50 &&
                                          getHomePlayerFitness(index) <= 75 && {
                                            background:
                                              "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                                          }),
                                        ...(getHomePlayerFitness(index) >
                                          75 && {
                                          background:
                                            "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                                        }),
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <span style={{ width: "25%", fontSize: "10px" }}>
                              {getHomePlayerFitness(index)}%
                            </span>
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {getHomePlayerScore(index)}{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{
                        width: "55%",
                        backgroundColor: "#fff",
                        border: "solid 1px #fff",
                      }}
                    ></td>
                    <td
                      style={{
                        width: "30%",
                        backgroundColor: "#99ff32",
                        border: "solid 1px #fff",
                        color: "#000b1d",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "3px 5px",
                      }}
                    >
                      TAV TOTAL:{" "}
                    </td>
                    <td
                      style={{
                        width: "25%",
                        backgroundColor: "#99ff32",
                        border: "solid 1px #fff",
                        color: "#000b1d",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "3px 5px",
                        textAlign: "center",
                      }}
                    >
                      {result?.homeTAV.totalScore}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="team2-list">
                <table
                  className="results-table"
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <tbody>
                    <tr>
                      <th style={{ width: "4%" }} scope="col">
                        &nbsp;
                      </th>
                      <th style={{ width: "4%" }} scope="col">
                        &nbsp;
                      </th>
                      <th
                        style={{ width: "46%", textAlign: "left" }}
                        scope="col"
                      >
                        Name
                      </th>
                      <th style={{ width: "31%" }} scope="col">
                        Fitness
                      </th>
                      <th style={{ width: "19%" }} scope="col">
                        TAV
                      </th>
                    </tr>
                    {opponentTeam?.lineup.map((player, index) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td>{renderImage(player, true)}</td>
                        <td>
                          {userAssets.opponentAssets != null
                            ? userAssets.opponentAssets[player].name
                            : ""}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              gap: "3px",
                            }}
                          >
                            <div
                              style={{
                                width: "75%",
                                display: "flex",
                                alignItems: "stretch",
                              }}
                            >
                              <Grid container width={"100px"}>
                                <Grid xs item>
                                  <LinearProgress
                                    variant="determinate"
                                    value={getAwayPlayerFitness(index)}
                                    sx={{
                                      height: 5,
                                      borderRadius: 60,
                                      boxShadow:
                                        "0px 2px 0px 0px rgba(0, 0, 0, 0.30)",
                                      "& .MuiLinearProgress-bar1Determinate": {
                                        animation:
                                          getKeyFrames(
                                            getAwayPlayerFitness(index)
                                          ) + " 3s normal forwards",
                                        ...(getAwayPlayerFitness(index) <=
                                          25 && {
                                          background:
                                            "linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(177, 0, 0, 1) 100%)",
                                        }),
                                        ...(getAwayPlayerFitness(index) > 25 &&
                                          getAwayPlayerFitness(index) <= 50 && {
                                            background:
                                              "linear-gradient(90deg, rgba(255, 153, 0, 1) 0%, rgba(255, 183, 26, 1) 50%)",
                                          }),
                                        ...(getAwayPlayerFitness(index) > 50 &&
                                          getAwayPlayerFitness(index) <= 75 && {
                                            background:
                                              "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                                          }),
                                        ...(getAwayPlayerFitness(index) >
                                          75 && {
                                          background:
                                            "linear-gradient(90deg, rgba(153, 255, 51, 1) 0%, rgba(109, 214, 5, 1) 50%)",
                                        }),
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <span style={{ width: "25%", fontSize: "10px" }}>
                              {getAwayPlayerFitness(index)}%
                            </span>
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {getAwayPlayerScore(index)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table>
                  <tr style={{ border: "none" }}>
                    <td
                      style={{
                        width: "55%",
                        backgroundColor: "#fff",
                        border: "solid 1px #fff",
                      }}
                    ></td>
                    <td
                      style={{
                        width: "30%",
                        backgroundColor: "#99ff32",
                        border: "solid 1px #fff",
                        color: "#000b1d",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "3px 5px",
                      }}
                    >
                      TAV TOTAL:{" "}
                    </td>
                    <td
                      style={{
                        width: "25%",
                        backgroundColor: "#99ff32",
                        border: "solid 1px #fff",
                        color: "#000b1d",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "3px 5px",
                        textAlign: "center",
                      }}
                    >
                      {result?.awayTAV.totalScore}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                className="blue-btn"
                sx={{
                  width: "100px",
                  "&:hover": { backgroundColor: "#001636", color: "#9f3" },
                }}
                variant="contained"
                onClick={() => {
                  onClose();
                }}
              >
                FINISH
              </Button>
            </div>
          </div>
        </Fade>
      );
    }
  };

  const renderHomeScore = () => {
    switch (gameStatus) {
      case CHALLENGE:
      case HOMETEAM_CHECK:
      case AWAYTEAM_CHECK:
        return <span>?</span>;
      case DISPLAYRESULT:
        return <span>{result?.homeScore}</span>;
      default:
        return <span className="spinScoreHome"></span>;
    }
  };

  const renderAwayScore = () => {
    switch (gameStatus) {
      case CHALLENGE:
      case HOMETEAM_CHECK:
      case AWAYTEAM_CHECK:
        return <span>?</span>;
      case DISPLAYRESULT:
        return <span>{result?.awayScore}</span>;
      default:
        return <span className="spinScoreAway"></span>;
    }
  };

  return (
    // <Dialog

    //   open={showDialog}
    //   onClose={onClose}
    //   fullScreen={isFullScreen}
    //   maxWidth="lg"
    //   sx={{
    //     "& .MuiDialogContent-root": {
    //       paddingTop: "40px",
    //       borderRadius: "0px",
    //     },
    //   }}
    // >
    //   <DialogContent>
    <div className="modal-content" style={{ marginTop: "40px" }}>
      <div className="results-top">
        <div className="modal-close">
          <img
            src="/images/close-btn.png"
            style={{
              cursor: "pointer",
            }}
            onClick={onClose}
            width="42"
            height="42"
            alt="Close Window"
          />
        </div>
        <img src={"/images/game-pitch.png"} />
      </div>
      <div className="results-modal">
        <div className="results-score-row">
          <div className="team1-wrap">
            <div className="team1">{myTeam?.name}</div>
            <div className="manager1">
              Manager: <b>{userData.userName}</b>
            </div>
          </div>
          <div className="scoreboard">
            <div className="score">
              {renderHomeScore()}-{renderAwayScore()}
            </div>
          </div>
          <div className="team2-wrap">
            <div className="team2">{opponentTeam?.name}</div>
            <div className="manager2">
              Manager: <b>{opponentTeam?.manager}</b>
            </div>
          </div>
        </div>
      </div>
      {renderDisplay()}
    </div>
    //   </DialogContent>
    // </Dialog>
    // <div style={{ color: "white" }}>
    //   <Button onClick={testButton}>Test</Button>
    // </div>
  );
};
export default MatchResult;
