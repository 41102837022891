import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { ATTRIBUTE_BOOST_CARD_100, ATTRIBUTE_BOOST_CARD_250, ATTRIBUTE_BOOST_CARD_500, Templates } from '../../inventory';
import { SchemaInfo } from '../../types/SchemaInfo';
import { TransactionResult } from '../../types/TransactionResult';
import { User } from '../../types/User';
import { UserAssets } from '../../types/UserAssets';
import { getPlayersScore } from '../../utils/calculateTeamTotal';
import { ACTIVE_SCHEMAS, ATOMICASSETS_ACCOUNT, COLLECTION_NAME, EMOTION, ENFORCERS, PROMOPLAYERS, SERIES1, SERIES2, VINTYNIFTYS_ACCOUNT, SPECIALS, VINTYSTAKES_ACCOUNT, WCLEGENDS1 } from '../../utils/constants';
import * as actionTypes from '../actions/types';
import { AppThunk } from '../configureStore';


export const requestUserAssets = () => {
    return{
        type: actionTypes.REQUEST_USER_ASSETS,
        isFetching: true,
        error: undefined
    };
};

export const receiveUserAssets = (data: UserAssets) =>{
  
    return{
        type: actionTypes.RECEIVE_USER_ASSETS,
        payload:{
            data: data
        },
        error: undefined,
        isFetching: false 
    };
};

export const errorUserAssets = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER_ASSETS,
        error: error
    };
};

export const fetchUserAssets = (account: string, schemaInfo: SchemaInfo) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestUserAssets())
    try{
        const assetList :{[id: number] : IAsset} = {};
        const attributeCards : IAsset[] = [];
        const assetsByPosition : {[key: string] : number} ={};
        const assetScores : {[id: number] : number} = {};
        const stakedAssets :{[id: number] : IAsset} = {};

        // loop through schemas and retrieve all assets
        for(let key in schemaInfo.schemaCounts){
            // retrieve assets in pages of 500.
            let count = schemaInfo.schemaCounts[key];
            console.log('Retrieving ' + count + ' assets from ' + key);
            let pages = Math.ceil(count/500);
            if(pages > 0){
                for(let i = 0;i < pages;i++){
                    let assets = await api.atomicAssets.getAssets(
                        {
                            owner: account,
                            collection_name: COLLECTION_NAME,
                            schema_name: key
                          },
                          i+1,
                          500,
                          []
                      );
                    
                    for(let j = 0;j < assets.length;j++){
                        let asset = assets[j];
                        assetList[parseInt(asset.asset_id)] = asset;

                        if(asset.mutable_data != null && asset.mutable_data.Position != null){
                            let score = getPlayersScore(null, asset, Templates[asset.template?.template_id!], false);
                            assetScores[parseInt(asset.asset_id)] = score.score;

                            if(assetsByPosition[asset.mutable_data.Position.toString()])
                            {
                                assetsByPosition[asset.mutable_data.Position.toString()] = assetsByPosition[asset.mutable_data.Position.toString()]+1;
                            }
                            else{
                                assetsByPosition[asset.mutable_data.Position.toString()] = 1;
                            }
                        }
                    }
                }
            }
        }
        console.log("Loaded " + Object.keys(assetList).length + " user assets");

        // get staked assets
        console.log("Loading staked assets");
        const stakeResponse = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYSTAKES_ACCOUNT,     // Contract that we target      
            scope: VINTYSTAKES_ACCOUNT,        // Account that owns the data   
            table: 'stakes',        // Table name 
            key_type: 'name',
            index_position: 2,
            lower_bound: account, // specify both lower and upper
            upper_bound: account,
            limit:100
        });

        if(stakeResponse.rows.length > 0){
            let tmpStakeAssets : string[] = [];
            stakeResponse.rows.forEach((a)=>{
                console.log(`${a.asset_id} ${a.owner}`);
                tmpStakeAssets.push(a.asset_id);
            });
            if(tmpStakeAssets.length > 0){
                console.log(`Found ${tmpStakeAssets.length} staked assets, loading from Atomic Assets`);
                let assets = await api.atomicAssets.getAssets(
                    {
                        owner: VINTYSTAKES_ACCOUNT,
                        collection_name: COLLECTION_NAME,
                        ids:tmpStakeAssets.join()
                      },
                      1,
                      100,
                      []
                  );
                for(let i=0;i< assets.length;i++){
                    stakedAssets[parseInt(assets[i].asset_id)] = assets[i];
                }
                console.log(`Loaded ${Object.keys(stakedAssets).length} assets`);
            }
        }

        let attributeCardTemplates : number[] = [ATTRIBUTE_BOOST_CARD_100, ATTRIBUTE_BOOST_CARD_250, ATTRIBUTE_BOOST_CARD_500];
        for(let i=0;i< attributeCardTemplates.length;i++){
            let attributeCardAssets = await api.atomicAssets.getAssets(
                {
                    owner: account,
                    collection_name: COLLECTION_NAME,
                    schema_name: "specials",
                    template_id: attributeCardTemplates[i]
                },
                1,
                500,
                []
            );
    
            for(let i = 0;i < attributeCardAssets.length;i++){
                attributeCards.push(attributeCardAssets[i]);
            }
        }
        
        console.log("Loaded " + attributeCards.length + " attribute cards");

        let starPlayer : IAsset | null = null;
        if(Object.keys(assetScores).length > 0){
             // now get star player if available.
            var scoreItems = Object.keys(assetScores).map(function(key) {return [parseInt(key), assetScores[parseInt(key)]];});
            // Sort the array based on the second element
            scoreItems.sort(function(first, second) {
                return second[1] - first[1];
            });
            let topAsset = scoreItems.shift();
            if(topAsset != null){
                console.log("Found Star Player: " + topAsset);
                starPlayer = assetList[topAsset[0]];
               
            }
        }

        // now get card claims
        console.log("Loading claimed stakes data");
        const claimsResponse = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'claims',        // Table name 
            key_type: 'name',
            index_position: 2,
            lower_bound: account, // specify both lower and upper
            limit:100
        });
        let stakedClaims : {[id : number] : number} = {};
        if(claimsResponse.rows.length > 0){
            claimsResponse.rows.forEach(c => {
                const cooldown = new Date(0);
                cooldown.setUTCSeconds(c.time);
                stakedClaims[parseInt(c.asset_id)] = cooldown.getTime() - new Date().getTime();
            });
            console.log(`Loaded ${claimsResponse.rows.length} staked claims`);
        }

        const userAssets: UserAssets = {
            assets : assetList,
            attributeCards: attributeCards,
            assetsByPosition: assetsByPosition,
            starPlayer: starPlayer,
            claimedStakes: stakedClaims,
            stakedAssets: stakedAssets
        }
        
        dispatch(receiveUserAssets(userAssets));
    }
    catch(e){
        console.log(e);
        dispatch(errorUserAssets(e));
    }
}



export const requestSingleUserAsset = () => {
    return{
        type: actionTypes.REQUEST_SINGLE_USER_ASSET,
        error: undefined
    };
};

export const receiveSingleUserAsset = (data: UserAssets) =>{
  
    return{
        type: actionTypes.RECEIVE_SINGLE_USER_ASSET,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorSingleUserAsset = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_SINGLE_USER_ASSET,
        error: error
    };
};

export const fetchSingleAsset= (assetId : string, attributes : {[key: string] : any}) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestSingleUserAsset());
    try{
        console.log('Retrieving asset Id ' + assetId);
        let asset = await api.atomicAssets.getAsset(assetId);
        if(asset != null){
           
            asset.mutable_data = attributes;
            console.log(asset);
            const userAssets : UserAssets = {
                assets : {[parseInt(asset.asset_id)] : asset},
                attributeCards:undefined
            }
            console.log('Asset ' + asset.asset_id + ' retrieved. Updating store');
            dispatch(receiveSingleUserAsset(userAssets));
        }
        else{
            console.log('Could not find asset ' + assetId);
        }
    }
    catch(e){
        console.log(e);
        dispatch(errorSingleUserAsset(e));
    }
}

export const fetchSingleAsset2 = (assetId : string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestSingleUserAsset());
    try{
        console.log('Retrieving asset Id ' + assetId);
        let asset = await api.atomicAssets.getAsset(assetId);
        if(asset != null){
            
            const userAssets : UserAssets = {
                assets : {[parseInt(asset.asset_id)] : asset},
                attributeCards:undefined
            }
            console.log('Asset ' + asset.asset_id + ' retrieved. Updating store');
            console.log(asset);
            dispatch(receiveSingleUserAsset(userAssets));
        }
        else{
            console.log('Could not find asset ' + assetId);
        }
    }
    catch(e){
        console.log(e);
        dispatch(errorSingleUserAsset(e));
    }
}

export const updateAsset = (data: UserAssets) =>{
    return{
        type: actionTypes.UPDATE_USER_ASSET,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
}

export const requestUserAttributeCards = () => {
    return{
        type: actionTypes.REQUEST_USER_ATTRIBUTE_CARDS,
        error: undefined
    };
};

export const receiveUserAttributeCards = (data: UserAssets) =>{
  
    return{
        type: actionTypes.RECEIVE_USER_ATTRIBUTE_CARDS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorUserAttributeCards = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER_ATTRIBUTE_CARDS,
        error: error
    };
};

export const fetchUserAttributeCards = (account: string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestUserAttributeCards());
    try{
        const attributeCards : IAsset[] = [];
       
        let attributeCardTemplates : number[] = [ATTRIBUTE_BOOST_CARD_100, ATTRIBUTE_BOOST_CARD_250, ATTRIBUTE_BOOST_CARD_500];
        for(let i=0;i< attributeCardTemplates.length;i++){
            let attributeCardAssets = await api.atomicAssets.getAssets(
                {
                    owner: account,
                    collection_name: COLLECTION_NAME,
                    schema_name: "specials",
                    template_id: attributeCardTemplates[i]
                },
                1,
                500,
                []
            );
    
            for(let i = 0;i < attributeCardAssets.length;i++){
                attributeCards.push(attributeCardAssets[i]);
            }
        }
        
        console.log("Loaded " + attributeCards.length + " attribute cards");
    
        const userAssets: UserAssets = {
            assets : undefined,
            attributeCards: attributeCards
        }
        
        dispatch(receiveUserAttributeCards(userAssets));
    }
    catch(e){
        dispatch(errorUserAttributeCards(e));
    }
    
}

export const burnAttributeCard = (activeUser: any, assetId : string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestBurnAttributeCard());
    try{
        console.log('Burning attribute card ' + assetId + ' for owner ' + activeUser['accountName']);
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: ATOMICASSETS_ACCOUNT,
                name:  'burnasset',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    asset_owner: activeUser['accountName'],
                    asset_id: parseInt(assetId)
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Attribute card ' + assetId + ' could not be burned. Error ' + result.error);
            dispatch(setBurnAttributeCardResult({transaction_id: "ERROR"}));
        }
        else{
            console.log('Attribute card ' + assetId + ' was burned. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setBurnAttributeCardResult({transaction_id: result.transaction.transaction_id}));
        }
    }
    catch(e:any){
        dispatch(errorBurnAttributeCard(e));
    }
}

export const requestBurnAttributeCard = () => {
    return{
        type: actionTypes.REQUEST_USER_ATTRIBUTE_CARDS,
        error: undefined
    };
};

export const errorBurnAttributeCard = (error: unknown) => {
    return{
        type: actionTypes.ERROR_BURN_ATTRIBUTE_CARD,
        error: error
    };
};

export const setBurnAttributeCardResult = (data: TransactionResult) =>{
   
    return{
        type: actionTypes.BURN_ATTRIBUTE_CARD_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const fetchOpponentAssets = (account: string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestOpponentAssets());
    try{

        console.log('Getting user details for ' + account);
        // first get users actual wax address.
        const opponentUserData = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'users',        // Table name    ,
            index_position: 2 ,
            key_type: 'name',
            lower_bound: account,  
            limit: 1,              // maximum number of rows that we want to get
        });

        console.log(opponentUserData);

        if(opponentUserData.rows.length == 0){
            dispatch(errorOpponentAssets(account + " does not exist!!"));
            return;
        }

        const waxAccount = opponentUserData.rows[0].user;;
        console.log('Found opponent ' + waxAccount);

        // Get schemas first
        console.log('getting schmema counts for ' + waxAccount);
        const accountStats  = await api.atomicAssets.getAccountCollection(waxAccount, COLLECTION_NAME);
        if(accountStats.schemas.length > 0){
            const schemaCounts : { [name: string] : number;} = {};

            // NOTE: filter on schemas we're interested in.
            accountStats.schemas.filter((s)=>{
                // switch(s.schema_name.toString()){
                //     case SERIES1:
                //     case SERIES2:
                //     case EMOTION:
                //     case ENFORCERS:
                //     case PROMOPLAYERS:
                //     case WCLEGENDS1:
                //     case SPECIALS:
                //         return true;
                // }
                return ACTIVE_SCHEMAS.includes(s.schema_name.toString());
                //return s.schema_name.toString() == SERIES1 || s.schema_name.toString() == EMOTION || s.schema_name.toString() == PROMOPLAYERS || s.schema_name.toString() == ENFORCERS || s.schema_name.toString() == SERIES2;
            }).forEach(schema => {
                schemaCounts[schema.schema_name.toString()] = +schema.assets;
            });
           
            console.log(schemaCounts);
           
            // Now load assets for each schema
            const opponentAssets :{[id: number] : IAsset} = {};

            for(let key in schemaCounts){
                // retrieve assets in pages of 500.
                let count = schemaCounts[key];
                console.log('Retrieving ' + count + ' assets from ' + key);
                let pages = Math.ceil(count/500);
                if(pages > 0){
                    for(let i = 0;i < pages;i++){
                        let assets = await api.atomicAssets.getAssets(
                            {
                                owner: waxAccount,
                                collection_name: COLLECTION_NAME,
                                schema_name: key
                              },
                              i+1,
                              500,
                              []
                          );
                        
                          for(let j = 0;j < assets.length;j++){
                           // console.log(assets[j].asset_id);
                            opponentAssets[parseInt(assets[j].asset_id)] = assets[j];
                          }
                    }
                }
            }

            console.log("Loaded " + Object.keys(opponentAssets).length + " opponent assets");

            const userAssets: UserAssets = {
                assets : undefined,
                attributeCards: undefined,
                opponentAssets : opponentAssets
            }
            
            dispatch(receiveOpponentAssets(userAssets));
        }
        else{
            
            dispatch(errorOpponentAssets(account + " does not have any assets, woah!"));
        }
    }
    catch(e:any){
        errorOpponentAssets(e);
    }
}


export const requestOpponentAssets = () => {
    return{
        type: actionTypes.REQUEST_OPPONENT_ASSETS,
        error: undefined
    };
};

export const receiveOpponentAssets = (data: UserAssets) =>{
  
    return{
        type: actionTypes.RECEIVE_OPPONENT_ASSETS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorOpponentAssets = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_OPPONENT_ASSETS,
        error: error
    };
};

export const resetOpponentAssets = () : AppThunk => async(dispatch, _, api) =>{
    const userAssets: UserAssets = {
        assets : undefined,
        attributeCards: undefined,
        opponentAssets : null
    }
    dispatch(receiveOpponentAssets(userAssets));
}

// export const fetchStakedClaims = (user : User) : AppThunk => async(dispatch, _, api) =>{
//     dispatch(requestStakeClaims());
//     try{
//         console.log("Loading claimed stakes data");
//         const claimsResponse = await api.waxRpc.get_table_rows({
//             json: true,              // Get the response as json
//             code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
//             scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
//             table: 'claims',        // Table name 
//             key_type: 'name',
//             index_position: 1,
//             lower_bound: user.account, // specify both lower and upper
//             limit:1000
//         });
//         let stakedClaims : {[id : number] : Date} = {};
//         if(claimsResponse.rows.length > 0){
//             claimsResponse.rows.forEach(c => {
//                 const cooldown = new Date(0);
//                 cooldown.setUTCSeconds(c.time);
//                 const nextClaim = cooldown.getTime() - new Date().getTime();
//                 stakedClaims[parseInt(c.asset_id)] = new Date(nextClaim);
//             });
//             console.log(`Loaded ${claimsResponse.rows.length} staked claims`);
//         }

//         const userAssets: UserAssets = {
//             claimedStakes: stakedClaims
//         }
//         dispatch(receiveStakeClaims(userAssets));
//     }
//     catch(e:any){
//         dispatch(errorStakeClaims(e));
//     }
// }

export const requestStakeClaims = () => {
    return{
        type: actionTypes.REQUEST_STAKED_CLAIMS,
        error: undefined
    };
};

export const receiveStakeClaims = (data: UserAssets) =>{
  
    return{
        type: actionTypes.RECEIVE_STAKED_CLAIMS,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorStakeClaims = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_STAKED_CLAIMS,
        error: error
    };
};


export const checkStakeTransfer = (account: string, assetId : string ) : AppThunk => async(dispatch, _, api) => {
    dispatch(requestCheckStakeTransfer());
    try{
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYSTAKES_ACCOUNT,     // Contract that we target      
            scope: VINTYSTAKES_ACCOUNT,        // Account that owns the data   
            table: 'stakes',        // Table name 
            lower_bound: assetId, // primary key
            index_position: 1,
            limit:1
        });

        if(response.rows.length == 1){
            let result : boolean = false;
            response.rows.forEach(s => {
                result = s.owner == account;
            });
            if(result){
                dispatch(setCheckStakeTransferResult({transaction_id:'SUCCESS'}));
            }
        }
        else{
            dispatch(setCheckStakeTransferResult({transaction_id:'WAITING'}));
        }
    }
    catch(e:any){
        dispatch(setCheckStakeTransferResult({transaction_id:'FAILED'}));
    }
}

export const checkDestakeTransfer = (account: string, assetId : string ) : AppThunk => async(dispatch, _, api) => {
    dispatch(requestCheckStakeTransfer());
    try{
        console.log(`Checking ownership of  ${assetId}`);
        const asset  = await api.atomicAssets.getAsset(assetId);
        
        if(asset != null){
            if(asset.owner == account)
            {
                dispatch(setCheckDestakeTransferResult({transaction_id:'SUCCESS'}));
            }
            else{
                dispatch(setCheckDestakeTransferResult({transaction_id:'WAITING'}));
            }
        }
        else{
            dispatch(setCheckDestakeTransferResult({transaction_id:'WAITING'}));
        }
    }
    catch(e:any){
        dispatch(setCheckDestakeTransferResult({transaction_id:'FAILED'}));
    }
}

export const requestCheckStakeTransfer = () => {
    return{
        type: actionTypes.REQUEST_CHECK_STAKE_TRANSFER,
        error: undefined
    };
};

export const errorCheckStakeTransfer = (error: unknown) => {
    return{
        type: actionTypes.ERROR_STAKE_TRANSFER_ERROR,
        error: error
    };
};

export const setCheckStakeTransferResult = (data: TransactionResult) =>{
   
    return{
        type: actionTypes.SET_STAKE_TRANSFER_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
}

export const requestCheckDestakeTransfer = () => {
    return{
        type: actionTypes.REQUEST_CHECK_DESTAKE_TRANSFER,
        error: undefined
    };
};

export const errorCheckDestakeTransfer = (error: unknown) => {
    return{
        type: actionTypes.ERROR_DESTAKE_TRANSFER_ERROR,
        error: error
    };
};

export const setCheckDestakeTransferResult = (data: TransactionResult) =>{
   
    return{
        type: actionTypes.SET_DESTAKE_TRANSFER_RESULT,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
}