import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { Templates } from "../inventory";
import { DEFENDER, EMOTION, ENFORCERS, FORWARD, GOALKEEPER, MIDFIELDER, PROMOPLAYERS, SERIES1 } from "./constants";
import { getAttributeMap } from "./createMutableAttributes";


export const upgradeMutableAttributes = (asset : IAsset, value : number ) : {key: string; value:(string|number)[]}[] | null =>{
    
    let level = parseInt(asset.mutable_data.Level)+1;
   
    
    switch(asset.mutable_data.Position){
        case GOALKEEPER:
            let goalKeeper =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", GOALKEEPER]},
                {"key": "Reflexes", "value": ["uint64", parseInt(asset.mutable_data.Reflexes)+value]},
                {"key": "Diving", "value": ["uint64",  parseInt(asset.mutable_data.Diving)+value]},
                {"key": "Throwing", "value": ["uint64",  parseInt(asset.mutable_data.Throwing)+value]},
                {"key": "Handling", "value": ["uint64",  parseInt(asset.mutable_data.Handling)+value]},
                {"key": "Physical", "value": ["uint64",  parseInt(asset.mutable_data.Physical)+value]},
                {"key": "Distribution", "value": ["uint64",  parseInt(asset.mutable_data.Distribution)+value]}
            ];
            return goalKeeper;
        case DEFENDER:
            let defender =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", DEFENDER]},
                {"key": "Tackling", "value": ["uint64", parseInt(asset.mutable_data.Tackling)+value]},
                {"key": "Marking", "value": ["uint64",  parseInt(asset.mutable_data.Marking)+value]},
                {"key": "Physical", "value": ["uint64", parseInt(asset.mutable_data.Physical)+value]},
                {"key": "Heading", "value": ["uint64", parseInt(asset.mutable_data.Heading)+value]},
                {"key": "Pace", "value": ["uint64",  parseInt(asset.mutable_data.Pace)+value]},
                {"key": "Passing", "value": ["uint64",  parseInt(asset.mutable_data.Passing)+value]},
            ];
            return defender;   
        case MIDFIELDER:
            let midfielder =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", MIDFIELDER]},
                {"key": "Passing", "value": ["uint64", parseInt(asset.mutable_data.Passing)+value]},
                {"key": "Dribbling", "value": ["uint64",  parseInt(asset.mutable_data.Dribbling)+value]},
                {"key": "Pace", "value": ["uint64",  parseInt(asset.mutable_data.Pace)+value]},
                {"key": "Physical", "value": ["uint64",  parseInt(asset.mutable_data.Physical)+value]},
                {"key": "Technique", "value": ["uint64",  parseInt(asset.mutable_data.Technique)+value]},
                {"key": "Creativity", "value": ["uint64",  parseInt(asset.mutable_data.Creativity)+value]},
            ];
            return midfielder; 
        case FORWARD:
            let forward =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", FORWARD]},
                {"key": "Finishing", "value": ["uint64", parseInt(asset.mutable_data.Finishing)+value]},
                {"key": "Shooting", "value": ["uint64",  parseInt(asset.mutable_data.Shooting)+value]},
                {"key": "Heading", "value": ["uint64",  parseInt(asset.mutable_data.Heading)+value]},
                {"key": "Physical", "value": ["uint64", parseInt(asset.mutable_data.Physical)+value]},
                {"key": "Pace", "value": ["uint64",  parseInt(asset.mutable_data.Pace)+value]},
                {"key": "Passing", "value": ["uint64",  parseInt(asset.mutable_data.Passing)+value]},
            ];
            return forward;
    }
    return null;   
}

export const getUpgradedTav = (asset : IAsset, value : number) : number =>{
    switch(asset.mutable_data.Position){
        case GOALKEEPER:
            return (parseInt(asset.mutable_data.Reflexes)+value)+(parseInt(asset.mutable_data.Diving)+value)+(parseInt(asset.mutable_data.Throwing)+value)+(parseInt(asset.mutable_data.Handling)+value)
            +(parseInt(asset.mutable_data.Physical)+value)+(parseInt(asset.mutable_data.Distribution)+value);
        case DEFENDER:
            return (parseInt(asset.mutable_data.Tackling)+value)+(parseInt(asset.mutable_data.Marking)+value)+(parseInt(asset.mutable_data.Physical)+value)+
                (parseInt(asset.mutable_data.Heading)+value)+(parseInt(asset.mutable_data.Pace)+value)+(parseInt(asset.mutable_data.Passing)+value);
        case MIDFIELDER:
            return (parseInt(asset.mutable_data.Passing)+value)+(parseInt(asset.mutable_data.Dribbling)+value)+(parseInt(asset.mutable_data.Pace)+value)+
            (parseInt(asset.mutable_data.Physical)+value)+(parseInt(asset.mutable_data.Technique)+value)+(parseInt(asset.mutable_data.Creativity)+value);
        case FORWARD:
            return (parseInt(asset.mutable_data.Finishing)+value)+( parseInt(asset.mutable_data.Shooting)+value)+(parseInt(asset.mutable_data.Heading)+value)+
            (parseInt(asset.mutable_data.Physical)+value)+(parseInt(asset.mutable_data.Pace)+value)+(parseInt(asset.mutable_data.Passing)+value);

    }
    return 0;   
}


export const resetMutableAttributes = (position : string, level : number, values : number[]) : {key: string; value:(string|number)[]}[] | null =>{
  // asset.mutable_data.Position
    switch(position){
        case GOALKEEPER:
            let goalKeeper =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", GOALKEEPER]},
                {"key": "Reflexes", "value": ["uint64", values[0]]},
                {"key": "Diving", "value": ["uint64",  values[1]]},
                {"key": "Throwing", "value": ["uint64",  values[2]]},
                {"key": "Handling", "value": ["uint64", values[3]]},
                {"key": "Physical", "value": ["uint64",  values[4]]},
                {"key": "Distribution", "value": ["uint64",  values[5]]}
            ];
            return goalKeeper;
        case DEFENDER:
            let defender =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", DEFENDER]},
                {"key": "Tackling", "value": ["uint64", values[0]]},
                {"key": "Marking", "value": ["uint64",  values[1]]},
                {"key": "Physical", "value": ["uint64", values[2]]},
                {"key": "Heading", "value": ["uint64", values[3]]},
                {"key": "Pace", "value": ["uint64",  values[4]]},
                {"key": "Passing", "value": ["uint64",  values[5]]},
            ];
            return defender;   
        case MIDFIELDER:
            let midfielder =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", MIDFIELDER]},
                {"key": "Passing", "value": ["uint64", values[0]]},
                {"key": "Dribbling", "value": ["uint64",  values[1]]},
                {"key": "Pace", "value": ["uint64",  values[2]]},
                {"key": "Physical", "value": ["uint64", values[3]]},
                {"key": "Technique", "value": ["uint64",  values[4]]},
                {"key": "Creativity", "value": ["uint64",  values[5]]},
            ];
            return midfielder; 
        case FORWARD:
            let forward =[ 
                {"key": "Level", "value": ["uint64", level]},
                {"key": "Position", "value": ["string", FORWARD]},
                {"key": "Finishing", "value": ["uint64", values[0]]},
                {"key": "Shooting", "value": ["uint64",  values[1]]},
                {"key": "Heading", "value": ["uint64",  values[2]]},
                {"key": "Physical", "value": ["uint64", values[3]]},
                {"key": "Pace", "value": ["uint64",  values[4]]},
                {"key": "Passing", "value": ["uint64",  values[5]]},
            ];
            return forward;
    }
    return null;
}



export const resetPlayerPosition = (asset : IAsset) : {key: string; value:(string|number)[]}[] | null =>{
    // here we will take the attributes values and apply to the appropriate attributes and also reset the position
    // to the template position value (real-life position)
    // get the commoin Physical attribute value
    let currentAttributeValue = parseInt(asset.mutable_data.Physical);
    let array = Array<number>(6);
    array.fill(currentAttributeValue);
    let template = Templates[asset.template?.template_id!];
    let values = resetMutableAttributes(template.position,parseInt(asset.mutable_data.Level), array);
    return values;
}


const getRandomUpgradeValues = (asset : IAsset) : number[] => {
    let array = Array<number>(6);
    let range = getValueRange(asset);
    for(let i=0;i<array.length;i++){
        array[i] = randomIntFromInterval(range.min, range.max);
    }
    return array;
}

const getValueRange = (asset : IAsset) : {min:number, max:number} =>{
    switch(asset.schema.schema_name){
        case SERIES1:
            switch(asset.data.Rarity){
                case "Common":
                    return {min:1, max:3};
                case "Uncommon":
                    return {min:1, max:4};
                case "Limited":
                    return {min:1, max:5};
                case "Relic":
                    return {min:1, max:7};
                case "Rare":
                    return {min:1, max:10};
                case "Epic":
                    return {min:1, max:30};
                case "Legendary":
                    return {min:1, max:40};
                case "Mythical":
                    return {min:1, max:70};
                case "Celestial":
                    return {min:1, max:100};
                case "Transfer":
                    return {min:1, max:40};
                case "Manager":
                    return {min:1, max:40};
            }
            break;
        case EMOTION:
            return {min:1, max:40};
        case ENFORCERS:
            switch(asset.data.Rarity){
                case "Base":
                    return {min:1, max:7};
                case "Light Bruising":
                    return {min:1, max:10};
                case "Yellow Card":
                    return {min:1, max:10};
                case "The Terry Butcher":
                    return {min:1, max:30};
                case "Red Card":
                    return {min:1, max:40};
                case "Locked Up":
                    return {min:1, max:70};
                case "Halo":
                    return {min:1, max:40};
                case "MOTM":
                    return {min:1, max:40};
                case "Red Mist":
                    return {min:1, max:70};
            }
            break;
        case PROMOPLAYERS:
            return {min:1, max:40};
        default:
            // add zeros for non mapped cards - we shouldn't get here
            return {min:1, max:3};
    }
    return {min:1, max:3};
}

export const randomIntFromInterval = (min : number, max : number) : number => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}