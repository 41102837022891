import { useMediaQuery, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Templates } from "../../inventory";
import { fetchSingleAsset2 } from "../../store/actions/userAssets";
import { RootState } from "../../store/reducers";
import {
  BRYLCREEM,
  CIGGY,
  CLEAN_BOOTS,
  PREP_TALK,
  STRETCHING,
  WALK,
} from "../../utils/constants";
import { createMutableAttributes } from "../../utils/createMutableAttributes";
import { webServiceInstance } from "../../utils/webServiceInstance";

type OnboardPlayerProps = {
  // pass position i.e. Forward, Midfield, Defender etc?
  showDialog: boolean;
  position: string;
  asset: IAsset | null;
  onPlayerOnboarded(playerIsReady: boolean): void;
};

export const OnboardPlayer: FC<OnboardPlayerProps> = ({
  showDialog,
  position,
  asset,
  onPlayerOnboarded,
}) => {
  const dispatch = useDispatch<any>();
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: userAssets } = useSelector(
    (state: RootState) => state.userAssets
  );
  const [loadingCounter, setloadingCounter] = useState(-1);
  const [message, setMessage] = useState(STRETCHING);
  const [showLoading, setShowLoading] = useState(true);
  const [onBoardPlayer, setOnBoardPlayer] = useState(false);
  const [playerIsReady, setPlayerIsReady] = useState(false);
  const [newAttributes, setNewAttributes] = useState<
    { key: string; value: (string | number)[] }[] | null
  >(null);
  const [error, setError] = useState(false);
  const [pollRefresh, setPollRefresh] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (showDialog) {
      console.log("START ONBOARDING...");
      setTimeout(() => {
        setloadingCounter(0);
      }, 500);
    }
  }, [showDialog]);

  useEffect(() => {
    if (!pollRefresh) {
      return;
    }
    if (userAssets.assets != null && asset != null) {
      let tmpAsset = userAssets.assets[parseInt(asset.asset_id)];
      if (tmpAsset) {
        console.log("Checking if the player is ready");
        if (Object.keys(tmpAsset.mutable_data).length == 0) {
          setTimeout(() => {
            console.log("Player is still not ready, refresh player");
            dispatch(fetchSingleAsset2(tmpAsset.asset_id));
          }, 3000);
        } else {
          setPollRefresh(false);
          setShowLoading(false);
          setPlayerIsReady(true);
        }
      }
    }
  }, [userAssets.assets, pollRefresh]);

  useEffect(() => {
    if (loadingCounter == 0) {
      setTimeout(() => {
        setMessage(BRYLCREEM);
      }, 800);
    }
    if (loadingCounter == 1) {
      setTimeout(() => {
        setMessage(CLEAN_BOOTS);
      }, 800);
    }
    if (loadingCounter == 2) {
      setTimeout(() => {
        setMessage(CIGGY);
      }, 800);
    }
    if (loadingCounter == 3) {
      setTimeout(() => {
        setMessage(PREP_TALK);
      }, 800);
    }
    if (loadingCounter == 4) {
      setTimeout(() => {
        setMessage(PREP_TALK);
      }, 500);
    }
  }, [loadingCounter]);

  useEffect(() => {
    if (message == BRYLCREEM) {
      setloadingCounter(1);
    }
    if (message == CLEAN_BOOTS) {
      setloadingCounter(2);
    }
    if (message == CIGGY) {
      setloadingCounter(3);
    }
    if (message == PREP_TALK) {
      // now show the player with their new attributes
      setTimeout(() => {
        setOnBoardPlayer(true);
      }, 1000);
    }
    if (message == WALK) {
    }
  }, [message]);

  useEffect(() => {
    if (onBoardPlayer) {
      const addAttributes = async () => {
        // Create attributes
        if (asset != null) {
          console.log("Creating attributes for " + asset.asset_id);
          let newAttributes = createMutableAttributes(position, asset);

          if (newAttributes != null) {
            console.log(newAttributes);

            // TEST
            // setTimeout(() => {
            //   // fetching immediately after updating the asset via a transaction doesn't contain the new attributes (race condition I guess, the transaction hasn't been processed)
            //   //dispatch(fetchSingleAsset2(asset.asset_id));
            //   setShowLoading(false);
            //   setNewAttributes(newAttributes);
            //   setPlayerIsReady(true);
            // }, 1500);

            // PROD CODE
            try {
              const result = await webServiceInstance.post("/updateassetdata", {
                assetId: asset?.asset_id,
                owner: userData.account,
                attributes: newAttributes,
              });
              console.log(result);
              if (result.data == "ERROR") {
                setShowLoading(false);
                setError(true);
              } else {
                //console.log(result.data.transaction_id);
                console.log(result);

                // const attributes: { [key: string]: any } = {};
                // Object.entries(newAttributes).map(([index, value]) => {
                //   attributes[Object.values(value)[0].toString()] =
                //     Object.values(value)[1][1];
                // });

                setTimeout(() => {
                  // fetching immediately after updating the asset via a transaction doesn't contain the new attributes (race condition I guess, the transaction hasn't been processed)
                  setMessage(WALK);
                  setNewAttributes(newAttributes);
                  setPollRefresh(true);
                }, 2000);
              }
            } catch (e: any) {
              console.log(e);
              setShowLoading(false);
              setError(true);
            }
          }
        }
      };

      addAttributes();
    }
  }, [onBoardPlayer]);

  const onClose = () => {
    onPlayerOnboarded(playerIsReady);
    setloadingCounter(-1);
    setMessage(STRETCHING);
    setShowLoading(true);
    setOnBoardPlayer(false);
    setPlayerIsReady(false);
    setNewAttributes(null);
    setError(false);
    setPollRefresh(false);
    // if (asset != null) {
    //   dispatch(fetchSingleAsset2(asset.asset_id));
    // }
  };

  const onBoardingComplete = async () => {
    // TODO: report success to TeamSetup screen.

    onClose();
  };

  const viewAsset = () => {
    window.open(
      "https://wax.atomichub.io/explorer/asset/" + asset?.asset_id,
      "_blank"
    );
  };

  return (
    <Dialog open={showDialog} maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        {playerIsReady && <span>{asset?.name} is ready to go!</span>}
        {playerIsReady == false && <span>{asset?.name} is warming up!</span>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {playerIsReady == false && (
            <span>
              Onboarding {asset?.name} ({asset?.data.Rarity}) into the game...
            </span>
          )}
          {playerIsReady && <span>Check out his new attributes...</span>}
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            width: "100%",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            height: "350px",
          }}
        >
          <Fade in={showLoading}>
            <Box
              sx={{
                display: showLoading ? "flex" : "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
                flex: 1,
              }}
            >
              <CircularProgress sx={{ marginTop: "20px" }} color="secondary" />
              <div style={{ marginTop: "20px" }}>{message}</div>
            </Box>
          </Fade>
          <Fade in={showLoading == false}>
            <div
              style={{ display: "flex", gap: "20px", flexDirection: "column" }}
            >
              <div
                style={{
                  display: showLoading ? "none" : "flex",
                  gap: "20px",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignItems: "stretch",
                  flex: 1,
                }}
              >
                <div style={{ display: "flex" }}>
                  {asset != null && (
                    <img
                      src={
                        "images/" +
                        Templates[asset?.template!.template_id!].imgPath
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "250px",
                    border: "1px solid #99ff32",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#000b1d",
                      height: "50px",
                      borderBottom: "1px solid #99ff32",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    <div style={{}}>{!mobile && asset?.name}</div>
                    <div style={{ fontSize: "10px" }}>
                      Asset: {asset?.asset_id}, Rarity: {asset?.data.Rarity}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      flexDirection: "column",
                      marginTop: "3px",

                      marginRight: "3px",
                    }}
                  >
                    {newAttributes &&
                      Object.entries(newAttributes).map(([index, value]) => (
                        <div style={{ display: "flex", lineHeight: "32px" }}>
                          <div style={{ flex: "1" }}>
                            <span style={{ marginLeft: "10px" }}>
                              {Object.values(value)[0]}
                            </span>
                          </div>
                          <div style={{ flex: "1" }}>
                            <span
                              style={{ marginLeft: "10px", fontWeight: "bold" }}
                            >
                              {Object.values(value)[1][1]}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {playerIsReady && (
                  <div style={{ flex: 1, display: "flex" }}>
                    <Button
                      sx={{ marginLeft: "10px" }}
                      size="small"
                      variant="contained"
                      className="blue-btn"
                      onClick={viewAsset}
                    >
                      View on Atomic hub
                    </Button>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        size="small"
                        sx={{ width: "50px" }}
                        variant="contained"
                        className="green-btn"
                        onClick={onBoardingComplete}
                      >
                        ok
                      </Button>
                    </div>
                  </div>
                )}
                {error && (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Collapse in={error}>
                      <Alert
                        variant="filled"
                        severity="error"
                        onClose={() => {
                          onClose();
                        }}
                      >
                        An error occurred while onboarding
                      </Alert>
                    </Collapse>
                  </div>
                )}
              </div>
            </div>
          </Fade>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
