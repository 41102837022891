import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";



export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#99ff32',
      },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#001636',
     
      maxWidth:520,
     
      border: '1px solid #99ff32',
    },
  }));