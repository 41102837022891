import * as actionTypes from '../actions/types';
import { TransactionResult } from "../../types/TransactionResult";
import { InitialState, ReducerAction } from "../configureStore";


const initialState: InitialState<TransactionResult>= {
    isFetching: true,
    error: undefined,
    data:{
       transaction_id : null
    }
};

export const airgrabResult = (state = initialState,
    action: ReducerAction<TransactionResult>)=>{
        switch(action.type){
            case actionTypes.RECEIVE_AIRGRAB_VINTY:
                return{
                    ...state,
                    data: action.payload.data
                };
            default:
                return state;
        }
}

export const saveTeamResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{
    switch(action.type){
        case actionTypes.SAVE_TEAM_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export const deleteTeamResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{
    switch(action.type){
        case actionTypes.DELETE_TEAM_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export const burnAttributeCardResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{
    switch(action.type){
        case actionTypes.BURN_ATTRIBUTE_CARD_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export const stakeTransferResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{
    switch(action.type){
        case actionTypes.SET_STAKE_TRANSFER_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export const destakeTransferResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{
    switch(action.type){
        case actionTypes.SET_DESTAKE_TRANSFER_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export const sendTokensResult = (state = initialState,
    action: ReducerAction<TransactionResult>) =>{
        switch(action.type){
            case actionTypes.SEND_TOKENS_RESULT:
                return{
                    ...state,
                    data: action.payload.data
                };
            default:
                return state;
        }
}

export const enterTournoResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{

    switch(action.type){
        case actionTypes.ENTER_TOURNAMENT_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}

export const startMatchResult = (
    state = initialState,
    action: ReducerAction<TransactionResult>
)=>{
    
    switch(action.type){
        case actionTypes.START_MATCH_RESULT:
            return{
                ...state,
                data: action.payload.data
            };
        default:
            return state;
    }
}