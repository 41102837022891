import { Config } from "../../types/Config";
import { VINTYNIFTYS_ACCOUNT } from "../../utils/constants";
import * as actionTypes from '../actions/types';
import { AppThunk } from "../configureStore";


export const fetchConfig = (): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestConfig());
    try{
        const response = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'config',        // Table name        
            limit: 1,               // maximum number of rows that we want to get
            reverse: true
        });
        if(response.rows.length > 0){
            let data = response.rows[0];
            const config: Config={
                matchesLocked: data.matches_locked,
                appLocked: data.app_locked,
                maxTeams: data.max_teams,
            };
            console.log(config);
            dispatch(receiveConfig(config));
        }
        else{
            dispatch(errorConfig('Could not retrieve config. Please contact VN support :)'));
        }
    }
    catch(e){
        dispatch(errorConfig(e));
    }
};

export const toggleAppLocked = (activeUser: any, locked : boolean): AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'lockapp',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                   lock: locked
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('App could not be ' + (locked ? 'locked' : 'unlocked') + '. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('App has been ' + (locked ? 'locked' : 'unlocked') + '. Transaction Id ' + result.transaction.transaction_id);
            // re-fetch all teams + players
            dispatch(updateLockedStatus({appLocked:locked}));
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
}

export const toggleMatchesLocked = (activeUser: any, locked : boolean): AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'lockmatches',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    matches_locked: locked
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Matches could not be ' + (locked ? 'locked' : 'unlocked') + '. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('Matches has been ' + (locked ? 'locked' : 'unlocked') + '. Transaction Id ' + result.transaction.transaction_id);
            // re-fetch all teams + players
            dispatch(updateMatchesLockedStatus({matchesLocked:locked}));
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
}

export const updateMaxTeamsConfig = (activeUser: any, maxTeams : number) : AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'maxteams',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    max_teams: maxTeams
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Could not update max teams. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('Max teams updated to' +  maxTeams + '. Transaction Id ' + result.transaction.transaction_id);
            dispatch(setMaxTeamsConfig({maxTeams:maxTeams}));
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
}

export const addTournament = (activeUser: any, name : string, startDate : number, endDate : number, teamCount : number, gameCount : number, buyIn : number, prize : number, active : boolean, unlockLimit : number) : AppThunk => async(dispatch, _, api) =>{
    try{
        
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'addtourno',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    name: name,
                    start_date : startDate,
                    end_date : endDate,
                    team_count : teamCount,
                    game_count : gameCount,
                    buy_in : buyIn,
                    prize: prize,
                    active : active,
                    unlocklimit : unlockLimit
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Could not add tournament. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('Tournament updated. Transaction Id ' + result.transaction.transaction_id);
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
}

export const updateTournament = (activeUser: any, id : number, name : string, startDate : number, endDate : number, teamCount : number, gameCount : number, buyIn : number, prize : number, active : boolean, unlockLimit : number) : AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'updatetourno',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    id: id,
                    name: name,
                    start_date : startDate,
                    end_date : endDate,
                    team_count : teamCount,
                    game_count : gameCount,
                    buy_in : buyIn,
                    prize: prize,
                    active : active,
                    unlocklimit : unlockLimit,
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Could not update tournament. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('Tournament updated. Transaction Id ' + result.transaction.transaction_id);
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
}

export const deleteTournament  = (activeUser: any, id : number): AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'deltourno',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    id: id
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Could not delete tournament. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('Tournament deleted. Transaction Id ' + result.transaction.transaction_id);
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
};

export const closeTournament  = (activeUser: any, id : number, winningTeamId : number, manager : string): AppThunk => async(dispatch, _, api) =>{
    try{
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: VINTYNIFTYS_ACCOUNT,
                name:  'closetourno',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    id: id,
                    winning_team : winningTeamId,
                    manager : manager
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 30,
        });

        if(result.error != null){
            console.log('Could not close tournament. Error ' + result.error);
            dispatch(errorConfig(result.error));
        }
        else{
            console.log('Tournament closed. Transaction Id ' + result.transaction.transaction_id);
        }
    }
    catch(e:any){
        dispatch(errorConfig(e));
    }
};


export const requestConfig = () => {
    return{
        type: actionTypes.REQUEST_CONFIG,
        error: undefined
    };
};

export const receiveConfig = (config: Config) =>{
   
    return{
        type: actionTypes.RECEIVE_CONFIG,
        payload:{
            data: config,
        },
        error: undefined,
        isFetchig: false 
    };
};

export const updateLockedStatus = (config: Config)=>{
    return{
        type: actionTypes.UPDATE_LOCKED_STATUS,
        payload:{
            data: config,
        },
        error: undefined,
        isFetchig: false 
    };
};

export const updateMatchesLockedStatus = (config: Config)=>{
    return{
        type: actionTypes.UPDATE_MATCHES_LOCKED_STATUS,
        payload:{
            data: config,
        },
        error: undefined,
        isFetchig: false 
    };
};

export const setMaxTeamsConfig = (config: Config) =>{
    return{
        type: actionTypes.UPDATE_MAX_TEAMS,
        payload:{
            data: config,
        },
        error: undefined,
        isFetchig: false 
    };
}

export const errorConfig = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_CONFIG,
        error: error
    };
};




