import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readyTeam } from "../store/actions/gameData";
import { RootState } from "../store/reducers";
import { useUAL } from "../utils/useUAL";

const TestTransaction = () => {
  //const matchState = useLocation().state as MatchResultProps;
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const dispatch = useDispatch<any>();
  const ual = useUAL() as any;
  const [message, setMessage] = useState("");
  const { data: saveResult, error: saveError } = useSelector(
    (state: RootState) => state.saveTeamResult
  );

  //   useEffect(() => {
  //     if (gameData.tournamentPositions != null) {
  //       let pos = gameData.tournamentPositions.find(
  //         (t) => t.manager === userData.userName
  //       );
  //       if (pos != null && gameData.userTeams != null) {
  //         let team = gameData.userTeams[pos.teamId];
  //         dispatch(readyTeam(userData, ual["activeUser"], team, !team.locked));
  //         //setMyTeam(team);
  //       }
  //     }
  //   }, []);

  useEffect(() => {
    if (saveResult.transaction_id != null) {
      if (saveResult.transaction_id == "ERROR") {
        setMessage("error");
        return;
      }
      setMessage("we good");

      //   setTimeout(() => {
      //     // simulate a lag so when we query the blockchain table the data will have been committed

      //     if (saveResult.transaction_id == "ERROR") {
      //       // setShowLoading(false);
      //       //setMessage("An error occurred, please contact VN!");
      //       setError(saveResult.error!);
      //       return;
      //     }
      //     setError("WE GOOD");
    }
  }, [saveResult]);

  const testButton = () => {
    if (gameData.tournamentPositions != null) {
      let pos = gameData.tournamentPositions.find(
        (t) => t.manager === userData.userName
      );
      if (pos != null && gameData.userTeams != null) {
        let team = gameData.userTeams[pos.teamId];
        dispatch(readyTeam(userData, ual["activeUser"], team, !team.locked));
        //setMyTeam(team);
      }
    }
  };

  return (
    <div style={{ color: "#fff" }}>
      <Button onClick={testButton}>Test</Button>
    </div>
  );
};
export default TestTransaction;
