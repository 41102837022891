import { TransactionResult } from "../../types/TransactionResult";
import { User } from "../../types/User";
import {  EOSIO_ACCOUNT, VINTYNIFTYS_ACCOUNT, VINTY, VINTYTOKENS_ACCOUNT } from "../../utils/constants";
import * as actionTypes from '../actions/types';
import { AppThunk } from "../configureStore";


export const requestAccountInfo = () =>{
    return{
        type: actionTypes.REQUEST_ACCOUNT_INFO,
        error: undefined
    };
};

export const receiveAccountInfo = (user : User) =>{
    return{
        type: actionTypes.RECEIVE_ACCOUNT_INFO,
        payload:{
            data: user
        },
        error: undefined
    };
};

export const errorAccountInfo = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_ACCOUNT_INFO,
        error: error
    };
};

export const requestWaxBalance = () => {
    return{
        type: actionTypes.REQUEST_USER_WAX_BALANCE,
        error: undefined
    };
};

export const receiveWaxBalance = (user: User) =>{
    
    return{
        type: actionTypes.RECEIVE_USER_WAX_BALANCE,
        payload:{
            data: user
        },
        error: undefined,
        isFetching: false 
    };
};

export const errorWaxBalance = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER_WAX_BALANCE,
        error: error
    };
};

export const requestVintyBalance = () => {
    return{
        type: actionTypes.REQUEST_USER_VINTY_BALANCE,
        error: undefined
    };
};

export const receiveVintyBalance = (user: User) =>{
    
    return{
        type: actionTypes.RECEIVE_USER_VINTY_BALANCE,
        payload:{
            data: user
        },
        error: undefined,
        isFetching: false 
    };
};

export const errorVintyBalance = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER_VINTY_BALANCE,
        error: error
    };
};


export const fetchAccountInfo = (account: string): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestAccountInfo());
    try{
        const data = await api.waxRpc.get_account(account);
        const user : User = {
            account: account,
            cpu: (data.cpu_limit.used/data.cpu_limit.max)*100,
            net:(data.net_limit.used/data.net_limit.max)*100,
            ram:(data.ram_usage/data.ram_quota)*100

        }
     
       // console.log(user);
        dispatch(receiveAccountInfo(user));
    }
    catch(e){
        dispatch(errorAccountInfo(e));
    }
};

export const fetchWaxBalance = (account: string): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestWaxBalance());
    try{
        const data = await api.waxRpc.get_currency_balance(EOSIO_ACCOUNT, account,'WAX');
        console.log("WAX bal " + data);
        var parts = data[0].split(' ');
        let bal =  parseFloat(parseFloat(parts[0]).toFixed(0));
        console.log(bal);
        const user : User = {
            account: account,
            waxBalance: bal
        }
        dispatch(receiveWaxBalance(user));
    }
    catch(e){
        dispatch(errorWaxBalance(e));
    }
};

export const fetchVintyBalance = (account: string): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestVintyBalance());
    try{
        const data = await api.waxRpc.get_currency_balance(VINTYTOKENS_ACCOUNT, account,'VNTY');
        console.log("VNTY bal " + data);
        var parts = data[0].split(' ');
        let bal =  parseFloat(parseFloat(parts[0]).toFixed(4));
        console.log(bal);
        const user : User = {
            account: account,
            vintyBalance: bal
        }
        dispatch(receiveVintyBalance(user));
    }
    catch(e){
        dispatch(errorVintyBalance(e));
    }
};


export const updateAvatar = (account : string, avatarUrl : string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestUpdateAvatar());
    try{
       
        const user : User = {
            account: account,
           avatarUrl: avatarUrl
        }
        dispatch(receiveUpdateAvatar(user));
    }
    catch(e){
        dispatch(errorUpdateAvatar(e));
    }

}

export const checkVintyAccountExists = (account: string): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestVintyUser());
    try{
        console.log("Checking if vinty account exists.")
        const data = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYTOKENS_ACCOUNT,     // Contract that we target      
            scope: account,        // Account that owns the data   
            table: 'accounts',        // Table name     
            limit: 1,              // maximum number of rows that we want to get
        });
        if(data.rows.length == 1){
            const user : User = {
                account: account,
                hasVintyAccount: true
            };
            dispatch(receiveVintyUser(user));
        }
        console.log(data);
    }
    catch(e:any){
        dispatch(errorVintyUser(e));
    }
}

export const airgrabVintyTokens = (activeUser: any): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestAirgrabVinty());
    try{
        const result = await activeUser.signTransaction(
            {
              actions: [
                {
                  account: VINTYTOKENS_ACCOUNT,
                  name: "airgrab",
                  authorization: [
                    {
                      actor: activeUser["accountName"],
                      permission: activeUser.requestPermission,
                    },
                  ],
                  data: {
                    owner: activeUser["accountName"],
                  },
                },
              ],
            },
            {
              blocksBehind: 3,
              expireSeconds: 30,
            }
          );

        if(result.error != null){
            console.log("could not airgrab tokens");
            dispatch(receiveAirgrabTokens({transaction_id: "ERROR"}));
        }
        else{
            console.log('Airgrab successful. Transaction Id ' + result.transaction.transaction_id);
            dispatch(receiveAirgrabTokens({transaction_id: result.transaction.transaction_id}));
        }
    }
    catch(e:any){
        console.log(e);
        dispatch(receiveAirgrabTokens({transaction_id: "ERROR"}));
    }
}

export const fetchUser = (account: string): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestUser());
    try{
        const data = await api.waxRpc.get_table_rows({
            json: true,              // Get the response as json
            code: VINTYNIFTYS_ACCOUNT,     // Contract that we target      
            scope: VINTYNIFTYS_ACCOUNT,        // Account that owns the data   
            table: 'users',        // Table name     
            lower_bound: account,   
            upper_bound: account,
            limit: 1,              // maximum number of rows that we want to get
        });
        console.log(data);
        if(data.rows.length == 1){
            const user: User = {
                account: account,
                userName: data.rows[0].username,
                club: data.rows[0].clubname,
                ground: data.rows[0].ground,
                exists: true,
                isAdmin: account == VINTYNIFTYS_ACCOUNT,
            };
            dispatch(receiveUser(user));
        }
        else{
            const user: User = {
                account: account,
                exists: false
            };
            dispatch(receiveUser(user));
        }
        
    }
    catch(e){
        dispatch(errorUser(e));
    }
};

export const requestUserReg = () => {
    return{
        type: actionTypes.REQUEST_USER_REGISTRATION,
        error: undefined
    };
};

export const receiveUserReg = (user: User) =>{
  
    return{
        type: actionTypes.RECEIVE_USER_REGISTRATION,
        payload:{
            data: user
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorUserReg = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER_REGISTRATION,
        error: error
    };
};

export const registerUser = (activeUser: any, username: string, club: string, ground: string): AppThunk => async(dispatch, _, api) =>{
    dispatch(requestUserReg());
    try{
        const result = await activeUser.signTransaction(
            {
                actions: [{
                    account: VINTYNIFTYS_ACCOUNT,
                    name: 'registeruser',
                    authorization: [{
                        actor: activeUser['accountName'],
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        user: activeUser['accountName'],
                        username: username,
                        clubname: club,
                        ground: ground
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 300,
            });
        
        if(result.error != null){
            dispatch(errorUserReg(result.error));
        }
        else{
            const user: User = {
                account: activeUser['accountName'],
                userName: username,
                club: club,
                ground: ground,
                exists: true
            };
            dispatch(receiveUserReg(user));
        }
        
    }
    catch(e){
        dispatch(errorUserReg(e));
    }
}


export const requestUser = () => {
    return{
        type: actionTypes.REQUEST_USER,
        isFetching: true,
        error: undefined
    };
};

export const receiveUser = (user: User) =>{
  
    return{
        type: actionTypes.RECEIVE_USER,
        payload:{
            data: user
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorUser = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_USER,
        error: error
    };
};

export const requestVintyUser = () =>{
    return{
        type: actionTypes.REQUEST_VINTY_USER,
        isFetching: true,
        error: undefined
    };
}

export const receiveVintyUser = (user : User) =>{
    return{
        type: actionTypes.RECEIVE_VINTY_USER,
        payload:{
            data: user
        },
        error: undefined,
        isFetchig: false 
    };
}

export const errorVintyUser = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_VINTY_USER,
        error: error
    };
}

export const requestAirgrabVinty = () =>{
    return{
        type: actionTypes.REQUEST_AIRGRAB_VINTY,
        isFetching: true,
        error: undefined
    };
}

export const errorAirgrabVinty = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_AIRGRAB_VINTY,
        error: error
    };
}

export const receiveAirgrabTokens = (data: TransactionResult)=>{
    return{
        type: actionTypes.RECEIVE_AIRGRAB_VINTY,
        payload:{
            data: data
        },
        error: undefined,
        isFetchig: false 
    };
}

export const requestUpdateAvatar = () => {
    return{
        type: actionTypes.REQUEST_UPDATEAVATAR,
        isFetching: true,
        error: undefined
    };
};

export const receiveUpdateAvatar = (user: User) =>{
  
    return{
        type: actionTypes.RECEIVE_UPDATEAVATAR,
        payload:{
            data: user
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorUpdateAvatar = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_UPDATEAVATAR,
        error: error
    };
};

export const requestSendTokens = () =>{
    return{
        type: actionTypes.REQUEST_SEND_TOKENS,
        isFetching: true,
        error: undefined
    };
}

export const setSendTokensResult = (result: TransactionResult) =>{
    return{
        type: actionTypes.SEND_TOKENS_RESULT,
        payload:{
            data: result
        },
        error: undefined,
        isFetchig: false 
    };
};

const errorSendTokens = (error: any) =>{
    return{
        type: actionTypes.ERROR_SEND_TOKENS,
        error: error
    };
};

export const sendTokens = (activeUser: any, to: string, amount : string, tokenType : string, memo : string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestSendTokens());
    try{
        let quantity = formatTokenAmount(amount, tokenType, true);
        console.log(`Sending ${quantity} to ${to}`);

        // NOTE: if token is VNTY send account is the vintytokens
        const result = await activeUser.signTransaction(
        {
            actions: [{
                account: tokenType == VINTY ? VINTYTOKENS_ACCOUNT :  EOSIO_ACCOUNT,
                name: 'transfer',
                authorization: [{
                    actor: activeUser['accountName'],
                    permission: activeUser.requestPermission
                }],
                data: {
                    from: activeUser['accountName'],
                    to: to,
                    quantity: quantity,
                    memo: memo
                }
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 300,
        });

        if(result.error != null){
            dispatch(setSendTokensResult({transaction_id: "ERROR"}));
        }
        else{
            
            dispatch(setSendTokensResult({transaction_id: result.transaction.transaction_id}));
        }
    }
    catch(e:any){
        dispatch(errorSendTokens(e));
    }
}

export const formatTokenAmount = (num : string, tokenType : string, addSymbol : boolean) : string =>{
    if(num.indexOf('.') != -1){
        
        let numParts = num.split('.');
        let numDp = numParts[1].length;
        // vinty or wax
        let precision = tokenType == VINTY ? 4 : 8;
        let trailingZeroCount = precision - numDp;
        if(trailingZeroCount > 0){
            console.log(trailingZeroCount);
            let newAmount = num.padEnd(num.length + trailingZeroCount, '0');
            console.log(newAmount);
            return addSymbol ? `${newAmount} ${tokenType}` : newAmount;
        }
        else{
            // format whole number
            return formatTokenAmount(numParts[0], tokenType, addSymbol);
        }
    }
    else{
        // whole number
        let amount = `${num}${tokenType == VINTY ? '.0000' : '.00000000'}`;
        amount = addSymbol ? `${amount} ${tokenType}` : amount;
        return amount;
    }
}
