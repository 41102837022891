import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readyTeam } from "../../store/actions/gameData";
import { setSaveTeamResult } from "../../store/actions/transactions";
import { RootState } from "../../store/reducers";
import { Team } from "../../types/Team";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { createNextState } from "@reduxjs/toolkit";

type LockTeamProps = {
  showDialog: boolean;
  team: Team | null;
  isTeamLocked: boolean;
  onLock(locked: boolean): void;
};

export const LockTeam: FC<LockTeamProps> = ({
  showDialog,
  team,
  isTeamLocked,
  onLock,
}) => {
  const { data: userData } = useSelector(
    (state: RootState) => state.userSession
  );
  const { data: saveResult, error: saveError } = useSelector(
    (state: RootState) => state.saveTeamResult
  );
  const dispatch = useDispatch<any>();
  const ual = useUAL() as any;
  const [showLoading, setShowLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    if (showDialog == true) {
      const activeUser = ual["activeUser"];

      if (team != null) {
        // current locked status (set in teamSetup.tsx)
        console.log("team is current locked: " + isTeamLocked);
        setIsLocked(isTeamLocked);

        dispatch(readyTeam(userData, activeUser, team, !isTeamLocked));
      }
    }
  }, [showDialog]);

  const onClose = () => {
    onLock(isLocked);
    setShowLoading(true);
    setError(false);
    setMessage("");
    dispatch(setSaveTeamResult({ transaction_id: null }));
  };

  useEffect(() => {
    if (saveResult.transaction_id != null) {
      setTimeout(() => {
        // simulate a lag so when we query the blockchain table the data will have been committed

        if (saveResult.transaction_id == "ERROR") {
          setShowLoading(false);
          setMessage("An error occurred, please contact VN!");
          setError(true);
          return;
        }

        // flick ready status
        setIsLocked(!isLocked);

        setShowLoading(false);
        setMessage("Done!");
      }, 1500);
    }
  }, [saveResult]);

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            width: "300px",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            height: "200px",
          }}
        >
          <Fade in={showLoading}>
            <div
              style={{
                flex: 1,
                display: showLoading ? "flex" : "none",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="secondary" />
              <div style={{ marginTop: "20px" }}>
                {isTeamLocked ? "Unlocking" : "Locking"} team...
              </div>
            </div>
          </Fade>
          <Fade in={!showLoading}>
            <div
              style={{
                flex: 1,
                display: showLoading ? "none" : "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!error && (
                <DoneIcon color="secondary" sx={{ fontSize: "100px" }} />
              )}
              {error && <CloseIcon color="error" sx={{ fontSize: "100px" }} />}
              <div
                style={{
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{message}</span>
              </div>
              <div>
                <Button
                  size="small"
                  className="green-btn"
                  variant="outlined"
                  onClick={() => onClose()}
                >
                  OK
                </Button>
              </div>
            </div>
          </Fade>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
