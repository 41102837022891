import { DEFENDER, FORWARD, GOALKEEPER, MIDFIELDER } from "./constants";
import { defenderPositionSort, forwardPositionSort, goalKeeperPositionSort, midfielderPositionSort } from "./createMutableAttributes";


export const sortAttributes = (attributes : string[], position : string, removeLevel : boolean) : string[] => {
    attributes.sort(function(a, b){
        switch(position){
            case FORWARD:
                return forwardPositionSort.indexOf(a) - forwardPositionSort.indexOf(b);
            case DEFENDER:
                return defenderPositionSort.indexOf(a) - defenderPositionSort.indexOf(b);
            case MIDFIELDER:
                return midfielderPositionSort.indexOf(a) - midfielderPositionSort.indexOf(b);
            case GOALKEEPER:
                return goalKeeperPositionSort.indexOf(a) - goalKeeperPositionSort.indexOf(b);
        }
        return 0;
    });
    if(removeLevel){
        attributes.splice(0,1);
    }
    //console.log(position + ' ' + attributes);
    return attributes;
}