import { Alert, Button, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/reducers";
import AddIcon from "@mui/icons-material/Add";
import { Team } from "../types/Team";
import {
  FORMATION_3142,
  FORMATION_343,
  FORMATION_352,
  FORMATION_4321,
  FORMATION_433,
  FORMATION_4411,
  FORMATION_442,
  FORMATION_442_DIAMOND,
  FORMATION_451,
  FORMATION_532,
  FORMATION_541,
  MYTEAMS_ROUTE,
  TEAMSETUP_ROUTE,
} from "../utils/constants";
import { Header } from "./components/Header";
import { NavBar } from "./components/NavBar";
import { MyTeam3142 } from "./formations/myteam/3142";
import { MyTeam343 } from "./formations/myteam/343";
import { MyTeam352 } from "./formations/myteam/352";
import { MyTeam4321 } from "./formations/myteam/4321";
import { MyTeam433 } from "./formations/myteam/433";
import { MyTeam4411 } from "./formations/myteam/4411";
import { MyTeam442 } from "./formations/myteam/442";
import { MyTeam442Diamond } from "./formations/myteam/442Diamond";
import { MyTeam451 } from "./formations/myteam/451";
import { MyTeam541 } from "./formations/myteam/541";
import { useState } from "react";

const MyTeams = () => {
  const { data: gameData } = useSelector((state: RootState) => state.gameData);
  const [showError, setShowError] = useState(false);
  const { data: appConfig } = useSelector(
    (state: RootState) => state.appConfig
  );
  const navigate = useNavigate();

  const renderTeam = (team: Team) => {
    let tactic = gameData.tactics![team.tacticId];
    switch (tactic.desc) {
      case FORMATION_343:
        return <MyTeam343 team={team} />;
      case FORMATION_352:
        return <MyTeam352 team={team} />;
      case FORMATION_433:
        return <MyTeam433 team={team} />;
      case FORMATION_442:
        return <MyTeam442 team={team} />;
      case FORMATION_442_DIAMOND:
        return <MyTeam442Diamond team={team} />;
      case FORMATION_451:
        return <MyTeam451 team={team} />;
      case FORMATION_532:
        return <MyTeam352 team={team} />;
      case FORMATION_541:
        return <MyTeam541 team={team} />;
      case FORMATION_3142:
        return <MyTeam3142 team={team} />;
      case FORMATION_4321:
        return <MyTeam4321 team={team} />;
      case FORMATION_4411:
        return <MyTeam4411 team={team} />;
      default:
        return <MyTeam442 team={team} />;
    }
  };

  // TODO - show error dialog when creating > max teams
  const addNewTeam = () => {
    // We limit users to 5 teams max - no need to create more (this is just for performance reasons and
    // also do users really need 5 teams).
    if (gameData.userTeams != null) {
      let numTeams = Object.keys(gameData.userTeams).length;
      if (numTeams == appConfig.maxTeams) {
        setShowError(true);
        return;
      }
    }

    navigate(TEAMSETUP_ROUTE);
  };

  const handleClose = () => {
    setShowError(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert variant="filled" onClose={handleClose} severity="error">
          You can only create {appConfig.maxTeams} teams.
        </Alert>
      </Snackbar>
      <Header />
      <div id="layoutSidenav">
        <NavBar selectedMenuItem={MYTEAMS_ROUTE} />
        <div id="layoutSidenav_content">
          <main>
            <div className="container-fluid">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  margin: "10px 20px",
                }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  src={"/images/teams-icon.svg"}
                  width="42"
                  height="42"
                />
                My Teams (
                {gameData.userTeams != null
                  ? Object.keys(gameData.userTeams).length
                  : 0}
                )&nbsp;
                <Button
                  className="green-btn"
                  variant="contained"
                  size="small"
                  style={{ display: "flex", marginLeft: "5px" }}
                  startIcon={<AddIcon />}
                  onClick={addNewTeam}
                >
                  Add
                </Button>
              </div>
              <div className="row2">
                <div
                  className="col-xxl-12 col-xl-12 col-md-12"
                  style={{
                    width: "100%",
                    margin: "0 0px",
                    overflow: "auto",
                    padding: "0 10px",
                  }}
                >
                  <div className="row2">
                    {!gameData.userTeams && <div>No teams</div>}
                    {gameData.userTeams &&
                      Object.entries(gameData.userTeams).map(([key, value]) =>
                        renderTeam(value)
                      )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default MyTeams;
