import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { useUAL } from "../../utils/useUAL";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchVintyBalance,
  fetchWaxBalance,
  sendTokens,
} from "../../store/actions/userSession";
import { VINTY } from "../../utils/constants";

type SendTokensProps = {
  account: string;
  showDialog: boolean;
  onSend(): void;
};

export const SendTokens: FC<SendTokensProps> = ({
  account,
  showDialog,
  onSend,
}) => {
  const amountField = useRef<HTMLInputElement>();
  const [amount, setAmount] = useState("");
  const [tokenType, setTokenType] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch<any>();
  const ual = useUAL() as any;
  const { data: tranResult } = useSelector(
    (state: RootState) => state.sendTokensResult
  );
  const { data: user } = useSelector((state: RootState) => state.userSession);
  const [status, setStatus] = useState("");

  // Once card has been burned we can upgrade the player
  useEffect(() => {
    if (tranResult.transaction_id != null) {
      if (tranResult.transaction_id == "ERROR") {
        setStatus("ERROR");
      } else {
        setStatus("GOOD");
      }
    }
  }, [tranResult.transaction_id]);

  const tokenTypeSelected = (event: SelectChangeEvent) => {
    if (event.target.value != null) {
      setTokenType(event.target.value.toString());
    }
  };

  const sendTokensClick = () => {
    setSubmitted(true);

    dispatch(
      sendTokens(
        ual["activeUser"],
        account,
        amount,
        tokenType != "" ? tokenType : VINTY,
        "From VN xxx"
      )
    );
  };

  const onClose = () => {
    dispatch(fetchWaxBalance(user.account));
    dispatch(fetchVintyBalance(user.account));
    onSend();
    setSubmitted(false);
  };

  const renderTransactionStatus = () => {
    if (status == "GOOD") {
      return (
        <>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DoneIcon color="secondary" sx={{ fontSize: "100px" }} />
            <span>Sent, nice!</span>
          </div>
          <Button className="green-btn" onClick={onClose}>
            OK
          </Button>
        </>
      );
    }
    if (status == "ERROR") {
      return (
        <>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon color="error" sx={{ fontSize: "100px" }} />
            <span>Doh! Something is up.</span>
          </div>
          <Button className="green-btn" onClick={onClose}>
            OK
          </Button>
        </>
      );
    }
    return <CircularProgress color="secondary" />;
  };

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Fade in={submitted}>
          <Collapse in={submitted}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "300px",
                height: "130px",
              }}
            >
              {renderTransactionStatus()}
            </div>
          </Collapse>
        </Fade>
        {!submitted && (
          <div
            style={{
              display: "flex",
              width: "300px",
              height: "130px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span>To: {account}</span>
            <div style={{ display: "flex", gap: "5px" }}>
              <TextField
                inputRef={amountField}
                value={amount}
                sx={{ flex: "1" }}
                onChange={(e) => setAmount(e.target.value)}
                size="small"
                label="Amount"
                variant="outlined"
              />
              <FormControl>
                <InputLabel size="small">Token</InputLabel>
                <Select
                  onChange={tokenTypeSelected}
                  label="Token"
                  size="small"
                  defaultValue={VINTY}
                  sx={{
                    width: "100px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #99ff32",
                    },
                    "&:hover": {
                      "&& fieldset": {
                        border: "1px solid #99ff32",
                      },
                    },
                  }}
                >
                  <MenuItem value="WAX">WAX</MenuItem>
                  <MenuItem value="VNTY">VNTY</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "20px",
                marginTop: "5px",
              }}
            >
              <Button className="blue-btn" onClick={onClose}>
                Cancel
              </Button>
              <Button className="green-btn" onClick={sendTokensClick}>
                SEND
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
