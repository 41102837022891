import { SchemaInfo } from '../../types/SchemaInfo';
import { ACTIVE_SCHEMAS, COLLECTION_NAME, EMOTION, ENFORCERS, PROMOPLAYERS, SERIES1, SERIES2, SPECIALS, WCLEGENDS1 } from '../../utils/constants';
import * as actionTypes from '../actions/types';
import { AppThunk } from '../configureStore';

export const requestSchemaInfo = () => {
    return{
        type: actionTypes.REQUEST_SCHEMA_INFO,
        error: undefined
    };
};

export const receiveSchemaInfo = (info: SchemaInfo) =>{
  
    return{
        type: actionTypes.RECEIVE_SCHEMA_INFO,
        payload:{
            data: info
        },
        error: undefined,
        isFetchig: false 
    };
};

export const errorSchemaInfo = (error: unknown) =>{
    return{
        type: actionTypes.ERROR_SCHEMA_INFO,
        error: error
    };
};

export const fetchSchemaInfo = (account: string) : AppThunk => async(dispatch, _, api) =>{
    dispatch(requestSchemaInfo())
    try{
        console.log('getting account info for ' + account);
        const accountStats  = await api.atomicAssets.getAccountCollection(account, COLLECTION_NAME);
        if(accountStats.schemas.length > 0){
            const counts : { [name: string] : number;} = {};

            // NOTE: filter on schemas we're interested in.
            accountStats.schemas.filter((s)=>{
                return ACTIVE_SCHEMAS.includes(s.schema_name.toString());
                // switch(s.schema_name.toString()){
                //     case SERIES1:
                //     case SERIES2:
                //     case EMOTION:
                //     case ENFORCERS:
                //     case PROMOPLAYERS:
                //     case WCLEGENDS1:
                //     case SPECIALS:
                //         return true;
                // }
                //return false;
                //return s.schema_name.toString() == SERIES1 || s.schema_name.toString() == EMOTION || s.schema_name.toString() == PROMOPLAYERS || s.schema_name.toString() == ENFORCERS || s.schema_name.toString() == SERIES2 || s.schema_name.toString() == WCLEGENDS1;
            }).forEach(schema => {
                counts[schema.schema_name.toString()] = +schema.assets;
            });
            const schemaInfo : SchemaInfo = {
                schemaCounts: counts
            }
            console.log(counts);
            dispatch(receiveSchemaInfo(schemaInfo));
        }
        else{
            const schemaInfo : SchemaInfo = {
                schemaCounts: {}
            }
            dispatch(receiveSchemaInfo(schemaInfo));
            return;
        }
    }
    catch(e){
        console.log(e);
        dispatch(errorSchemaInfo(e));
    }
}